import { useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { PiArrowLeft } from "react-icons/pi";

import * as Variable from "../../../shared/utils/variables";

import JobPref from "./JobPref";
import ResumePref from "./ResumePref";

function JobResumePref({ ENV_NAME, userId, onPrevStep, onNextStep, onComplete, onSkip }) {
	const FORMS = [Variable.JOB_PREF[ENV_NAME], Variable.RESUME_PREF[ENV_NAME]];
	const [currForm, setCurrForm] = useState(1);

	const handleBack = () => {
		if (currForm === 1) onPrevStep();
		if (currForm > 1) setCurrForm((prev) => prev - 1);
	};

	const handleNext = () => currForm < FORMS.length && setCurrForm((prev) => prev + 1);

	return (
		<div className="about-me">
			<div className="header">
				<PiArrowLeft
					color="#667085"
					className="cursorPointer icon-back"
					onClick={handleBack}
				/>
				<ProgressBar
					completed={(currForm * 100) / FORMS.length}
					height="10px"
					bgColor="#193560"
					baseBgColor="#f2f4f7"
					isLabelVisible={false}
				/>

				<h2>{FORMS[currForm - 1]}</h2>
			</div>
			<section>
				{currForm === 1 && (
					<JobPref
						ENV_NAME={ENV_NAME}
						userId={userId}
						onNext={handleNext}
						onSkip={onSkip}
					/>
				)}
				{currForm === 2 && (
					<ResumePref
						ENV_NAME={ENV_NAME}
						userId={userId}
						onComplete={onComplete}
						onSkip={onSkip}
					/>
				)}
			</section>
		</div>
	);
}

export default JobResumePref;
