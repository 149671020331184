import { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import cookies from "react-cookies";

import useToast from "shared/hooks/useToast";
import * as ActionTypes from "../../store/actions/actionTypes";
import * as RoutePath from "../../shared/utils/routeLink";
import ResetPasswordSuccess from "../ResetPasswordComponent/ResetPasswordSuccess";
import {
	LOGIN,
	SOMETHING_WENT_WRONG_TRY_AGAIN,
	VERIFIED,
	VERIFY_EMAIL_DESC,
} from "../../shared/utils/variables";
import makeRequest from "../../shared/utils/request";
import { generateRequestOptions, homeUrl } from "../../shared/utils/apiEndPoints";
import LoadingComponent from "../../shared/component/LoadingComponent/LoadingComponent";
import { useHistory, withRouter } from "react-router";
import { USER_TYPES } from "shared/constants/constants";
import { toggleLoginModalShow } from "store/actions/profileActions";

function EmailVerified({ ...props }) {
	const toast = useToast();
	const dispatch = useDispatch();

	const { ENV_NAME } = useSelector((state) => ({
		ENV_NAME: state.auth.selectedEnvironment,
	}));
	const [isLoading, setIsLoading] = useState(true);
	const [isActivated, setIsActivated] = useState(false);
	const history = useHistory();
	const URLParams = new URLSearchParams(history.location.search);
	const company = URLParams.get("company");
	const user_id = URLParams.get("user_id");
	const user_type = URLParams.get("user_type");
	const timestamp = URLParams.get("timestamp");
	const signature = URLParams.get("signature");
	const employee_id = URLParams.get("employee_id");
	const email = URLParams.get("email");

	useEffect(() => {
		if (!!company) {
			history.push(
				`${RoutePath.ACTIVATE_ACCOUNT}?user_id=${user_id}&company=${company}&user_type=${user_type}&signature=${signature}&timestamp=${timestamp}&employee_id=${employee_id}&email=${email}`,
			);
			return;
		}
		activateAccount();
	}, []);

	const activateAccount = async () => {
		const URLParams = new URLSearchParams(history.location.search);
		const user_id = URLParams.get("user_id");
		const user_type = URLParams.get("user_type");
		const timestamp = URLParams.get("timestamp");
		const signature = URLParams.get("signature");
		const employee_id = URLParams.get("employee_id");

		const res = await makeRequest({
			...generateRequestOptions("activateAccount"),
			data: {
				user_id,
				user_type,
				timestamp,
				signature,
				...(!!employee_id && { employee_id: employee_id }),
			},
		});

		if (res.code === 200) {
			setIsLoading(false);
			setIsActivated(true);
			if (user_type === USER_TYPES.JOB_MARKET_SUPER_ADMIN || USER_TYPES.JOB_MARKET_ADMIN) {
				cookies.save(
					"verify_company",
					{
						user_type,
						user_id,
						timestamp,
						signature,
						...(!!employee_id && { employee_id: employee_id }),
					},
					{
						domain: homeUrl,
						path: "/",
						expires: new Date("9999-12-31T12:00:00.000Z"),
					},
				);
			}
		} else {
			toast.error(res.message || SOMETHING_WENT_WRONG_TRY_AGAIN[ENV_NAME]);
			setIsLoading(false);
			setIsActivated(false);
			// dispatch(toggleLoginModalShow(true));
		}
	};

	return (
		<div>
			{isLoading ? (
				<LoadingComponent />
			) : !isActivated ? (
				""
			) : (
				<ResetPasswordSuccess
					title={VERIFIED[ENV_NAME]}
					description={VERIFY_EMAIL_DESC[ENV_NAME]}
					btnTitle={LOGIN[ENV_NAME]}
					onClick={() => {
						props.setLoginModalShow(true);
						if (!cookies.load("verify_company")) {
							props.history.push(RoutePath.ROOT);
						}
					}}
				/>
			)}
		</div>
	);
}

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {
		setLoginModalShow: (payload = true) => {
			if (payload) {
				dispatch({
					type: ActionTypes.OPEN_LOGIN_MODAL,
				});
			} else {
				dispatch({
					type: ActionTypes.CLOSE_LOGIN_MODAL,
				});
			}
		},
	};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(EmailVerified));
