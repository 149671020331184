import { useHistory } from "react-router-dom";

import * as Variable from "../../shared/utils/variables";
import * as RoutePath from "../../shared/utils/routeLink";
import Image from "../../shared/component/UI/Image";

import GreenCheck from "../../assets/svg/green-check.svg";
import Button from "../../shared/component/ButtonComponent/Button";
import Countdown from "../../shared/component/CountdownComponent/Countdown";
import { useState, useEffect } from "react";

function ResetPasswordSuccess({
	ENV_NAME,
	icon,
	title,
	description,
	btnTitle,
	btnWithTimer,
	initialTimer,
	onClick,
}) {
	const history = useHistory();
	const [timerEnabled, setTimerEnabled] = useState();

	useEffect(() => {
		const timerState = localStorage.getItem("timerStateResetPasswordSuccess");
		if (!!timerState) {
			localStorage.setItem("timerStateResetPasswordSuccess", JSON.stringify(true));
			setTimerEnabled(true);
		} else {
			setTimerEnabled(JSON.parse(timerState));
		}
	}, []);

	return (
		<div className="rpc-success">
			<Image src={icon || GreenCheck} />

			<div className="rpc-success-desc">
				<h3>{title || Variable.PASSWORD_RESET[ENV_NAME]}</h3>
				<p
					dangerouslySetInnerHTML={{
						__html: description || Variable.PASSWORD_RESET_DESC[ENV_NAME],
					}}
				></p>
			</div>
			{btnWithTimer ? (
				<Button
					type="primary"
					title={
						timerEnabled ? (
							<Countdown
								ENV_NAME={ENV_NAME}
								init={initialTimer}
								disableTimer={() => setTimerEnabled(false)}
								timerEnabled={timerEnabled}
								localStorageName="ResetPasswordSuccess"
							/>
						) : (
							Variable.RESEND[ENV_NAME]
						)
					}
					size="sm"
					btnClassName="w-100 flex-grow-1"
					className="flex-grow-1 continue-btn"
					onClick={() => {
						onClick();
						setTimerEnabled(true);
					}}
					disabled={timerEnabled}
				></Button>
			) : (
				<Button
					type="primary"
					title={btnTitle || Variable.CONTINUE[ENV_NAME]}
					size="sm"
					btnClassName="w-100 flex-grow-1"
					className="flex-grow-1 continue-btn"
					onClick={() =>
						typeof onClick === "function" ? onClick() : history.push(RoutePath.ROOT)
					}
				></Button>
			)}
		</div>
	);
}

export default ResetPasswordSuccess;
