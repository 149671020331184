import { useEffect, useState } from "react";

import useToast from "shared/hooks/useToast";
import * as Variable from "../../shared/utils/variables";
import Image from "../../shared/component/UI/Image";
import Input from "../../shared/component/InputComponent/Input";
import Button from "../../shared/component/ButtonComponent/Button";
import makeRequest from "../../shared/utils/request";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import { isValueAllowed } from "shared/utils/numberUtils";

import "./ApplyJobModal.scss";

function ContactInfoForm({ ENV_NAME, jobAppId, userDetails, onFetchJobs, ...props }) {
	const toast = useToast();

	const [form, setForm] = useState({
		email: "",
		first_name: "",
		last_name: "",
		profile_picture: "",
		current_company: "",
		current_job_title: "",
		mobile_phone_number: "",
		mobile_phone_number_2: "",
	});
	const [isEdited, setIsEdited] = useState(false);
	const [error, setError] = useState(false);

	useEffect(() => {
		jobAppId && retrieveFormData();
	}, [jobAppId]);

	useEffect(() => {
		if (userDetails) {
			setForm((p) => ({
				...p,
				profile_picture: userDetails?.profile_picture,
			}));
		}
	}, [userDetails]);

	const retrieveFormData = async () => {
		const res = await makeRequest(
			generateRequestOptions("fetchContactDetails", {
				urlParams: jobAppId,
			}),
		);

		if (res.code === 200) setForm((p) => ({ ...p, ...res.data }));
		else toast.error(res.message);
	};

	const validateAndSave = async () => {
		if (!isEdited) return props.onUpdateActiveForm(2);

		const { mobile_phone_number, mobile_phone_number_2 } = form;
		if (!mobile_phone_number) return setError(true);

		const data = {
			mobile_phone_number,
			mobile_phone_number_2: mobile_phone_number_2 || null,
		};

		const res = await makeRequest({
			...generateRequestOptions("updateContactDetail", { urlParams: jobAppId }),
			body: data,
		});

		if (res.code === 200) {
			props.onUpdateActiveForm(2);
			resetState();
			setIsEdited(false);
			setError(false);
		} else toast.error(res.message);
	};

	const onChange = (e, type = null) => {
		const { name, value } = e.target;

		if (type === "phone") {
			setForm((prev) => ({
				...prev,
				[name]: isValueAllowed(value, type) ? value : prev[name],
			}));
		} else
			setForm((prev) => ({
				...prev,
				[name]: value,
			}));
		setIsEdited(true);
		setError(false);
	};

	const resetState = () =>
		setForm({
			email: "",
			first_name: "",
			last_name: "",
			profile_picture: "",
			current_company: "",
			current_job_title: "",
			phone: "",
			alternate: "",
		});

	return (
		<div className="form-container">
			<div className="contact-form">
				<div className="header">
					<Image src={form?.profile_picture} className="avatar" />
					<div className="userDetails">
						<p className="name">{`${form.first_name} ${form?.last_name}`}</p>
						<p className="email">{form?.email}</p>
						<p className="company-role">
							{form?.current_company} {form?.current_job_title ? <span>|</span> : ""}{" "}
							{form?.current_job_title}
						</p>
					</div>
				</div>
				<div className="body">
					<Input
						label={Variable.MOBILE_NUMBER_1[ENV_NAME]}
						placeholder={Variable.MOBILE_NUMBER_1[ENV_NAME]}
						value={form?.mobile_phone_number || ""}
						name="mobile_phone_number"
						onChange={(e) => onChange(e, "phone")}
						inputType="label"
						className="w-100"
						required
						isError={error}
					/>
					<Input
						label={Variable.ALTERNATE_MOBILE_NUMBER[ENV_NAME]}
						placeholder={Variable.ALTERNATE_MOBILE_NUMBER[ENV_NAME]}
						value={form?.mobile_phone_number_2 || ""}
						name="mobile_phone_number_2"
						onChange={(e) => onChange(e, "phone")}
						inputType="label"
						className="w-100"
					/>
				</div>
				<div className="footer">
					<p>{Variable.UPDATE_CONTACT_WARN[ENV_NAME]}</p>
				</div>
			</div>
			<div className="btn-grp">
				<div className="flex-all-center gap-md w-100">
					<Button
						type="outline"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={() => {
							props.onHide();
							onFetchJobs();
							resetState();
						}}
						title={Variable.CLOSE[ENV_NAME]}
					/>
					<Button
						type="primary"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={validateAndSave}
						title={Variable.NEXT[ENV_NAME]}
						disabled={!form?.mobile_phone_number}
					/>
				</div>
			</div>
		</div>
	);
}

export default ContactInfoForm;
