import { nFormatter } from "./string";

const currencyFormatter = (currency) =>
	new Intl.NumberFormat("id-ID", {
		style: "currency",
		currency: "IDR",
		maximumFractionDigits: 0,
	}).format(currency);

const salaryDisplayMode = (salary: number, mode: string, ENV: string) =>
    
	mode === "short" ? nFormatter(salary, 0, ENV) : currencyFormatter(salary);

export const salaryRangeDesc = (el, ENV = "bhs", mode = "") => {
	switch (el?.salary_type) {
		case "range_amount":
			return [
				salaryDisplayMode(el?.salary_starting_amount, mode, ENV),
				salaryDisplayMode(el?.salary_maximum_amount, mode, ENV),
			]
				.filter(Boolean)
				.join(" - ");
		case "exact_amount":
			return salaryDisplayMode(el?.salary_amount, mode, ENV);
		case "starting_amount":
			return salaryDisplayMode(el?.salary_starting_amount, mode, ENV);
		case "maximum_amount":
			return salaryDisplayMode(el?.salary_maximum_amount, mode, ENV);
		default:
			return "-";
	}
};
