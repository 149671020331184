import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { isEmpty, isEqual } from "lodash";

import useToast from "shared/hooks/useToast";
import * as Variable from "../../../shared/utils/variables";
import makeRequest from "../../../shared/utils/request";
import { generateRequestOptions } from "../../../shared/utils/apiEndPoints";
import Button from "../../../shared/component/ButtonComponent/Button";
import Image from "../../../shared/component/UI/Image";
import ModalInfo from "../../../shared/component/ModalComponent/ModalInfo";
import FileCard from "../../../shared/component/CardComponent/FileCard";
import { getIconByName } from "../../../shared/utils/file";
import useUserDetails from "../../../shared/hooks/userDetails";

import uploadImage from "../../../assets/svg/upload_image.svg";
import RemoveMediaIcon from "../../../assets/image/remove-media.png";
import WarningIcon from "../../../assets/svg/red_warning.svg";
import { formatString } from "shared/utils/string";

const PortfolioResumeFile = ({
	ENV_NAME,
	userId,
	onNext,
	onNextStep,
	onComplete,
	onSkip,
	isResume,
}) => {
	const toast = useToast();
	const userDetails = useUserDetails(userId);
	const [templateFile, setTemplateFile] = useState(null);
	const [showConfirm, setShowConfirm] = useState(false);
	const [showSkip, setShowSkip] = useState(false);
	const [isUploading, setIsUploading] = useState(false);

	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		accept: {
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
			"application/msword": [".doc"],
			"application/rtf": [".rtf"],
			"application/pdf": [".pdf"],
		},
		multiple: false,
		maxFiles: 1,
		maxSize: 20000000,
		onDrop: (acceptedFiles, fileRejections) => onFileReject(acceptedFiles, fileRejections),
	});

	useEffect(() => {
		if (userDetails) {
			const { resume, portfolio } = userDetails;
			let fileObj = {};
			if (isResume && resume?.resume_file)
				fileObj = {
					id: resume?.id,
					file_name: resume?.file_name,
					resume_file: resume?.resume_file,
					isResumeSaved: true,
				};
			else if (portfolio?.length)
				fileObj = {
					id: portfolio[0]?.id,
					file_name: portfolio[0]?.file_name,
					portfolio_file: portfolio[0]?.portfolio_file,
					isPortfolioSaved: true,
				};

			setTemplateFile(fileObj);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userDetails]);

	useEffect(() => {
		if (acceptedFiles?.length > 0 && !isEqual(acceptedFiles, templateFile)) {
			setTemplateFile({
				file_name: acceptedFiles[0]?.name,
				...(isResume
					? { resume_file: acceptedFiles[0] }
					: { portfolio_file: acceptedFiles[0] }),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [acceptedFiles]);

	// Function to call if the file the selected file doesn't fulfill the criteria
	const onFileReject = (acceptedFiles, fileRejections) => {
		if (fileRejections.length > 0) {
			fileRejections?.[0]?.errors.forEach((i) => {
				if (i.code === "file-invalid-type" || i.code === "file-too-large") {
					setTemplateFile(null);
					toast.error(Variable.UNSUPPORTED_FILE[ENV_NAME]);
				}
			});
			return;
		}
	};

	const handleSave = async () => {
		if (isEmpty(templateFile)) return;

		setIsUploading(true);
		const formData = new FormData();
		if (isResume) formData.append("resume_file", templateFile?.resume_file);
		else formData.append("portfolio_file", templateFile?.portfolio_file);

		const res = await makeRequest({
			...generateRequestOptions(isResume ? "updateResumePref" : "createPortfolio"),
			data: formData,
		});

		if (res.code === 200) {
			setTemplateFile((prev) => ({
				...prev,
				...(isResume ? { isResumeSaved: true } : { isPortfolioSaved: true }),
				id: res.data.id,
			}));
			toast.success(
				isResume
					? Variable.RESUME_UPLOADED[ENV_NAME]
					: Variable.PORTFOLIO_UPLOADED[ENV_NAME],
			);
		} else toast.error(res.message);
		setIsUploading(false);
	};

	const handleDeletePortfolio = async () => {
		console.log("delete portfolio");
		const fileId = templateFile?.id;

		if (fileId) {
			let url = generateRequestOptions("deletePortfolio", {
				urlParams: fileId,
			});

			if (isResume) url = generateRequestOptions("deleteOtherResume");

			const res = await makeRequest({
				...url,
			});

			if (res.code === 200) setTemplateFile(null);
			else toast.error(res.message);
		} else setTemplateFile(null);

		setShowConfirm(false);
	};

	return (
		<>
			<div className="portfolio-resume">
				{!(isResume ? templateFile?.resume_file : templateFile?.portfolio_file) ? (
					<div className="drop-area" {...getRootProps()}>
						<input {...getInputProps()} />
						<Image src={uploadImage} className="placeholder-image" />
						<p>
							{Variable.DRAG_FILE_LABEL[ENV_NAME]}{" "}
							<strong className="choose-file">
								{Variable.CHOOSE_FILE[ENV_NAME]}
							</strong>{" "}
						</p>
						<strong>
							{formatString(
								Variable.FILE_FORMAT_SIZE_2[ENV_NAME],
								" DOC, DOCX, TXT, RTF",
								"PDF",
								20,
							)}
						</strong>
					</div>
				) : (
					<FileCard
						title={templateFile?.file_name}
						icon={getIconByName(templateFile?.file_name)}
						onDelete={() => setShowConfirm(true)}
						className="mb-3 fileCard"
						showGreenCheck={false}
						isUploading={isUploading}
					/>
				)}
			</div>

			{/* Delete Modal */}
			<ModalInfo
				isShow={showConfirm}
				onHide={() => setShowConfirm(false)}
				onConfirm={handleDeletePortfolio}
				type="delete"
				customIcon={<Image src={RemoveMediaIcon} className="modal-image-icon" />}
			/>

			{/* Skip Modal */}
			<ModalInfo
				isShow={showSkip}
				onHide={() => setShowSkip(false)}
				onConfirm={() => {
					onSkip();
					isResume ? onNext() : onNextStep();
				}}
				type="other"
				customIcon={<Image src={WarningIcon} className="modal-image-icon" />}
				title={Variable.SURE_TO_SKIP[ENV_NAME]}
				description={
					formatString(
						Variable.SKIP_DESC[ENV_NAME],
						Variable.PORTFOLIO_RESUME[ENV_NAME].toLowerCase(),
					) +
					" " +
					Variable.HISTORY[ENV_NAME]
				}
				confirmLabel={Variable.SKIP_BTN_TITLE[ENV_NAME]}
			/>

			<div className="d-flex align-items-center">
				<Button
					type="outline"
					size="md"
					title={Variable.SKIP_STEP[ENV_NAME]}
					className="mt-5 me-4"
					btnClassName="btn-initial-profile"
					onClick={() => setShowSkip(true)}
				/>
				<Button
					type="primary"
					size="sm"
					title={
						(isResume ? templateFile?.isResumeSaved : templateFile?.isPortfolioSaved)
							? Variable.NEXT_STEP[ENV_NAME]
							: Variable.SAVE[ENV_NAME]
					}
					btnClassName="btn-initial-profile"
					className="mt-5"
					onClick={() => {
						if (isResume) {
							if (templateFile?.isResumeSaved) {
								onComplete();
								onNext();
							} else handleSave();
						} else {
							if (templateFile?.isPortfolioSaved) {
								onNext();
							} else handleSave();
						}
					}}
					disabled={
						isEmpty(templateFile) ||
						isUploading ||
						(isResume && !templateFile.resume_file)
					}
				/>
			</div>
		</>
	);
};

export default PortfolioResumeFile;
