import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import * as BSIcons from "react-icons/bs";
import { Anchor } from "react-bootstrap";
import cookies from "react-cookies";

import * as Variable from "shared/utils/variables";
import * as RoutePath from "shared/utils/routeLink";
import { classHelper } from "shared/utils/stringUtils";
import Button from "shared/component/ButtonComponent/Button";
import Image from "shared/component/UI/Image";
import { CaretDown } from "shared/component/svg/Icons";
import { homeUrl } from "shared/utils/apiEndPoints";
import homepageIcon from "assets/svg/homepage.svg";
import useUserDetails from "shared/hooks/userDetails";
import { ReactComponent as HAccUserBlank } from "assets/svg/h-acc-notification-user.svg";
import IdIcon from "assets/icons/indo.png";
import EnIcon from "assets/icons/eng.png";
import { ENV } from "component/JobDetail/types";

import "./HeaderMobileComponent.scss";
import { MENU_ENUM } from "./HeaderComponent";

interface IHeaderMobileComponent extends RouteComponentProps {
	isAuthenticated?: boolean;
	ENV_NAME: "eng" | "bhs";
	visible?: boolean;
	userDetails?: any;
	active?: string;
	currency?: {
		name: string;
		id: string;
		locale: string;
	};
	changeCurr?: (currency: IHeaderMobileComponent["currency"]) => any;
	onClose?: () => any;
	onLoginClick?: () => any;
	onSignUpClick?: () => any;
	handleLogout?: () => any;
	changeLang?: (selectedLang: ENV) => any;
	onSwitchSite?: () => any;
}

interface IDropDownComponent extends React.PropsWithChildren {
	openDropdown?: string;
	dropdownName: string;
	leftIcon?: React.ReactNode;
	title: React.ReactNode;
	className?: string;
	toggleDropDown: (name: string) => any;
}

const ProfilePicture = ({ userId }: { userId: string }) => {
	const userDetail: any = useUserDetails(userId);

	if (!userDetail?.personal_info?.profile_picture) return <HAccUserBlank />;
	return <Image className="profile-image" src={userDetail.personal_info.profile_picture} />;
};

const DropDownComponent = ({ toggleDropDown, leftIcon, ...props }: IDropDownComponent) => (
	<div
		className={classHelper(
			"cursorPointer dropdown-comp",
			props.openDropdown === props.dropdownName && "active",
			props.className,
		)}
		onClick={() => toggleDropDown(props.dropdownName)}
	>
		<div className="d-flex flex-row align-items-center">
			{leftIcon && <div className="left-icon">{leftIcon}</div>}
			<div className="d-flex flex-grow-1 flex-row align-items-center justify-content-between">
				<h5 className="mb-0 dropdown-title">{props.title}</h5>
				<CaretDown />
			</div>
		</div>

		<div className="dropdown-item-container">{props.children}</div>
	</div>
);

const HeaderMobileComponent = (props: IHeaderMobileComponent) => {
	const [openDropdown, setOpenDropdown] = useState<string>("");

	function disableScroll() {
		const scrollTop = document.documentElement.scrollTop;
		const scrollLeft = document.documentElement.scrollLeft;

		window.onscroll = function () {
			window.scrollTo({ left: scrollLeft, top: scrollTop, behavior: "instant" });
		};
	}

	function enableScroll() {
		window.onscroll = function () {};
	}

	const goToHomepage = () => {
		props.history.push(RoutePath.ROOT);
	};

	useEffect(() => {
		if (props.visible) {
			disableScroll();
		} else {
			enableScroll();
		}

		return () => {
			enableScroll();
		};
	}, [props.visible]);

	const toggleDropDown = (name: string) => setOpenDropdown(openDropdown === name ? "" : name);

	const LoginSignupButton = (
		<div className="login-signup-container">
			<Button
				type="outline"
				size="md"
				className="flex-grow-1"
				btnClassName="w-100"
				onClick={props.onLoginClick}
				title={Variable.LOGIN[props.ENV_NAME]}
			/>
			<Button
				type="primary"
				size="md"
				className="flex-grow-1"
				btnClassName="w-100"
				onClick={props.onSignUpClick}
				title={Variable.REGISTER[props.ENV_NAME]}
			/>
		</div>
	);

	const ProfileDropdown = (
		<DropDownComponent
			toggleDropDown={toggleDropDown}
			openDropdown={openDropdown}
			dropdownName="profile"
			leftIcon={
				props.userDetails?.id ? (
					<ProfilePicture userId={props.userDetails?.id} />
				) : (
					<HAccUserBlank />
				)
			}
			title={`${props.userDetails?.first_name || ""} ${props.userDetails?.last_name || ""}`}
		>
			<Anchor href={"https://myjob." + homeUrl + "/profil"} className="dropdown-item">
				{Variable.VIEW_PROFILE[props.ENV_NAME]}
			</Anchor>
			<button className="dropdown-item">{Variable.MY_JOBS[props.ENV_NAME]}</button>
			<button className="dropdown-item">{Variable.ACCOUNT_SETTINGS[props.ENV_NAME]}</button>
			<button className="dropdown-item">{Variable.HELP[props.ENV_NAME]}</button>
		</DropDownComponent>
	);

	const LanguageDropdown = (
		<DropDownComponent
			toggleDropDown={toggleDropDown}
			openDropdown={openDropdown}
			dropdownName="language"
			className="dropdown language-dropdown"
			leftIcon={
				<Image
					className="hn-dropdown-item-icon lang-logo"
					src={props.ENV_NAME === "bhs" ? IdIcon : EnIcon}
					alt="logo"
				/>
			}
			title={props.ENV_NAME === "bhs" ? "Bahasa Indonesia" : "English"}
		>
			<button
				className={`dropdown-item ${props.ENV_NAME === "bhs" ? "active" : ""}`}
				onClick={() => props.changeLang?.("bhs")}
			>
				<BSIcons.BsCheckLg />
				<div className="caption">Bahasa Indonesia</div>
			</button>
			<button
				className={`dropdown-item ${props.ENV_NAME === "eng" ? "active" : ""}`}
				onClick={() => props.changeLang?.("eng")}
			>
				<BSIcons.BsCheckLg />
				<div className="caption">English</div>
			</button>
		</DropDownComponent>
	);

	const CurrencyDropdown = (
		<DropDownComponent
			toggleDropDown={toggleDropDown}
			openDropdown={openDropdown}
			dropdownName="currency"
			className="dropdown"
			title={props.currency?.name}
		>
			{Variable.CURRENCY_LIST?.map((i) => (
				<button
					className={`dropdown-item ${
						// 'idr' === i?.id ? "active" : ""
						props.currency?.id === i?.id ? "active" : ""
					}`}
					onClick={() => props.changeCurr?.(i)}
					key={i?.id}
				>
					<BSIcons.BsCheckLg />
					<div className="caption">{i?.name}</div>
				</button>
			))}
		</DropDownComponent>
	);

	return (
		<div className={classHelper("hcm hcm-container", props.visible && "visible")}>
			<div className="container hn">
				<div className="hn-left">
					<Link to={RoutePath.ROOT} onClick={props.onClose}>
						<Image
							src={homepageIcon}
							onClick={() => goToHomepage()}
							className="cursorPointer hn-left-img"
						/>
					</Link>
				</div>
				<div className="hn-right" onClick={props.onClose}>
					<BSIcons.BsXLg size="2.4rem" />
				</div>
			</div>
			<div className="main-menu">
				{props.isAuthenticated ? ProfileDropdown : LoginSignupButton}
				<hr />
				<div className="jobs-company-menu">
					<Link
						to={RoutePath.JOB_SEARCH}
						onClick={props.onClose}
						className={`menu-item mxw-auto ${
							props.active === MENU_ENUM.JOBS ? "active" : ""
						}`}
					>
						{Variable.JOBS_LABEL[props.ENV_NAME]}
					</Link>
					{/* <div className="menu-item mxw-auto">{Variable.COMPANY[props.ENV_NAME]}</div> */}
				</div>
				<hr />
				<div>{LanguageDropdown}</div>
				<hr />
				{/* <div>
          { CurrencyDropdown }
        </div>
        <hr /> */}
			</div>
			{cookies.load("ptkjauthtoken") && (
				<div className="logout-btn">
					<Button
						type="error"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={props.handleLogout}
						title={Variable.LOGOUT[props.ENV_NAME]}
					/>
				</div>
			)}
		</div>
	);
};

export default HeaderMobileComponent;
