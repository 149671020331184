import cookies from "react-cookies";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";

import Image from "shared/component/UI/Image";
import SideContent from "assets/image/side-content.png";
import { Typography } from "shared/component/Typography/Typography";
import Button from "shared/component/ButtonComponent/Button";
import Input from "shared/component/InputComponent/Input";
import useTranslate from "shared/hooks/useTranslate";
import useSearchParams from "shared/hooks/useSearchParams";
import { useHistory } from "react-router";
import { generateRequestOptions, homeUrl } from "shared/utils/apiEndPoints";
import makeRequest from "shared/utils/request";
import { CloseEyeIcon, OpenEyeIcon } from "../../shared/component/svg/Icons";

import { OPEN_LOGIN_MODAL } from "store/actions/actionTypes";
import * as RoutePath from "shared/utils/routeLink";

const ActivateAccount = () => {
	const t = useTranslate();

	const [isError, setIsError] = useState(false);

	const [isShowPassword, setIsShowPassword] = useState(false);

	const { getParam } = useSearchParams();

	const [form, setForm] = useState({
		password: "",
	});

	const history = useHistory();

	const [isLoading, setIsLoading] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		localStorage.clear();
		cookies.remove("ptkjauthtoken", { path: "/", domain: homeUrl });
		cookies.remove("ptkjrefreshtoken", { path: "/", domain: homeUrl });
		cookies.save("JobSeeker", { path: "/", domain: homeUrl });
	}, []);

	const validationPasswordRegex = (text: string) =>
		new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/).test(text);

	const activateAccount = async () => {
		const URLParams = new URLSearchParams(history.location.search);
		const user_id = URLParams.get("user_id");
		const user_type = URLParams.get("user_type");
		const timestamp = URLParams.get("timestamp");
		const signature = URLParams.get("signature");
		const employee_id = URLParams.get("employee_id");

		const res = await makeRequest({
			...generateRequestOptions("activateAccount"),
			data: {
				user_id,
				user_type,
				timestamp,
				signature,
				...(!!employee_id && { employee_id: employee_id }),
				password: form.password,
			},
		});

		if (res.code === 200) {
			setIsLoading(false);
			history.push(RoutePath.ROOT);
			dispatch({
				type: OPEN_LOGIN_MODAL,
				payload: true,
			});
		} else {
			toast.error(res.message || t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
			setIsLoading(false);
		}
	};

	return (
		<div
			style={{
				minHeight: "100vh",
				display: "flex",
				alignItems: "stretch",
				flexWrap: "wrap-reverse",
			}}
		>
			<Image
				src={SideContent}
				style={{
					height: "auto",
					width: "100%",
					maxWidth: "600px",
				}}
			/>
			<div
				style={{
					minHeight: "100vh",
					minWidth: "500px",
					flex: 1.5,
					justifyContent: "center",
					display: "flex",
					width: "100%",
				}}
			>
				<div
					style={{
						minHeight: "100vh",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
						gap: "40px",
						maxWidth: "550px",
						width: "100%",
					}}
				>
					<Typography.Title size="sm" fontWeight="semibold">
						{t("ACTIVATE_ACCOUNT")}
					</Typography.Title>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "20px",
							width: "100%",
						}}
					>
						<Input
							label={t("COMPANY")}
							placeholder={t("COMPANY")}
							value={getParam("company") || ""}
							name="company"
							inputType="label"
							className="w-100"
							disabled
						/>
						<Input
							label={t("EMAIL")}
							value={getParam("email") || ""}
							name="email"
							placeholder={t("EMAIL")}
							inputType="label"
							className="w-100"
							disabled
						/>
						<div>
							<div className="position-relative">
								<Input
									label={t("PASSWORD")}
									placeholder={t("PASSWORD")}
									value={form.password}
									name="mobile_phone_number"
									showIcon={false}
									onChange={(e) => {
										if (!validationPasswordRegex(e.target.value)) {
											setIsError(true);
											setForm((prev) => ({
												...prev,
												password: e.target.value,
											}));
											return;
										}

										setIsError(false);
										setForm((prev) => ({ ...prev, password: e.target.value }));
									}}
									inputType={isShowPassword ? "text" : "password"}
									className="w-100"
									required
									isError={isError}
								/>
								<div
									className={`pwd-eye cursorPointer ${
										!isShowPassword ? "active" : ""
									}`}
									style={{
										position: "absolute",
										right: "10px",
										top: "55%",
									}}
									onClick={() => setIsShowPassword((p) => !p)}
								>
									{isShowPassword ? (
										<OpenEyeIcon
											width="1.6rem"
											height="1.6rem"
											stroke="#056cf2"
										/>
									) : (
										<CloseEyeIcon
											width="1.6rem"
											height="1.6rem"
											stroke="#BEC4CF"
										/>
									)}
								</div>
							</div>
							<Typography.Text
								size="sm"
								style={{
									color: isError ? "#F04438" : "#667085",
								}}
							>
								{t("PASSWORD_VALIDATION_MESSAGE")}
							</Typography.Text>
						</div>
					</div>
					<div
						style={{
							alignSelf: "start",
						}}
					>
						<Button
							type="primary"
							size="md"
							onClick={activateAccount}
							isLoading={isLoading}
							disabled={isError}
						>
							{t("SUBMIT_LABEL")}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ActivateAccount;
