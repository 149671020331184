import React from "react";
import cookies from "react-cookies";
import {
	PiBuildings,
	PiBuildingsFill,
	PiSuitcaseSimple,
	PiUserCircleGear,
	PiUsersThree,
	PiUsersThreeFill,
} from "react-icons/pi";

import Image from "shared/component/UI/Image";
import PortalKerja from "assets/logo/portal-kerja.png";
import { Typography } from "shared/component/Typography/Typography";
import CardModule, {
	CardModuleProps,
	moduleIconStyles,
} from "../../shared/component/CardModule/CardModule";
import * as RoutePath from "shared/utils/routeLink";
import "./RoleRegistrationSelector.scss";
import useTranslate from "shared/hooks/useTranslate";
import { homeUrl } from "shared/utils/apiEndPoints";

type ModulesType = Omit<CardModuleProps, "onClick"> & {
	url: string;
};

const RoleRegistrationSelector = () => {
	const t = useTranslate();

	const modules: ModulesType[] = [
		{
			id: 1,
			title: t("CANDIDATE"),
			icon: <PiUsersThreeFill style={moduleIconStyles} />,
			url: `https://${homeUrl}${RoutePath.SIGN_UP}`,
		},
		{
			id: 2,
			title: t("EMPLOYER"),
			icon: <PiBuildingsFill style={moduleIconStyles} />,
			url: `${RoutePath.EMPLOYER_HOME}${RoutePath.SIGN_UP}`,
		},
	];

	const handleRedirect = (url: string) => () => {
		window.open(url, "_self");
	};
	return (
		<div className="modules-select">
			<Image src={PortalKerja} className="modules-select__logo" />
			<Typography.Text size="xl" color="#1D2939" fontWeight="bold">
				{t("SELECT_ROLE_REGISTRATION_TITLE")}
			</Typography.Text>
			<div className="modules-select__module-list-wrapper">
				{modules.map((module) => (
					<CardModule
						title={module.title}
						icon={module.icon}
						key={module.id}
						onClick={handleRedirect(module.url)}
					/>
				))}
			</div>
		</div>
	);
};

export default RoleRegistrationSelector;
