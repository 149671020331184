import { homeUrl } from "./apiEndPoints";

export const ROOT = "/";
export const COLLEGE = "/kampus";
export const MAJOR = "/major";
export const CAREER = "/karir";
export const SCHOLARSHIP = "/beasiswa";
export const FAVORITE = "/favorit";
export const PROFILE = "/profil";
export const CREATE_ACCOUNT = "/buat-akun";
export const FORGOT_PASSWORD = "/lupa-password";
export const COLLEGE_DETAIL = "/detail-kampus";
export const MAJOR_DETAIL = "/detail-jurusan";
export const CAREER_DETAIL = "/detail-karir";
export const SCHOLARSHIP_DETAIL = "/detail-beasiswa";
export const RESET_PASSWORD = "/verify-reset-password";
export const SETUP_PROFILE = "/pengaturan-profil";
export const SIGNUP_VERIFY_EMAIL = "/verify-email";
export const ACTIVATE_ACCOUNT = "/activate-account";

export const DASHBOARD = "/dashboard";
export const TNC = "/tnc";
export const PRIVACY_POLICY = "/privacy-policy";
export const TERMS_OF_USE = "/terms-of-use";

export const RECOMMENDED_JOB = "/recommended-job";
export const POPULAR_JOB = "/popular-job";
export const TRENDING_JOB = "/trending-job";
export const JOB_CATEGORY = "/job-category";

// Job Portal
export const ALL_COMPONENT = "/all-component";
export const SIGN_UP = "/daftar";
export const JOB_SEARCH = "/cari-kerja";
export const JOB_DETAIL = "/info-kerja";
export const COMPARISON = "/perbandingan";

// HRMS
export const SELECT_MODULES = "/select-modules";
export const SELECT_ROLE_REGISTRATION = "/select-role-registration";
export const HRMS = `https://hrms.${homeUrl}`;
export const EMPLOYER_HOME = `https://employer.${homeUrl}`;
