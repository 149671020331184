import React, { useEffect, useState } from "react";
import * as Variable from "../../utils/variables";
import { classHelper } from "../../utils/stringUtils";
import useDebounce from "../../../shared/hooks/debounce";
import "./Pagination.scss";

const Pagination = (props) => {
	const {
		selectedEnvironment,
		page,
		paginationSize,
		getRequestedPage,
		className,
		ableText,

		maxPageSize,
		lastItemIndex,
		handleLastItemIndex,
	} = props;

	const ENV_NAME = selectedEnvironment || "bhs";
	const [inputPage, setInputPage] = useState(page);
	const debouncePage = useDebounce(inputPage, 1000);

	useEffect(() => {
		if (inputPage !== page) {
			setInputPage(inputPage);
		}
	}, [page, inputPage]);

	const handleChange = (e) => {
		const value = e.target.value;
		if (value > 0 && value <= paginationSize) {
			setInputPage(value);
		}
	};

	useEffect(() => {
		if (inputPage > 0 && inputPage <= paginationSize) {
			getRequestedPage(Number(inputPage));
		}
	}, [debouncePage]); // eslint-disable-line

	return (
		<nav
			aria-label="Page navigation example"
			className={classHelper("custom-navigation flexBox", className)}
		>
			{ableText && (
				<div className="text">
					{Variable.PAGE_LABEL[ENV_NAME]}{" "}
					<input
						className="page-num"
						type="number"
						min="1"
						max={paginationSize}
						value={inputPage}
						onChange={handleChange}
						size={paginationSize.toString().length}
					/>{" "}
					{Variable.OF_LABEL[ENV_NAME]} {paginationSize}
					&nbsp;&nbsp;&nbsp;
				</div>
			)}
			<ul className="pagination justify-content-center">
				<li
					className={page === 1 ? "page-item disabled" : "page-item"}
					onClick={() => {
						if (page > 1) {
							getRequestedPage(page - 1);
						}
					}}
				>
					<span className="page-link">
						<i className="fa fa-arrow-left"></i>
					</span>
				</li>
				{paginationSize <= 4 ? (
					<>
						{Array.from(Array(paginationSize), (_, i) => (
							<li
								key={i + 1}
								className={`page-item ${page === i + 1 ? "active" : ""}`}
								onClick={() => getRequestedPage(i + 1)}
							>
								<span className="page-link">{i + 1}</span>
							</li>
						))}
					</>
				) : (
					<>
						{page > 2 && (
							<li className="page-item" onClick={() => getRequestedPage(1)}>
								<span className="page-link">1</span>
							</li>
						)}
						{page > 3 && (
							<li className="page-item disabled">
								<span className="page-link">...</span>
							</li>
						)}
						<li
							className={`page-item ${page === 1 ? "active" : ""}`}
							onClick={() => {
								let goToPage = 0;
								if (page === 1) {
									goToPage = 1;
								} else if (page === paginationSize) {
									goToPage = page - 2;
								} else {
									goToPage = page - 1;
								}
								getRequestedPage(goToPage);
							}}
						>
							<span className="page-link">
								{page === 1 ? page : page === paginationSize ? page - 2 : page - 1}
							</span>
						</li>
						<li
							className={`page-item ${
								page !== 1 && page !== paginationSize ? "active" : ""
							}`}
							onClick={() => {
								let goToPage = 0;
								if (page === 1) {
									goToPage = page + 1;
								} else if (page === paginationSize) {
									goToPage = page - 1;
								} else {
									goToPage = page;
								}
								getRequestedPage(goToPage);
							}}
						>
							<span className="page-link">
								{page === 1 ? page + 1 : page === paginationSize ? page - 1 : page}
							</span>
						</li>
						<li
							className={`page-item ${page === paginationSize ? "active" : ""}`}
							onClick={() => {
								let goToPage = 0;
								if (page === 1) {
									goToPage = page + 2;
								} else if (page === paginationSize) {
									goToPage = page;
								} else {
									goToPage = page + 1;
								}
								getRequestedPage(goToPage);
							}}
						>
							<span className="page-link">
								{page === 1 ? page + 2 : page === paginationSize ? page : page + 1}
							</span>
						</li>
						{page <= paginationSize - 3 && (
							<li className="page-item disabled">
								<span className="page-link">...</span>
							</li>
						)}
						{page < paginationSize - 1 && (
							<li
								className="page-item"
								onClick={() => getRequestedPage(paginationSize)}
							>
								<span className="page-link">{paginationSize}</span>
							</li>
						)}
					</>
				)}
				<li
					className={page === paginationSize ? "page-item disabled" : "page-item"}
					onClick={() => {
						if (lastItemIndex < paginationSize * 10) {
							getRequestedPage(page + 1);
						}
					}}
				>
					<span className="page-link">
						<i className="fa fa-arrow-right"></i>
					</span>
				</li>
			</ul>
		</nav>
	);
};

export default Pagination;
