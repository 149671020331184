import React, { useState, useEffect, useRef, useCallback } from "react";
import InputRange from "react-input-range";

import * as Variable from "../../utils/variables";

import "react-input-range/lib/css/index.css";
import "./CustomDualRangeSlider.scss";

function CustomDualRangeSlider({ min, max, ENV_NAME, ...props }) {
	const [value, setValue] = useState({
		min,
		max,
	});
	const [step, setStep] = useState(5_00_000);

	const handleOnChange = (newValue) => {
		const updatedValues = {
			min: Math.max(0, newValue?.min),
			max: Math.min(100_000_000, newValue?.max),
		};

		setValue(updatedValues);
		props.onUpdate(updatedValues);
	};

	useEffect(() => {
		if (value?.min <= 50_000_000) {
			setStep(5 * 1_000_000);
		} else setStep(2 * 5 * 1_000_000);
	}, [value.min]);

	useEffect(() => {
		if (value?.max <= 50_000_000) {
			setStep(5 * 1_000_000);
		} else setStep(2 * 5 * 1_000_000);
	}, [value.max]);

	useEffect(() => {
		if (props.value?.min && props.value?.max) {
			setValue(props.value);
		}
	}, [props.value]);

	return (
		<div>
			<InputRange
				minValue={min}
				maxValue={max}
				value={value}
				onChange={handleOnChange}
				step={step}
			/>
			<div className="d-flex justify-content-between mt-3">
				<p className="mb-0">{Variable.MIN[ENV_NAME]}</p>
				<p className="mb-0">{Variable.MAX[ENV_NAME]}</p>
			</div>
		</div>
	);
}

export default CustomDualRangeSlider;
