import React from "react";
import cookies from "react-cookies";
import { useSelector } from "react-redux";

import useAuth from "shared/hooks/useAuth";
import { homeUrl } from "shared/utils/apiEndPoints";
import { configCookies } from "shared/utils/cookies";
import { RootState } from "component/JobDetail/types";
import { USER_TYPES } from "shared/constants/constants";
import { useHistory } from "react-router";
import * as Routepath from "shared/utils/routeLink";

const excludeRoutes = [Routepath.ACTIVATE_ACCOUNT, Routepath.SIGNUP_VERIFY_EMAIL];

const UserModuleVerification = ({ children }: React.PropsWithChildren) => {
	const { isAuthenticated } = useAuth();

	const history = useHistory();

	const userDetail = useSelector((state: RootState) => state.auth.userDetails);

	const handleRedirect = () => {
		const moduleSelector = cookies.load("moduleSelector");

		switch (moduleSelector) {
			case "JobPoster":
				window.open(`https://employer.${homeUrl}/job-ads-list`, "_self");
				break;
			case "HRMS":
				window.open(`https://hrms.${homeUrl}`, "_self");
				break;
			default:
				break;
		}
	};

	const handleRedirectFirstLogin = () => {
		const { user_type, hrms_type } = userDetail;

		if (
			!!hrms_type &&
			!!cookies.load(
				"ptkjauthtoken" &&
					!excludeRoutes.some((route) => history.location.pathname.includes(route)),
			)
		) {
			cookies.save("moduleSelector", "HRMS", configCookies);

			window.open(`https://hrms.${homeUrl}`, "_self");
			return;
		}

		if (!cookies.load("ptkjauthtoken")) {
			localStorage.clear();
			return;
		}

		switch (user_type) {
			case USER_TYPES.JOB_MARKET_SUPER_ADMIN:
			case USER_TYPES.JOB_MARKET_ADMIN:
				cookies.save("moduleSelector", "JobPoster", configCookies);
				window.open(`https://employer.${homeUrl}/job-ads-list`, "_self");
				break;
			case USER_TYPES.JOB_SEEKER:
				cookies.save("moduleSelector", "JobSeeker", configCookies);
				window.open(`https://${homeUrl}`, "_self");
				break;
			default:
				break;
		}
	};

	if (!!userDetail && !cookies.load("moduleSelector") && !!cookies.load("ptkjauthtoken")) {
		cookies.save("moduleSelector", "HRMS", configCookies);

		window.open(`https://hrms.${homeUrl}`, "_self");
		return;
	}

	if (
		!!userDetail &&
		!userDetail.hrms_type &&
		!cookies.load("moduleSelector") &&
		!excludeRoutes.some((route) => history.location.pathname.includes(route))
	) {
		handleRedirectFirstLogin();
		return;
	}

	if (
		!!isAuthenticated &&
		!!cookies.load("moduleSelector") &&
		cookies.load("moduleSelector") !== "JobSeeker" &&
		!excludeRoutes.some((route) => history.location.pathname.includes(route))
	) {
		handleRedirect();
		return;
	}

	return <>{children}</>;
};

export default UserModuleVerification;
