import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { classHelper } from "../../utils/stringUtils";

import * as Variable from "../../utils/variables";
import "./JobListCardComponent.scss";
import Image from "../UI/Image";
import {
	BookmarkIcon,
	BriefcaseOutlineIcon,
	BuildingOutlineIcon,
	IdCardIcon,
	MoneyOutlineIcon,
} from "../svg/Icons";
import { formatString, getCurrencySymbol, getLocale, nFormatter } from "../../utils/string";
import hotJob from "../../../assets/icons/hot-job.png";
import Button from "../ButtonComponent/Button";
import { salaryRangeDesc } from "shared/utils/salaryRangeDesc";

const JobListCardComponent = ({ data, selected, ENV_NAME, onClick, ...props }) => {
	return (
		<div
			className={classHelper(
				"J-listCardComp cursorPointer",
				selected ? "active" : "",
				props.className,
			)}
			onClick={onClick}
		>
			{data?.hot_job && <Image src={hotJob} className="J-listCardComp-hotJob" />}
			<div className="J-listCardComp-icon ">
				<Image src={data?.company?.company_logo || ""} />
			</div>
			<div className="J-listCardComp-data">
				<h3 className="J-listCardComp-title cursorPointer">{data?.job_title}</h3>
				<div className="J-listCardComp-item mb-sm py-0">{data?.company?.name}</div>
				<div className="J-listCardComp-item">{data?.posting_location_city}</div>
				{data?.display_salary && (
					<div className="J-listCardComp-item item-salary">
						<MoneyOutlineIcon
							width="1.4rem"
							height="1.4rem"
							stroke="#1D2939"
							className="me-xxs"
						/>
						{`${getCurrencySymbol(
							data?.salary_currency,
							getLocale(ENV_NAME),
						)} ${salaryRangeDesc(data, ENV_NAME, "short")} ${
							data?.salary_rate
								? Variable.PERIOD_UNIT[data?.salary_rate][ENV_NAME].toLowerCase()
								: ""
						}`}
					</div>
				)}
				<div className="J-listCardComp-item">
					<div className="d-flex align-items-center">
						<BuildingOutlineIcon
							width="1.4rem"
							height="1.4rem"
							stroke="#1D2939"
							className="me-xxs"
						/>
						{Variable.WORK_MODEL_HELPER(data?.work_model)[ENV_NAME]}
					</div>
					<div className="mx-xs">|</div>
					<div className="d-flex align-items-center">
						<BriefcaseOutlineIcon
							width="1.4rem"
							height="1.4rem"
							stroke="#1D2939"
							className="me-xxs"
						/>
						{Variable.JOB_TYPE_HELPER(data?.job_type[0])[ENV_NAME]}
					</div>
					<div className="mx-xs">|</div>
					<div className="d-flex align-items-center">
						<IdCardIcon
							width="1.4rem"
							height="1.4rem"
							stroke="#1D2939"
							className="me-xxs"
						/>
						{formatString(Variable.APPLICANTS[ENV_NAME], data?.applicants)}
					</div>
				</div>
				<div className="J-listCardComp-item smaller">
					{formatString(Variable.POSTED_AGO[ENV_NAME], data?.posting_timestamp)}
					<div className="mx-xxs">|</div>
					{formatString(
						Variable.URGENCY[ENV_NAME],
						Variable.URGENCY_VALUE_HELPER(data?.urgency)[ENV_NAME] ?? "",
					)}
					<div className="ms-auto">
						<Button
							type="textGray"
							title={
								<BookmarkIcon
									stroke={data?.is_bookmarked ? "#fc9607" : "#667085"}
									fill={data?.is_bookmarked ? "#fc9607" : "transparent"}
								/>
							}
							size="xs"
							btnClassName="px-0"
							// onClick={(e) => {
							// 	e.preventDefault();
							// 	e.stopPropagation();
							// 	props.onBookmark(data?.id, !data?.is_bookmarked);
							// }}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(JobListCardComponent));
