import React, { useState, useEffect } from "react";

import "./CustomPopupMenu.scss";
import { classHelper } from "../../utils/stringUtils";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";

function CustomPopupMenu({ items, id, ...props }) {
	const [showPopup, setShowPopup] = useState(false);
	const popupRef = React.createRef();

	// hide popup when clicked outside
	useEffect(() => {
		const popupClickOutside = async (event) => {
			if (popupRef && !popupRef.current?.contains(event.target) && showPopup) {
				setShowPopup(false);
			}
		};

		document.addEventListener("mousedown", popupClickOutside);

		return () => document.removeEventListener("mousedown", popupClickOutside);
	}, [popupRef, showPopup]);

	return (
		<div className={classHelper("popupContainer", props.className)}>
			<PiDotsThreeOutlineVerticalFill
				size={20}
				className="mt-1"
				color="#193560"
				onClick={() => setShowPopup((prev) => !prev)}
			/>
			{showPopup && (
				<ul className="popupList" ref={popupRef}>
					{items.map((item, idx) => (
						<li key={idx} onClick={() => item?.task(id)}>
							{item.name}
						</li>
					))}
				</ul>
			)}
		</div>
	);
}

export default CustomPopupMenu;
