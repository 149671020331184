import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import useToast from "shared/hooks/useToast";
import makeRequest from "../../../shared/utils/request";
import { generateRequestOptions } from "../../../shared/utils/apiEndPoints";
import * as Variable from "../../../shared/utils/variables";
import { SETUP_PROFILE } from "../../../shared/utils/routeLink";
import Input from "../../../shared/component/InputComponent/Input";
import Select from "../../../shared/component/SelectComponent/Select";
import Toggle from "../../../shared/component/ToggleComponent/Toggle";
import {
	COUNTRY_LIST,
	FIELD_OF_STUDY,
	LEVEL_OF_EDUCATION,
} from "../../../shared/utils/dataOptions";
import DatePickerDropdown from "../../../shared/component/DatePickerDropdown/DatePickerDropdown";
import Button from "../../../shared/component/ButtonComponent/Button";
import { PlusIcon } from "../../../shared/component/svg/Icons";
import FileCard from "../../../shared/component/CardComponent/FileCard";
import { getIconByName } from "../../../shared/utils/file";
import ModalInfo from "../../../shared/component/ModalComponent/ModalInfo";
import Image from "../../../shared/component/UI/Image";

import RemoveMediaIcon from "../../../assets/image/remove-media.png";
import { isValueAllowed } from "shared/utils/numberUtils";

function AddEducation({ ENV_NAME, onNext }) {
	const toast = useToast();

	const GPA_PATTERN = /^(\d+(?:[.,]\d*)?|)$/;
	const regionNames = new Intl.DisplayNames([ENV_NAME === "bhs" ? "id" : "en"], {
		type: "region",
	});
	const history = useHistory();
	const educationId = history?.location?.pathname?.split("/")?.[3];

	const [form, setForm] = useState({
		institution_name: "",
		country: "",
		is_still_enrolled: false,
		started_at: "",
		graduated_at: "",
		field_of_study: "",
		institution_level: "",
		major: "",
		gpa: "",
		education_document_proof: [],
	});
	const [errors, setErrors] = useState({});
	const [monitor, setMonitor] = useState({});
	const [countryList, setCountryList] = useState(null);
	const [isEdited, setIsEdited] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [showConfirm, setShowConfirm] = useState({ show: false, data: null });

	const uploadRef = useRef();

	// TODO: if there is any education id in the url then retrieve the education
	useEffect(() => {
		if (educationId) retrieveEducation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [educationId]);

	useEffect(() => {
		if (ENV_NAME) {
			setCountryList(COUNTRY_LIST?.map((i) => ({ id: i, name: regionNames?.of(i) })));
		}
	}, [ENV_NAME]); // eslint-disable-line

	const retrieveEducation = async () => {
		const res = await makeRequest(
			generateRequestOptions("getEducation", { urlParams: educationId }),
		);

		if (res.code === 200) setForm(res.data);
		else toast.error(res.message);
	};

	const onChange = (e, type = null) => {
		const { name, value } = e.target;
		if (type === "numberWithDecimal") {
			setForm((prev) => ({
				...prev,
				[name]: isValueAllowed(value, type) ? value : prev[name],
			}));
		} else
			setForm((prev) => ({
				...prev,
				[name]: value,
			}));

		setErrors((prev) => ({ ...prev, [name]: false }));
		setMonitor((prev) => ({ ...prev, [name]: true }));
		setIsEdited(true);
	};

	const handleSave = async () => {
		const {
			institution_name,
			institution_level,
			country,
			is_still_enrolled,
			started_at,
			graduated_at,
		} = form;

		const errorsData = {};
		if (!institution_name) errorsData["institution_name"] = true;
		if (!country) errorsData["country"] = true;
		if (!started_at) errorsData["started_at"] = true;
		if (!institution_level) errorsData["institution_level"] = true;
		if (!is_still_enrolled && !graduated_at) errorsData["graduated_at"] = true;
		if (form.gpa && !isValueAllowed(form?.gpa, "custom", GPA_PATTERN)) errorsData["gpa"] = true;

		const hasErrors = Object.values(errorsData).some((err) => err);
		if (hasErrors) return setErrors(errorsData);

		// TODO: if the user haven't edited the correctly filled form
		// then instead of making api call simply move to the next form
		if (!hasErrors && !isEdited) return onNext();

		const docs = form?.education_document_proof
			?.filter((doc) => !doc?.education)
			?.map((doc) => doc?.education_document_proof);

		if (form?.is_still_enrolled) {
			delete form.graduated_at;
			delete monitor.graduated_at;
		}

		// TODO: The GPA can't be null but we don't want any value for GPA when the institution level is SD, SMP, SMA/SMU/SMK/STM
		// so BE suggested that we should pass 0 as the value because this field is not nullable

		const formData = new FormData();
		for (const item in monitor) {
			if (item === "education_document_proof" && docs.length && !educationId)
				docs.forEach((doc) => formData.append("education_document_proof", doc));
			else if (item !== "education_document_proof") formData.append(item, form[item]);
		}
		if (!LEVEL_OF_EDUCATION.slice(3).includes(form?.institution_level))
			formData.append("gpa", 0);

		let url = generateRequestOptions("createEducation");
		if (educationId) {
			url = generateRequestOptions("editEducation", {
				urlParams: educationId,
			});
		}

		setIsUploading(true);
		const res = await makeRequest({
			...url,
			data: formData,
		});

		if (res.code === 200) {
			if (docs.length && educationId) {
				const uploadPromises = docs.map((file) => {
					return handleUploadDocument(file);
				});

				await Promise.all(uploadPromises);
			}
			history.push(SETUP_PROFILE);
			onNext();
		} else toast.error(res.message);
		setIsUploading(false);
	};

	const handleUploadDocument = async (file) => {
		const formData = new FormData();
		formData.append("education_document_proof", file);
		formData.append("education", educationId);

		const res = await makeRequest({
			...generateRequestOptions("createEducationDocument"),
			data: formData,
		});

		if (res.code !== 200) toast.error(res.message);
	};

	const onFileChange = async (e) => {
		console.log("Uploading File");
		const file = e.target.files[0];
		const fileObj = {
			education_document_proof: file,
			file_name: file?.name,
			id: Date.now(),
		};
		const formClone = { ...form };
		if (formClone.education_document_proof) formClone.education_document_proof.push(fileObj);
		else {
			formClone.education_document_proof = [{ ...fileObj }];
		}
		setForm(formClone);
		setMonitor((prev) => ({ ...prev, education_document_proof: true }));
	};

	const filterRemainingDocs = (docId) => {
		setForm({
			...form,
			education_document_proof: form.education_document_proof.filter(
				(doc) => doc.id !== docId,
			),
		});
	};

	const handleDeleteDocument = async () => {
		// There will be two kinds of delete
		// if there exists an education for the document proof, then call the API
		// otherwise, filter out the documents
		const docId = showConfirm?.data?.id;

		const documentProof = form.education_document_proof.find((doc) => doc.id === docId);

		if (documentProof?.education) {
			const res = await makeRequest(
				generateRequestOptions("deleteEducationDocument", {
					urlParams: docId,
				}),
			);

			if (res.code === 200) {
				filterRemainingDocs(docId);
				setShowConfirm({ show: false, data: null });
			} else toast.error(res.message);
		} else {
			filterRemainingDocs(docId);
			setShowConfirm({ show: false, data: null });
		}
	};

	return (
		<>
			<div className="edu-form">
				<Input
					label={Variable.SCHOOL_COLLEGE_NAME[ENV_NAME]}
					placeholder={`${Variable.EXAMPLE_ABBV[ENV_NAME]} Universitas Indonesia`}
					value={form?.institution_name || ""}
					name="institution_name"
					onChange={onChange}
					inputType="label"
					className="w-100"
					required
					isError={errors["institution_name"]}
				/>
				<Select
					label={Variable.COUNTRY[ENV_NAME]}
					placeholder={Variable.COUNTRY[ENV_NAME]}
					name="country"
					value={form?.country || ""}
					items={countryList}
					onChange={onChange}
					searchable={false}
					required
					noDefault
					isError={errors["country"]}
				/>
				<Toggle
					type="switch"
					leftText={Variable.CURRENTLY_ENROLLED[ENV_NAME]}
					name="is_still_enrolled"
					checked={form?.is_still_enrolled}
					onChange={(e) => {
						setForm((p) => ({ ...p, is_still_enrolled: e.target.checked }));
						setMonitor((prev) => ({ ...prev, is_still_enrolled: true }));
					}}
				/>
				<div>
					<label className="custom-label isRequired">
						{Variable.START_DATE[ENV_NAME]}
					</label>
					<DatePickerDropdown
						className="form-row"
						dayLabel={<></>}
						monthLabel={<></>}
						yearLabel={<></>}
						name="started_at"
						value={form?.started_at}
						onChange={onChange}
						isError={errors["started_at"]}
						hideDate
					/>
				</div>
				{!form?.is_still_enrolled && (
					<div>
						<label className="custom-label isRequired">
							{Variable.GRADUATION_DATE[ENV_NAME]}
						</label>
						<DatePickerDropdown
							className="form-row"
							dayLabel={<></>}
							monthLabel={<></>}
							yearLabel={<></>}
							name="graduated_at"
							value={form?.graduated_at}
							onChange={onChange}
							isError={errors["graduated_at"]}
							hideDate
						/>
					</div>
				)}
				<Select
					label={Variable.FIELD_OF_STUDY[ENV_NAME]}
					placeholder={Variable.FIELD_OF_STUDY[ENV_NAME]}
					name="field_of_study"
					value={form?.field_of_study}
					items={FIELD_OF_STUDY?.map((i) => ({ id: i, name: i }))}
					onChange={onChange}
					searchable={false}
					className="w-100"
					noDefault
				/>
				<Input
					label={Variable.MAJOR[ENV_NAME]}
					placeholder={Variable.MAJOR[ENV_NAME]}
					value={form?.major || ""}
					name="major"
					onChange={onChange}
					inputType="label"
					className="w-100"
				/>
				<div className="form-row">
					<Select
						label={Variable.LEVEL_OF_EDUCATION[ENV_NAME]}
						placeholder={Variable.LEVEL_OF_EDUCATION[ENV_NAME]}
						name="institution_level"
						value={form?.institution_level || ""}
						items={LEVEL_OF_EDUCATION?.map((i) => ({ id: i, name: i }))}
						onChange={onChange}
						searchable={false}
						className="w-100"
						required
						isError={errors["institution_level"]}
						noDefault
					/>
					{LEVEL_OF_EDUCATION?.slice(3)?.includes(form?.institution_level) && (
						<Input
							label={Variable.GPA[ENV_NAME]}
							placeholder={Variable.GPA[ENV_NAME]}
							value={form?.gpa || ""}
							name="gpa"
							onChange={(e) => onChange(e, "numberWithDecimal")}
							inputType="label"
							className="w-100"
							isError={errors["gpa"]}
							required
						/>
					)}
				</div>
				<div>
					<h5 className="text-md font-weight-medium text-label-gray mb-sm">
						{Variable.DOCUMENT[ENV_NAME]}
					</h5>
					<span className="text-sm">
						{Variable.DOCUMENT_EDUCATION_SUBTITLE[ENV_NAME]}
					</span>
				</div>
				<div className="input-btn">
					<Button
						type="secondary"
						size="md"
						title={
							<div className="flex-all-center gap-xxs">
								<PlusIcon
									width="1.8rem"
									height="1.8rem"
									stroke="white"
									strokeWidth="3"
								/>
								{Variable.ADD_FILE[ENV_NAME]}
							</div>
						}
						onClick={() => uploadRef?.current?.click()}
					/>
					<input
						id="upload-files"
						type="file"
						name="subjectIcon"
						className="inputfile d-none"
						onChange={(e) => {
							onFileChange(e);
							uploadRef.current.value = null;
						}}
						accept="image/*, application/pdf"
						ref={uploadRef}
					/>
				</div>

				{form?.education_document_proof?.map((i) => (
					<FileCard
						key={i?.id}
						title={i?.file_name}
						icon={getIconByName(i?.file_name)}
						onDelete={() => setShowConfirm({ show: true, data: i })}
						className="mb-3 fileCard"
						showGreenCheck={false}
						isUploading={isUploading}
					/>
				))}
			</div>

			<Button
				type="primary"
				size="sm"
				title={Variable.SAVE[ENV_NAME]}
				className="mt-5"
				btnClassName="btn-initial-profile"
				onClick={handleSave}
				disabled={
					!form?.institution_name ||
					!form?.country ||
					!form?.started_at ||
					!form?.institution_level ||
					(!form?.is_still_enrolled && !form?.graduated_at) ||
					isUploading
				}
			/>

			{/* Delete Modal */}
			<ModalInfo
				isShow={showConfirm?.show}
				onHide={() => setShowConfirm({ show: false, data: null })}
				onConfirm={handleDeleteDocument}
				type="delete"
				customIcon={<Image src={RemoveMediaIcon} className="modal-image-icon" />}
			/>
		</>
	);
}

export default AddEducation;
