import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Modal } from "react-bootstrap";

import * as RoutePath from "../../../shared/utils/routeLink";
import * as Variable from "../../../shared/utils/variables.js";
// import * as ActionTypes from "../../../store/actions/actionTypes";

import "./ModalComponent.scss";
import Icon from "../IconComponent/Icon";
import Button from "../ButtonComponent/Button";

const ModalDescription = ({ isShow, isHide, data, ENV_NAME, ...props }) => {
  return (
    <>
      <Modal
        show={isShow}
        onHide={isHide}
        dialogClassName="modal-dialog-centered modal-description"
        size="md"
      >
        <Modal.Header>
          <div className="mDesc-head">
            {Variable.FULL_JOB_DESCRIPTION[ENV_NAME]}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="mDesc-body">
            <h4>{Variable.QUALIFICATIONS[ENV_NAME]}</h4>
            <ul>
              <li>Bachelor of Civil Engineering Education</li>
              <li>Maximum age 30 years</li>
              <li>Having experience for 1 year is a good plus</li>
              <li>
                Open to fresh graduates who are strong in software engineering
                design , structural calculations, able to read technical
                drawings, and make detailed budget plans
              </li>
              <li>
                Can operate well Auto CAD, Sketch UP, Corel Draw, Sketch Drawing
              </li>
              <li>Capable and meticulous in doing engineering calculations</li>
              <li>
                Preferably those who understand structural calculation programs
              </li>
              <li>
                Able to make RAB, understand the preparation of work schedules,
                and work methods
              </li>
              <li>
                Have a good analysis of construction design (both in terms of
                buildings and structures)
              </li>
              <li>
                Good at cross-checking images regarding the accuracy of
                calculations in images
              </li>
            </ul>
            <h4 className="second">{Variable.RESPONSIBILITIES[ENV_NAME]}</h4>
            <ul>
              <li>
                Make working drawings, RAB, BQ, and work progress reports
                (project supervision)
              </li>
              <li>
                Perform technical analysis regarding construction design (in
                terms of buildings and structures)
              </li>
              <li>
                Checking drawings to ensure accuracy of calculations in drawings
              </li>
              <li>Ensuring the quality of work according to plan</li>
              <li>
                Coordinate with internal and external parties in the context of
                project implementation
              </li>
              <li>
                Monitor project work and ensure that it runs according to the
                specified schedule and according to the budget
              </li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="flex-grow-1 mDesc-footer"
            btnClassName="w-100"
            type="primary"
            size="md"
            title={Variable.CLOSE_LABEL[ENV_NAME]}
            onClick={isHide}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(withRouter(ModalDescription));
