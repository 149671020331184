import React, { useState } from "react";
import cookies from "react-cookies";
import { PiSuitcaseSimpleFill } from "react-icons/pi";
import UserCircleGear from "assets/svg/user-circle-gear.svg";

import Image from "shared/component/UI/Image";
import PortalKerja from "assets/logo/portal-kerja.png";
import { Typography } from "shared/component/Typography/Typography";
import CardModule, {
	CardModuleProps,
	moduleIconStyles,
} from "../../shared/component/CardModule/CardModule";
import * as RoutePath from "shared/utils/routeLink";
import "./ModulesSelect.scss";
import useTranslate from "shared/hooks/useTranslate";
import { homeUrl } from "shared/utils/apiEndPoints";
import { configCookies } from "shared/utils/common";
import ModalInfo from "shared/component/ModalComponent/ModalInfo";
import ErrorIcon from "../../assets/icons/alert/alert-error.png";
import useFetch from "shared/hooks/useFetch";
import LoadingComponent from "shared/component/LoadingComponent/LoadingComponent";
import { USER_TYPES } from "shared/constants/constants";

type ModulesType = Omit<CardModuleProps, "onClick"> & {
	url: string;
	moduleName: "JobPoster" | "HRMS" | "JobSeeker";
};

interface BasicUserDetailType {
	hrms_type: "0" | "1" | "2" | null;
	user_type: "0" | "1" | "2";
}

const ModulesSelect = () => {
	const [modalShow, setModalShow] = useState(false);

	const isCompanyVerified = !!cookies.load("verify_company");

	const JOB_SEEKER_URL = `https://${homeUrl}${RoutePath.JOB_SEARCH}`;

	const JOB_MARKET_ADMIN_URL = isCompanyVerified
		? `https://employer.${homeUrl}/verifikasi-perusahaan`
		: `https://employer.${homeUrl}/job-ads-list`;

	const { data, loading } = useFetch<BasicUserDetailType>("getUserViaToken");

	const jobMarketUrl =
		data?.user_type === USER_TYPES.JOB_SEEKER ? JOB_SEEKER_URL : JOB_MARKET_ADMIN_URL;

	const modules: ModulesType[] = [
		{
			id: 1,
			title: "Job Market",
			icon: <PiSuitcaseSimpleFill style={moduleIconStyles} />,
			moduleName: data?.user_type === USER_TYPES.JOB_SEEKER ? "JobSeeker" : "JobPoster",
			url: jobMarketUrl,
		},
		{
			id: 2,
			title: "HRMS",
			moduleName: "HRMS",
			icon: <Image src={UserCircleGear} />,
			url: `https://hrms.${homeUrl}?role=${data?.hrms_type ?? ""}`,
		},
	];

	const t = useTranslate();

	const handleRedirect = (url: string, moduleName: "JobPoster" | "HRMS" | "JobSeeker") => () => {
		if (moduleName === "HRMS" && data && !data?.hrms_type) {
			setModalShow(true);
			return;
		}

		cookies.save("moduleSelector", moduleName, configCookies);
		window.open(url, "_self");
	};

	if (loading) {
		return <LoadingComponent />;
	}

	return data ? (
		<>
			<div className="modules-select">
				<Image src={PortalKerja} className="modules-select__logo" />
				<Typography.Text size="xl" color="#1D2939" fontWeight="bold">
					{t("SELECT_MODULE_TITLE")}
				</Typography.Text>
				<div className="modules-select__module-list-wrapper">
					{modules.map((module) => (
						<CardModule
							title={module.title}
							icon={module.icon}
							key={module.id}
							onClick={handleRedirect(module.url, module.moduleName)}
						/>
					))}
				</div>
			</div>
			<ModalInfo
				isShow={modalShow}
				onConfirm={() => setModalShow(false)}
				type="other"
				title={t("NO_PERMISSION_TITLE")}
				description={t("NO_PERMISSION_DESC")}
				customIcon={<Image src={ErrorIcon} style={{ width: "56px", height: "56px" }} />}
				confirmButtonType="primary"
				confirmLabel={t("OKAY")}
				showCancelBtn={false}
			/>
		</>
	) : (
		<></>
	);
};

export default ModulesSelect;
