import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import useToast from "shared/hooks/useToast";
import makeRequest from "../../../shared/utils/request";
import { generateRequestOptions } from "../../../shared/utils/apiEndPoints";
import * as Variable from "../../../shared/utils/variables";
import { ROOT } from "../../../shared/utils/routeLink";
import Input from "../../../shared/component/InputComponent/Input";
import Select from "../../../shared/component/SelectComponent/Select";

import Button from "../../../shared/component/ButtonComponent/Button";

import ModalInfo from "../../../shared/component/ModalComponent/ModalInfo";
import Image from "../../../shared/component/UI/Image";
import formatNumber from "../../../shared/utils/formatNumber";

import WarningIcon from "../../../assets/svg/red_warning.svg";
import useUserDetails from "../../../shared/hooks/userDetails";
import { formatString } from "shared/utils/string";

function JobPref({ ENV_NAME, userId, onNext, onSkip }) {
	const toast = useToast();

	const WORK_MODEL = [
		{ id: "remote", name: Variable.REMOTE_LABEL[ENV_NAME] },
		{ id: "hybrid", name: Variable.HYBRID_LABEL[ENV_NAME] },
		{ id: "on_site", name: Variable.ONSITE_LABEL[ENV_NAME] },
	];
	const history = useHistory();
	const userDetails = useUserDetails(userId);
	const [form, setForm] = useState({
		currency: "",
		expected_salary: "",
		preferred_work_model: "",
		preferred_work_location: "",
		preferred_work_location_2: "",
		preferred_work_location_3: "",
	});

	const [isEdited, setIsEdited] = useState(false);
	const [errors, setErrors] = useState({});
	const [monitor, setMonitor] = useState({
		currency: true,
		preferred_work_model: true,
	});
	const [showSkip, setShowSkip] = useState(false);

	useEffect(() => {
		if (userDetails) {
			const { job_preference } = userDetails;
			setForm({
				id: job_preference?.id,
				currency: job_preference?.currency || "idr",
				expected_salary: formatNumber(job_preference?.expected_salary),
				preferred_work_model: job_preference?.preferred_work_model,
				preferred_work_location: job_preference?.preferred_work_location,
				preferred_work_location_2: job_preference?.preferred_work_location_2,
				preferred_work_location_3: job_preference?.preferred_work_location_3,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userDetails]);

	useEffect(() => {
		if (form?.preferred_work_model && form?.preferred_work_model === "remote") {
			setForm((prev) => ({
				...prev,
				preferred_work_location: "",
				preferred_work_location_2: "",
				preferred_work_location_3: "",
			}));
		}
	}, [form?.preferred_work_model]);

	const onChange = (e) => {
		const { name, value } = e.target;
		setForm((p) => ({
			...p,
			[name]: name === "expected_salary" ? formatNumber(value, form?.currency) : value,
		}));
		setMonitor((prev) => ({ ...prev, [name]: true }));
		setErrors({});
		setIsEdited(true);
	};

	const handleSave = async () => {
		if (form?.id && !isEdited) return onNext();

		const {
			preferred_work_model,
			preferred_work_location,
			preferred_work_location_2,
			preferred_work_location_3,
		} = form;

		const errorsData = {};
		if (
			["hybrid", "onsite"].includes(preferred_work_model) &&
			!preferred_work_location &&
			!preferred_work_location_2 &&
			!preferred_work_location_3
		) {
			errorsData["preferred_work_location"] = true;
			errorsData["preferred_work_location_2"] = true;
			errorsData["preferred_work_location_3"] = true;
		}

		const hasErrors = Object.values(errorsData).some((err) => err);
		if (hasErrors) return setErrors(errorsData);

		// TODO: if the user haven't edited the correctly filled form
		// then instead of making api call simply move to the next form
		if (!hasErrors && !isEdited) return onNext();

		const data = {};
		form.expected_salary = form?.expected_salary?.toString()?.replace(/[^0-9]/g, "");

		for (let key in monitor) data[key] = form[key];
		form.currency = "idr";

		if (data?.preferred_work_model === "remote") {
			data.preferred_work_location = "";
			data.preferred_work_location_2 = "";
			data.preferred_work_location_3 = "";
		}

		let url = generateRequestOptions("createJobPref");

		if (form?.id) url = generateRequestOptions("editJobPref", { urlParams: form?.id });

		const res = await makeRequest({
			...url,
			data,
		});

		if (res.code === 200) onNext();
		else toast.error(res.message);
	};

	return (
		<>
			<div className="am-form job-pref-form mt-5">
				<div className="form-row">
					<Select
						label={Variable.EXPECTED_PAY_CURRENCY[ENV_NAME]}
						placeholder={Variable.EXPECTED_PAY_CURRENCY[ENV_NAME]}
						name="currency"
						value={form?.currency || "idr"}
						items={Variable.CURRENCY_LIST}
						onChange={onChange}
						searchable={false}
						className="w-100"
						disabled
					/>
					<Input
						label={Variable.EXPECTED_PAY_AMOUNT[ENV_NAME]}
						placeholder={Variable.EXPECTED_PAY_AMOUNT[ENV_NAME]}
						name="expected_salary"
						value={form?.expected_salary || ""}
						onChange={onChange}
						inputType="number"
						className="w-100"
					/>
				</div>
				<Select
					label={Variable.WORK_MODE_LABEL[ENV_NAME]}
					placeholder={Variable.WORK_MODE_LABEL[ENV_NAME]}
					name="preferred_work_model"
					value={form?.preferred_work_model || ""}
					items={WORK_MODEL}
					onChange={onChange}
					searchable={false}
					className="w-100"
					noDefault
				/>
				<Input
					label={Variable.PREFERED_WORK_LOCATION[ENV_NAME] + " 1"}
					placeholder={Variable.PREFERED_WORK_LOCATION[ENV_NAME] + " 1"}
					value={form?.preferred_work_location || ""}
					name="preferred_work_location"
					onChange={onChange}
					inputType="label"
					className="w-100"
					isError={errors["preferred_work_location"]}
					disabled={form?.preferred_work_model === "remote"}
				/>
				<Input
					label={Variable.PREFERED_WORK_LOCATION[ENV_NAME] + " 2"}
					placeholder={Variable.PREFERED_WORK_LOCATION[ENV_NAME] + " 2"}
					value={form?.preferred_work_location_2 || ""}
					name="preferred_work_location_2"
					onChange={onChange}
					inputType="label"
					className="w-100"
					isError={errors["preferred_work_location_2"]}
					disabled={form?.preferred_work_model === "remote"}
				/>
				<Input
					label={Variable.PREFERED_WORK_LOCATION[ENV_NAME] + " 3"}
					placeholder={Variable.PREFERED_WORK_LOCATION[ENV_NAME] + " 3"}
					value={form?.preferred_work_location_3 || ""}
					name="preferred_work_location_3"
					onChange={onChange}
					inputType="label"
					className="w-100"
					isError={errors["preferred_work_location_3"]}
					disabled={form?.preferred_work_model === "remote"}
				/>
			</div>

			{/* Skip Modal */}
			<ModalInfo
				isShow={showSkip}
				onHide={() => setShowSkip(false)}
				onConfirm={() => {
					onSkip();
					sessionStorage.removeItem("profileSetup");
					history.push(ROOT);
				}}
				type="other"
				customIcon={<Image src={WarningIcon} className="modal-image-icon" />}
				title={Variable.SURE_TO_SKIP[ENV_NAME]}
				description={
					formatString(
						Variable.SKIP_DESC[ENV_NAME],
						Variable.JOB_RESUME_PREF[ENV_NAME].toLowerCase(),
					) +
					" " +
					Variable.HISTORY[ENV_NAME]
				}
				confirmLabel={Variable.SKIP_BTN_TITLE[ENV_NAME]}
			/>

			<div className="d-flex align-items-center">
				<Button
					type="outline"
					size="md"
					title={Variable.SKIP_STEP[ENV_NAME]}
					className="mt-5 me-4"
					btnClassName="btn-initial-profile"
					onClick={() => setShowSkip(true)}
				/>
				<Button
					type="primary"
					size="sm"
					title={Variable.SAVE[ENV_NAME]}
					className="mt-5"
					btnClassName="btn-initial-profile"
					onClick={handleSave}
				/>
			</div>
		</>
	);
}

export default JobPref;
