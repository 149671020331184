import Paper from "../../assets/svg/paper.svg";
import Image from "../../assets/svg/files/image.svg";
import Ppt from "../../assets/svg/files/ppt.svg";
import Video from "../../assets/svg/files/video.svg";
import Pdf from "../../assets/svg/files/pdf.svg";
import Excel from "../../assets/svg/files/excel.svg";
import Word from "../../assets/svg/files/word.svg";
import Zip from "../../assets/svg/files/zip.svg";
import Text from "../../assets/svg/files/txt.svg";

export const isValidImage = (file, allowed) => {
	let invalid;
	if (file instanceof File) {
		return allowed?.includes?.(file?.type);
	}
	if (file instanceof FileList) {
		for (let i = 0; i < file.length; i++) {
			invalid = allowed?.includes?.(file[i]?.type);
			if (!invalid) return false;
		}
		return true;
	}
	return false;
};
export const isFileSizeLessOrEqual = (file, size = 1024) => {
	let invalid;
	if (file instanceof File) {
		return file?.size <= size;
	}
	if (file instanceof FileList) {
		for (let i = 0; i < file.length; i++) {
			invalid = file[i]?.size <= size;
			if (!invalid) return false;
		}
		return true;
	}
	return false;
};

export const getIconByName = (url) => {
	let ext = ((url + "").split(".").pop() || "").toLowerCase();
	switch (ext) {
		case "png":
		case "jpeg":
		case "jpg":
		case "svg":
		case "gif":
			return Image;
		case "doc":
		case "docx":
		case "rtf":
			return Word;
		case "xlsx":
		case "xls":
		case "csv":
			return Excel;
		case "ppt":
		case "pptx":
			return Ppt;
		case "pdf":
			return Pdf;
		case "mp4":
		case "mkv":
		case "m3u8":
			return Video;
		case "rar":
		case "zip":
			return Zip;
		case "txt":
			return Text;
		default:
			return Paper;
	}
};
