import React, { useEffect, useState } from "react";
import cookies from "react-cookies";
import moment from "moment";
import "moment/locale/id";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
// import useToast from "shared/hooks/useToast";

import { homeUrl } from "../../shared/utils/apiEndPoints";
import * as ActionTypes from "../../store/actions/actionTypes";
import * as RoutePath from "../../shared/utils/routeLink";
// import bookmark from "../../assets/svg/bookmark.svg";
// import bookmark from "../../assets/icons/bookmark.png";
// import * as Variable from "../../shared/utils/variables";
// import * as USERROLES from "../../shared/utils/userroles";

import "./MainComponent.scss";
import LoadingComponent from "../../shared/component/LoadingComponent/LoadingComponent";
import Header, { MENU_ENUM } from "../HeaderComponent/HeaderComponent";
import HomeComponent from "../../component/HomeComponent/HomeComponent";
import DummyComponent from "../../component/DummyComponent/DummyComponent";
import JobSearching from "../../component/JobSearching/JobSearching";
import FooterComponent from "../FooterComponent/FooterComponent";
import JobComparison from "../../component/JobComparison/JobComparison";
import { CaretDown } from "../../shared/component/svg/Icons";
import { SCROLL_TO_TOP } from "../../shared/utils/variables";
import ModalLogin from "../../shared/component/ModalComponent/ModalLogin";
import SignUpComponent from "../SignUpComponent/SignUpComponent";
import ForgetPassComponent from "../ForgetPassComponent/ForgetPassComponent";
import LogStatusHandler from "./LogStatusHandler";
import ResetPassword from "../ResetPasswordComponent/ResetPassword";
import InitialProfile from "../InitialProfileComponent/InitialProfile";
import EmailVerified from "../SignUpComponent/EmailVerified";
import PrivacyPolicy from "../../component/PrivacyPolicy";
import TermsOfUse from "../../component/TermsofUse";
import Button from "../../shared/component/ButtonComponent/Button";
import JobDetail from "../../component/JobDetail/JobDetail";
import { PiCaretUpBold } from "react-icons/pi";
import JobCategory from "component/JobCategory";
import useAuth from "shared/hooks/useAuth";
import ModulesSelect from "component/ModulesSelect/ModulesSelect";
import ActivateAccount from "component/ActivateAccountComponent/ActivateAccount";
import RoleRegistrationSelector from "component/RoleRegistrationSelector/RoleRegistrationSelector";
import UserModuleVerification from "shared/hoc/UserModuleVerification";

// This function will return the Component on any given address
const getActiveComp = (name) => {
	if (name && name.includes(RoutePath.JOB_DETAIL)) {
		name = RoutePath.JOB_DETAIL;
	}
	if (name && name.includes(RoutePath.RESET_PASSWORD)) {
		name = RoutePath.RESET_PASSWORD;
	}
	if (name && name.includes(RoutePath.SIGNUP_VERIFY_EMAIL)) {
		name = RoutePath.SIGNUP_VERIFY_EMAIL;
	}
	if (name && name.includes(RoutePath.SETUP_PROFILE)) {
		name = RoutePath.SETUP_PROFILE;
	}
	if (
		name &&
		(name.includes(RoutePath.RECOMMENDED_JOB) ||
			name.includes(RoutePath.TRENDING_JOB) ||
			name.includes(RoutePath.POPULAR_JOB))
	) {
		name = RoutePath.JOB_CATEGORY;
	}

	switch (name) {
		case RoutePath.ROOT: {
			return {
				activeComponent: HomeComponent,
				isActiveComponent: RoutePath.ROOT,
			};
		}
		case RoutePath.ALL_COMPONENT: {
			return {
				activeComponent: DummyComponent,
				isActiveComponent: RoutePath.ALL_COMPONENT,
			};
		}
		case RoutePath.JOB_SEARCH: {
			return {
				activeComponent: JobSearching,
				isActiveComponent: RoutePath.JOB_SEARCH,
				headerActive: MENU_ENUM.JOBS,
				footerType: "minimal",
			};
		}
		case RoutePath.JOB_DETAIL: {
			return {
				activeComponent: JobDetail,
				isActiveComponent: RoutePath.JOB_DETAIL,
				headerActive: MENU_ENUM.JOBS,
			};
		}
		case RoutePath.COMPARISON: {
			return {
				activeComponent: JobComparison,
				isActiveComponent: RoutePath.COMPARISON,
			};
		}
		case RoutePath.FORGOT_PASSWORD: {
			return {
				activeComponent: ForgetPassComponent,
				isActiveComponent: RoutePath.FORGOT_PASSWORD,
			};
		}
		case RoutePath.SIGN_UP: {
			return {
				activeComponent: SignUpComponent,
				isActiveComponent: RoutePath.SIGN_UP,
				hideHeader: true,
				hideFooter: true,
			};
		}

		case RoutePath.RESET_PASSWORD: {
			return {
				activeComponent: ResetPassword,
				isActiveComponent: RoutePath.RESET_PASSWORD,
				hideFooter: true,
			};
		}
		case RoutePath.SETUP_PROFILE: {
			return {
				activeComponent: InitialProfile,
				isActiveComponent: RoutePath.SETUP_PROFILE,
				hideFooter: true,
				hideHeader: true,
				isAuthenticated: true,
			};
		}
		case RoutePath.PRIVACY_POLICY: {
			return {
				activeComponent: PrivacyPolicy,
				isActiveComponent: RoutePath.PRIVACY_POLICY,
			};
		}
		case RoutePath.JOB_CATEGORY: {
			return {
				activeComponent: JobCategory,
				isActiveComponent: RoutePath.JOB_CATEGORY,
			};
		}
		case RoutePath.TERMS_OF_USE: {
			return {
				activeComponent: TermsOfUse,
				isActiveComponent: RoutePath.TERMS_OF_USE,
			};
		}
		case RoutePath.SIGNUP_VERIFY_EMAIL: {
			return {
				activeComponent: EmailVerified,
				isActiveComponent: RoutePath.SIGNUP_VERIFY_EMAIL,
				hideFooter: true,
			};
		}
		case RoutePath.SELECT_MODULES: {
			return {
				activeComponent: ModulesSelect,
				isActiveComponent: RoutePath.SELECT_MODULES,
				hideFooter: true,
				hideHeader: true,
			};
		}
		case RoutePath.SELECT_ROLE_REGISTRATION: {
			return {
				activeComponent: RoleRegistrationSelector,
				isActiveComponent: RoutePath.SELECT_ROLE_REGISTRATION,
				hideFooter: true,
				hideHeader: true,
			};
		}
		case RoutePath.ACTIVATE_ACCOUNT: {
			return {
				activeComponent: ActivateAccount,
				isActiveComponent: RoutePath.ACTIVATE_ACCOUNT,
				hideFooter: true,
				hideHeader: true,
			};
		}

		default: {
			return {
				activeComponent: LoadingComponent,
				isActiveComponent: name,
			};
		}
	}
};

const MainComponent = ({ ENV_NAME, ...props }) => {
	let location = useLocation();
	const [component, setComponent] = useState({
		activeComponent: LoadingComponent,
		isActiveComponent: "",
	});
	const [showScrollButton, setShowScrollButton] = useState(false);
	const history = useHistory();
	const { isAuthenticated } = useAuth();

	// We remove console log functionality in production
	useEffect(() => {
		const handleScrollButtonVisibility = () => {
			setShowScrollButton(window.scrollY > window.screen.height * 0.5);
		};

		if (homeUrl === "portalsekolah.co.id") {
			console.log = function () {};
		}
		window.addEventListener("scroll", handleScrollButtonVisibility);

		return () => {
			window.removeEventListener("scroll", handleScrollButtonVisibility);
		};
	}, []);

	// For every link change, we set the component according to the page
	useEffect(() => {
		let component = getActiveComp(location.pathname);
		if (!!component.isAuthenticated && !isAuthenticated) {
			history.push(RoutePath.ROOT);
			return;
		}
		setComponent(component);
		window.scrollTo({ top: 0, left: 0, behavior: "instant" });
	}, [location]);

	// I don't really know what's the best way to detect the cookie change
	// But for every cookie change, we set our language prefs aswell
	useEffect(() => {
		let lang = cookies.load("lang");
		let curr = cookies.load("curr");
		let momentLocale = moment.locale() === "id" ? "bhs" : "eng";
		if (lang !== ENV_NAME || lang !== momentLocale) {
			props.changeLanguage(lang);
			if (lang !== "eng") {
				moment.locale("id");
				moment.updateLocale("id", {
					longDateFormat: {
						LT: "HH:mm",
						LTS: "HH:mm:ss",
						L: "DD/MM/YYYY",
						LL: "D MMMM YYYY",
						LLL: "D MMMM YYYY [pukul] HH:mm",
						LLLL: "dddd, D MMMM YYYY [pukul] HH:mm",
					},
				});
			} else moment.locale("en");
		}

		if (curr && curr?.id !== props.curr?.id) {
			props.changeCurrency(curr);
		}
	});

	return (
		<>
			<Helmet>
				<title>Portal Kerja</title>
			</Helmet>
			{/* This is to check the login status of a user */}
			<LogStatusHandler />

			<div className="main-wrapper">
				{!component.hideHeader && <Header active={component.headerActive} />}

				<ModalLogin
					show={props.loginModalShow}
					onHide={() => props.setLoginModalShow(false)}
				/>
				<div className="active-comp">
					<UserModuleVerification>
						<component.activeComponent />
					</UserModuleVerification>
				</div>

				{!component.hideFooter && <FooterComponent footerType={component.footerType} />}
			</div>

			<Button
				type="primary"
				onClick={() => {
					window.scrollTo({
						top: 0,
						behavior: "smooth",
					});
				}}
				title={<PiCaretUpBold color="#ffffff" size={20} />}
				btnClassName={`btn btn-scrollTop ${showScrollButton ? "" : "hide"}`}
				titleTooltip={SCROLL_TO_TOP[ENV_NAME]}
			></Button>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		...state,
		loginModalShow: state.auth.loginModalShow,
		curr: state.auth.currency,
		ENV_NAME: state.auth.selectedEnvironment || "bhs",
	};
};

const mapStateToDispatch = (dispatch) => {
	return {
		changeLanguage: (payload) =>
			dispatch({
				type: ActionTypes.UPDATE_LANGUAGE,
				payload: payload,
			}),
		changeCurrency: (payload) =>
			dispatch({
				type: ActionTypes.UPDATE_CURRENCY,
				payload: payload,
			}),
		setLoginModalShow: (payload = true) => {
			if (payload) {
				dispatch({
					type: ActionTypes.OPEN_LOGIN_MODAL,
				});
			} else {
				dispatch({
					type: ActionTypes.CLOSE_LOGIN_MODAL,
				});
			}
		},
	};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(MainComponent));
