/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { IoBookmark, IoBookmarkOutline } from "react-icons/io5";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";

import { CreateJobApplication, Location } from "types/API/TypesSwagger";
import { JobAdListAdded } from "types/API/JobAdList";
import { RootState } from "store/types/RootStateTypes";
import { toggleLoginModalShow } from "store/actions/profileActions";
import useToast from "shared/hooks/useToast";
import makeRequest from "shared/utils/request";
import { generateRequestOptions } from "shared/utils/apiEndPoints";
import DropdownComponent from "shared/component/DropdownComponent/Dropdown";
import InputComponent from "shared/component/InputComponent/Input";
import JobListCardComponent from "shared/component/JobListCardComponent/JobListCardComponent";
import LoadingComponent from "shared/component/LoadingComponent/LoadingComponent";
import Image from "shared/component/UI/Image";
import { formatString, getCurrencySymbol, getLocale, nFormatter } from "shared/utils/string";
import * as Variable from "shared/utils/variables";
import * as RoutePath from "shared/utils/routeLink";
import Button from "shared/component/ButtonComponent/Button";
import Pagination from "shared/component/Pagination/Pagination";
import NoContentComponent from "shared/component/NoContentComponent/NoContentComponent";
import ModalInfo from "shared/component/ModalComponent/ModalInfo";
import { capitalize } from "shared/utils/stringUtils";
import formatNumber from "shared/utils/formatNumber";
import jobUnavailableIcon from "assets/svg/job_unavailable.svg";
import GreenCheck from "assets/svg/green-check.svg";
import ReportImage from "assets/svg/report_warning_icon.svg";
import HasReported from "assets/svg/has_reported.svg";
import SuccessReport from "assets/svg/success_report.svg";

import JobComparison from "../JobComparison/JobComparison";
import ApplyJobModal from "../ApplyJobComponent/ApplyJobModal";
import "./JobSearching.scss";
import { salaryRangeDesc } from "shared/utils/salaryRangeDesc";

type FilterType = Partial<{
	date_posted: string;
	salary: {
		min?: number;
		max?: number;
	};
	job_type: string[];
	work_model: string;
	experience_level: string[];
	industry: string[];
	underTenApp?: boolean;
	city: any; // Doesn't exist in Filters menu
}>;

type OnChangeData = {
	value: string;
	isChecked: boolean;
};

const JobSearching = ({ ...props }) => {
	const toast = useToast();
	const jobInfoRef = useRef<HTMLDivElement>(null);

	const ENV_NAME: string = (useSelector<RootState>((state) => state.auth.selectedEnvironment) ??
		"bhs") as string;

	const userId = useSelector<RootState>((state) => state.auth.userDetails?.id);
	const dispatch = useDispatch();
	const searchQp = useLocation().search;
	const searchQueryParams = new URLSearchParams(searchQp).get("search") ?? "";
	const locationQueryParams = new URLSearchParams(searchQp).get("location") ?? "";
	const history = useHistory();

	const [selectedJobs, setSelectedJobs] = useState<JobAdListAdded | undefined>();
	const [jobs, setJobs] = useState<JobAdListAdded[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [filters, setFilters] = useState<FilterType>({});
	const [isMobile, setIsMobile] = useState(false);
	const [pageData, setPageData] = useState({
		page_size: 10,
		max_page_size: 10,
		current_page: 1,
		max_pages: 1,
		last_item_index: 0,
		total_items: 0,
	});
	const [search, setSearch] = useState("");
	const [isFetchingLocation, setIsFetchingLocation] = useState(true);
	const [location, setLocation] = useState("");
	const [locations, setLocations] = useState<Location[]>([]);
	const [comparing, setComparing] = useState(false);
	const [compareValue, setCompareValue] = useState("");
	const [reportModal, setReportModal] = useState(false);
	const [reportFailedModal, setReportFailedModal] = useState(false);
	const [reportSuccessModal, setReportSuccessModal] = useState(false);
	const [isError, setIsError] = useState(false);
	const [radio, setRadio] = useState("");
	const [comment, setComment] = useState("");

	const [jobApp, setJobApp] = useState<{ id: number; company: string } | null>(null);
	const [applyJobModal, setApplyJobModal] = useState<{
		show: boolean;
		data: null | { id: number; title: string; isContinued?: boolean };
	}>({
		show: false,
		data: null,
	});
	const [isApplied, setIsApplied] = useState(false);
	const radioOptions = [
		{
			name: Variable.SPAM_OR_SCAM[ENV_NAME],
			value: "spam_or_scam",
		},
		{
			name: Variable.MISSLEADING[ENV_NAME],
			value: "misleading",
		},
		{
			name: Variable.DISCRIMINATION_OR_OFFENSIVE[ENV_NAME],
			value: "discrimination_or_offensive",
		},
		{
			name: Variable.OTHER[ENV_NAME],
			value: "other",
		},
	];

	const handleReport = async () => {
		if (!radio || !comment) {
			setIsError(true);
		} else {
			try {
				const { code, error, message } = await makeRequest({
					...generateRequestOptions("reportJob"),
					body: {
						reason: radio,
						comment,
						job_ad: selectedJobs?.id,
					},
					json: true,
				});
				if (code === 200 && error === false) {
					setReportModal(false);
					setReportSuccessModal(true);
				}
				if (code === 400 && message === "You have reported this job ad") {
					setReportModal(false);
					setReportFailedModal(true);
				}
				if (code === 400 && message !== "You have reported this job ad") {
					setReportModal(false);
					return toast.error(Variable.RESOURCE_NOT_FOUND[ENV_NAME]);
				}
			} catch (err) {
				return toast.error(Variable.RESOURCE_NOT_FOUND[ENV_NAME]);
			}
		}
	};

	useEffect(() => {
		if (jobInfoRef?.current) {
			jobInfoRef?.current?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
		}
	}, [selectedJobs]);

	useEffect(() => {
		if (!!searchQp) {
			setSearch(searchQueryParams);
			const locationSplit = locationQueryParams.includes(",")
				? locationQueryParams.split(", ")[1]
				: locationQueryParams;
			setLocation(locationQueryParams);
			setFilters((prev) => ({
				...prev,
				city: locationSplit,
			}));
		}
		findJobs();
	}, []);

	useEffect(() => {
		// handleResetPageData();
		findJobs();
	}, [filters]);

	useEffect(() => {
		if (jobs.length === 0) return;
		getJob(jobs[0].id);
	}, [jobs]);

	useEffect(() => {
		const debounceSearch = setTimeout(fetchLocation, 800);
		return () => clearTimeout(debounceSearch);
	}, [location, isFetchingLocation]);

	/**
	 * @return {boolean}
	 */
	const needUserToBeLoggedIn = () => {
		if (!userId) {
			dispatch(toggleLoginModalShow(true));
			return false;
		}
		return true;
	};

	const compareJob = () => {
		props.history.push(RoutePath.COMPARISON);
	};

	const fetchLocation = async () => {
		if (isFetchingLocation) {
			if (!location || !!locationQueryParams) return setLocations([]);
			const res = await makeRequest(
				generateRequestOptions("getLocation", {
					queryParams: { search: location, page_size: 7 },
				}),
			);

			if (res.code === 200) {
				setLocations(res.data);
			} else toast.error(res.message);
		}
	};

	const handleResetPageData = () =>
		setPageData({
			page_size: 10,
			max_page_size: 10,
			current_page: 1,
			max_pages: 1,
			last_item_index: 0,
			total_items: 0,
		});

	useEffect(() => {
		findJobs();
	}, [pageData?.current_page]);

	const findJobs = async () => {
		setIsLoading(true);

		let queryParams = {
			page_size: 10,
			page: pageData?.current_page,
		};
		const city = locationQueryParams.includes(",")
			? locationQueryParams.split(", ")[1]
			: locationQueryParams;
		if (search || !!searchQueryParams) {
			queryParams["keywords"] = searchQueryParams || search;
			queryParams["page"] = 1;
		}
		if (filters?.city || !!locationQueryParams)
			queryParams["posting_location_city"] = city || filters?.city;
		if (filters?.date_posted) {
			queryParams["posting_timestamp"] =
				filters.date_posted === "Any time"
					? "any"
					: filters.date_posted.toLowerCase()?.replaceAll(" ", "_");
		}
		if (filters?.salary) {
			queryParams["salary_amount_min"] = filters?.salary?.min;
			queryParams["salary_amount_max"] = filters?.salary?.max;
		}

		if (filters?.job_type) {
			queryParams["job_type"] = filters.job_type.map((j) =>
				j.toLowerCase()?.replaceAll(" ", "_"),
			);
		}
		if (filters?.work_model) {
			queryParams["work_model"] = filters.work_model.toLowerCase()?.replaceAll(" ", "");
		}

		if (filters?.experience_level) {
			queryParams["experience_level"] = filters.experience_level.map((exp) =>
				exp.toLowerCase()?.replaceAll(" ", "_"),
			);
		}
		if (filters?.industry) {
			queryParams["industry"] = filters.industry;
		}

		if (filters?.underTenApp) {
			queryParams["applicants_number_max"] = !!filters?.underTenApp ? 10 : undefined;
		}

		const res = await makeRequest(
			generateRequestOptions("findJobs", {
				queryParamsNoArrIdx: queryParams,
				queryOption: { arrayFormat: "repeat" },
			}),
		);

		if (res.code === 200) {
			setJobs(res.data);
			setPageData({
				...pageData,
				max_pages: Math.ceil(res?.total_items / 10),
				max_page_size: Math.min(res?.data?.length, 10),
				total_items: res?.total_items,
				...(Math.ceil(res?.total_items / 10) === 1 ? { current_page: 1 } : {}),
			});
			if (!!searchQp) {
				history.push(RoutePath.JOB_SEARCH);
			}
		} else toast.error(res.message);

		setIsLoading(false);
	};

	const getJob = async (jobId: number) => {
		const res = await makeRequest(
			generateRequestOptions("getJob", {
				urlParams: jobId,
			}),
		);

		if (res.code === 200) {
			setSelectedJobs(res.data);
		} else toast.error(res.message);
	};

	const handleFilterCheckboxArray = (
		name: string,
		datas: { value: string; isChecked: boolean }[],
	) => {
		if (datas.length <= 0) {
			const filtersClone = { ...filters };
			delete filtersClone[name];

			setFilters(filtersClone);
			return;
		}

		setFilters((currFilters) => ({
			...currFilters,
			[name]: datas.filter((v) => v.isChecked).map((v) => v.value),
		}));
	};

	const toggleBookmark = async (job_id: number, isBookmarked: boolean) => {
		if (!needUserToBeLoggedIn()) return;

		let url = {
			...generateRequestOptions("addBookmark"),
			json: true,
			body: { job_ad: job_id },
		};

		if (!isBookmarked) url = generateRequestOptions("removeBookmark", { urlParams: job_id });

		const res = await makeRequest(url);

		if (res.code === 200) {
			const job = jobs.find((job) => job.id === job_id);
			if (!job) return;

			job.is_bookmarked = isBookmarked;

			if (job_id === selectedJobs?.id)
				setSelectedJobs((prev) =>
					prev !== undefined
						? {
								...prev,
								is_bookmarked: isBookmarked,
						  }
						: undefined,
				);
		} else toast.error(res.message);
	};

	const handleJobCardClick = (job: JobAdListAdded) => (e: any) => {
		if (!isMobile) {
			e.preventDefault();
			e.stopPropagation();
		}
		getJob(job?.id);
	};

	const handleResetFilters = () => setFilters({});

	const handleOnEnterPressFindJobs = (e: any) => (e.keyCode === 13 ? findJobs() : null);

	const createJobApplication = async (job_ad: number, company: string) => {
		const res: { data: CreateJobApplication; message: string; code: number } =
			await makeRequest({
				...generateRequestOptions("createJobApplication"),
				body: {
					job_ad,
				},
			});

		if (res.code === 200) setJobApp({ id: res.data.id, company });
		else toast.error(res.message);
	};

	return (
		<div className="job-searching">
			<header className="blue-header">
				<div className="container">
					<div className="d-flex-desktop gap-xs mb-med align-items-center">
						<InputComponent
							dropdownType="search"
							placeholder={Variable.JOB_SEARCH_PLACEHOLDER[ENV_NAME]}
							className="flex-grow-1 w-mobile-100"
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							onKeyDown={handleOnEnterPressFindJobs}
							onClickClose={() => setSearch("")}
						/>
						<div className="flex-grow-1 location__search">
							<InputComponent
								dropdownType="location"
								placeholder={Variable.LOCATION_PLACEHOLDER[ENV_NAME]}
								className="flex-grow-1 w-mobile-100"
								value={location}
								onKeyDown={handleOnEnterPressFindJobs}
								onChange={(e) => {
									setLocation(e.target.value);
									setFilters({ ...filters, city: e.target.value });
								}}
								onClickClose={() => {
									setIsFetchingLocation(true);
									setLocation("");
									setFilters({ ...filters, city: "" });
								}}
							/>
							{locations.length ? (
								<div className="J-ddComp-menu mt-3">
									{locations?.map((loc, idx) => (
										<div
											className="menuDD"
											key={idx}
											onClick={() => {
												setFilters({ ...filters, city: loc?.city });
												setLocation(`${loc?.province}, ${loc?.city}`);
												setLocations([]);
												setIsFetchingLocation(false);
											}}
										>
											<p>
												{loc?.province}, {loc?.city}
											</p>
										</div>
									))}
								</div>
							) : (
								""
							)}
						</div>
						<Button
							type="secondary"
							title={Variable.FIND_JOB[ENV_NAME]}
							size="sm"
							onClick={() => findJobs()}
						/>
					</div>
					<div className="filter-scroll-cont">
						<div className="filter-cont">
							<DropdownComponent
								className="text-nowrap"
								isNotEmpty={!!filters.date_posted}
								type="date"
								value={filters?.date_posted}
								onApplyButton={(value: string) => {
									handleResetPageData();
									setFilters({ ...filters, date_posted: value });
								}}
							/>
							<DropdownComponent
								className="text-nowrap"
								isNotEmpty={filters.salary?.min || filters.salary?.max}
								value={{
									min: filters.salary?.min ?? 0,
									max: filters.salary?.max ?? 100_000_000,
								}}
								type="salary"
								onApplyButton={(salary: { min: number; max: number }) =>
									setFilters({
										...filters,
										salary,
									})
								}
							/>
							<DropdownComponent
								className="text-nowrap"
								isNotEmpty={(filters.job_type?.length ?? 0) > 0}
								type="jobType"
								value={filters?.job_type || []}
								onApplyButton={(datas: OnChangeData[]) => {
									handleResetPageData();
									handleFilterCheckboxArray("job_type", datas);
								}}
							/>
							<DropdownComponent
								className="text-nowrap"
								isNotEmpty={!!filters.work_model}
								type="workMode"
								value={filters?.work_model}
								onApplyButton={(work: string) => {
									handleResetPageData();
									setFilters({
										...filters,
										work_model: work,
									});
								}}
							/>
							<DropdownComponent
								className="text-nowrap"
								isNotEmpty={(filters.experience_level?.length ?? 0) > 0}
								type="experienceLevel"
								value={filters?.experience_level || []}
								onApplyButton={(datas: OnChangeData[]) => {
									handleResetPageData();
									handleFilterCheckboxArray("experience_level", datas);
								}}
							/>
							<DropdownComponent
								className="text-nowrap"
								isNotEmpty={(filters.industry?.length ?? 0) > 0}
								type="industry"
								value={filters?.industry || []}
								onApplyButton={(datas: OnChangeData[]) => {
									handleResetPageData();
									handleFilterCheckboxArray("industry", datas);
								}}
							/>
							<DropdownComponent
								className="text-nowrap"
								isNotEmpty={filters.underTenApp}
								type="underTenApp"
								value={!!filters?.underTenApp}
								onApplyButton={() =>
									setFilters((filter) => {
										if (filter.underTenApp) {
											delete filter.underTenApp;
											return { ...filter };
										}
										return {
											...filter,
											underTenApp: !filter.underTenApp,
										};
									})
								}
							/>
						</div>
						{Object.keys(filters).length > 0 && (
							<div className="reset-btn-cont">
								<div className="reset-btn" onClick={handleResetFilters}>
									Reset
								</div>
							</div>
						)}
					</div>
				</div>
			</header>

			<div className="container gap-std d-flex py-xl jobs-cont">
				{isLoading ? (
					<LoadingComponent />
				) : jobs.length ? (
					<>
						<div className="job-listing-wrapper">
							<div className="job-listing">
								<span className="listing-count">
									{formatString(
										Variable.JOB_MATCHES[ENV_NAME],
										pageData?.last_item_index + 1,
										pageData?.last_item_index + pageData?.max_page_size,
										pageData?.total_items,
									)}
								</span>
								<div className="job-listing-container">
									{jobs?.map((job, idx) => (
										<JobListCardComponent
											key={idx + 1}
											data={job}
											selected={selectedJobs?.id === job?.id}
											onClick={handleJobCardClick(job)}
											onBookmark={() =>
												toggleBookmark(job?.id, !job?.is_bookmarked)
											}
										/>
									))}
								</div>
								<Pagination
									selectedEnvironment={ENV_NAME}
									page={pageData?.current_page}
									paginationSize={pageData?.max_pages}
									getRequestedPage={(inputPage: number) => {
										if (
											inputPage > 0 &&
											inputPage <= pageData?.max_pages &&
											pageData?.last_item_index >= 0
										) {
											setPageData((p) => ({
												...p,
												current_page: inputPage,
												last_item_index:
													inputPage * pageData?.page_size -
													pageData?.page_size,
											}));
										}
									}}
									maxPageSize={pageData?.max_page_size}
									lastItemIndex={pageData?.last_item_index}
									className="py-0 d-flex justify-content-start"
								/>
							</div>
						</div>

						<div className="job-info-wrapper">
							{selectedJobs && (
								<div ref={jobInfoRef} className="job-info">
									<>
										<div className="jl-company-logo mb-md">
											<Image src={selectedJobs?.company?.company_logo} />
										</div>
										<h3 className="jl-job-title mb-sm">
											{selectedJobs?.job_title}
										</h3>
										<div className="d-flex align-items-center flex-wrap mb-1 jl-company-name">
											{selectedJobs?.company?.name}
										</div>
										<Link
											to="#"
											className="text-decoration-none btn-primary-default jl-company-location"
										>
											{selectedJobs?.posting_location_city}
										</Link>
										{/* Commented because location property is not available in job_ad/published-get response */}
										{/* <div className="my-xxs">{selectedJobs?.location}</div> */}
										{selectedJobs?.display_salary && (
											<div className="mb-std font-weight-semibold">
												{`${getCurrencySymbol(
													selectedJobs?.salary_currency,
													getLocale(ENV_NAME),
												)} ${salaryRangeDesc(
													selectedJobs,
													ENV_NAME,
													"short",
												)} ${
													selectedJobs?.salary_rate
														? Variable.PERIOD_UNIT[
																selectedJobs?.salary_rate
														  ][ENV_NAME].toLowerCase()
														: ""
												}`}
											</div>
										)}
										<div className="d-flex align-items-center flex-wrap jl-additional-info">
											{formatString(
												Variable.POSTED_AGO[ENV_NAME],
												selectedJobs?.posting_timestamp,
											)}
											<div className="mx-xxs">|</div>
											{formatString(
												Variable.URGENCY[ENV_NAME],
												Variable.URGENCY_VALUE_HELPER(
													selectedJobs?.urgency,
												)[ENV_NAME],
											)}
											<div className="mx-xxs">|</div>
											{formatString(
												Variable.APPLICANTS[ENV_NAME],
												selectedJobs?.applicants,
											)}
											<div className="mx-xxs">|</div>
											<Link
												to={RoutePath.JOB_DETAIL + "/" + selectedJobs?.id}
												className="text-decoration-none btn-primary-default font-weight-semibold"
												target="_blank"
											>
												{Variable.OPEN_IN_NEW_TAB[ENV_NAME]}
											</Link>
										</div>
										<div className="job-info-sticky-top">
											<div className="job-info-btn-cont">
												<Button
													type="primary"
													title={
														selectedJobs?.is_submitted
															? Variable.APPLIED[ENV_NAME]
															: selectedJobs?.is_applied
															? Variable.CONTINUE[ENV_NAME]
															: Variable.APPLY_NOW[ENV_NAME]
													}
													size="sm"
													onClick={(e) => {
														if (!userId)
															return toast.error(
																Variable.LOGIN_FIRST_ERROR[
																	ENV_NAME
																],
															);

														// TODO: Continue Application
														if (
															selectedJobs?.is_applied &&
															!selectedJobs?.is_submitted
														) {
															setApplyJobModal({
																show: true,
																data: {
																	id: selectedJobs?.id,
																	title: selectedJobs?.company
																		?.name,
																	isContinued: true,
																},
															});
															setJobApp({
																id: selectedJobs?.id,
																company:
																	selectedJobs?.company?.name,
															});
															return;
														}

														// TODO: Create a new job application
														createJobApplication(
															selectedJobs?.id,
															selectedJobs?.company?.name,
														);
														setApplyJobModal({
															show: true,
															data: {
																id: selectedJobs?.id,
																title: selectedJobs?.company?.name,
															},
														});
													}}
													disabled={selectedJobs?.is_submitted}
												/>
												{/* <Button
													type="outline"
													title={Variable.ADD_TO_COMPARE[ENV_NAME]}
													size="sm"
													onClick={(e) => {
														e.stopPropagation();
														setComparing(true);
														setCompareValue(selectedJobs?.name);
													}}
												/> */}
												<div className="J-cardComp-btn cursorPointer">
													<Button
														type="outline"
														title={
															selectedJobs?.is_bookmarked
																? Variable.SAVED[ENV_NAME]
																: Variable.SAVE_JOB[ENV_NAME]
														}
														size="sm"
														onClick={() =>
															toggleBookmark(
																selectedJobs?.id,
																!selectedJobs?.is_bookmarked,
															)
														}
														customIcon={
															selectedJobs?.is_bookmarked ? (
																<IoBookmark
																	size={20}
																	color="#fc9607"
																/>
															) : (
																<IoBookmarkOutline
																	size={20}
																	color="#193560"
																/>
															)
														}
													/>
												</div>
											</div>
											<div className="divider" />
										</div>
										<h4 className="text-lg text-label-black font-weight-semibold mb-std">
											{Variable.FULL_JOB_DESCRIPTION[ENV_NAME]}
										</h4>
										<h3 className="text-md text-label-black font-weight-semibold mb-sm">
											{Variable.QUALIFICATIONS[ENV_NAME]}:
										</h3>
										<p
											className="mb-std text-sm"
											dangerouslySetInnerHTML={{
												__html: selectedJobs?.job_requirements,
											}}
										></p>
										<h3 className="text-md text-label-black font-weight-semibold mb-sm">
											{Variable.RESPONSIBILITIES[ENV_NAME]}:
										</h3>
										<p
											className="mb-std text-sm"
											dangerouslySetInnerHTML={{
												__html: selectedJobs?.job_description,
											}}
										></p>
										<h3 className="text-md text-label-black font-weight-semibold mb-sm">
											{Variable.JOB_BENEFIT[ENV_NAME]}:
										</h3>
										<p
											className="mb-std text-sm"
											dangerouslySetInnerHTML={{
												__html: selectedJobs?.job_benefits,
											}}
										></p>
										<div className="divider" />
										<h4 className="text-lg text-label-black font-weight-semibold mb-std">
											{Variable.JOB_DETAILS[ENV_NAME]}
										</h4>
										<div className="job-info-details-grid">
											<div className="job-info-details-item">
												<h3 className="text-md text-label-black font-weight-semibold mb-sm">
													{Variable.JOB_TYPE_LABEL[ENV_NAME]}
												</h3>
												<div className="text-sm">
													<p className="text-sm">
														{selectedJobs?.job_type
															?.filter(
																(v) =>
																	!!Variable.JOB_TYPE_HELPER(v),
															)
															.map(
																(v) =>
																	Variable.JOB_TYPE_HELPER(v)[
																		ENV_NAME
																	],
															)
															.join(", ")}
													</p>
												</div>
											</div>
											<div className="job-info-details-item">
												<h3 className="text-md text-label-black font-weight-semibold mb-sm">
													{Variable.EXPERIENCE_LEVEL_LABEL[ENV_NAME]}
												</h3>
												<div className="text-sm">
													{Variable.EXPERIENCE_LEVEL_HELPER(
														selectedJobs?.experience_level,
													)[ENV_NAME] || "-"}
												</div>
											</div>
											<div className="job-info-details-item">
												<h3 className="text-md text-label-black font-weight-semibold mb-sm">
													{Variable.WORK_MODE_LABEL[ENV_NAME]}
												</h3>
												<div className="text-sm">
													{Variable.WORK_MODEL_HELPER(
														selectedJobs?.work_model,
													)[ENV_NAME] || "-"}
												</div>
											</div>
										</div>
										<div className="divider" />
										<div className="d-flex align-items-center gap-xs">
											<Button
												type="outline"
												title={Variable.MORE_JOB_SEARCH[ENV_NAME]}
												size="sm"
												// className
												onClick={() => {
													setSearch(selectedJobs?.company?.name);
													setTimeout(findJobs, 100);
													window.scrollTo(0, 0);
												}}
											/>
											{userId ? (
												<Button
													type="textGray"
													title={Variable.REPORT[ENV_NAME]}
													size="sm"
													// className
													onClick={() => {
														setReportModal(true);
														setRadio("");
														setComment("");
														setIsError(false);
													}}
												/>
											) : (
												""
											)}
										</div>
									</>
								</div>
							)}
						</div>
					</>
				) : (
					<NoContentComponent
						icon={jobUnavailableIcon}
						title={Variable.NO_JOB_FOUND[ENV_NAME]}
						description={Variable.CHECK_SPELLING_CRITERIA[ENV_NAME]}
					/>
				)}
			</div>

			<div className={`job-compare ${comparing ? "show" : ""}`}>
				<div className="container">
					<div className="job-compare-list">
						<InputComponent
							className="flex-grow-1"
							dropdownType="company"
							value={compareValue}
							onClickClose={() => {
								setCompareValue("");
							}}
							disabled
						/>
						<InputComponent
							className="flex-grow-1"
							dropdownType="company"
							value={compareValue}
							onClickClose={() => {
								setCompareValue("");
							}}
							disabled
						/>
						<InputComponent
							className="flex-grow-1"
							dropdownType="company"
							value={compareValue}
							onClickClose={() => {
								setCompareValue("");
							}}
							disabled
						/>
						<Button
							size="sm"
							type="outline"
							title={Variable.CLOSE_LABEL[ENV_NAME]}
							onClick={() => setComparing(false)}
						/>
						<Button
							size="sm"
							type="secondary"
							title={Variable.COMPARE_JOB_LABEL[ENV_NAME]}
							onClick={() => compareJob()}
						/>
					</div>
				</div>
			</div>
			{comparing && <JobComparison data={selectedJobs} />}

			<Modal
				show={reportModal}
				dialogClassName="report_modal"
				aria-labelledby="example-custom-modal-styling-title"
				centered
			>
				<Modal.Body>
					<div className="top">
						<img src={ReportImage} alt="Report" />
						<p>{Variable.REPORT_THIS_JOB[ENV_NAME]}</p>
					</div>
					<div className="reason">
						{!isError && (
							<span className="label">{Variable.REASON_LABEL[ENV_NAME]}</span>
						)}
						{isError && radio && (
							<span className="label">{Variable.REASON_LABEL[ENV_NAME]}</span>
						)}
						{isError && !radio && (
							<span
								className="label"
								style={{ color: "#1D2939", fontWeight: "600", fontSize: "16px" }}
							>
								{Variable.REASON_LABEL[ENV_NAME]}
							</span>
						)}
						{isError && !radio && (
							<span style={{ display: "block" }}>
								{Variable.SELECT_REASON[ENV_NAME]}
							</span>
						)}
						<div className="reason_option">
							{radioOptions.map((el) => (
								<Form.Check
									label={el.name}
									name={el.value}
									key={el.value}
									type={"radio"}
									value={el.value}
									onChange={() => {
										setRadio(el.value);
										setIsError(false);
									}}
									id={`inline-${el.value}-1`}
									checked={el.value === radio}
								/>
							))}
						</div>
					</div>
					<div className="comment">
						<span>{Variable.ADDITIONAL_COMMENTS[ENV_NAME]}</span>
						<Form.Control
							as="textarea"
							rows={5}
							placeholder={Variable.ENTER_DESCRIPTION[ENV_NAME]}
							value={comment}
							onChange={(e) => setComment(e.target.value)}
						/>
						<div>
							{isError && !comment ? (
								<span>{Variable.ERROR_MESSAGE[ENV_NAME]}</span>
							) : (
								<span />
							)}
							<span>{`${comment.length}/5000`}</span>
						</div>
					</div>
					<div className="action">
						<button onClick={() => setReportModal(false)}>
							{Variable.CLOSE[ENV_NAME]}
						</button>
						<button onClick={handleReport} className="submit">
							{Variable.SUBMIT_LABEL[ENV_NAME]}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			<Modal show={reportFailedModal} dialogClassName="report_result_modal" centered>
				<Modal.Body>
					<img src={HasReported} alt="Reported" />
					<p>{Variable.INFORMATION[ENV_NAME]}</p>
					<span>{Variable.HAS_REPORTED[ENV_NAME]}</span>
					<button onClick={() => setReportFailedModal(false)}>
						{Variable.DONE[ENV_NAME]}
					</button>
				</Modal.Body>
			</Modal>
			<Modal
				show={reportSuccessModal}
				dialogClassName="report_result_modal"
				onHide={() => setReportSuccessModal(false)}
				centered
			>
				<Modal.Body>
					<img src={SuccessReport} alt="Reported" />
					<p>{Variable.SUCCESS_REPORT_TITLE[ENV_NAME]}</p>
					<span>{Variable.SUCCESS_REPORT_MESSAGE[ENV_NAME]}</span>
				</Modal.Body>
			</Modal>

			{applyJobModal.show && (
				<ApplyJobModal
					ENV_NAME={ENV_NAME}
					show={applyJobModal.show}
					onHide={() => setApplyJobModal({ show: false, data: null })}
					userId={userId}
					onFetchJobs={findJobs}
					jobAppId={jobApp?.id}
					jobData={applyJobModal.data}
					onApply={() => setIsApplied(true)}
				/>
			)}

			<ModalInfo
				isShow={isApplied}
				onConfirm={() => {
					selectedJobs && getJob(selectedJobs?.id);
					setIsApplied(false);
					setJobApp(null);
				}}
				type="other"
				title={`${Variable.APPLICATION_SENT_HEAD[ENV_NAME]} ${jobApp?.company || "-"}`}
				description={Variable.APPLICATION_SENT_DESC[ENV_NAME]}
				customIcon={<Image src={GreenCheck} className="modal-image-icon" />}
				confirmButtonType="primary"
				confirmLabel={Variable.DONE[ENV_NAME]}
				showCancelBtn={false}
			/>
		</div>
	);
};

export default withRouter(JobSearching);
