import cookies from "react-cookies";
import { useDispatch, useSelector } from "react-redux";

import useToast from "shared/hooks/useToast";
import { homeUrl } from "shared/utils/apiEndPoints";
import { LOGOUT, LOGOUT_SUCCEED } from "store/actions/actionTypes";
import { RootState } from "component/JobDetail/types";

import usePost from "./usePost";

const useAuth = () => {
	const toast = useToast();

	const { postFunction: logout } = usePost("logout");

	const dispatch = useDispatch();

	const isAuthenticated = cookies.load("ptkjauthtoken");

	const handleLogout = async () => {
		if (cookies.load("ptkjrefreshtoken")) {
			const res = await logout({
				body: {
					refresh_token: cookies.load("ptkjrefreshtoken"),
				},
			}).then(async (status) => {
				if (status) {
					cookies.remove("ptkjauthtoken", { path: "/", domain: homeUrl });
					cookies.remove("ptkjrefreshtoken", { path: "/", domain: homeUrl });
					cookies.remove("moduleSelector", { path: "/", domain: homeUrl });
					dispatch({ type: LOGOUT });
					dispatch({
						type: LOGOUT_SUCCEED,
					});
				} else {
					toast.error("Something went wrong");
					cookies.remove("ptkjauthtoken", { path: "/", domain: homeUrl });
					cookies.remove("ptkjrefreshtoken", { path: "/", domain: homeUrl });
					cookies.remove("moduleSelector", { path: "/", domain: homeUrl });
					dispatch({ type: LOGOUT });
					dispatch({
						type: LOGOUT_SUCCEED,
					});
				}
				return status;
			});
			return res;
		}
	};

	return {
		handleLogout,
		isAuthenticated,
	};
};

export default useAuth;
