// eslint-disable-next-line no-unused-vars
import {
	ExperienceLevelEnum,
	JobTypeEnum,
	UrgencyEnum,
	WorkModelEnum,
} from "types/API/TypesSwagger";

export const t = (key, env, text) => {
	if (key == null || typeof key !== "object") {
		key == null && console.error("translation not found", text);
		return text || "--";
	}
	return key[env];
};

export const CURRENCY_LIST = [
	{ id: "idr", name: "IDR", locale: "id-ID" },
	{ id: "usd", name: "USD", locale: "en-US" },
	{ id: "sgd", name: "SGD", locale: "en-SG" },
	{ id: "myr", name: "MYR", locale: "en-MY" },
	{ id: "inr", name: "INR", locale: "en-IN" },
	{ id: "php", name: "PHP", locale: "en-PH" },
	{ id: "aud", name: "AUD", locale: "en-AU" },
	{ id: "thb", name: "THB", locale: "th-TH" },
	{ id: "hkd", name: "HKD", locale: "zh-HK" },
	{ id: "eur", name: "EUR", locale: "de-DE" },
	{ id: "cny", name: "CNY", locale: "zh-CN" },
	{ id: "jpy", name: "JPY", locale: "ja-JP" },
	{ id: "gbp", name: "GBP", locale: "en-GB" },
	{ id: "krw", name: "KRW", locale: "ko-KR" },
	{ id: "vnd", name: "VND", locale: "vi-VN" },
	{ id: "bdt", name: "BDT", locale: "bn-BD" },
	{ id: "nzd", name: "NZD", locale: "en-NZ" },
];

export const RESOURCE_NOT_FOUND = {
	eng: "Resource not found",
	bhs: "Sumber tidak ditemukan",
};
// Log Status Handler
export const TOAST_SESSION_EXPIRED = {
	eng: "Session Expired",
	bhs: "Sesi berakhir",
};
export const TOAST_USER_NOT_VALID = {
	eng: "User Not Valid",
	bhs: "Pengguna Tidak Valid",
};

// Loading Component
export const LOADING_LABEL = { eng: "Loading...", bhs: "Memuat..." };

// No content Component
export const NO_CONTENT_DEFAULT_LABEL = {
	eng: "No Content",
	bhs: "Tidak ada konten",
};
export const NO_CONTENT_DEFAULT_DESCRIPTION = {
	eng: "No Content Available",
	bhs: "Tidak ada konten tersedia",
};

// Header
export const HEADER_SRC_PLACEHOLDER = {
	eng: "Search for Major, College",
	bhs: "Cari Jurusan, Kampus",
};
export const SIGN_UP_FREE = {
	eng: "Sign up for FREE Now",
	bhs: "Daftar GRATIS sekarang",
};
export const REGISTER = {
	eng: "Sign Up",
	bhs: "Daftar",
};
export const SIGN_IN = {
	eng: "Log In",
	bhs: "Masuk",
};
export const FAVORITE = {
	eng: "Favorite",
	bhs: "Favorit",
};
export const DASHBOARD = {
	eng: "Dashboard",
	bhs: "Dashboard",
};
export const LOGOUT = {
	eng: "Log out",
	bhs: "Keluar",
};
export const LOGGED_OUT = {
	eng: "Logged out",
	bhs: "Keluar Akun",
};
export const VIEW_PROFILE = {
	eng: "My Profile",
	bhs: "Profil Saya",
};
export const ACCOUNT_SETTINGS = {
	eng: "Settings",
	bhs: "Pengaturan",
};
export const MY_JOBS = {
	eng: "My Jobs",
	bhs: "My Jobs",
};
export const HELP = {
	eng: "Help",
	bhs: "Bantuan",
};
export const FOR_EMPLOYER = {
	eng: "For Employer",
	bhs: "Untuk Perusahaan",
};

// Footer
export const CONTACT_US = {
	eng: "Contact Us",
	bhs: "Hubungi Kami",
};
export const PRIVACY_POLICY = {
	eng: "Privacy Policy",
	bhs: "Kebijakan Privasi",
};
export const TNC = {
	eng: "Terms & Conditions",
	bhs: "Syarat dan Ketentuan",
};
export const MAIL_TO_SUBJECT = {
	eng: "Inquiry / Complaint",
	bhs: "Pertanyaan / Keluhan",
};

// Home Component
export const BANNER_DESC = {
	eng: "Find job easier & faster",
	bhs: "Mencari kerja lebih mudah & cepat",
};
export const COLLEGE = {
	eng: "College",
	bhs: "Kampus",
};
export const MAJOR = {
	eng: "Major",
	bhs: "Jurusan",
};
export const CAREER = {
	eng: "Career",
	bhs: "Karier",
};
export const SCHOLARSHIP = {
	eng: "Scholarship",
	bhs: "Beasiswa",
};
export const SEARCH_COLLEGE_CITY = {
	eng: "Search colleges located in {0}",
	bhs: "Cari kampus yang terletak di {0}",
};
export const NO_ITEM_FOUND_QUERY = {
	eng: "No item found for {0}",
	bhs: "Tidak ada {0} ditemukan",
};
export const CITY = {
	eng: "City",
	bhs: "Kota",
};
export const RECENTLY_VIEW_COLLEGE = {
	eng: "Recently Viewed College",
	bhs: "Kampus yang Terakhir Dilihat",
};
export const RECENTLY_VIEW_MAJOR = {
	eng: "Recently Viewed Major",
	bhs: "Jurusan yang Terakhir Dilihat",
};
export const OUR_COLLEGE_RECOMMENDATION = {
	eng: "Our Recommended College",
	bhs: "Kampus Rekomendasi Kami",
};
export const OUR_MAJOR_RECOMMENDATION = {
	eng: "Our Recommended Major",
	bhs: "Jurusan Rekomendasi Kami",
};
export const VIEW_ALL = {
	eng: "View All",
	bhs: "Selengkapnya",
};
export const LOGOUT_SUCCEED = {
	eng: "User logged out",
	bhs: "Pengguna berhasil keluar",
};
export const ACCREDITATION = {
	eng: "Accreditation",
	bhs: "Akreditasi",
};
export const ASIAN_RANKING = {
	eng: "QS Asia Ranking",
	bhs: "QS Asia Ranking",
};
export const WORLD_RANKING = {
	eng: "QS World Ranking",
	bhs: "QS World Ranking",
};
export const START = {
	eng: "Start",
	bhs: "Mulai dari",
};
export const SMT = {
	eng: "smt",
	bhs: "smt",
};

// PS Login Modal
export const EMAIL_USERNAME = {
	eng: "Email / Username",
	bhs: "Email / Username",
};
export const EMAIL_USERNAME_PLACEHOLDER = {
	eng: "Enter Your Email / Username",
	bhs: "Masukkan Email / Username Anda",
};
export const PASSWORD = {
	eng: "Password",
	bhs: "Kata Sandi",
};
export const FORGOT_PASSWORD = {
	eng: "Forgot Password",
	bhs: "Lupa Kata Sandi",
};
export const FILL_CORRECT_PASSWORD = {
	eng: "Password Empty",
	bhs: "Kata Sandi kosong",
};
export const FILL_CORRECT_USER_ID = {
	eng: "Email/Username Empty",
	bhs: "Email/Nama Pengguna kosong",
};
export const FILL_CORRECT_USER_ID_PASSWORD = {
	eng: "User Name & Password Empty",
	bhs: "Nama Pengguna & Kata Sandi kosong",
};

// Login Modal
export const LOGIN = {
	eng: "Login",
	bhs: "Masuk",
};
export const OR_FILL_FORM_BELOW = {
	eng: "Or fill the form below",
	bhs: "Atau isi form di bawah",
};
export const NEW_USER = {
	eng: "New User",
	bhs: "Pengguna Baru",
};
export const CLICK_HERE_CREATE_ACCOUNT = {
	eng: "Click here to create an account!",
	bhs: "Klik di sini untuk buat akun!",
};
export const LOGIN_SUCCESS = {
	eng: "Login success!",
	bhs: "Berhasil masuk!",
};
export const ERROR_INVALID_LOGIN = {
	eng: "Login failed, please check your username and/or password!",
	bhs: "Gagal untuk masuk, silakan periksa username dan/atau password kamu!",
};

// Create Account
export const LETS_CREATE_ACCOUNT = {
	eng: "Let's create a Direktori Kampus account",
	bhs: "Ayo buat akun Direktori Kampus",
};
export const SIGNUP_WITH_GOOGLE = {
	eng: "Sign up with Google",
	bhs: "Daftar dengan Google",
};
export const ENTER = {
	eng: "Enter",
	bhs: "Masukkan",
};
export const FIRST_NAME = {
	eng: "First Name",
	bhs: "Nama Depan",
};
export const LAST_NAME = {
	eng: "Last Name",
	bhs: "Nama Belakang",
};
export const EMAIL = {
	eng: "Email",
	bhs: "Email",
};
export const USERNAME = {
	eng: "Username",
	bhs: "Username/Nama Pengguna",
};
export const ALREADY_HAVE_ACCOUNT = {
	eng: "Already have an account?",
	bhs: "Sudah punya akun?",
};
export const CLICK_HERE_LOGIN = {
	eng: "Click here to log in",
	bhs: "Klik di sini untuk masuk",
};
export const CONFIRM_PASSWORD = {
	eng: "Confirm Password",
	bhs: "Konfirmasi Password/Kata Sandi",
};
export const PASSWORD_NOT_MATCHED = {
	eng: "Password and Confirm Password does not match",
	bhs: "Kata Sandi dan Konfirmasi Kata Sandi tidak sama",
};
export const ACCEPT_PRIVACY_POLICY = {
	eng: "I accept with the privacy policy of",
	bhs: "Saya setuju dengan kebijakan privasi",
};
export const PARENT_UNDERAGE_CONSENT = {
	eng: "This agreement needs to be given to your parent if you're still under legal age.",
	bhs: "Persetujuan ini harus diberikan oleh orang tua Anda jika Anda masih di bawah umur.",
};
export const REGISTER_SUCCESS = {
	eng: "Successfully creating a new account",
	bhs: "Berhasil membuat akun baru",
};
export const EMAIL_INVALID = {
	eng: "Invalid email!",
	bhs: "Email tidak valid!",
};
export const USERNAME_ACCEPT = {
	eng: "Username can only accept letters, digits, @, ., +, -, and _",
	bhs: "Username/Nama Pengguna hanya menerima huruf, angka, @, ., +, -, dan _",
};

// Forgot Password
export const FORGOT_PASSWORD_MESSAGE = {
	eng: "Please enter your username",
	bhs: "Silakan masukkan nama pengguna anda",
};
export const FORGOT_PASSWORD_MAIL_SENT = {
	eng: "We have sent a confirmation email. please check your email to reset your password",
	bhs: "Kami telah mengirimkan email konfirmasi. silakan cek email Anda untuk mengatur ulang kata sandi Anda",
};
export const FORGOT_PASSWORD_MAIL_SENT_SHORT = {
	eng: "Confirmation Email Sent!",
	bhs: "Email konfirmasi terkirim!",
};
export const SEND = {
	eng: "Send",
	bhs: "Kirim",
};
export const RESEND = {
	eng: "Resend",
	bhs: "Kirim Ulang",
};
export const BACK_TO_HOME = {
	eng: "Back to the Homepage",
	bhs: "Kembali ke Halaman Utama",
};
export const HAVENT_GOT_EMAIL = {
	eng: "Haven't got e-mail?",
	bhs: "Belum mendapatkan e-mail?",
};

// Profile Detail
export const EDIT_PROFILE = {
	eng: "Edit Profile",
	bhs: "Ubah Profil",
};
export const CHANGE_PASSWORD = {
	eng: "Change Password",
	bhs: "Ganti Password/Kata Sandi",
};
export const CURRENT_PASSWORD = {
	eng: "Current Password",
	bhs: "Password/Kata Sandi saat ini",
};
export const NEW_PASSWORD = {
	eng: "New Password",
	bhs: "Password/Kata Sandi baru",
};
export const SAVE_PROFILE = {
	eng: "Save Profile",
	bhs: "Simpan Profil",
};
export const PLEASE_FILL_NECESSARY_FORM = {
	eng: "Please fill all necessary form",
	bhs: "Mohon mengisi bagian yang wajib diisi",
};
export const PROFILE_DETAIL_CHANGED = {
	eng: "Profile detail changed!",
	bhs: "Detil profil diubah!",
};
export const PASSWORD_CHANGED = {
	eng: "Password changed!",
	bhs: "Kata Sandi/Password diubah!",
};
export const PROFILE = {
	eng: "Profile",
	bhs: "Profil",
};

// Listing
export const SHOWING = {
	eng: "Showing",
	bhs: "Menampilkan",
};
export const COLLEGES = {
	eng: "College(s)",
	bhs: "Kampus",
};
export const MAJORS = {
	eng: "Major(s)",
	bhs: "Jurusan",
};
export const CAREERS = {
	eng: "Careers(s)",
	bhs: "Karier",
};
export const SCHOLARSHIPS = {
	eng: "Scholarships(s)",
	bhs: "Beasiswa",
};
export const VIEW_MORE = {
	eng: "View More",
	bhs: "Tampilkan Lebih Banyak",
};
export const FILTERS = {
	eng: "Filters",
	bhs: "Filter",
};

// Filter Box
export const SRC_PLACEHOLDER = {
	eng: "Search...",
	bhs: "Cari...",
};
export const SRC_BY_PLACEHOLDER = {
	eng: "Search {0} Name",
	bhs: "Cari Nama {0}",
};
export const RESET = {
	eng: "Reset",
	bhs: "Reset",
};
export const APPLIED = {
	eng: "Applied",
	bhs: "Dilamar",
};
export const APPLY = {
	eng: "Apply",
	bhs: "Terapkan",
};
export const APPLY_JOB = {
	eng: "Apply",
	bhs: "Lamar",
};
export const POSTED = {
	eng: "Posted",
	bhs: "Tayang",
};
export const URGENCYY = {
	eng: "Urgency",
	bhs: "Urgensi",
};
export const ONE_WEEK = {
	eng: "1 Week",
	bhs: "1 Minggu",
};
export const APPLICANT = {
	eng: "Applicants",
	bhs: "Pelamar",
};
export const EXPERIENCE_ENTRY = {
	eng: "Entry Level",
	bhs: "Tingkat Masuk",
};
export const EXPERIENCE_ASSOCIATE = {
	eng: "Associate",
	bhs: "Kolega",
};
export const EXPERIENCE_INTERMEDIATE = {
	eng: "Intermediate",
	bhs: "Menengah",
};
export const EXPERIENCE_SENIOR = {
	eng: "Senior",
	bhs: "Atasan",
};
export const EXPERIENCE_DIRECTOR = {
	eng: "Director",
	bhs: "Direktur",
};
export const EXPERIENCE_EXECUTIVE = {
	eng: "Executive",
	bhs: "Pelaksana",
};
export const JOB_TYPE_FT = {
	eng: "Full Time",
	bhs: "Penuh Waktu",
};
export const JOB_TYPE_PT = {
	eng: "Part Time",
	bhs: "Paruh Waktu",
};
export const JOB_TYPE_FREEL = {
	eng: "Freelance",
	bhs: "Lepas",
};
export const JOB_TYPE_CONTRACT = {
	eng: "Contract",
	bhs: "Kontrak",
};
export const JOB_TYPE_INTERNSHIP = {
	eng: "Internship",
	bhs: "Magang",
};
export const JOB_TYPE_TEMPORARY = {
	eng: "Temporary",
	bhs: "Sementara",
};
export const URGENCY_1_TO_3 = {
	eng: "1 to 3 days",
	bhs: "1 sampai 3 hari",
};
export const URGENCY_3_TO_7 = {
	eng: "3 to 7 days",
	bhs: "3 sampai 7 hari",
};
export const URGENCY_1_TO_2 = {
	eng: "1 to 2 weeks",
	bhs: "1 sampai 2 minggu",
};
export const URGENCY_2_TO_4 = {
	eng: "2 to 4 weeks",
	bhs: "2 sampai 4 minggu",
};
export const URGENCY_MORE_4 = {
	eng: "More than 4 weeks",
	bhs: "Lebih dari 4 minggu",
};
export const WORK_MODEL_HYBRID = {
	eng: "Hybrid",
	bhs: "Hibrida",
};
export const WORK_MODEL_ONSITE = {
	eng: "Onsite",
	bhs: "Ditempat",
};
export const WORK_MODEL_REMOTE = {
	eng: "Remote",
	bhs: "Jarak Jauh",
};
// College Listing
export const LOCATION = {
	eng: "Location",
	bhs: "Lokasi",
};
export const SRC_LOCATION = {
	eng: "Search Location",
	bhs: "Cari Lokasi",
};
export const SRC_ACCREDITATION = {
	eng: "Search Accreditation",
	bhs: "Cari Akreditasi",
};
export const DEGREE = {
	eng: "Degree",
	bhs: "Level Studi",
};
export const SRC_MAJOR = {
	eng: "Search Major",
	bhs: "Cari Jurusan",
};
export const ALL_MAJORS = {
	eng: "All majors",
	bhs: "Semua jurusan",
};
export const DIPLOMA_1 = {
	eng: "Diploma 1",
	bhs: "Diploma 1",
};
export const DIPLOMA_2 = {
	eng: "Diploma 2",
	bhs: "Diploma 2",
};
export const DIPLOMA_3 = {
	eng: "Diploma 3",
	bhs: "Diploma 3",
};
export const DIPLOMA_4 = {
	eng: "Diploma 4",
	bhs: "Diploma 4",
};
export const UNDERGRAD = {
	eng: "Undergraduate",
	bhs: "Sarjana",
};
export const POSTGRAD = {
	eng: "Postgraduate",
	bhs: "Pasca Sarjana",
};
export const DOCTORAL = {
	eng: "Doctoral",
	bhs: "Doktoral",
};
export const SOMETHING_WENT_WRONG_TRY_AGAIN = {
	eng: "Something went wrong please try again after some time!",
	bhs: "Ada yang tidak beres, coba lagi setelah beberapa saat!",
};
export const STATE_UNIVERSITY_ABBV = {
	eng: "State",
	bhs: "Negeri",
};
export const PRIVATE_UNIVERSITY_ABBV = {
	eng: "Private",
	bhs: "Swasta",
};
export const SHOW_ALL = {
	eng: "Show All",
	bhs: "Lihat Semua",
};
export const YES = {
	eng: "Yes",
	bhs: "Ya",
};
export const NO = {
	eng: "No",
	bhs: "Tidak",
};
export const NO_ACCREDITATION = {
	eng: "No Accreditation",
	bhs: "Tidak Terakreditasi",
};
export const OTHER_ACCREDITATION = {
	eng: "Other Accreditation",
	bhs: "Akreditasi Lainnya",
};
export const SELECTED = {
	eng: "{0} selected",
	bhs: "{0} terpilih",
};

// Major Listing
export const FACULTY_NAME = {
	eng: "Faculty Name",
	bhs: "Bidang Studi",
};
export const TUITION_FEE_SMT = {
	eng: "Tuition Fee per semester",
	bhs: "Biaya Per Semester",
};
export const ALL_FACULTY = {
	eng: "All Faculties",
	bhs: "Semua bidang studi",
};
export const ALL_TUITION = {
	eng: "All Tuition",
	bhs: "Semua biaya",
};
export const SRC_FACULTY = {
	eng: "Search Faculty",
	bhs: "Cari Fakultas",
};

// Career Listing
export const SECTOR = {
	eng: "Sector",
	bhs: "Karier",
};
export const ALL_SECTOR = {
	eng: "All Sectors",
	bhs: "Semua Karier",
};

// Scholarship Listing
export const SCHOLARSHIP_GIVER = {
	eng: "Scholarship giver",
	bhs: "Jenis",
};
export const ALL_SCHOLARSHIP_GIVER = {
	eng: "All Scholarship Giver",
	bhs: "Semua Jenis",
};
export const GOVERNMENT = {
	eng: "Government",
	bhs: "Pemerintah",
};
export const ORGANIZATION = {
	eng: "Organization",
	bhs: "Organisasi",
};
export const INTERNATIONAL = {
	eng: "International",
	bhs: "Internasional",
};
export const DESTINATION = {
	eng: "Destination",
	bhs: "Tujuan",
};
export const ALL_DESTINATION = {
	eng: "All Destinations",
	bhs: "Semua Tujuan",
};
export const DOMESTIC = {
	eng: "Domestic",
	bhs: "Dalam Negeri",
};
export const ABROAD = {
	eng: "Abroad",
	bhs: "Luar Negeri",
};
export const INTERNATIONAL_DESTINATION = {
	eng: "International",
	bhs: "Luar Negeri",
};
export const UNTIL = {
	eng: "Until",
	bhs: "Hingga",
};

// College Details
export const GENERAL_INFORMATION = {
	eng: "General Information",
	bhs: "Informasi Umum",
};
export const COLLEGE_NOT_FOUND = {
	eng: "Selected college not found!",
	bhs: "Tidak dapat menemukan kampus terpilih",
};
export const RANKING = {
	eng: "Ranking",
	bhs: "Ranking",
};
export const BASED_ON_WEBOMETRIC_INDO = {
	eng: "Based on Webometrics Country Rank in Indonesia",
	bhs: "Berdasarkan Webometrics Country Rank di Indonesia",
};
export const STATE_UNIVERSITY = {
	eng: "State University",
	bhs: "Universitas Negeri",
};
export const PRIVATE_UNIVERSITY = {
	eng: "Private University",
	bhs: "Universitas Swasta",
};
export const ADD_TO_FAVORITES = {
	eng: "Add to Favorites",
	bhs: "Tambahkan ke Favorit",
};
export const DOWNLOAD_BROCHURE = {
	eng: "Download Brochure",
	bhs: "Download Brosur",
};
export const REMOVE_FROM_FAVORITES = {
	eng: "Remove from Favorites",
	bhs: "Hapus dari Favorit",
};
export const STUDENT = {
	eng: "Student",
	bhs: "Mahasiswa",
};
export const LECTURER = {
	eng: "Lecturer",
	bhs: "Dosen",
};
export const WEBSITE = {
	eng: "Website",
	bhs: "Website",
};
export const PHONE = {
	eng: "Phone Number",
	bhs: "Nomor HP",
};
export const MOBILE_NUMBER_1 = {
	eng: "Mobile phone number 1",
	bhs: "Nomor HP 1",
};
export const ALTERNATE_MOBILE_NUMBER = {
	eng: "Mobile phone number 2",
	bhs: "Nomor HP 2",
};
export const WHATSAPP = {
	eng: "WhatsApp",
	bhs: "WhatsApp",
};
export const DATA_NOT_AVAILABLE = {
	eng: "Data is not available",
	bhs: "Data tidak tersedia",
};
export const COLLEGE_DESCRIPTION = {
	eng: "College Description",
	bhs: "Deskripsi Kampus",
};
export const SRC_MAJOR_PLACEHOLDER = {
	eng: "Search Major",
	bhs: "Cari Jurusan",
};
export const FACILITIES = {
	eng: "Facilities",
	bhs: "Fasilitas",
};
export const FACULTIES_AND_MAJORS = {
	eng: "Faculties and Majors",
	bhs: "Fakultas dan Jurusan",
};
export const GENERAL_TUITION_FEES = {
	eng: "General Tuition Fees",
	bhs: "Biaya Kuliah",
};
export const SCHOLARSHIP_INFORMATION = {
	eng: "Scholarship Information",
	bhs: "Informasi Beasiswa",
};
export const MORE_INFO = {
	eng: "More Info",
	bhs: "Informasi Lebih Banyak",
};
export const TESTIMONY = {
	eng: "Testimony",
	bhs: "Testimoni",
};
export const LOCATIONS = {
	eng: "Location(s)",
	bhs: "Lokasi",
};
export const OPEN_IN_MAP = {
	eng: "Open in Map",
	bhs: "Lihat di Peta",
};
export const CAMPUS_NUM = {
	eng: "Campus {0}",
	bhs: "Kampus {0}",
};

// Major Details
export const MAJOR_NOT_FOUND = {
	eng: "Selected major not found!",
	bhs: "Tidak dapat menemukan jurusan terpilih",
};
export const FACULTY = {
	eng: "Faculty",
	bhs: "Fakultas",
};
export const MAJOR_DESCRIPTION = {
	eng: "Major Description",
	bhs: "Deskripsi Jurusan",
};
export const REGISTRATION_FEE = {
	eng: "Registration Fee",
	bhs: "Biaya Pendaftaran",
};
export const STUDY_TIME = {
	eng: "Study Time (Years)",
	bhs: "Lama Studi (Tahun)",
};
export const SKS = {
	eng: "SKS",
	bhs: "SKS",
};
export const NUMBER_STUDENT = {
	eng: "Number of Student",
	bhs: "Jumlah Siswa",
};
export const NUMBER_LECTURER = {
	eng: "Number of Lecturer",
	bhs: "Jumlah Dosen",
};
export const MAJOR_WEBSITE = {
	eng: "Major Website",
	bhs: "Website Jurusan",
};
export const MAJOR_EMAIL = {
	eng: "Major Email",
	bhs: "Email Jurusan",
};
export const MAJOR_PHONE = {
	eng: "Major Phone",
	bhs: "Telepon Jurusan",
};
export const REGISTRATION_INFO = {
	eng: "Registration Info",
	bhs: "Info Registrasi",
};
export const STUDY_FEES = {
	eng: "Study Fees",
	bhs: "Biaya Kuliah",
};
export const CURRICULUM = {
	eng: "Curriculum",
	bhs: "Kurikulum",
};
export const ACHIEVEMENTS = {
	eng: "Achievements",
	bhs: "Prestasi",
};
export const CONCENTRATION = {
	eng: "Concentration",
	bhs: "Konsentrasi",
};
export const CARRER_PATH = {
	eng: "Career Path",
	bhs: "Karier Terkait",
};
export const CARRER_PATH_DESC = {
	eng: "If you study in this major, you can work as:",
	bhs: "Jika kamu kuliah di jurusan ini, maka kamu dapat bekerja sebagai:",
};

// Career Details
export const CAREER_NOT_FOUND = {
	eng: "Selected career not found!",
	bhs: "Tidak dapat menemukan karier terpilih",
};
export const START_FROM = {
	eng: "Starts from",
	bhs: "Dimulai dari",
};
export const SALARY = {
	eng: "Salary",
	bhs: "Gaji",
};
export const MONTHLY = {
	eng: "Monthly",
	bhs: "Bulanan",
};
export const SALARY_RANGE = {
	eng: "salary range",
	bhs: "kisaran gaji",
};
export const MIN = {
	eng: "Min",
	bhs: "Min",
};
export const MAX = {
	eng: "Max",
	bhs: "Maks",
};
export const MIN_DEGREE = {
	eng: "Minimum Degree",
	bhs: "Minimal Pendidikan",
};
export const CERTIFICATION = {
	eng: "Certification",
	bhs: "Sertifikasi",
};
export const CAREER_DESCRIPTION = {
	eng: "Career Description",
	bhs: "Deskripsi Karier",
};
export const FACT_STATS = {
	eng: "Fact Stats",
	bhs: "Fakta dan Statistik",
};
export const ROLE_RESPONSIBILITIES = {
	eng: "Role and Responsibilities",
	bhs: "Peran dan Tanggung Jawab",
};
export const REQUIRED_SKILLS = {
	eng: "Knowledge and Skills Required",
	bhs: "Pengetahuan dan Keahlian",
};
export const CAREER_PATH = {
	eng: "Career Path",
	bhs: "Jenjang Karier",
};
export const RELATED_MAJOR = {
	eng: "Faculty/Major Related",
	bhs: "Fakultas/Jurusan Terkait",
};

// Scholarship Details
export const SCHOLARSHIP_NOT_FOUND = {
	eng: "Selected scholarship not found!",
	bhs: "Tidak dapat menemukan beasiswa terpilih",
};
export const REGISTRATION_PERIOD = {
	eng: "Registration Period",
	bhs: "Pendaftaran",
};
export const SCHOLARSHIP_DESCRIPTION = {
	eng: "Scholarship Description",
	bhs: "Deskripsi Beasiswa",
};
export const REGISTRATION_TIMELINE = {
	eng: "Registration Timeline",
	bhs: "Info Proses Pendaftaran",
};
export const IMPORTANT_INFO = {
	eng: "Important Info",
	bhs: "Info Penting",
};
export const DOCUMENTS_REQUIRED = {
	eng: "Documents Required",
	bhs: "Berkas yang dibutuhkan",
};
export const SPECIAL_REQS = {
	eng: "Special Requirements",
	bhs: "Syarat khusus yang perlu diperhatikan",
};
export const LEVEL_OF_COMPS = {
	eng: "Level of Competition",
	bhs: "Tingkat persaingan untuk beasiswa",
};
export const SELECTION_PERIOD = {
	eng: "Selection Period",
	bhs: "Periode seleksi beasiswa",
};
export const CHECKING_ANNOUNCEMENT = {
	eng: "Checking Announcement",
	bhs: "Bagaimana cara mengetahui saya diterima atau tidak?",
};
export const OTHER_EXPLANATION = {
	eng: "Other Explanation",
	bhs: "Penjelasan lain-lain",
};
export const WHAT_BENEFIT = {
	eng: "What benefit you will get?",
	bhs: "Benefit apa saja yang didapat?",
};

// Favorite page
export const HOME = {
	eng: "Home",
	bhs: "Home",
};
export const NO_FAVORITE_TITLE = {
	eng: "You haven't save any favorite {0}",
	bhs: "Anda belum menyimpan {0} favorit Anda",
};
export const NO_FAVORITE_DESC = {
	eng: "Go to the {0} page here and mark as favorite",
	bhs: "Lihat halaman {0} di sini dan tandai sebagai favorit",
};
export const GENERAL_PAGE = {
	eng: "{0} Page",
	bhs: "Halaman {0}",
};
export const FAILED_FETCH_FAVORITES = {
	eng: "Failed to fetch favorites",
	bhs: "Gagal mendapatkan data favorit",
};
export const SUCCESS_ADD_FAVORITES = {
	eng: "Successfully adding this {0} to the favorite",
	bhs: "Berhasil menambahkan {0} ini ke favorit",
};
export const SUCCESS_REMOVE_FAVORITES = {
	eng: "Successfully removing this {0} from the favorite",
	bhs: "Berhasil menghapus {0} ini dari favorit",
};
export const FAILED_ADD_FAVORITES = {
	eng: "Failed to add this {0} to the favorite",
	bhs: "Gagal menambahkan {0} ini ke favorit",
};
export const FAILED_REMOVE_FAVORITES = {
	eng: "Failed to remove this {0} from the favorite",
	bhs: "Gagal menghapus {0} ini dari favorit",
};
export const CANNOT_GET_DATA = {
	eng: "Cannot get the sufficient data to do this operation",
	bhs: "Tidak dapat mendapatkan data untuk melakukan operasi ini",
};
export const SCROLL_TO_TOP = {
	eng: "Scroll to Top",
	bhs: "Scroll ke atas",
};

// Component
export const LOGIN_PORTAL_KERJA = {
	eng: "Log in to Portal Kerja",
	bhs: "Masuk ke Portal Kerja",
};
export const BUTTON_CTA = {
	eng: "Button CTA",
	bhs: "Button CTA",
};
export const LOCATION_LABEL = {
	eng: "Location",
	bhs: "Lokasi",
};
export const SEARCH_LABEL = {
	eng: "Search",
	bhs: "Pencarian",
};
export const COLLEGE_LABEL = {
	eng: "College",
	bhs: "Universitas",
};
export const REQUIRED_LABEL = {
	eng: "Required",
	bhs: "Diperlukan",
};
export const LABEL_NAME = {
	eng: "Label",
	bhs: "Label",
};
export const DATE_LABEL = {
	eng: "Date Post",
	bhs: "Tanggal Unggah",
};
export const JOB_TYPE_LABEL = {
	eng: "Job Type",
	bhs: "Tipe Pekerjaan",
};
export const WORK_MODE_LABEL = {
	eng: "Work Mode",
	bhs: "Mode Kerja",
};
export const EXPERIENCE_LEVEL_LABEL = {
	eng: "Experience Level",
	bhs: "Level Pengalaman",
};
export const WORK_MODEL = {
	eng: "Work Model",
	bhs: "Model Kerja",
};
export const INDUSTRY_LABEL = {
	eng: "Industry",
	bhs: "Industri",
};
export const UNDER_TEN_APP_LABEL = {
	eng: "Under 10 applicants",
	bhs: "Di Bawah 10 pelamar",
};
export const JOB_BENEFIT = {
	eng: "Job Benefit",
	bhs: "Tunjangan Pekerjaan",
};
export const ANY_TIME_LABEL = {
	eng: "Any time",
	bhs: "Semua Waktu",
};
export const LAST_24_HOURS = {
	eng: "Last 24 hours",
	bhs: "24 Jam Lalu",
};
export const LAST_3_DAYS = {
	eng: "Last 3 days",
	bhs: "3 Hari Lalu",
};
export const LAST_7_DAYS = {
	eng: "Last 7 days",
	bhs: "7 Hari Lalu",
};
export const LAST_14_DAYS = {
	eng: "Last 14 days",
	bhs: "14 Hari Lalu",
};
export const LAST_30_DAYS = {
	eng: "Last 30 days",
	bhs: "30 Hari Lalu",
};
export const ONSITE_LABEL = {
	eng: "On site",
	bhs: "Di tempat",
};
export const HYBRID_LABEL = {
	eng: "Hybrid",
	bhs: "Hibrid",
};
export const REMOTE_LABEL = {
	eng: "Remote",
	bhs: "Remote",
};
export const FULL_TIME_LABEL = {
	eng: "Full Time",
	bhs: "Penuh Waktu",
};
export const PART_TIME_LABEL = {
	eng: "Part Time",
	bhs: "Paruh Waktu",
};
export const FRELANCE_LABEL = {
	eng: "Freelance",
	bhs: "Pekerja Lepas",
};
export const CONTRACT_LABEL = {
	eng: "Contract",
	bhs: "Kontrak",
};
export const INTERNSHIP_LABEL = {
	eng: "Internship",
	bhs: "Magang",
};
export const TEMPORARY_LABEL = {
	eng: "Temporary",
	bhs: "Temporer",
};
export const ENTRY_LEVELLABEL = {
	eng: "Entry Level",
	bhs: "Pemula",
};
export const ASSOCIATE_LABEL = {
	eng: "Associate",
	bhs: "Junior",
};
export const INTERMEDIATE_LABEL = {
	eng: "Intermediate",
	bhs: "Staff",
};
export const SENIOR_LABEL = {
	eng: "Senior",
	bhs: "Senior",
};
export const DIRECTOR_LABEL = {
	eng: "Director",
	bhs: "Direktur",
};
export const EXECUTIVE_LABEL = {
	eng: "Executive",
	bhs: "Eksekutif",
};
export const INPUT_PLACEHOLDER = {
	eng: "Input your text",
	bhs: "Masukkan tulisan anda",
};
export const EMAIL_PLACEHOLDER = {
	eng: "olivia@portalkerja.com",
	bhs: "olivia@portalkerja.com",
};
export const SALARY_PLACEHOLDER = {
	eng: "5.000",
	bhs: "5.000",
};
export const PHONE_PLACEHOLDER = {
	eng: "08777752364",
	bhs: "08777752364",
};
export const HINT_MESSAGE = {
	eng: "This is a hint text to help user",
	bhs: "Ini adalah bantuan untuk membimbing pengguna",
};
export const ERROR_MESSAGE = {
	eng: "This field is required",
	bhs: "Field ini harus di isi",
};
export const ERROR_HINT_MESSAGE = {
	eng: "This is an error message",
	bhs: "Ini adalah pesan error",
};
export const TEXTAREA = {
	eng: "Description",
	bhs: "Deskripsi",
};
export const JOBS_LABEL = {
	eng: "Jobs",
	bhs: "Pekerjaan",
};
export const HELP_LABEL = {
	eng: "Help",
	bhs: "Bantuan",
};
export const PRIVACY_LABEL = {
	eng: "Privacy",
	bhs: "Privasi",
};
export const COMPANY = {
	eng: "Company",
	bhs: "Perusahaan",
};
export const COMPANY_NAME = {
	eng: "Company Name",
	bhs: "Nama Perusahaan",
};
export const COMPANY_PLACEHOLDER = {
	eng: "Company Three",
	bhs: "Perusahaan",
};
export const REMOVE_LABEL = {
	eng: "Remove",
	bhs: "Hapus",
};
export const READ_MORE = {
	eng: "Read More",
	bhs: "Lebih Banyak",
};
export const TO = {
	eng: "to",
	bhs: "ke",
};

// Pagination
export const OF_LABEL = {
	eng: "of",
	bhs: "dari",
};
export const PAGE_LABEL = {
	eng: "Page",
	bhs: "Halaman",
};

// Home Component
export const POPULAR_JOBS = {
	eng: "Popular Jobs",
	bhs: "Pekerjaan Populer",
};
export const TRENDING_JOBS = {
	eng: "Trending Jobs",
	bhs: "Pekerjaan Tren",
};

export const ENTER_YOUR_EMAIL = {
	eng: "Enter Your Email",
	bhs: "Masukkan Email anda",
};
export const COMPARE_JOB_LABEL = {
	eng: "Compare Job",
	bhs: "Compare Job",
};
export const SUBSCRIBE_LABEL = {
	eng: "Subscribe",
	bhs: "Subscribe",
};
export const UNSUBSCRIBE_LABEL = {
	eng: "Unsubscribed",
	bhs: "Unsubscribed",
};

// Modal Component
export const REPORT_THIS_JOB = {
	eng: "Report This Job",
	bhs: "Laporkan Pekerjaan Ini",
};
export const REASON_LABEL = {
	eng: "Reason",
	bhs: "Alasan",
};
export const SELECT_REASON = {
	eng: "Select the reason for report this job",
	bhs: "Pilih alasan untuk melaporkan pekerjaan ini",
};
export const SPAM_OR_SCAM = {
	eng: "Spam or Scam",
	bhs: "Spam atau Scam",
};
export const DISCRIMINATION_OR_OFFENSIVE = {
	eng: "Discrimination or Offensive",
	bhs: "Diskriminasi atau Penyerangan",
};
export const MISSLEADING = {
	eng: "Missleading",
	bhs: "Menyesatkan",
};
export const OTHER = {
	eng: "Other",
	bhs: "Lainnya",
};
export const ADDITIONAL_COMMENTS = {
	eng: "Additional Comments",
	bhs: "Komentar Tambahan",
};
export const ENTER_DESCRIPTION = {
	eng: "Enter Description ...",
	bhs: "Masukkan Deskripsi ...",
};
export const SUBMIT_LABEL = {
	eng: "Submit",
	bhs: "Kirim",
};
export const CLOSE_LABEL = {
	eng: "Close",
	bhs: "Kembali",
};
export const REPORT_SUCCESS_TITLE = {
	eng: "Thank you for submitting a report",
	bhs: "Terima kasih sudah memberikan laporan",
};
export const REPORT_SUCCESS_TEXT = {
	eng: "We take report Seriously and after a throught review, our support team will get back to you.",
	bhs: "Kami menanggapi laporan dengan serius dan setelah tinjauan menyeluruh, tim pendukung kami akan menghubungi Anda kembali.",
};

// Job Searching
export const FIND_JOB = {
	eng: "Find Job",
	bhs: "Cari",
};

export const JOB_SEARCH_PLACEHOLDER = {
	eng: "Job title, Keywords, Company",
	bhs: "Posisi, Kata Kunci, Perusahaan",
};
export const JOB_SEARCH_PLACEHOLDER_HOMEPAGE = {
	eng: "Job title, Keywords, Company",
	bhs: "Posisi, Perusahaan",
};
export const LOCATION_PLACEHOLDER = {
	eng: "Area, city",
	bhs: "Lokasi",
};
export const JOB_MATCHES = {
	eng: "{0}-{1} of {2} job matches",
	bhs: "{0}-{1} dari {2} pekerjaan ditemukan",
};
export const PER_MONTH = {
	eng: "per month",
	bhs: "per bulan",
};
export const POSTED_AGO = {
	eng: "Posted {0}",
	bhs: "Tayang {0}",
};
export const URGENCY = {
	eng: "Urgency: {0}",
	bhs: "Urgensi: {0}",
};
export const APPLICANTS = {
	eng: "{0} Applicants",
	bhs: "{0} Pelamar",
};
export const OPEN_IN_NEW_TAB = {
	eng: "Open in new tab",
	bhs: "Buka di tab baru",
};
export const APPLY_NOW = {
	eng: "Apply Now",
	bhs: "Lamar Sekarang",
};
export const ADD_TO_COMPARE = {
	eng: "Add to Compare",
	bhs: "Bandingkan Pekerjaan",
};
export const SAVE_JOB = {
	eng: "Save Job",
	bhs: "Simpan",
};
export const SAVED = {
	eng: "Saved",
	bhs: "Disimpan",
};
export const FULL_JOB_DESC = {
	eng: "Full Job Description",
	bhs: "Deskripsi Lengkap Pekerjaan",
};
export const FULL_JOB_DESCRIPTION = {
	eng: "Full Job Description",
	bhs: "Deskripsi Pekerjaan",
};
export const QUALIFICATIONS = {
	eng: "Qualifications",
	bhs: "Kualifikasi",
};
export const RESPONSIBILITIES = {
	eng: "Responsibilities",
	bhs: "Tanggung jawab",
};
export const JOB_DETAILS = {
	eng: "Job Details",
	bhs: "Detail Pekerjaan",
};
export const MORE_JOB_SEARCH = {
	eng: "More Job Search",
	bhs: "Cari Lebih Banyak Pekerjaan",
};
export const REPORT = {
	eng: "Report",
	bhs: "Laporkan",
};

// Job Detail
export const SPECIFIED_JOB_NOT_FOUND = {
	eng: "Selected job not found!",
	bhs: "Tidak dapat menemukan pekerjaan",
};
export const RECOMMENDATION = {
	eng: "Recommendation",
	bhs: "Recommendation",
};

// Sign Up
export const SIGN_UP = {
	eng: "Sign Up",
	bhs: "Mendaftar",
};
export const PASSWORD_HELPER_TEXT = {
	eng: "Password must be atleast 8 characters long, and should contain a number, an upper case and a lower case letter",
	bhs: "Kata sandi harus setidaknya 8 karakter, dan harus berisi angka, huruf besar dan huruf kecil",
};
export const USER_ALREADY_EXIST_TEXT = {
	eng: "The email is already registered, please user another email",
	bhs: "Email sudah terdaftar, silakan pengguna email lain",
};
export const ONBOARDING_1 = {
	eng: "Get Inspired",
	bhs: "Dapatkan Inspirasi",
};
export const ONBOARDING_1_DESC = {
	eng: "Receive career advice, tips, announcements, and other commercial emails from Portal Kerja",
	bhs: "Terima saran karier, tips, pengumuman, dan email komersial lainnya dari Portal Kerja",
};
export const ONBOARDING_2 = {
	eng: "Connect the best candidates and employers",
	bhs: "Mempertemukan kandidat dan perusahaan terbaik",
};
export const ONBOARDING_2_DESC = {
	eng: "Increase opportunity to find your dream job with Portal Kerja",
	bhs: "Tingkatkan kesempatan untuk menemukan pekerjaan impian dengan Portal Kerja",
};
export const ONBOARDING_3 = {
	eng: "One profile, million opportunities",
	bhs: "Satu profil, jutaan peluang",
};
export const ONBOARDING_3_DESC = {
	eng: "Complete profile easily to be noticed by employers",
	bhs: "Lengkapi profil dengan mudah agar dikenali perusahaan",
};
export const CHECK_YOUR_EMAIL = {
	eng: "Check your email",
	bhs: "Periksa email Anda",
};
export const RESEND_IN = {
	eng: "Resend in",
	bhs: "Ulang",
};
export const SIGNUP_EMAIL_SENT_1 = {
	eng: "We have sent an email to ",
	bhs: "Kami telah mengirim email ke ",
};
export const SIGNUP_EMAIL_SENT_2 = {
	eng: " verify your account",
	bhs: " verifikasi akun anda",
};
export const REGISTER_ACCOUNT = {
	eng: "Register Account",
	bhs: "Daftar akun",
};

export const SIGN_UP_TITLE = {
	eng: "Please Confirm the validity of your email address",
	bhs: "Harap Konfirmasi validitas alamat email Anda",
};
export const VERIFICATION_TITLE = {
	eng: "Check your email for an verification link",
	bhs: "Periksa email Anda untuk tautan verifikasi",
};
export const MODAL_VERIFICATION_TITLE = {
	eng: "Check your email",
	bhs: "Periksa email Anda",
};
export const HELP_TITLE = {
	eng: "Didnt receive the email? please check your spam folder or try to resend the email.",
	bhs: "Tidak menerima email? silakan periksa folder spam Anda atau coba kirim ulang email.",
};
export const BACK_TO_LOGIN = {
	eng: "Back to login",
	bhs: "Kembali ke login",
};
export const SEND_EMAIL = {
	eng: "Send email",
	bhs: "Kirim email",
};
export const HELP_TEXT_FORGOT = {
	eng: "No worries, we’ll send you reset instruction.",
	bhs: "Jangan khawatir, kami akan mengirimkan instruksi reset.",
};
export const RESET_PASSWORD = {
	eng: "Reset Password",
	bhs: "Ubah Kata Sandi",
};

export const RESET_PASSWORD_VERIFICATION = {
	eng: "We have sent an email for verification to ",
	bhs: "Kami telah mengirimkan email untuk verifikasi ke ",
};
export const UPLOAD_FAILED = {
	eng: "Upload Failed",
	bhs: "Gagal Mengunggah",
};
export const FORMAT_NOT_SUPPORTED = {
	eng: "The file format is not supported",
	bhs: "Format file tidak didukung",
};
// Reset Password
export const SET_NEW_PASSWORD = {
	eng: "Set New Password",
	bhs: "Atur kata sandi baru",
};
export const HELP_TEXT_NP = {
	eng: "Set your new password",
	bhs: "Setel Kata Sandi Baru Anda",
};
export const PASSWORD_RESET = {
	eng: "Password Reset",
	bhs: "Reset Kata Sandi",
};
export const PASSWORD_RESET_DESC = {
	eng: "Your password has been successfully reset",
	bhs: "Kata sandi Anda telah berhasil diatur ulang",
};
export const CONTINUE = {
	eng: "Continue",
	bhs: "Melanjutkan",
};
export const RESET_PASSWORD_FAILED_1 = {
	eng: "Password / Confirm Password are mandatory fields",
	bhs: "Kata Sandi / Konfirmasi Kata Sandi adalah bidang wajib",
};
export const RESET_PASSWORD_FAILED_2 = {
	eng: "Password / Confirm Password do not match",
	bhs: "Kata Sandi / Konfirmasi Kata sandi tidak cocok",
};

export const NO_JOB_FOUND = {
	eng: "We couldn't find any jobs matching your search",
	bhs: "Kami tidak dapat menemukan pekerjaan yang cocok dengan pencarian Anda",
};
export const CHECK_SPELLING_CRITERIA = {
	eng: "Check the spelling and adjust the filter criteria",
	bhs: "Periksa ejaan dan sesuaikan kriteria filter",
};
export const NO_JOB_FOUND_2 = {
	eng: "Bring together the best candidates and employers",
	bhs: "Mempertemukan pencari kerja terbaik dengan perusahaan terbaik",
};
export const NO_JOB_FOUND_DESC_2 = {
	eng: "Increase opportunity to find your dream job with Portal Kerja",
	bhs: "Tingkatkan kesempatan untuk mendapatkan pekerjaan impian Anda dengan Portal Kerja",
};
export const SEARCH_JOB = {
	eng: "Search Jobs",
	bhs: "Cari Kerja",
};
export const INPUT = {
	eng: "Input",
	bhs: "Ketik",
};
export const OPTIONAL = {
	eng: "Optional",
	bhs: "Opsional",
};

// complete profile
export const ABOUT_ME = {
	eng: "About Me",
	bhs: "Tentang saya",
};
export const EDUCATION = {
	eng: "Education",
	bhs: "Pendidikan",
};
export const EXPERIENCE = {
	eng: "Experience",
	bhs: "Pengalaman",
};
export const SKILLS = {
	eng: "Skills",
	bhs: "Keahlian",
};
export const LANGUAGE = {
	eng: "Language",
	bhs: "Bahasa",
};
export const PORTFOLIO_RESUME = {
	eng: "Portfolio & Resume",
	bhs: "Portfolio & Resume",
};
export const JOB_RESUME_PREF = {
	eng: "Job & Resume Preference",
	bhs: "Preferensi Pekerjaan & Lanjutkan",
};

export const PERSONAL_INFORMATION = {
	eng: "Personal Information",
	bhs: "Informasi pribadi",
};
export const CONTACT_INFO = {
	eng: "Contact Info",
	bhs: "Info kontak",
};
export const PROFILE_PICTURE = {
	eng: "Profile Picture",
	bhs: "Foto Profil",
};
export const DOB = {
	eng: "Date of Birth",
	bhs: "Tanggal lahir",
};
export const DATE = {
	eng: "Date",
	bhs: "Tanggal",
};
export const MONTH = {
	eng: "month",
	bhs: "bulan",
};
export const YEAR = {
	eng: "year",
	bhs: "tahun",
};
export const SELECT = {
	eng: "Select",
	bhs: "Pilih",
};
export const RESULT_LABEL = {
	eng: "Result",
	bhs: "Hasil",
};
export const GENDER = {
	eng: "Gender",
	bhs: "Jenis kelamin",
};
export const MALE = {
	eng: "Male",
	bhs: "Pria",
};
export const FEMALE = {
	eng: "Female",
	bhs: "Perempuan",
};
export const VERIFIED = {
	eng: "Verified",
	bhs: "Diverifikasi",
};
export const VERIFY_EMAIL_DESC = {
	eng: "Your account has been verified",
	bhs: "Akun Anda telah diverifikasi",
};
export const NEXT_STEP = {
	eng: "Next Step",
	bhs: "Selanjutnya",
};
export const SKIP_STEP = {
	eng: "Skip",
	bhs: "Lewati",
};
export const COUNTRY = {
	eng: "Country",
	bhs: "Negara",
};
export const STREET_ADDRESS = {
	eng: "Street Address",
	bhs: "Alamat jalan",
};
export const POSTAL_CODE = {
	eng: "Postal Code",
	bhs: "Kode Pos",
};
export const NATIONALITY = {
	eng: "Nationality",
	bhs: "Kebangsaan",
};
export const DRAG_FILE = {
	eng: "Drag file here to upload or ",
	bhs: "Tarik berkas ke sini atau ",
};
export const UPLOAD_FILE = {
	eng: "Upload file here or ",
	bhs: "Unggah berkas di sini atau ",
};
export const CHOOSE_FILE = {
	eng: "choose file",
	bhs: "pilih berkas",
};
export const FILE_FORMAT_SIZE = {
	eng: "{0} or {1} format are allowed. Maximum {2} MB",
	bhs: "Format yang diperbolehkan: {0} atau {1}. Maksimal {2} MB",
};
export const FILE_FORMAT_SIZE_2 = {
	eng: "File allowed only with type of {0}, or {1} format. PNG or PDF format, maximum {2} MB",
	bhs: "Hanya 1 file dengan format {0}, atau {1} yang diizinkan. Format PNG atau PDF, maksimal {2} MB",
};
export const REVIEW_EDUCATION = {
	eng: "Review Education",
	bhs: "Review Pendidikan",
};

// Education
export const EXAMPLE_ABBV = {
	eng: "Ex:",
	bhs: "Contoh:",
};
export const SCHOOL_COLLEGE_NAME = {
	eng: "School or College name",
	bhs: "Nama Sekolah atau Universitas",
};
export const CURRENTLY_ENROLLED = {
	eng: "Currently Enrolled",
	bhs: "Masih Bersekolah di sini",
};
export const START_DATE = {
	eng: "Start date",
	bhs: "Tanggal Masuk",
};
export const START_DATE_EXPERIENCE = {
	eng: "Start Date",
	bhs: "Tanggal Mulai",
};
export const GRADUATION_DATE = {
	eng: "Graduation date (or expected)",
	bhs: "Tanggal Lulus (ekspektasi)",
};
export const FIELD_OF_STUDY = {
	eng: "Field of study",
	bhs: "Bidang studi",
};
export const LEVEL_OF_EDUCATION = {
	eng: "Level of Education",
	bhs: "Jenjang Pendidikan",
};
export const GRADE = {
	eng: "Grade",
	bhs: "Nilai",
};
export const GPA = {
	eng: "GPA",
	bhs: "IPK",
};
export const DOCUMENT = {
	eng: "Document",
	bhs: "Dokumen",
};
export const DOCUMENT_EDUCATION_SUBTITLE = {
	eng: "Add documents or images that supports your education",
	bhs: "Tambah dokumen atau gambar yang dapat mendukung pendidikan Anda",
};
export const ADD_FILE = {
	eng: "Add File",
	bhs: "Tambahkan Berkas",
};
export const DELETE_MEDIA_LABEL = {
	eng: "Delete Media",
	bhs: "Hapus Media",
};
export const DELETE_TITLE = {
	eng: "Do you want delete this item?",
	bhs: "Apakah Anda ingin menghapus item ini?",
};
export const CANCEL_LABEL = {
	eng: "Cancel",
	bhs: "Batalkan",
};
export const DELETE_LABEL = {
	eng: "Delete",
	bhs: "Hapus",
};
export const CLOSE = {
	eng: "Close",
	bhs: "Tutup",
};
export const SAVE = {
	eng: "Save",
	bhs: "Simpan",
};
export const ADD_ANOTHER = {
	eng: "Add Another",
	bhs: "Tambahkan yang lain",
};
export const REVIEW = {
	eng: "Review",
	bhs: "Tinjauan",
};

// Experience
export const JOB_TITLE = {
	eng: "Job Title",
	bhs: "Jabatan",
};
export const WORK_HERE_CURRENTLY = {
	eng: "I am currently working here",
	bhs: "Saya sedang bekerja di sini",
};
export const END_DATE = {
	eng: "End Date",
	bhs: "Tanggal Selesai",
};
export const DESCRIPTION = {
	eng: "Description",
	bhs: "Deskripsi",
};
export const DOCUMENT_EXPERIENCE_SUBTITLE = {
	eng: "Add documents or images that supports your work experience",
	bhs: "Tambah dokumen atau gambar yang dapat mendukung pengalaman kerja Anda",
};
export const MONTHLY_SALARY = {
	eng: "Monthly Salary",
	bhs: "Gaji Bulanan",
};
export const PRESENT = {
	eng: "Present",
	bhs: "Sekarang",
};
export const MONTHS = {
	eng: "months",
	bhs: "bulan",
};
export const SURE_TO_SKIP = {
	eng: "Are you sure to skip?",
	bhs: "Apakah Anda yakin untuk melewati?",
};
export const SKIP_DESC = {
	eng: "If you skip this, all filled fields will not be saved in your {0}",
	bhs: "Jika Anda melewati ini, semua data yang telah diisi tidak akan disimpan di",
};
export const HISTORY = {
	eng: "history",
	bhs: "riwayat",
};
export const SKIP_BTN_TITLE = {
	eng: "Yes, skip",
	bhs: "Ya, lewati",
};
// Skills
export const SKILL = {
	eng: "Skill",
	bhs: "Keahlian",
};
export const SKILL_PLACEHOLDER = {
	eng: "Ex. Prototype",
	bhs: "Contoh: Prototype",
};
export const LEVEL = {
	eng: "Level",
	bhs: "Tingkat",
};
export const BEGINNER_LABEL = {
	eng: "Beginner",
	bhs: "Pemula",
};
export const INTERMEDIATE_SKILL_LABEL = {
	eng: "Intermediate",
	bhs: "Menengah",
};
export const ADVANCE_LABEL = {
	eng: "Advanced",
	bhs: "Mahir",
};
export const ADVANCED_LANGUAGE_LABEL = {
	eng: "Advanced",
	bhs: "Lancar",
};
export const FLUENT_LABEL = {
	eng: "Fluent",
	bhs: "Lancar",
};
export const EXPERT_LABEL = {
	eng: "Expert",
	bhs: "Ahli",
};
export const NATIVE_LABEL = {
	eng: "Native",
	bhs: "Penutur asli",
};
export const DOC_SKILL_SUBTITLE = {
	eng: "Add documents or images that support your profile",
	bhs: "Tambahkan dokumen atau gambar yang mendukung profil Anda",
};
export const ADD_SKILL = {
	eng: "Add Skill",
	bhs: "Tambahkan Keahlian",
};

// Language
export const LANGUAGE_NAME = {
	eng: "Language Name",
	bhs: "Nama bahasa",
};
export const SPOKEN_PROFICIENCY = {
	eng: "Spoken Proficiency",
	bhs: "Tingkat Kemahiran Berbicara",
};
export const WRITTEN_PROFICIENCY = {
	eng: "Written Proficiency",
	bhs: "Tingkat Kemahiran Tulisan",
};
export const LANGUAGE_CERTIFICATION_NAME = {
	eng: "Language certification name",
	bhs: "Nama sertifikasi bahasa",
};
export const LANGUAGE_PROFICIENCY_SCORE = {
	eng: "Language proficiency score",
	bhs: "Skor kemahiran bahasa",
};
export const PRIMARY = {
	eng: "Primary",
	bhs: "Utama",
};

// Portfolio & Resume
export const UPLOAD = {
	eng: "Upload",
	bhs: "Unggah",
};
export const PORTFOLIO = {
	eng: "Portfolio",
	bhs: "Portofolio",
};
export const PORTFOLIO_UPLOADED = {
	eng: "Portfolio uploaded successfully.",
	bhs: "Portofolio berhasil diupload.",
};
export const OTHER_RESUME = {
	eng: "Other Resume",
	bhs: "Resume lainnya",
};
export const OTHER_RESUME_DESC = {
	eng: "Please select a document to be set as your additional information",
	bhs: "Pilih dokumen yang akan ditetapkan sebagai informasi tambahan Anda",
};
export const DRAG_FILE_LABEL = {
	eng: "Upload file here or",
	bhs: "Unggah berkas disini atau",
};
export const RESUME_FILE_TYPES = {
	eng: "Word (.doc or .docx), Text (.txt), Rich Text (.rtf) or PDF (.pdf) format. Maximum 20mb",
	bhs: "Word (.doc atau .docx), text (.txt), format teks kaya (.rtf) atau pdf (.pdf). Maksimal 20mb",
};
export const UPLOAD_FILE_FAILED_MSG = {
	eng: "Upload file again to save the",
	bhs: "Unggah file lagi untuk menyimpan",
};
export const INVALID_FILE = {
	eng: "Invalid File Upload. Only PNG and PDF file format is allowed. Maximum file size can be 20mb",
	bhs: "Unggah file tidak valid. Hanya format file PNG dan PDF yang diizinkan. Ukuran file maksimal bisa 20MB",
};
export const UPLOAD_IMAGE_ERR = {
	eng: "Your image is larger than 5 MB",
	bhs: "Gambar Anda lebih besar dari 5 MB",
};

// Job Resume Pref
export const JOB_PREF = {
	eng: "Job Preferences",
	bhs: "Referensi pekerjaan",
};
export const RESUME_PREF = {
	eng: "Resume Preferences",
	bhs: "Lanjutkan preferensi",
};
export const PROFILE_SUCCESSFULLY_SAVED = {
	eng: "Your profile was saved successfully saved",
	bhs: "Profil Anda berhasil disimpan",
};
export const EXPECTED_PAY_CURRENCY = {
	eng: "Expected Pay Currency",
	bhs: "Mata Uang Gaji yang Diharapkan",
};
export const EXPECTED_PAY_AMOUNT = {
	eng: "Expected Pay Amount",
	bhs: "Jumlah Gaji yang Diharapkan",
};
export const PREFERED_WORK_LOCATION = {
	eng: "Preferred work location",
	bhs: "Lokasi bekerja pilihan",
};

export const WEEK = {
	eng: "week",
	bhs: "minggu",
};
export const WEEKS = {
	eng: "weeks",
	bhs: "minggu",
};
export const READY_TO_WORK = {
	eng: "Ready to Work",
	bhs: "Siap untuk Kerja",
};
export const NOT_READY_TO_WORK = {
	eng: "Not Available to Work",
	bhs: "Tidak tersedia untuk Kerja",
};
export const LAST_UPDATED = {
	eng: "Last Updated",
	bhs: "Terakhir Diubah",
};
export const PUBLIC = {
	eng: "Public",
	bhs: "Publik",
};
export const PRIVATE = {
	eng: "Private",
	bhs: "Pribadi",
};
export const INFORMATION = {
	eng: "Information",
	bhs: "Informasi",
};
export const NOTIFICATION = {
	eng: "Notification",
	bhs: "Notifikasi",
};
export const HAS_REPORTED = {
	eng: "You have reported this Job Ad",
	bhs: "Anda telah melaporkan Iklan Lowongan ini",
};
export const PUBLIC_DESCRIPTION = {
	eng: "YYour resume and profile information can be found through Portal Kerja by Recruiter who looking for candidates.",
	bhs: "Resume dan informasi profil Anda dapat ditemukan melalui Portal Kerja oleh perusahaan  yang mencari kandidat.",
};
export const PRIVATE_DESCRIPTION = {
	eng: "Employers cannot find your resume in a search. This does not affect previous applications or prevent employers you responded to from contacting you.",
	bhs: "Perusahaan tidak dapat menemukan resume Anda dalam pencarian. Hal ini tidak mempengaruhi lamaran sebelumnya atau mencegah perusahaan yang Anda respons untuk menghubungi Anda.",
};
export const RESUME_VISIBILITY = {
	eng: "Resume Visibility",
	bhs: "Visibilitas Resume",
};
export const NOTICE_PERIOD = {
	eng: "Notice Period",
	bhs: "Tenggang Waktu",
};
export const LAST_MODIFIED = {
	eng: "Last Modified",
	bhs: "Terakhir Diubah",
};
export const FINISH = {
	eng: "Finish",
	bhs: "Selesai",
};
export const ADD = {
	eng: "Add",
	bhs: "Tambahkan",
};
export const EDIT_LABEL = {
	eng: "Edit",
	bhs: "Ubah",
};

export const APPLY_TO = {
	eng: "Apply to",
	bhs: "Lamar ke",
};
export const RESUME = {
	eng: "Resume",
	bhs: "Resume",
};
export const RESUME_UPLOADED = {
	eng: "Resume uploaded successfully",
	bhs: "Resume berhasil diupload",
};
export const ADDITIONAL_QUESTIONS = {
	eng: "Additional Questions",
	bhs: "Pertanyaan Tambahan",
};
export const MAKE_PITCH = {
	eng: "Make your pitch (Recommended)",
	bhs: "Promosikan diri Anda (Direkomendasikan)",
};
export const COVER_LETTER = {
	eng: "Cover Letter",
	bhs: "Surat Lamaran",
};
export const REVIEW_PROFILE = {
	eng: "Review your profile",
	bhs: "Review profil Anda",
};
export const UPDATE_CONTACT_WARN = {
	eng: "Updating the above data will automatically update your profile detail.",
	bhs: "Mengubah data di atas secara otomatis akan mengubah detail profil Anda.",
};
export const NEXT = {
	eng: "Next",
	bhs: "Selanjutnya",
};
export const BACK = {
	eng: "Back",
	bhs: "Kembali",
};
export const UPLOAD_RESUME = {
	eng: "Upload Resume",
	bhs: "Unggah Resume",
};
export const UPLOAD_RESUME_FILE_FORMAT = {
	eng: "DOC, DOCX, TXT, RTF, or PDF format. Maximum 20 MB",
	bhs: "DOC, DOCX, TXT, RTF, or PDF format. Maksimal 20 MB",
};
export const COVER_LETTER_FORMAT = {
	eng: "PDF, WORD, or Image file only. Maximum 20 MB",
	bhs: "Hanya file bertipe PDF, Word, atau gambar saja. Maksimal 20 MB",
};
export const VIEW = {
	eng: "View",
	bhs: "Lihat",
};
export const CHANGE = {
	eng: "Change",
	bhs: "Mengubah",
};
export const FILE_SIZE_ERROR = {
	eng: "File is too large. Maximum file size is 20 MB",
	bhs: "File terlalu besar. Ukuran maksimal adalah 20 MB",
};
export const PITCH_DESC_PLACEHOLDER = {
	eng: "Tell the employer why you are best suited for this role. Highlight specific skills and how you can contribute. Avoid generi pitches e.g I am responsible",
	bhs: "Beri tahu perusahaan mengapa Anda paling cocok untuk peran ini. Soroti keterampilan khusus dan bagaimana Anda dapat berkontribusi. Hindari penawaran umum, misalnya saya bertanggung jawab.",
};
export const UPLOAD_COVER_LETTER = {
	eng: "Upload Cover Letter",
	bhs: "Unggah Surat Lamaran",
};
export const SELECT_LABEL = {
	eng: "Select",
	bhs: "Pilih",
};
export const NOT_UPLOADED = {
	eng: "Not Uploaded",
	bhs: "Belum diunggah",
};
export const NOT_FILLED_YET = {
	eng: "Not filled yet",
	bhs: "Belum diisi",
};
export const SUBMIT_APPLICATION = {
	eng: "Submit Application",
	bhs: "Kirim lamaran",
};
export const APPLICATION_SENT_HEAD = {
	eng: "Your application was sent to",
	bhs: "Lamaran Anda telah dikirm ke",
};
export const APPLICATION_SENT_DESC = {
	eng: 'You can keep track of your application in the "applied" tabs of my jobs',
	bhs: 'Anda dapat memantau proses lamaran pada tab "Dilamar" yang ada di my Jobs.',
};
export const DONE = {
	eng: "Done",
	bhs: "Selesai",
};
export const SUCCESS_REPORT_TITLE = {
	eng: "Thank you for submitting the report",
	bhs: "Terima kasih telah mengirimkan laporannya",
};
export const SUCCESS_REPORT_MESSAGE = {
	eng: "We take the report seriously and after a thorough review, our support team will get back to you.",
	bhs: "Kami menanggapi laporan tersebut dengan serius dan setelah melakukan tinjauan menyeluruh, tim dukungan kami akan menghubungi Anda.",
};
export const LOGIN_FIRST_ERROR = {
	eng: "Please login in order to apply for this job",
	bhs: "Harap masuk untuk melamar pekerjaan ini",
};

export const URGENCY_VALUE_1_TO_3_DAYS = {
	eng: "1 to 3 days",
	bhs: "1 sampai 3 hari",
};
export const URGENCY_VALUE_3_TO_7_DAYS = {
	eng: "3 to 7 days",
	bhs: "3 sampai 7 hari",
};
export const URGENCY_VALUE_1_TO_2_WEEKS = {
	eng: "1 to 2 weeks",
	bhs: "1 sampai 2 hari",
};
export const URGENCY_VALUE_2_TO_4_WEEKS = {
	eng: "2 to 4 weeks",
	bhs: "2 sampai 4 hari",
};
export const URGENCY_MORE_THAN_4_WEEKS = {
	eng: "more than 4 weeks",
	bhs: "Lebih dari 4 minggu",
};
export const SUCCESS = {
	eng: "Success",
	bhs: "Berhasil",
};
export const ERROR = {
	eng: "Error",
	bhs: "Kesalahan",
};
export const WARNING = {
	eng: "Warning",
	bhs: "Peringatan",
};
export const UNSUPPORTED_FILE = {
	eng: "File not supported",
	bhs: "File tidak didukung",
};

/**
 * @param {Pick<UrgencyEnum | undefined>} urgencyKey
 * @return {{ eng: string, bhs: string }}
 */
export const URGENCY_VALUE_HELPER = (urgencyKey) => {
	return (
		{
			"1_to_3_days": URGENCY_VALUE_1_TO_3_DAYS,
			"3_to_7_days": URGENCY_VALUE_3_TO_7_DAYS,
			"1_to_2_weeks": URGENCY_VALUE_1_TO_2_WEEKS,
			"2_to_4_weeks": URGENCY_VALUE_2_TO_4_WEEKS,
			more_than_4_weeks: URGENCY_MORE_THAN_4_WEEKS,
		}[urgencyKey] ?? { bhs: "", eng: "" }
	);
};

export const ONSITE = {
	eng: "On site",
	bhs: "Di tempat",
};

export const HYBRID = {
	eng: "Hybrid",
	bhs: "Hibrid",
};

export const REMOTE = {
	eng: "Remote",
	bhs: "Remote",
};

/**
 * @param {Pick<WorkModelEnum | undefined>} workModelKey
 * @return {{ eng: string, bhs: string }}
 */
export const WORK_MODEL_HELPER = (workModelKey) => {
	return (
		{
			onsite: ONSITE,
			hybrid: HYBRID,
			remote: REMOTE,
		}[workModelKey] ?? { bhs: "", eng: "" }
	);
};

export const FULLTIME = {
	eng: "Full time",
	bhs: "Penuh waktu",
};

export const PARTTIME = {
	eng: "Part time",
	bhs: "Paruh waktu",
};

export const FREELANCE = {
	eng: "Freelance",
	bhs: "Pekerja lepas",
};

export const CONTRACT = {
	eng: "Contract",
	bhs: "Kontrak",
};

export const INTERNSHIP = {
	eng: "Internship",
	bhs: "Magang",
};

export const TEMPORARY = {
	eng: "Temporary",
	bhs: "Temporer",
};

/**
 * @param {Pick<JobTypeEnum | undefined>} jobTypeKey
 * @return {{ eng: string, bhs: string }}
 */
export const JOB_TYPE_HELPER = (jobTypeKey) => {
	return (
		{
			full_time: FULLTIME,
			part_time: PARTTIME,
			freelance: FREELANCE,
			contract: CONTRACT,
			internship: INTERNSHIP,
			temporary: TEMPORARY,
		}[jobTypeKey] ?? { bhs: "", eng: "" }
	);
};

export const ENTRY_LEVEL = {
	eng: "Entry level",
	bhs: "Pemula",
};
export const ASSOCIATE = {
	eng: "Associate",
	bhs: "Junior",
};
export const INTERMEDIATE = {
	eng: "Intermediate",
	bhs: "Staff",
};
export const SENIOR = {
	eng: "Senior",
	bhs: "Senior",
};
export const DIRECTOR = {
	eng: "Director",
	bhs: "Direktur",
};
export const EXECUTIVE = {
	eng: "Executive",
	bhs: "Eksekutif",
};

/**
 * @param {Pick<ExperienceLevelEnum | undefined>} experienceLevelKey
 * @return {{ eng: string, bhs: string }}
 */
export const EXPERIENCE_LEVEL_HELPER = (experienceLevelKey) => {
	return (
		{
			entry_level: ENTRY_LEVEL,
			associate: ASSOCIATE,
			intermediate: INTERMEDIATE,
			senior: SENIOR,
			director: DIRECTOR,
			executive: EXECUTIVE,
		}[experienceLevelKey] ?? { bhs: "", eng: "" }
	);
};

export const FIELD_REQUIRED = {
	eng: "{0} is required",
	bhs: "{0} wajib diisi",
};
export const RECOMMENDED = {
	eng: "Recommendation",
	bhs: "Recommended",
};
export const PASSWORD_VALIDATION_ERR_MSG = {
	eng: "Password must be at least 8 characters, with 1 number, 1 upper case, and 1 lower case",
	bhs: "Kata sandi harus setidaknya 8 karakter terdiri dari 1 angka, 1 huruf besar, dan 1 huruf kecil",
};
export const PASSWORD_DONT_MATCH_ERR_MSG = {
	eng: "Password do not match with the confirm password. Please try again.",
	bhs: "Kata sandi tidak cocok dengan kata sandi konfirmasi. Silakan coba lagi.",
};

export const PER_HOUR = {
	eng: "Per hour",
	bhs: "Per jam",
};
export const PER_DAY = {
	eng: "Per day",
	bhs: "Per hari",
};
export const PER_WEEK = {
	eng: "Per week",
	bhs: "Per minggu",
};
export const PER_MONTH_2 = {
	eng: "Per month",
	bhs: "Per bulan",
};
export const PER_YEAR = {
	eng: "Per year",
	bhs: "Per tahun",
};
export const SELECT_MODULE_TITLE = {
	eng: "Please select module to start",
	bhs: "Silakan pilih modul untuk memulai",
};
export const SELECT_ROLE_REGISTRATION_TITLE = {
	eng: "Which role that you want to register?",
	bhs: "Role apa yang Anda ingin daftar?",
};
export const CANDIDATE = {
	eng: "Candidate",
	bhs: "Kandidat",
};
export const EMPLOYER = {
	eng: "Employer",
	bhs: "Perusahaan",
};
export const OKAY = {
	eng: "Okay",
	bhs: "Mengerti",
};
export const NO_PERMISSION_TITLE = {
	eng: "You do not have permission to this module",
	bhs: "Anda tidak memiliki akses ke modul ini",
};
export const NO_PERMISSION_DESC = {
	eng: "You haven't been added to any companies",
	bhs: "Anda belum ditambahkan ke suatu perusahaan",
};
export const DUMMY_ACCOUNT_TOAST_MSG = {
	eng: "Cannot login with dummy account",
	bhs: "Tidak dapat masuk melalui akun dummy",
};
export const SWITCH_TO_JOB_POSTER = {
	eng: "Switch to job poster",
	bhs: "Ganti sebagai admin",
};
export const SWITCH_HRMS_MODULE = {
	eng: "Switch HRMS Module",
	bhs: "Ganti sebagai HRMS",
};
export const PERMISSION_ROLE_MODAL_ERROR = {
	eng: "You do not have permission to this role",
	bhs: "Anda tidak memiliki akses ke role ini",
};

export const PERIOD_UNIT = {
	per_hour: PER_HOUR,
	per_day: PER_DAY,
	per_week: PER_WEEK,
	per_month: PER_MONTH_2,
	per_year: PER_YEAR,
};

export const ACTIVATE_ACCOUNT = {
	eng: "Activate Account",
	bhs: "Aktivasi Akun",
};
export const PASSWORD_VALIDATION_MESSAGE = {
	eng: "The password must be at least 8 characters. It must contain at least numbers(0-9), upper and lower case letters (A-Z, a-z).",
	bhs: "Kata sandi min 8 karakter dengan 1 angka (0-9), 1 huruf besar, dan 1 huruf kecil (A-Z, a-z).",
};
