import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

import useToast from "shared/hooks/useToast";
import * as Variable from "../../../shared/utils/variables";
import { ROOT } from "../../../shared/utils/routeLink";
import Toggle from "../../../shared/component/ToggleComponent/Toggle";
import Select from "../../../shared/component/SelectComponent/Select";
import Card from "../../../shared/component/CardComponent/Card";
import Button from "../../../shared/component/ButtonComponent/Button";
import ModalInfo from "../../../shared/component/ModalComponent/ModalInfo";
import useUserDetails from "../../../shared/hooks/userDetails";
import Image from "../../../shared/component/UI/Image";
import makeRequest from "../../../shared/utils/request";
import { generateRequestOptions } from "../../../shared/utils/apiEndPoints";

import WarningIcon from "../../../assets/svg/red_warning.svg";
import { formatString } from "shared/utils/string";

function ResumePref({ ENV_NAME, userId, onComplete, onSkip }) {
	const toast = useToast();

	const NOTICE_PERIOD = [
		{ id: "1_week", name: `1 ${Variable.WEEK[ENV_NAME]}` },
		{ id: "2_weeks", name: `2 ${Variable.WEEKS[ENV_NAME]}` },
		{ id: "3_weeks", name: `3 ${Variable.WEEKS[ENV_NAME]}` },
		{ id: "4_weeks", name: `4 ${Variable.WEEKS[ENV_NAME]}` },
		{ id: "1_month", name: `1 ${Variable.MONTH[ENV_NAME]}` },
		{ id: "2_months", name: `2 ${Variable.MONTHS[ENV_NAME]}` },
		{ id: "3_months", name: `3 ${Variable.MONTHS[ENV_NAME]}` },
		{ id: "4_months", name: `4 ${Variable.MONTHS[ENV_NAME]}` },
	];
	const userDetails = useUserDetails(userId);
	const history = useHistory();
	const [form, setForm] = useState({
		visibility: "",
		ready_to_work: "",
		notice_period: "",
	});
	const [monitor, setMonitor] = useState({});
	const [showSkip, setShowSkip] = useState(false);

	useEffect(() => {
		if (userDetails) {
			const { resume } = userDetails;
			setForm({
				id: resume?.id,
				visibility: resume?.visibility || "",
				ready_to_work: resume?.ready_to_work || "",
				notice_period: resume?.notice_period || "",
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userDetails]);

	const onChange = (e) => {
		const { name, value } = e.target;
		setForm((p) => ({ ...p, [name]: value }));
		setMonitor((prev) => ({ ...prev, [name]: true }));
	};

	const updateProfile = async () => {
		const res = await makeRequest({
			...generateRequestOptions("updateProfile"),
			data: { is_initial_login: false },
		});

		if (res.code !== 200) toast.error(res.message);
	};

	const handleSave = async () => {
		const data = {};
		for (let key in monitor) data[key] = form[key];

		const res = await makeRequest({
			...generateRequestOptions("updateResumePref"),
			data,
		});

		if (res.code === 200) {
			onComplete();
			sessionStorage.removeItem("profileSetup");
			history.push(ROOT);
			toast.success(Variable.PROFILE_SUCCESSFULLY_SAVED[ENV_NAME]);
			updateProfile();
		} else toast.error(res.message);
	};

	return (
		<div>
			<div className="am-form resume-pref-form mt-5">
				<Toggle
					type="switch"
					leftText={Variable.READY_TO_WORK[ENV_NAME]}
					name="ready_to_work"
					checked={form?.ready_to_work}
					onChange={(e) => {
						setForm((p) => ({ ...p, ready_to_work: e.target.checked }));
						setMonitor((prev) => ({ ...prev, ready_to_work: true }));
					}}
					className="justify-content-between"
				/>
				{!form?.ready_to_work && (
					<Select
						label={Variable.NOTICE_PERIOD[ENV_NAME]}
						placeholder={Variable.NOTICE_PERIOD[ENV_NAME]}
						name="notice_period"
						value={form?.notice_period || ""}
						items={NOTICE_PERIOD}
						onChange={onChange}
						searchable={false}
						className="w-100"
						noDefault
					/>
				)}
				<div>
					<label className="as-label">{Variable.RESUME_VISIBILITY[ENV_NAME]}</label>
					<div className="d-flex flex-column gap-sm">
						<Card
							type="public"
							active={form?.visibility === "public"}
							onClick={() => {
								setForm((p) => ({ ...p, visibility: "public" }));
								setMonitor((prev) => ({ ...prev, visibility: true }));
							}}
						/>

						<Card
							type="private"
							active={form?.visibility === "private"}
							onClick={() => {
								setForm((p) => ({ ...p, visibility: "private" }));
								setMonitor((prev) => ({ ...prev, visibility: true }));
							}}
						/>
					</div>
				</div>
				{form?.last_updated_resume && (
					<div className="text-sm text-label-gray">
						{`${Variable.LAST_MODIFIED[ENV_NAME]}: ${moment(
							form?.last_updated_resume,
						).format("LL")}`}
					</div>
				)}
			</div>

			{/* Skip Modal */}
			<ModalInfo
				isShow={showSkip}
				onHide={() => setShowSkip(false)}
				onConfirm={() => {
					onSkip();
					sessionStorage.removeItem("profileSetup");
					history.push(ROOT);
				}}
				type="other"
				customIcon={<Image src={WarningIcon} className="modal-image-icon" />}
				title={Variable.SURE_TO_SKIP[ENV_NAME]}
				description={
					formatString(
						Variable.SKIP_DESC[ENV_NAME],
						Variable.JOB_RESUME_PREF[ENV_NAME].toLowerCase(),
					) +
					" " +
					Variable.HISTORY[ENV_NAME]
				}
				confirmLabel={Variable.SKIP_BTN_TITLE[ENV_NAME]}
			/>

			<div className="d-flex justify-content-between align-items-center">
				<Button
					type="outline"
					size="md"
					title={Variable.SKIP_STEP[ENV_NAME]}
					className="mt-5"
					onClick={() => setShowSkip(true)}
				/>
				<Button
					type="primary"
					size="sm"
					title={Variable.FINISH[ENV_NAME]}
					className="mt-5"
					onClick={handleSave}
				/>
			</div>
		</div>
	);
}

export default ResumePref;
