import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import useToast from "shared/hooks/useToast";
import makeRequest from "../../../shared/utils/request";
import { generateRequestOptions } from "../../../shared/utils/apiEndPoints";
import * as Variable from "../../../shared/utils/variables";
import { SETUP_PROFILE } from "../../../shared/utils/routeLink";
import Input from "../../../shared/component/InputComponent/Input";
import Select from "../../../shared/component/SelectComponent/Select";
import Toggle from "../../../shared/component/ToggleComponent/Toggle";
import { COUNTRY_LIST } from "../../../shared/utils/dataOptions";
import DatePickerDropdown from "../../../shared/component/DatePickerDropdown/DatePickerDropdown";
import Button from "../../../shared/component/ButtonComponent/Button";
import { PlusIcon } from "../../../shared/component/svg/Icons";
import FileCard from "../../../shared/component/CardComponent/FileCard";
import { getIconByName } from "../../../shared/utils/file";
import ModalInfo from "../../../shared/component/ModalComponent/ModalInfo";
import Image from "../../../shared/component/UI/Image";
import formatNumber from "../../../shared/utils/formatNumber";

import RemoveMediaIcon from "../../../assets/image/remove-media.png";
import WarningIcon from "../../../assets/svg/red_warning.svg";
import { formatString } from "shared/utils/string";

function AddExperience({ ENV_NAME, onNext, onNextStep, onSkip }) {
	const toast = useToast();

	const regionNames = new Intl.DisplayNames([ENV_NAME === "bhs" ? "id" : "en"], {
		type: "region",
	});
	const history = useHistory();
	const experienceId = history?.location?.pathname?.split("/")?.[3];

	const [form, setForm] = useState({
		job_title: "",
		company_name: "",
		country: "",
		city: "",
		is_currently_working: "",
		started_at: "",
		ended_at: "",
		salary: "",
		currency: "idr",
		experience_document_proof: [],
		description: "",
	});
	const [errors, setErrors] = useState({});
	const [monitor, setMonitor] = useState({});
	const [countryList, setCountryList] = useState(null);
	const [isEdited, setIsEdited] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [showConfirm, setShowConfirm] = useState({ show: false, data: null });
	const [showSkip, setShowSkip] = useState(false);

	const uploadRef = useRef();

	// TODO: if there is any experience id in the url then retrieve the experience
	useEffect(() => {
		if (experienceId) retrieveExperience();
	}, [experienceId]);

	useEffect(() => {
		if (ENV_NAME) {
			setCountryList(COUNTRY_LIST?.map((i) => ({ id: i, name: regionNames?.of(i) })));
		}
	}, [ENV_NAME]); // eslint-disable-line

	const retrieveExperience = async () => {
		const res = await makeRequest(
			generateRequestOptions("getExperience", { urlParams: experienceId }),
		);

		if (res.code === 200) setForm(res.data);
		else toast.error(res.message);
	};

	const onChange = (e) => {
		const { name, value } = e.target;
		setForm((p) => ({
			...p,
			[name]: name === "salary" ? formatNumber(value, form?.currency) : value,
		}));
		setErrors((prev) => ({ ...prev, [name]: false }));
		setMonitor((prev) => ({ ...prev, [name]: true }));
		setIsEdited(true);
	};

	const handleSave = async () => {
		const { job_title, company_name, country, city, is_currently_working, ended_at } = form;

		const errorsData = {};
		if (!job_title) errorsData["job_title"] = true;
		if (!company_name) errorsData["company_name"] = true;
		if (!country) errorsData["country"] = true;
		if (!city) errorsData["city"] = true;
		if (!is_currently_working && !ended_at) errorsData["ended_at"] = true;

		const hasErrors = Object.values(errorsData).some((err) => err);
		if (hasErrors) return setErrors(errorsData);

		// TODO: if the user haven't edited the correctly filled form
		// then instead of making api call simply move to the next form
		if (!hasErrors && !isEdited) return onNext();

		const docs = form?.experience_document_proof
			?.filter((doc) => !doc?.experience)
			?.map((doc) => doc?.experience_document_proof);

		if (form?.is_currently_working) {
			delete form.ended_at;
			delete monitor.ended_at;
		}

		// TODO: Salary must be an integer
		form.salary = form?.salary?.toString()?.replace(/[^0-9]/g, "");

		const formData = new FormData();
		for (const item in monitor) {
			if (item === "experience_document_proof" && docs.length && !experienceId)
				docs.forEach((doc) => formData.append("experience_document_proof", doc));
			else if (item !== "experience_document_proof") formData.append(item, form[item]);
		}

		formData.append("currency", "idr");

		let url = generateRequestOptions("createExperience");
		if (experienceId) {
			url = generateRequestOptions("editExperience", {
				urlParams: experienceId,
			});
		}

		setIsUploading(true);
		const res = await makeRequest({
			...url,
			data: formData,
		});

		if (res.code === 200) {
			if (docs.length && experienceId) {
				const uploadPromises = docs.map((file) => {
					return handleUploadDocument(file);
				});

				await Promise.all(uploadPromises);
			}
			history.push(SETUP_PROFILE);
			onNext();
		} else toast.error(res.message);
		setIsUploading(false);
	};

	const handleUploadDocument = async (file) => {
		const formData = new FormData();
		formData.append("experience_document_proof", file);
		formData.append("experience", experienceId);

		const res = await makeRequest({
			...generateRequestOptions("createExperienceDocument"),
			data: formData,
		});

		if (res.code !== 200) toast.error(res.message);
	};

	const onFileChange = async (e) => {
		console.log("Uploading File");
		const file = e.target.files[0];
		const fileObj = {
			experience_document_proof: file,
			file_name: file?.name,
			id: Date.now(),
		};
		const formClone = { ...form };
		if (formClone.experience_document_proof) formClone.experience_document_proof.push(fileObj);
		else formClone.experience_document_proof = [{ ...fileObj }];

		setForm(formClone);
		setMonitor((prev) => ({ ...prev, experience_document_proof: true }));
	};

	const filterRemainingDocs = (docId) => {
		setForm({
			...form,
			experience_document_proof: form.experience_document_proof.filter(
				(doc) => doc.id !== docId,
			),
		});
	};

	const handleDeleteDocument = async () => {
		// There will be two kinds of delete
		// if there exists an experience for the document proof, then call the API
		// otherwise, filter out the documents
		const docId = showConfirm?.data?.id;

		const documentProof = form.experience_document_proof.find((doc) => doc.id === docId);
		if (documentProof?.experience) {
			const res = await makeRequest(
				generateRequestOptions("deleteExperienceDocument", {
					urlParams: docId,
				}),
			);

			if (res.code === 200) {
				filterRemainingDocs(docId);
				setShowConfirm({ show: false, data: null });
			} else toast.error(res.message);
		} else {
			filterRemainingDocs(docId);
			setShowConfirm({ show: false, data: null });
		}
	};

	return (
		<>
			<div className="edu-form">
				<Input
					label={Variable.JOB_TITLE[ENV_NAME]}
					placeholder={`${Variable.EXAMPLE_ABBV[ENV_NAME]} Sales`}
					value={form?.job_title || ""}
					name="job_title"
					onChange={onChange}
					inputType="label"
					className="w-100"
					required
					isError={errors["job_title"]}
				/>
				<Input
					label={Variable.COMPANY_NAME[ENV_NAME]}
					placeholder={`${Variable.EXAMPLE_ABBV[ENV_NAME]} Facebook`}
					value={form?.company_name || ""}
					name="company_name"
					onChange={onChange}
					inputType="label"
					className="w-100"
					required
					isError={errors["company_name"]}
				/>
				<div className="form-row">
					<Select
						label={Variable.COUNTRY[ENV_NAME]}
						placeholder={Variable.COUNTRY[ENV_NAME]}
						name="country"
						value={form?.country || ""}
						items={countryList}
						onChange={onChange}
						searchable={false}
						required
						noDefault
						isError={errors["country"]}
					/>
					<Input
						label={Variable.CITY[ENV_NAME]}
						placeholder={Variable.CITY[ENV_NAME]}
						value={form?.city || ""}
						name="city"
						onChange={onChange}
						inputType="label"
						className="w-100"
						required
						isError={errors["city"]}
					/>
				</div>
				<Toggle
					type="switch"
					leftText={Variable.WORK_HERE_CURRENTLY[ENV_NAME]}
					name="is_currently_working"
					checked={form?.is_currently_working}
					onChange={(e) => {
						setForm((p) => ({ ...p, is_currently_working: e.target.checked }));
						setMonitor((prev) => ({ ...prev, is_currently_working: true }));
					}}
				/>
				<div>
					<label className="custom-label isRequired">
						{Variable.START_DATE_EXPERIENCE[ENV_NAME]}
					</label>
					<DatePickerDropdown
						className="form-row"
						dayLabel={<></>}
						monthLabel={<></>}
						yearLabel={<></>}
						name="started_at"
						value={form?.started_at}
						onChange={onChange}
						isError={errors["started_at"]}
						hideDate
					/>
				</div>
				{!form?.is_currently_working && (
					<div>
						<label className="custom-label isRequired">
							{Variable.END_DATE[ENV_NAME]}
						</label>
						<DatePickerDropdown
							className="form-row"
							dayLabel={<></>}
							monthLabel={<></>}
							yearLabel={<></>}
							name="ended_at"
							value={form?.ended_at}
							onChange={onChange}
							isError={errors["ended_at"]}
							hideDate
						/>
					</div>
				)}
				<div className="form-row">
					<Select
						label={Variable.MONTHLY_SALARY[ENV_NAME]}
						placeholder={Variable.MONTHLY_SALARY[ENV_NAME]}
						name="currency"
						value={"idr" || form?.currency}
						items={Variable.CURRENCY_LIST}
						onChange={onChange}
						searchable={false}
						className="w-100"
						disabled
					/>
					<Input
						label={<>&nbsp;</>}
						placeholder={Variable.MONTHLY_SALARY[ENV_NAME]}
						value={formatNumber(form?.salary) || ""}
						name="salary"
						onChange={onChange}
						inputType="text"
						className="w-100"
					/>
				</div>
				<Input
					label={Variable.DESCRIPTION[ENV_NAME]}
					placeholder={Variable.DESCRIPTION[ENV_NAME]}
					value={form?.description || ""}
					name="description"
					onChange={(e) => {
						setForm({ ...form, description: e });
						setErrors((prev) => ({ ...prev, description: false }));
						setMonitor((prev) => ({ ...prev, description: true }));
					}}
					inputType="textarea"
					className="w-100"
				/>
				<div>
					<h5 className="text-md font-weight-medium text-label-gray mb-sm">
						{Variable.DOCUMENT[ENV_NAME]}
					</h5>
					<span className="text-sm">
						{Variable.DOCUMENT_EXPERIENCE_SUBTITLE[ENV_NAME]}
					</span>
				</div>
				<div className="input-btn">
					<Button
						type="secondary"
						size="md"
						title={
							<div className="flex-all-center gap-xxs">
								<PlusIcon
									width="1.8rem"
									height="1.8rem"
									stroke="white"
									strokeWidth="3"
								/>
								{Variable.ADD_FILE[ENV_NAME]}
							</div>
						}
						onClick={() => uploadRef?.current?.click()}
					/>
					<input
						id="upload-files"
						type="file"
						name="subjectIcon"
						className="inputfile d-none"
						onChange={(e) => {
							onFileChange(e);
							uploadRef.current.value = null;
						}}
						accept="image/*, application/pdf"
						ref={uploadRef}
					/>
				</div>
				{form?.experience_document_proof?.map((i) => (
					<FileCard
						key={i?.id}
						title={i?.file_name}
						icon={getIconByName(i?.file_name)}
						onDelete={() => setShowConfirm({ show: true, data: i })}
						className="mb-3 fileCard"
						showGreenCheck={false}
						isUploading={isUploading}
					/>
				))}
			</div>

			{/* Delete Modal */}
			<ModalInfo
				isShow={showConfirm?.show}
				onHide={() => setShowConfirm({ show: false, data: null })}
				onConfirm={handleDeleteDocument}
				type="delete"
				customIcon={<Image src={RemoveMediaIcon} className="modal-image-icon" />}
			/>

			{/* Skip Modal */}
			<ModalInfo
				isShow={showSkip}
				onHide={() => setShowSkip(false)}
				onConfirm={() => {
					onSkip();
					onNextStep();
				}}
				type="other"
				customIcon={<Image src={WarningIcon} className="modal-image-icon" />}
				title={Variable.SURE_TO_SKIP[ENV_NAME]}
				description={
					formatString(
						Variable.SKIP_DESC[ENV_NAME],
						Variable.EXPERIENCE[ENV_NAME].toLowerCase(),
					) +
					" " +
					Variable.HISTORY[ENV_NAME]
				}
				confirmLabel={Variable.SKIP_BTN_TITLE[ENV_NAME]}
			/>

			<div className="d-flex align-items-center">
				<Button
					type="outline"
					size="md"
					title={Variable.SKIP_STEP[ENV_NAME]}
					className="mt-5 me-4"
					btnClassName="btn-initial-profile"
					onClick={() => setShowSkip(true)}
				/>
				<Button
					type="primary"
					size="sm"
					title={Variable.SAVE[ENV_NAME]}
					className="mt-5"
					btnClassName="btn-initial-profile"
					onClick={handleSave}
					disabled={
						!form?.job_title ||
						!form?.company_name ||
						!form?.country ||
						!form?.city ||
						!form?.started_at ||
						isUploading
					}
				/>
			</div>
		</>
	);
}

export default AddExperience;
