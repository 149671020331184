import { save, load, remove } from "react-cookies";
import { homeUrl } from "./apiEndPoints";

export const setCookies = (cookieName: string, cookieValue: any, domain: string = homeUrl) => {
	const options = {
		domain,
		path: "/",
		expires: new Date("9999-12-31T12:00:00.000Z"),
	};
	save(cookieName, cookieValue, options);
};

export const getCookies = (cookieName) => {
	return load(cookieName);
};

export const removeCookies = (cookieName, domain: string = homeUrl) => {
	const options = {
		domain,
		path: "/",
		expires: new Date("9999-12-31T12:00:00.000Z"),
	};
	return remove(cookieName, options);
};

export const configCookies = {
	domain: homeUrl,
	path: "/",
	expires: new Date("9999-12-31T12:00:00.000Z"),
};
