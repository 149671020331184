import { homeUrl } from "./apiEndPoints";

export const getIfArray = (v) => (Array.isArray(v) ? v : []);
export const configCookies = {
	domain: homeUrl,
	path: "/",
	expires: new Date("9999-12-31T12:00:00.000Z"),
};
export const configRemoveCookies = { path: "/", domain: homeUrl };

export const ROLES = {
	JOB_SEEKER: "job_seeker",
	COMPANY_ADMIN: "company_admin",
	COMPANY_STAFF: "company_staff",
};
