import React from "react";

import * as Variable from "../../utils/variables";
import { classHelper } from "../../utils/stringUtils";
import Image from "../UI/Image";
import notFoundIcon from "../../../assets/image/not-found-error.svg";

import "./NoContentComponent.scss";

const NoContentComponent = ({
	icon,
	title,
	description,
	selectedEnvironment,
	onModal,
	...props
}) => {
	const ENV_NAME = selectedEnvironment ? selectedEnvironment : "bhs";
	return (
		<div className={classHelper("no-content-component", props.className)}>
			{React.isValidElement(icon) ? (
				icon
			) : (
				<Image
					src={icon || notFoundIcon}
					className="no-content-component__icon"
					alt="No Content Found"
				/>
			)}
			<h5 className="no-content-component__title">
				{title ?? Variable.NO_CONTENT_DEFAULT_LABEL[ENV_NAME]}
			</h5>
			<p className="no-content-component__description">
				{/* Just pass " " if you don't want to have any description */}
				{description ?? Variable.NO_CONTENT_DEFAULT_DESCRIPTION[ENV_NAME]}
			</p>
		</div>
	);
};

export default NoContentComponent;
