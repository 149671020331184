import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Modal } from "react-bootstrap";

import { classHelper } from "../../utils/stringUtils";

import "./ModalForm.scss";

const ModalForm = ({ show, onHide, ENV_NAME, ...props }) => {
	return (
		<Modal
			show={show}
			onHide={onHide}
			backdrop="static"
			dialogClassName={classHelper("modal-dialog-centered modal-form", props.className)}
		>
			{(props.header || props.title) && (
				<Modal.Header closeButton={props.closeButton || undefined}>
					{props.header ?? <Modal.Title>{props.title}</Modal.Title>}
				</Modal.Header>
			)}
			<Modal.Body>{props.children}</Modal.Body>
			{props.footer && <Modal.Footer>{props.footer}</Modal.Footer>}
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(ModalForm));
