import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

import useToast from "shared/hooks/useToast";
import * as RoutePath from "../../shared/utils/routeLink";
import * as Variable from "../../shared/utils/variables.js";
import * as ActionTypes from "../../store/actions/actionTypes";
import makeRequest from "../../shared/utils/request";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import Button from "../../shared/component/ButtonComponent/Button";
import Input from "../../shared/component/InputComponent/Input";
import { CloseEyeIcon, OpenEyeIcon } from "../../shared/component/svg/Icons";
import NoContentComponent from "../../shared/component/NoContentComponent/NoContentComponent";

import ResetPasswordSuccess from "../ResetPasswordComponent/ResetPasswordSuccess";

import onboarding1 from "../../assets/svg/onboarding_1.svg";
import onboarding2 from "../../assets/svg/no_job_exist.svg";
import onboarding3 from "../../assets/svg/onboarding_2.svg";
import emailSentIcon from "../../assets/svg/email.svg";

import "swiper/css";
import "swiper/css/pagination";
import "./SignUpComponent.scss";
import { formatString } from "shared/utils/string";

const SignUpComponent = ({ setLoginModalShow, ENV_NAME, ...props }) => {
	const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
	const PHONE_REGEX = /^[0-9\s]*$/;
	const toast = useToast();

	const [form, setForm] = useState({
		first_name: "",
		last_name: "",
		email: "",
		phone: "",
		password: "",
	});
	const [showPassword, setShowPassword] = useState(false);
	const [errors, setErrors] = useState({});
	const [emailSent, setEmailSent] = useState(false);
	const [showHelperText, setShowHelperText] = useState(false);
	const [passwordError, setPasswordError] = useState(null);
	const [emailError, setEmailError] = useState(false);

	useEffect(() => {
		const urlParams = new URLSearchParams(props.history.location.search);
		const isEmailSent = urlParams.get("is_sent");
		const email = urlParams.get("email");
		setEmailSent(isEmailSent);
		setForm((prev) => ({
			...prev,
			email,
		}));
	}, []);

	const onChange = (e) => {
		const { name, value } = e.target;
		setForm((prev) => ({
			...prev,
			[name]: name === "phone" ? (PHONE_REGEX.test(value) ? value : prev.phone) : value,
		}));

		setShowHelperText(!PASSWORD_REGEX.test(value) && name === "password");
		setEmailError(false);
		setPasswordError(null);

		setErrors((prev) => ({ ...prev, [name]: false }));
	};

	const handleRegisterUser = async () => {
		const { email, password, first_name, last_name, phone } = form;

		const errorsData = {};
		if (!first_name) errorsData["first_name"] = true;
		if (!email) errorsData["email"] = true;
		if (!phone) errorsData["phone"] = true;
		if (!password || !password.match(PASSWORD_REGEX)) errorsData["password"] = true;

		const hasErrors = Object.values(errorsData).some((err) => err);
		if (hasErrors) return setErrors(errorsData);

		const res = await makeRequest({
			...generateRequestOptions("jobSeekerSignUp"),
			data: {
				email,
				password,
				first_name,
				last_name,
				mobile_phone_number: phone,
			},
		});

		if (res.code === 200) {
			setEmailSent(true);
			props.history.push(`${RoutePath.SIGN_UP}?is_sent=true&email=${form?.email}`);
		} else {
			if (res?.message?.includes("User already exists")) setEmailError(true);
			if (res?.detail?.password) {
				setShowHelperText(false);
				setPasswordError(res?.detail?.password?.[0]);
				setErrors((prev) => ({ ...prev, password: true }));
			}
			if (!res?.message?.includes("User already exists") && !res?.detail?.password)
				toast.error(res.message);
		}
	};

	const handleResendEmail = async () => {
		const email = new URLSearchParams(props.history.location.search).get("email");
		if (!email) return toast.error(Variable.EMAIL_INVALID[ENV_NAME]);

		const res = await makeRequest({
			...generateRequestOptions("resendVerificationEmail"),
			data: { email },
		});

		if (res.code === 200) setEmailSent(true);
		else toast.error(res.message);
	};

	return (
		<div className="container-fluid suc">
			{emailSent ? (
				<div>
					<ResetPasswordSuccess
						ENV_NAME={ENV_NAME}
						icon={emailSentIcon}
						title={Variable.CHECK_YOUR_EMAIL[ENV_NAME]}
						description={
							Variable.SIGNUP_EMAIL_SENT_1[ENV_NAME] +
							"<strong>" +
							form?.email +
							"</strong>" +
							Variable.SIGNUP_EMAIL_SENT_2[ENV_NAME]
						}
						initialTimer={59}
						btnWithTimer={true}
						onClick={handleResendEmail}
					/>
				</div>
			) : (
				<div className="row p-0">
					<div className="col-md-6 d-none d-md-block p-0">
						<div className="suc-left">
							{/* Carousel */}
							<div className="onboarding-wrapper">
								<Swiper
									pagination={{
										dynamicBullets: true,
									}}
									autoplay={{
										delay: 2500,
										disableOnInteraction: false,
									}}
									modules={[Autoplay, Pagination]}
									className="onboarding-carousel"
								>
									<SwiperSlide>
										<NoContentComponent
											className="onboarding-slide"
											icon={onboarding1}
											title={Variable.ONBOARDING_1[ENV_NAME]}
											description={Variable.ONBOARDING_1_DESC[ENV_NAME]}
										/>
									</SwiperSlide>
									<SwiperSlide>
										<NoContentComponent
											className="onboarding-slide"
											icon={onboarding2}
											title={Variable.ONBOARDING_2[ENV_NAME]}
											description={Variable.ONBOARDING_2_DESC[ENV_NAME]}
										/>
									</SwiperSlide>
									<SwiperSlide>
										<NoContentComponent
											className="onboarding-slide"
											icon={onboarding3}
											title={Variable.ONBOARDING_3[ENV_NAME]}
											description={Variable.ONBOARDING_3_DESC[ENV_NAME]}
										/>
									</SwiperSlide>
								</Swiper>
							</div>
						</div>
					</div>
					<div className="col-md-6 p-0">
						<div className="suc-right">
							<h2>{Variable.REGISTER[ENV_NAME]}</h2>
							<div>
								<div className="suc-form">
									<Input
										label={Variable.FIRST_NAME[ENV_NAME]}
										placeholder={
											Variable.INPUT[ENV_NAME] +
											" " +
											Variable.FIRST_NAME[ENV_NAME]
										}
										value={form?.first_name || ""}
										name="first_name"
										onChange={onChange}
										inputType="label"
										className="w-100"
										required
										isError={errors["first_name"]}
										fieldErrorText={formatString(
											Variable.FIELD_REQUIRED[ENV_NAME],
											Variable.FIRST_NAME[ENV_NAME],
										)}
									/>
									<Input
										label={`${Variable.LAST_NAME[ENV_NAME]} (${Variable.OPTIONAL[ENV_NAME]})`}
										placeholder={
											Variable.INPUT[ENV_NAME] +
											" " +
											Variable.LAST_NAME[ENV_NAME]
										}
										value={form?.last_name || ""}
										name="last_name"
										onChange={onChange}
										inputType="label"
										className="w-100"
									/>
									<div>
										<Input
											label={`${Variable.EMAIL[ENV_NAME]}`}
											placeholder={
												Variable.INPUT[ENV_NAME] +
												" " +
												Variable.EMAIL[ENV_NAME]
											}
											value={form?.email || ""}
											name="email"
											onChange={onChange}
											inputType="email"
											showIcon={false}
											className="w-100"
											required
											isError={errors["email"] || emailError}
											fieldErrorText={
												errors["email"]
													? formatString(
															Variable.FIELD_REQUIRED[ENV_NAME],
															Variable.EMAIL[ENV_NAME],
													  )
													: emailError
													? Variable.USER_ALREADY_EXIST_TEXT[ENV_NAME]
													: ""
											}
										/>
									</div>
									<Input
										label={`${Variable.PHONE[ENV_NAME]}`}
										placeholder={Variable.PHONE_PLACEHOLDER[ENV_NAME]}
										value={form?.phone || ""}
										name="phone"
										onChange={onChange}
										inputType="label"
										className="w-100"
										required
										isError={errors["phone"]}
										fieldErrorText={formatString(
											Variable.FIELD_REQUIRED[ENV_NAME],
											Variable.PHONE[ENV_NAME],
										)}
									/>
									<div className="form-group J-inputComp mb-1p6rem w-100 lm-form">
										<label
											htmlFor="password"
											className="as-label pb-0 isRequired"
										>
											{Variable.PASSWORD[ENV_NAME]}
										</label>
										<div className="position-relative">
											<input
												id="password"
												type={showPassword ? "text" : "password"}
												className={`J-inputComp-input ${
													errors["password"] || passwordError
														? "field-error"
														: ""
												}`}
												name="password"
												value={form.password}
												autoComplete="none"
												onChange={onChange}
												placeholder={`${Variable.INPUT[ENV_NAME]} ${Variable.PASSWORD[ENV_NAME]}`}
												required
											/>
											<div
												className={`pwd-eye cursorPointer ${
													!showPassword ? "active" : ""
												}`}
												onClick={() => setShowPassword((prev) => !prev)}
											>
												{showPassword ? (
													<OpenEyeIcon
														width="1.6rem"
														height="1.6rem"
														stroke="#056cf2"
													/>
												) : (
													<CloseEyeIcon
														width="1.6rem"
														height="1.6rem"
														stroke="#BEC4CF"
													/>
												)}
											</div>
										</div>

										{showHelperText && (
											<span
												className={`helper-text ${
													errors["password"] ? "error-text" : ""
												}`}
											>
												{Variable.PASSWORD_HELPER_TEXT[ENV_NAME]}
											</span>
										)}

										{passwordError && (
											<span
												className={`helper-text ${
													errors["password"] ? "error-text" : ""
												}`}
											>
												{passwordError}
											</span>
										)}
										{!showHelperText &&
											!passwordError &&
											errors["password"] && (
												<span
													className={`helper-text ${
														errors["password"] ? "error-text" : ""
													}`}
												>
													{formatString(
														Variable.FIELD_REQUIRED[ENV_NAME],
														Variable.PASSWORD[ENV_NAME],
													)}
												</span>
											)}
									</div>
								</div>

								<Button
									type="primary"
									size="sm"
									title={Variable.REGISTER_ACCOUNT[ENV_NAME]}
									onClick={handleRegisterUser}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {
		setLoginModalShow: (payload = true) => {
			if (payload) {
				dispatch({
					type: ActionTypes.OPEN_LOGIN_MODAL,
				});
			} else {
				dispatch({
					type: ActionTypes.CLOSE_LOGIN_MODAL,
				});
			}
		},
	};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(SignUpComponent));
