import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import * as Variable from "../../utils/variables";
import { classHelper } from "../../utils/stringUtils";
import moment from "moment";

import "./DatePickerDropdown.scss";
import Select from "../SelectComponent/Select";
import { zeroPad } from "../../utils/numberUtils";

const DatePickerDropdown = ({ ENV_NAME, value, label, hideDate, required, isError, ...props }) => {
	const [date, setDate] = useState("");
	const [month, setMonth] = useState("");
	const [year, setYear] = useState("");

	const [dates, setDates] = useState(null);
	const [months, setMonths] = useState(null);
	const [years, setYears] = useState(null);

	useEffect(() => {
		if (value) {
			let momVal = moment(value);
			if (momVal.isValid()) {
				generateDays(momVal.daysInMonth());
				setDate(momVal.format("DD"));
				setMonth(momVal.format("MM"));
				setYear(momVal.format("YYYY"));
			}
		} else {
			// setDate("");
			// setMonth("");
			// setYear("");

			generateDays(31);
		}
	}, [value]);

	useEffect(() => {
		setMonths(
			(moment.months() || [])?.map((i, idx) => ({
				id: zeroPad(idx + 1),
				name: i,
			})),
		);
	}, [ENV_NAME]);

	useEffect(() => {
		let top = props.yearsAhead ?? 0;
		let down = props.yearsBehind ?? 120;

		let arr = [...Array(top + down + 1)];
		let startYear = moment().add(top, "year").year();

		setYears(
			arr?.map((i, idx) => ({
				id: `${startYear - idx}`,
				name: `${startYear - idx}`,
			})),
		);
	}, [props.yearsAhead, props.yearsBehind]);

	const generateDays = (dayCount) => {
		setDates(
			[...Array(dayCount)]?.map((i, idx) => ({
				id: zeroPad(idx + 1),
				name: zeroPad(idx + 1),
			})),
		);
	};

	useEffect(() => {
		if (month) {
			// 2001 is non leap year, just in case
			let dayCount = moment(`${month}-${year || 2001}`, "MM-YY").daysInMonth();
			setDate((p) => zeroPad(Math.min(dayCount, Number(p))));
			generateDays(dayCount);
		}
	}, [month, year]);

	useEffect(() => {
		if (date && month && year) {
			if (moment(`${year}-${month}-${date}`).isValid()) {
				props.onChange({
					type: "change",
					target: {
						name: props.name,
						value: `${year}-${month}-${date}`,
					},
				});
			}
			if (!moment(`${year}-${month}-${date}`).isValid()) {
				props.onChange({
					type: "change",
					target: {
						name: props.name,
						value: `${year}-${month}-01`,
					},
				});
			}
		} else {
			props.onChange({
				type: "change",
				target: {
					name: props.name,
					value: "",
				},
			});
		}
	}, [date, month, year]); // eslint-disable-line

	return (
		<div className={classHelper("date-picker-dropdown", props.className)}>
			{!hideDate && (
				<Select
					label={props.dayLabel || Variable.DATE[ENV_NAME]}
					placeholder={props.dayPlaceholder || Variable.DATE[ENV_NAME]}
					name={props.name || "date"}
					value={date || ""}
					items={dates}
					onChangeItem={(e) => setDate(e?.id)}
					searchable={false}
					className="w-100"
					required={required}
					isError={isError}
					noDefault
				/>
			)}
			<Select
				label={props.monthLabel || Variable.MONTH[ENV_NAME]}
				placeholder={props.monthPlaceholder || Variable.MONTH[ENV_NAME]}
				name={props.name || "date"}
				value={month || ""}
				items={months}
				onChangeItem={(e) => setMonth(e?.id)}
				searchable={false}
				className="w-100"
				hideLabelRequired={!hideDate}
				required={required}
				isError={isError}
				noDefault
			/>
			<Select
				label={props.yearLabel || Variable.DATE[ENV_NAME]}
				placeholder={props.yearPlaceholder || Variable.YEAR[ENV_NAME]}
				name={props.name || "date"}
				value={year || ""}
				items={years}
				onChangeItem={(e) => setYear(e?.id)}
				searchable={false}
				className="w-100"
				hideLabelRequired
				required={required}
				isError={isError}
				noDefault
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(DatePickerDropdown));
