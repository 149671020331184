import { useEffect, useState } from "react";
import { isEmpty, isEqual } from "lodash";
import { useDropzone } from "react-dropzone";

import useToast from "shared/hooks/useToast";
import * as Variable from "../../../shared/utils/variables";
import makeRequest from "../../../shared/utils/request";
import { generateRequestOptions } from "../../../shared/utils/apiEndPoints";
import Button from "../../../shared/component/ButtonComponent/Button";
import Image from "../../../shared/component/UI/Image";
import useUserDetails from "../../../shared/hooks/userDetails";

import avatarPlaceholder from "../../../assets/svg/avatar_placeholder.svg";
import { PiWarningCircle } from "react-icons/pi";
import { formatString } from "shared/utils/string";

function ProfilePicture({ ENV_NAME, userId, onNext, onNextStep, onComplete }) {
	const toast = useToast();

	const userDetails = useUserDetails(userId);
	const [templateFile, setTemplateFile] = useState(null);
	const [tempImgUrl, setTempImgUrl] = useState(null);
	const [isRejected, setIsRejected] = useState(false);
	const [isEdited, setIsEdited] = useState(false);
	const [fileError, setFileError] = useState(false);
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		accept: { "images/png": [".png"], "images/jpg": [".jpg", ".jpeg"] },
		multiple: false,
		maxFiles: 1,
		maxSize: 5000000,
		onDrop: (acceptedFiles, fileRejections) => onFileReject(acceptedFiles, fileRejections),
	});
	const [isUploading, setIsUploadinng] = useState(false);

	useEffect(() => {
		if (!isEmpty(userDetails)) {
			const { profile_picture } = userDetails?.personal_info;
			setTemplateFile(profile_picture);
		}
	}, [userDetails]);

	useEffect(() => {
		// create the preview
		if (!templateFile?.toString()?.startsWith("https")) {
			const objectUrl = templateFile ? URL.createObjectURL(templateFile) : undefined;
			setTempImgUrl(objectUrl);
			setFileError(false);
			// free memory when ever this component is unmounted
			return () => URL.revokeObjectURL(objectUrl);
		}
	}, [templateFile]);

	useEffect(() => {
		if (acceptedFiles?.length > 0 && !isEqual(acceptedFiles, templateFile)) {
			setTemplateFile(acceptedFiles[0]);
			setIsRejected(false);
			setIsEdited(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [acceptedFiles]);

	// Function to call if the file we selectede doesn't fulfill the criteria
	const onFileReject = (acceptedFiles, fileRejections) => {
		if (fileRejections.length > 0) {
			fileRejections?.[0]?.errors.forEach((i) => {
				if (i.code === "file-invalid-type")
					toast.error(
						formatString(Variable.FILE_FORMAT_SIZE[ENV_NAME], "PNG", "JPEG", 5),
					);
				if (i.code === "file-too-large") {
					console.log("inside");
					setFileError(true);
				}
			});
			return;
		}
	};

	const handleSave = async () => {
		if (!isEdited) {
			onNext();
			onNextStep();
			onComplete();
			return;
		}

		const formData = new FormData();
		formData.append("profile_picture", templateFile);
		setIsUploadinng(true);
		const res = await makeRequest({
			...generateRequestOptions("updateProfile"),
			data: formData,
		});

		if (res.code === 200) {
			onNext();
			onNextStep();
			onComplete();
			setIsUploadinng(false);
		} else {
			toast.error(res.message);
			setIsUploadinng(false);
		}
	};

	return (
		<>
			<div className="am-form">
				{!templateFile && !isRejected ? (
					<div className="drop-area ppic" {...getRootProps()}>
						<input {...getInputProps()} />
						<Image src={avatarPlaceholder} className="placeholder-image" />
						<div className="ppic-content">
							<p>
								{Variable.UPLOAD_FILE[ENV_NAME]}{" "}
								<strong className="choose-file">
									{Variable.CHOOSE_FILE[ENV_NAME]}
								</strong>{" "}
							</p>
							<strong>
								{formatString(
									Variable.FILE_FORMAT_SIZE[ENV_NAME],
									"PNG",
									"JPEG",
									5,
								)}
							</strong>
						</div>
						{fileError && (
							<p className="file-error">
								<PiWarningCircle className="icon-warning" />
								{Variable.UPLOAD_IMAGE_ERR[ENV_NAME]}
							</p>
						)}
					</div>
				) : (
					<div className={`drop-area ppic`}>
						<Image src={tempImgUrl || templateFile} className="placeholder-image" />

						<div className="replace-file mt-4" {...getRootProps()}>
							<input {...getInputProps()} />
							<div className="cursorPointer text-center ppic-content">
								<p>
									{Variable.UPLOAD_FILE[ENV_NAME]}{" "}
									<strong className="choose-file">
										{Variable.CHOOSE_FILE[ENV_NAME]}
									</strong>{" "}
								</p>
								<strong>
									{formatString(
										Variable.FILE_FORMAT_SIZE[ENV_NAME],
										"PNG",
										"JPEG",
										5,
									)}
								</strong>
							</div>
						</div>
						{fileError && (
							<p className="file-error">
								<PiWarningCircle className="icon-warning" />
								{Variable.UPLOAD_IMAGE_ERR[ENV_NAME]}
							</p>
						)}
					</div>
				)}
			</div>
			<Button
				type="primary"
				size="sm"
				title={Variable.NEXT_STEP[ENV_NAME]}
				className="mt-5"
				onClick={handleSave}
				isLoading={isUploading}
			/>
		</>
	);
}

export default ProfilePicture;
