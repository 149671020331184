import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import { PiArrowLeft } from "react-icons/pi";

import * as Variable from "../../../shared/utils/variables";

import AddLanguage from "./AddLanguage";
import ReviewLanguage from "./ReviewLanguage";
import useUserDetails from "../../../shared/hooks/userDetails";
import { SETUP_PROFILE } from "shared/utils/routeLink";

function Language({ ENV_NAME, userId, onPrevStep, onNextStep, onComplete, onSkip }) {
	const FORMS = [
		Variable.LANGUAGE[ENV_NAME],
		Variable.REVIEW[ENV_NAME] + " " + Variable.LANGUAGE[ENV_NAME],
	];
	const history = useHistory();
	const userDetails = useUserDetails(userId);
	const [currForm, setCurrForm] = useState(1);

	useEffect(() => {
		if (userDetails && userDetails?.language?.length) setCurrForm(2);
		else setCurrForm(1);
	}, [userDetails]);

	const handleBack = () => {
		if (currForm === 1) onPrevStep();
		if (currForm > 1) setCurrForm((prev) => prev - 1);
	};

	const handleNext = () => currForm < FORMS.length && setCurrForm((prev) => prev + 1);

	return (
		<div className="about-me">
			<div className="header">
				<PiArrowLeft
					color="#667085"
					className="cursorPointer icon-back"
					onClick={() => {
						history.replace(SETUP_PROFILE);
						currForm === 1 && userDetails?.language?.length
							? handleNext()
							: onPrevStep();
					}}
				/>
				<ProgressBar
					completed={(currForm * 100) / FORMS.length}
					height="10px"
					bgColor="#193560"
					baseBgColor="#f2f4f7"
					isLabelVisible={false}
				/>

				<h2>{FORMS[currForm - 1]}</h2>
			</div>
			<section>
				{currForm === 1 && (
					<AddLanguage
						ENV_NAME={ENV_NAME}
						onNext={handleNext}
						onNextStep={onNextStep}
						onSkip={onSkip}
					/>
				)}
				{currForm === 2 && (
					<ReviewLanguage
						ENV_NAME={ENV_NAME}
						onBack={handleBack}
						onNextStep={onNextStep}
						onComplete={onComplete}
					/>
				)}
			</section>
		</div>
	);
}

export default Language;
