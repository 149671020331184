import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PiPencilSimple, PiTrash } from "react-icons/pi";
import { capitalize } from "lodash";

import useToast from "shared/hooks/useToast";
import * as Variable from "../../../shared/utils/variables";
import * as RoutePath from "../../../shared/utils/routeLink";
import Image from "../../../shared/component/UI/Image";
import { getIconByName } from "../../../shared/utils/file";
import Button from "../../../shared/component/ButtonComponent/Button";
import { PlusIcon } from "../../../shared/component/svg/Icons";
import makeRequest from "../../../shared/utils/request";
import { generateRequestOptions } from "../../../shared/utils/apiEndPoints";
import CustomPopupMenu from "../../../shared/component/CustomPopupMenuComponent/CustomPopupMenu";

function ReviewLanguage({ ENV_NAME, onBack, onNextStep, onComplete }) {
	const toast = useToast();

	const ITEMS = [
		{
			name: Variable.EDIT_LABEL[ENV_NAME],
			task: (id) => handleEdit(id),
		},
		{
			name: Variable.DELETE_LABEL[ENV_NAME],
			task: (id) => handleDelete(id),
		},
	];

	const history = useHistory();
	const [languages, setLanguages] = useState([]);

	useEffect(() => {
		listLanguages();
	}, []);

	const listLanguages = async () => {
		const res = await makeRequest(generateRequestOptions("listLanguages"));

		if (res.code === 200) setLanguages(res.data);
		else toast.error(res.message);
	};

	const handleEdit = (langId = null) => {
		// Move back to the prev screen
		// update the URL with the language id --> language/id
		onBack();
		history.push(RoutePath.SETUP_PROFILE + "/language/" + langId);
	};

	const handleDelete = async (langId) => {
		const res = await makeRequest(
			generateRequestOptions("deleteLanguage", { urlParams: langId }),
		);

		if (res.code === 200) setLanguages(languages.filter((lang) => lang.id !== langId));
		else toast.error(res.message);
	};

	return (
		<div>
			<div className="review-wrapper">
				{languages.map((lang) => (
					<div className="edu-review lang-review" key={lang?.id}>
						<div className="edu-content">
							<h3>
								{lang?.name}{" "}
								{lang?.is_primary ? "(" + Variable.PRIMARY[ENV_NAME] + ")" : ""}{" "}
							</h3>
							<div className="proficiency">
								<p>
									{capitalize(lang?.spoken_proficiency)}{" "}
									{Variable.SPOKEN_PROFICIENCY[ENV_NAME].toLowerCase()}
								</p>

								<p>
									{capitalize(lang?.written_proficiency)}{" "}
									{Variable.WRITTEN_PROFICIENCY[ENV_NAME].toLowerCase()}
								</p>
							</div>
							<p>
								{Variable.LANGUAGE_CERTIFICATION_NAME[ENV_NAME]}:{" "}
								<strong>{lang?.language_proficiency_name}</strong>
							</p>
							<p>
								{Variable.LANGUAGE_PROFICIENCY_SCORE[ENV_NAME]}:{" "}
								<strong>{lang?.language_proficiency_score}</strong>
							</p>

							<div className="files">
								{lang?.language_document_proof?.map((doc, idx) => (
									<div className="file" key={doc?.id}>
										<Image
											src={getIconByName(doc?.file_name)}
											width="32rem"
											height="32rem"
										/>
										<p>{doc?.file_name}</p>
									</div>
								))}
							</div>
						</div>
						<div className="edit-delete-icons">
							<CustomPopupMenu id={lang?.id} items={ITEMS} className="d-sm-none" />

							<PiPencilSimple
								size={25}
								className="icon icon-edit d-none d-md-block"
								onClick={() => handleEdit(lang?.id)}
							/>

							<PiTrash
								size={25}
								className="icon icon-delete d-none d-md-block"
								onClick={() => handleDelete(lang?.id)}
							/>
						</div>
					</div>
				))}
			</div>
			<Button
				title={
					<div className="flex-all-center gap-xxs">
						<PlusIcon width="1.8rem" height="1.8rem" stroke="#667085" strokeWidth="3" />
						{Variable.ADD[ENV_NAME] + " " + Variable.LANGUAGE[ENV_NAME]}
					</div>
				}
				type="outline"
				className="mt-5"
				size="sm"
				onClick={() => {
					onBack();
				}}
			/>

			<div className="d-flex justify-content-between align-items-center">
				<Button
					title={Variable.NEXT_STEP[ENV_NAME]}
					type="primary"
					className="mt-5"
					size="sm"
					onClick={() => {
						history.push(RoutePath.SETUP_PROFILE);
						onNextStep();
						onComplete();
					}}
				/>
			</div>
		</div>
	);
}

export default ReviewLanguage;
