import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { PiPencilSimple, PiTrash } from "react-icons/pi";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import Truncate from "react-truncate";
import moment from "moment";

import useToast from "shared/hooks/useToast";
import * as Variable from "../../../shared/utils/variables";
import * as RoutePath from "../../../shared/utils/routeLink";
import Image from "../../../shared/component/UI/Image";
import { getIconByName } from "../../../shared/utils/file";
import Button from "../../../shared/component/ButtonComponent/Button";
import { PlusIcon } from "../../../shared/component/svg/Icons";
import makeRequest from "../../../shared/utils/request";
import { generateRequestOptions } from "../../../shared/utils/apiEndPoints";
import { getCurrencySymbol, getLocale, listToStr } from "../../../shared/utils/string";
import CustomPopupMenu from "../../../shared/component/CustomPopupMenuComponent/CustomPopupMenu";

const timeDuration = (start, end, ENV_NAME = "bhs") => {
	const diff = moment(start ?? undefined)?.diff(moment(end));
	const yearDiff = moment.duration(diff)?.years();
	const monthDiff = moment.duration(diff)?.months() % 12;
	if (yearDiff === 0) {
		return `${monthDiff} ${
			monthDiff <= 1 ? Variable.MONTH[ENV_NAME] : Variable.MONTHS[ENV_NAME]
		}`;
	}
	return `${yearDiff} ${Variable.YEAR[ENV_NAME]} ${monthDiff} ${
		monthDiff <= 1 ? Variable.MONTH[ENV_NAME] : Variable.MONTHS[ENV_NAME]
	}`;
};

const renderLocation = (address, city, postalCode, country) => {
	let arr = [address, city, postalCode, country]?.filter((i) => i);
	return listToStr(arr);
};

const Truncation = ({ children, ENV_NAME = "bhs", ...props }) => {
	const [showMore, setShowMore] = useState(false);

	return (
		<Truncate
			lines={showMore ? -1 : 2}
			trimWhitespace
			ellipsis={
				<>
					...
					<span
						className="cursorPointer ms-xxs text-label-blue font-weight-semibold"
						onClick={() => setShowMore(true)}
						style={{ color: "#3E6DBC" }}
					>
						{Variable.READ_MORE[ENV_NAME]}
					</span>
				</>
			}
			{...props}
		>
			{children}
		</Truncate>
	);
};

function ReviewExperience({ ENV_NAME, onBack, onNextStep, onComplete }) {
	const toast = useToast();

	const ITEMS = [
		{
			name: Variable.EDIT_LABEL[ENV_NAME],
			task: (id) => handleEdit(id),
		},
		{
			name: Variable.DELETE_LABEL[ENV_NAME],
			task: (id) => handleDelete(id),
		},
	];
	const regionNames = new Intl.DisplayNames([ENV_NAME === "bhs" ? "id" : "en"], {
		type: "region",
	});

	const history = useHistory();
	const [experiences, setExperiences] = useState([]);

	useEffect(() => {
		listExperiences();
	}, []);

	const listExperiences = async () => {
		const res = await makeRequest(generateRequestOptions("listExperience"));

		if (res.code === 200) setExperiences(res.data);
		else toast.error(res.message);
	};

	const handleEdit = (experienceId = null) => {
		// Move back to the prev screen
		// update the URL with the experience id --> experience/id
		onBack();
		history.push(RoutePath.SETUP_PROFILE + "/experience/" + experienceId);
	};

	const handleDelete = async (experienceId) => {
		const res = await makeRequest(
			generateRequestOptions("deleteExperience", { urlParams: experienceId }),
		);

		if (res.code === 200) setExperiences(experiences.filter((edu) => edu.id !== experienceId));
		else toast.error(res.message);
	};

	return (
		<>
			<div className="review-wrapper">
				{experiences.map((exp) => (
					<div className="edu-review" key={exp?.id}>
						<HiOutlineBuildingOffice size={30} className="icon icon-cap" />
						<div className="edu-content">
							<h3>{exp?.company_name}</h3>
							<div className="otherInfo">
								<p>{exp?.job_title}</p>
								<p>
									{`${moment(exp?.started_at)?.format("MMM YYYY")} - ${
										exp?.is_currently_working
											? Variable.PRESENT[ENV_NAME]
											: moment(exp?.ended_at)?.format("MMM YYYY")
									}`}
								</p>
								<p>
									{timeDuration(
										exp?.is_currently_working ? undefined : exp?.ended_at,
										exp?.started_at,
										ENV_NAME,
									)}
								</p>
							</div>
							<p className="country">
								{renderLocation(
									null,
									exp?.city,
									null,
									regionNames.of(exp?.country || ""),
								)}
							</p>
							{exp?.salary && (
								<div className="grade">
									{`${Variable.MONTHLY_SALARY[ENV_NAME]}: ${getCurrencySymbol(
										exp?.currency || "IDR",
										getLocale(ENV_NAME),
									)} ${Number(exp?.salary || 0)?.toLocaleString(
										getLocale(ENV_NAME),
									)}`}
								</div>
							)}

							<div className="truncation-text-sm text-sm">
								<Truncation ENV_NAME={ENV_NAME}>
									{exp?.description || ""}
								</Truncation>
							</div>

							<div className="files">
								{exp?.experience_document_proof?.map((doc) => (
									<div className="file" key={doc?.id}>
										<Image
											src={getIconByName(doc?.file_name)}
											width="32rem"
											height="32rem"
										/>
										<p>{doc?.file_name}</p>
									</div>
								))}
							</div>
						</div>
						<div className="edit-delete-icons">
							<CustomPopupMenu id={exp?.id} items={ITEMS} className="d-sm-none" />

							<PiPencilSimple
								size={25}
								className="icon icon-edit d-none d-md-block"
								onClick={() => handleEdit(exp?.id)}
							/>

							<PiTrash
								size={25}
								className="icon icon-delete d-none d-md-block"
								onClick={() => handleDelete(exp?.id)}
							/>
						</div>
					</div>
				))}
			</div>
			<Button
				title={
					<div className="flex-all-center gap-xxs">
						<PlusIcon width="1.8rem" height="1.8rem" stroke="#667085" strokeWidth="3" />
						{Variable.ADD[ENV_NAME] + " " + Variable.EXPERIENCE[ENV_NAME]}
					</div>
				}
				type="outline"
				className="mt-5"
				size="sm"
				onClick={() => {
					onBack();
					history.push(RoutePath.SETUP_PROFILE);
				}}
			/>

			<Button
				title={Variable.NEXT_STEP[ENV_NAME]}
				type="primary"
				className="mt-5"
				size="sm"
				onClick={() => {
					onNextStep();
					onComplete();
				}}
			/>
		</>
	);
}

export default ReviewExperience;
