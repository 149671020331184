import Slider from "react-slick";

import { CaretDown } from "../svg/Icons";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HorizontalScroll.scss";

function PrevArrow(props) {
	const { onClick } = props;
	return (
		<button
			className={`nav-button prev ${onClick ? "" : "d-none"}`}
			onClick={onClick}
		>
			<CaretDown width="2rem" height="2rem" strokeWidth="3" stroke="#193560" />
		</button>
	);
}

function NextArrow(props) {
	const { onClick } = props;
	return (
		<button
			className={`nav-button next ${onClick ? "" : "d-none"}`}
			onClick={onClick}
		>
			<CaretDown width="2rem" height="2rem" strokeWidth="3" stroke="#193560" />
		</button>
	);
}

const BREAKPOINTS = {
	sm: 576,
	lg: 992,
};

function HorizontalScroll({ children }) {
	const TOTAL_SLIDES_IN_VIEW = 4;

	return (
		<div className="mt-std carousel-cont">
			<Slider
				dots={false}
				infinite={false}
				speed={250}
				slidesToShow={TOTAL_SLIDES_IN_VIEW}
				slidesToScroll={TOTAL_SLIDES_IN_VIEW}
				prevArrow={<PrevArrow />}
				nextArrow={<NextArrow />}
				variableWidth={true}
				responsive={[
					// Here, the order will be applied from top to bottom
					// and the breakpoint will be for max-width
					{
						breakpoint: BREAKPOINTS.lg,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2,
						},
					},
					{
						breakpoint: BREAKPOINTS.sm,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
						},
					},
				]}
			>
				{children}
			</Slider>
		</div>
	);
}

export default HorizontalScroll;
