import { useEffect, useRef, useState } from "react";

import useToast from "shared/hooks/useToast";
import * as Variable from "../../shared/utils/variables";
import makeRequest from "../../shared/utils/request";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import Button from "../../shared/component/ButtonComponent/Button";
import Input from "../../shared/component/InputComponent/Input";

import "./ApplyJobModal.scss";

function Pitch({ ENV_NAME, jobAppId, hasQuestions, ...props }) {
	const toast = useToast();

	const [form, setForm] = useState({
		pitch: "",
	});
	const [isEdited, setIsEdited] = useState(false);

	useEffect(() => {
		if (jobAppId) retrieveFormData();
	}, [jobAppId]);

	const retrieveFormData = async () => {
		const res = await makeRequest(generateRequestOptions("getPitch", { urlParams: jobAppId }));

		if (res.code === 200) setForm({ pitch: res.data.self_promote_note || "" });
		else toast.error(res.message);
	};

	const validateAndSave = async () => {
		// if the form is not edited just skip this step
		if (!isEdited) return props.onUpdateActiveForm(5);

		const res = await makeRequest({
			...generateRequestOptions("updatePitch", { urlParams: jobAppId }),
			data: { self_promote_note: form?.pitch },
		});

		if (res.code === 200) {
			props.onUpdateActiveForm(5);
			resetState();
			setIsEdited(false);
		} else toast.error(res.message);
	};

	const resetState = () => setForm({ pitch: "" });

	return (
		<>
			<div className="contact-form">
				<div className="body">
					<Input
						value={form?.pitch}
						name="pitch"
						onChange={(value) => {
							setForm({ pitch: value });
							setIsEdited(true);
						}}
						characterLimit={200}
						inputType="textarea"
						placeholder={Variable.PITCH_DESC_PLACEHOLDER[ENV_NAME]}
					/>
				</div>
			</div>
			<div className="btn-grp">
				<div className="flex-all-center gap-md w-100">
					<Button
						type="outline"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={() => props.onUpdateActiveForm(hasQuestions ? 3 : 2)} // based on prescreen question move to either 3 or 2
						title={Variable.BACK[ENV_NAME]}
					/>
					<Button
						type="primary"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={validateAndSave}
						title={Variable.NEXT[ENV_NAME]}
					/>
				</div>
			</div>
		</>
	);
}

export default Pitch;
