// Import from external link
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

// Import from internal
import * as Variable from "../../shared/utils/variables";
import * as RoutePath from "../../shared/utils/routeLink";

// Import Components and Images
import "./JobComparison.scss";
import Image from "../../shared/component/UI/Image";
import Input from "../../shared/component/InputComponent/Input";
import Button from "../../shared/component/ButtonComponent/Button";
import ModalDescription from "../../shared/component/ModalComponent/ModalDescription";
import tokopediaIcon from "../../assets/svg/tokped.svg";

const JobComparison = ({ data, ENV_NAME, ...props }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="details-compare">
      <div className="container">
        <div className="details-compare-title">
          {Variable.COMPARE_JOB_LABEL[ENV_NAME]}
        </div>
        <div className="details-compare-search">
          <Input dropdownType="dropdown" />
          <Button
            type="primary"
            size="sm"
            title={Variable.ADD_TO_COMPARE[ENV_NAME]}
          />
        </div>
        <div className="details-compare-table">
          <table className="table table-bordered table-striped dct">
            <thead>
              <tr>
                <th className="dct-head text-center borRad-left">
                  {Variable.COMPANY_NAME[ENV_NAME]}
                </th>
                <th className="dct-head text-center">
                  <div className="dct-company">
                    <Image src={tokopediaIcon} />
                    <h3 className="dct-head">UI UX Designer</h3>
                    <p>Tokopedia</p>
                    <div className="dct-company-button">
                      <Button
                        className="flex-grow-1"
                        btnClassName="w-100"
                        type="error"
                        size="sm"
                        title={Variable.REMOVE_LABEL[ENV_NAME]}
                      />
                      <Button
                        className="flex-grow-1"
                        btnClassName="w-100"
                        type="secondary"
                        size="sm"
                        title={Variable.APPLY_NOW[ENV_NAME]}
                      />
                    </div>
                  </div>
                </th>
                <th className="dct-head text-center">
                  <div className="dct-company">
                    <Image src={tokopediaIcon} />
                    <h3 className="dct-head">UI UX Designer</h3>
                    <p>Tokopedia</p>
                    <div className="dct-company-button">
                      <Button
                        className="flex-grow-1"
                        btnClassName="w-100"
                        type="error"
                        size="sm"
                        title={Variable.REMOVE_LABEL[ENV_NAME]}
                      />
                      <Button
                        className="flex-grow-1"
                        btnClassName="w-100"
                        type="secondary"
                        size="sm"
                        title={Variable.APPLY_NOW[ENV_NAME]}
                      />
                    </div>
                  </div>
                </th>
                <th className="dct-head text-center borRad-right">
                  <div className="dct-company">
                    <Image src={tokopediaIcon} />
                    <h3 className="dct-head">UI UX Designer</h3>
                    <p>Tokopedia</p>
                    <div className="dct-company-button">
                      <Button
                        className="flex-grow-1"
                        btnClassName="w-100"
                        type="error"
                        size="sm"
                        title={Variable.REMOVE_LABEL[ENV_NAME]}
                      />
                      <Button
                        className="flex-grow-1"
                        btnClassName="w-100"
                        type="secondary"
                        size="sm"
                        title={Variable.APPLY_NOW[ENV_NAME]}
                      />
                    </div>
                  </div>
                </th>
                <th className="dct-head text-center borRad-right">
                  <div className="dct-company">
                    <Image src={tokopediaIcon} />
                    <h3 className="dct-head">UI UX Designer</h3>
                    <p>Tokopedia</p>
                    <div className="dct-company-button">
                      <Button
                        className="flex-grow-1"
                        btnClassName="w-100"
                        type="error"
                        size="sm"
                        title={Variable.REMOVE_LABEL[ENV_NAME]}
                      />
                      <Button
                        className="flex-grow-1"
                        btnClassName="w-100"
                        type="secondary"
                        size="sm"
                        title={Variable.APPLY_NOW[ENV_NAME]}
                      />
                    </div>
                  </div>
                </th>
                <th className="dct-head text-center borRad-right">
                  <div className="dct-company">
                    <Image src={tokopediaIcon} />
                    <h3 className="dct-head">UI UX Designer</h3>
                    <p>Tokopedia</p>
                    <div className="dct-company-button">
                      <Button
                        className="flex-grow-1"
                        btnClassName="w-100"
                        type="error"
                        size="sm"
                        title={Variable.REMOVE_LABEL[ENV_NAME]}
                      />
                      <Button
                        className="flex-grow-1"
                        btnClassName="w-100"
                        type="secondary"
                        size="sm"
                        title={Variable.APPLY_NOW[ENV_NAME]}
                      />
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="dct-body bold">Location</td>
                <td className="dct-body">Malang</td>
                <td className="dct-body">Malang</td>
                <td className="dct-body">Malang</td>
                <td className="dct-body">Malang</td>
                <td className="dct-body">Malang</td>
              </tr>
              <tr>
                <td className="dct-body bold">Salary</td>
                <td className="dct-body">IDR 3,5 jt - 6 jt perbulan</td>
                <td className="dct-body">IDR 4 jt - 6 jt perbulan</td>
                <td className="dct-body">IDR 6 jt - 9 jt perbulan</td>
                <td className="dct-body">IDR 6 jt - 9 jt perbulan</td>
                <td className="dct-body">IDR 6 jt - 9 jt perbulan</td>
              </tr>
              <tr>
                <td className="dct-body bold">Experience Level</td>
                <td className="dct-body">3-5 years</td>
                <td className="dct-body">2-5 years</td>
                <td className="dct-body">Min 3 years</td>
                <td className="dct-body">Min 3 years</td>
                <td className="dct-body">Min 3 years</td>
              </tr>
              <tr>
                <td className="dct-body bold">Job Type</td>
                <td className="dct-body">Freelance, Full-time, contract</td>
                <td className="dct-body">Full-time</td>
                <td className="dct-body">Contract</td>
                <td className="dct-body">Contract</td>
                <td className="dct-body">Contract</td>
              </tr>
              <tr>
                <td className="dct-body bold">Work Mode</td>
                <td className="dct-body">
                  Hybrid, Remote, On-site, Work from home
                </td>
                <td className="dct-body">Remote</td>
                <td className="dct-body">Work from home</td>
                <td className="dct-body">Work from home</td>
                <td className="dct-body">Work from home</td>
              </tr>
              <tr>
                <td className="dct-body bold">Urgency</td>
                <td className="dct-body">1 Week</td>
                <td className="dct-body">3 Week</td>
                <td className="dct-body">1 Week</td>
                <td className="dct-body">1 Week</td>
                <td className="dct-body">1 Week</td>
              </tr>
              <tr>
                <td className="dct-body bold">Job Description</td>
                <td className="dct-body">
                  <div className="dct-description">
                    <h4 className="dct-body semibold">
                      {Variable.QUALIFICATIONS[ENV_NAME]}
                    </h4>
                    <ul>
                      <li>Bachelor of Civil Engineering Education</li>
                      <li>Maximum age 30 years</li>
                      <li>Having experience for 1 year is a good plus</li>
                    </ul>
                    <Button
                      type="textLink"
                      size="xs"
                      title={Variable.READ_MORE[ENV_NAME]}
                      onClick={() => setShow(true)}
                    />
                  </div>
                </td>
                <td className="dct-body">
                  <div className="dct-description">
                    <h4 className="dct-body semibold">
                      {Variable.QUALIFICATIONS[ENV_NAME]}
                    </h4>
                    <ul>
                      <li>Bachelor of Civil Engineering Education</li>
                      <li>Maximum age 30 years</li>
                      <li>Having experience for 1 year is a good plus</li>
                    </ul>
                    <Button
                      type="textLink"
                      size="xs"
                      title={Variable.READ_MORE[ENV_NAME]}
                      onClick={() => setShow(true)}
                    />
                  </div>
                </td>
                <td className="dct-body">
                  <div className="dct-description">
                    <h4 className="dct-body semibold">
                      {Variable.QUALIFICATIONS[ENV_NAME]}
                    </h4>
                    <ul>
                      <li>Bachelor of Civil Engineering Education</li>
                      <li>Maximum age 30 years</li>
                      <li>Having experience for 1 year is a good plus</li>
                    </ul>
                    <Button
                      type="textLink"
                      size="xs"
                      title={Variable.READ_MORE[ENV_NAME]}
                      onClick={() => setShow(true)}
                    />
                  </div>
                </td>
                <td className="dct-body">
                  <div className="dct-description">
                    <h4 className="dct-body semibold">
                      {Variable.QUALIFICATIONS[ENV_NAME]}
                    </h4>
                    <ul>
                      <li>Bachelor of Civil Engineering Education</li>
                      <li>Maximum age 30 years</li>
                      <li>Having experience for 1 year is a good plus</li>
                    </ul>
                    <Button
                      type="textLink"
                      size="xs"
                      title={Variable.READ_MORE[ENV_NAME]}
                      onClick={() => setShow(true)}
                    />
                  </div>
                </td>
                <td className="dct-body">
                  <div className="dct-description">
                    <h4 className="dct-body semibold">
                      {Variable.QUALIFICATIONS[ENV_NAME]}
                    </h4>
                    <ul>
                      <li>Bachelor of Civil Engineering Education</li>
                      <li>Maximum age 30 years</li>
                      <li>Having experience for 1 year is a good plus</li>
                    </ul>
                    <Button
                      type="textLink"
                      size="xs"
                      title={Variable.READ_MORE[ENV_NAME]}
                      onClick={() => setShow(true)}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {show && <ModalDescription isShow={show} isHide={() => setShow(false)} />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(withRouter(JobComparison));
