import * as ActionTypes from "./actionTypes";

export const logout = () => ({ 
	type: ActionTypes.LOGOUT 
});

export const toggleLoginModalShow = (payload = true) => ({
	type: payload ? ActionTypes.OPEN_LOGIN_MODAL : ActionTypes.CLOSE_LOGIN_MODAL,
});

export const changeLanguage = (payload) => ({
		type: ActionTypes.UPDATE_LANGUAGE,
		payload: payload,
});

export const changeCurrency = (payload) => ({
		type: ActionTypes.UPDATE_CURRENCY,
		payload: payload,
});