import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import cookies from "react-cookies";

import * as RoutePath from "../../utils/routeLink";
import * as Variable from "../../utils/variables";
import * as ActionTypes from "../../../store/actions/actionTypes";
import { generateRequestOptions, homeUrl } from "../../utils/apiEndPoints";
import { CloseEyeIcon, OpenEyeIcon } from "../svg/Icons";
import iconPortalSekolah from "assets/svg/portal_sekolah_logo.svg";
import iconPortalKerja from "assets/svg/portal_kerja_logo.svg";
import isValidEmail from "../../utils/isValidEmail";
import makeRequest from "../../utils/request";
import Image from "../UI/Image";
import useFetch from "shared/hooks/useFetch";
import useToast from "shared/hooks/useToast";

import "./ModalComponent.scss";
import Button from "../ButtonComponent/Button";
import useAuth from "shared/hooks/useAuth";

const ModalLogin = ({ show, onHide, ENV_NAME, ...props }) => {
	const [errorMessage, setErrorMessage] = useState(null);
	const [email, setEmail] = useState();
	const [password, setPassword] = useState();
	const [isShowPassword, setIsShowPassword] = useState(false);
	const [isProcessing, setIsProcessing] = useState(false);
	const loginRef = useRef();
	const history = useHistory();
	const toast = useToast();

	const { getDatas: getPopularJob } = useFetch("popularJobs", {}, { lazy: true });
	const { getDatas: getTrendingJob } = useFetch("trendingJobs", {}, { lazy: true });
	const { getDatas: getRecommendedJob } = useFetch("findJobs", {}, { lazy: true });
	const { handleLogout } = useAuth();

	const dispatch = useDispatch();

	useEffect(() => {
		if (!show) {
			setEmail("");
			setPassword("");
			setIsShowPassword(false);
			setIsProcessing(false);
			setErrorMessage(null);
		}
	}, [show]);

	const logIn = async () => {
		setErrorMessage(null);

		let data = {
			email: email,
			password: password,
		};
		if (!(data.login || data.email) || !data.password) {
			if (!(data.login || data.email) && data.password) {
				setErrorMessage(Variable.FILL_CORRECT_USER_ID[ENV_NAME]);
			} else if (!data.password && (data.login || data.email)) {
				setErrorMessage(Variable.FILL_CORRECT_PASSWORD[ENV_NAME]);
			} else {
				setErrorMessage(Variable.FILL_CORRECT_USER_ID_PASSWORD[ENV_NAME]);
			}
		} else {
			setIsProcessing(true);
			let res = await makeRequest({
				...generateRequestOptions("logIn"),
				headers: {
					Accept: "application/json",
					"content-type": "application/json",
					"Accept-Language": `${cookies.load("lang") === "eng" ? "en-us" : "id"}`,
				},
				data,
				json: true,
			});
			if (res?.code === 200) {
				if (res?.data?.is_dummy_account) {
					toast.error(Variable.DUMMY_ACCOUNT_TOAST_MSG[ENV_NAME]);
					handleLogout();
					onHide();
					return;
				}

				if (!cookies.load("lang")) {
					cookies.save("lang", "bhs", {
						domain: homeUrl,
						path: "/",
						expires: new Date("9999-12-31T12:00:00.000Z"),
					});
				}
				if (!cookies.load("curr")) {
					cookies.save("curr", props.curr || { id: "IDR", name: "IDR" }, {
						domain: homeUrl,
						path: "/",
						expires: new Date("9999-12-31T12:00:00.000Z"),
					});
				}
				cookies.save("ptkjauthtoken", res.data?.token, {
					domain: homeUrl,
					path: "/",
					expires: new Date("9999-12-31T12:00:00.000Z"),
				});
				cookies.save("ptkjrefreshtoken", res.data?.refresh, {
					domain: homeUrl,
					path: "/",
					expires: new Date("9999-12-31T12:00:00.000Z"),
				});

				// let tokenData = JSON.parse(atob(res.data?.jwt?.access.split(".")[1]));
				let timerData = Date.now(res?.data?.last_login);
				cookies.save("tokenexpiry", timerData + 870000, {
					domain: homeUrl,
					path: "/",
					expires: new Date("9999-12-31T12:00:00.000Z"),
				});
				cookies.save("ptkjemail", data?.email, {
					domain: homeUrl,
					path: "/",
					expires: new Date("9999-12-31T12:00:00.000Z"),
				});
				cookies.save("userRole", res.data.role, {
					domain: homeUrl,
					path: "/",
					expires: new Date("9999-12-31T12:00:00.000Z"),
				});

				// TODO: Redirect the user to initial profile setup screen on first time login

				if (res?.data?.is_initial_login) props.history.replace(RoutePath.SETUP_PROFILE);
				else props.history.push(RoutePath.ROOT);

				onHide();

				props.dispatch_loginSucceed(null);
				history.push(RoutePath.SELECT_MODULES);
				// history.go(0);
				toast.success(Variable.LOGIN_SUCCESS[ENV_NAME]);
				// setTimeout(() => {
				// 	window.location.reload();
				// }, 500);
			} else {
				setErrorMessage(Variable.ERROR_INVALID_LOGIN[ENV_NAME]);
			}
			setIsProcessing(false);
			return res;
		}
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			backdrop={isProcessing ? "static" : undefined}
			dialogClassName="login-modal"
			centered
		>
			<Modal.Body>
				<form
					onSubmit={async (e) => {
						e.preventDefault();
						logIn().then((res) => {
							if (res.code === 200) {
								if (history.location.pathname === RoutePath.ROOT) {
									getTrendingJob().then((res) => {
										dispatch({
											type: ActionTypes.SET_JOBS_TRENDING,
											payload: res.data,
										});
									});

									getPopularJob().then((res) => {
										dispatch({
											type: ActionTypes.SET_JOBS_POPULAR,
											payload: res.data,
										});
									});

									getRecommendedJob().then((res) => {
										dispatch({
											type: ActionTypes.SET_JOBS_RECOMMENDED,
											payload: res.data,
										});
									});
								}
							}
						});
					}}
					className="flex-all-center flex-column"
				>
					<div className="mb-2rem text-center lm">
						<h4>{Variable.LOGIN_PORTAL_KERJA[ENV_NAME]}</h4>
						<div className="text-label-dark-gray">
							{/* Too complicated to use Variable name */}
							{ENV_NAME === "eng" ? (
								<span>
									{`Please login with`}
									<Image
										className="ms-xxs mb-1"
										src={iconPortalSekolah}
										height="12rem"
									/>
									{` account or`}
									<div className="mb-xs" />
									<Image
										className="ms-xxs"
										src={iconPortalKerja}
										height="14rem"
									/>
									{` account`}
								</span>
							) : (
								<span>
									{`Silakan masuk dengan akun`}
									<Image
										className="ms-xxs mb-1"
										src={iconPortalSekolah}
										height="12rem"
									/>
									<div className="mb-xs" />
									{` atau akun`}
									<Image
										className="ms-xxs"
										src={iconPortalKerja}
										height="14rem"
									/>
								</span>
							)}
						</div>
					</div>

					<div className="form-group J-inputComp w-100 lm-form">
						<label htmlFor="login" className="as-label pb-0">
							{Variable.EMAIL[ENV_NAME]}
						</label>
						<input
							id="login"
							type="email"
							className={`J-inputComp-input ${
								errorMessage || (email && !isValidEmail(email)) ? "borError" : ""
							}`}
							name="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeholder={`${Variable.ENTER[ENV_NAME]} ${Variable.EMAIL[ENV_NAME]}`}
							ref={loginRef}
							required
						/>
						{email && !isValidEmail(email) && (
							<span className="error-txt">{Variable.EMAIL_INVALID[ENV_NAME]}</span>
						)}
					</div>
					<div className="form-group J-inputComp mb-1p6rem w-100 lm-form">
						<label htmlFor="password" className="as-label pb-0">
							{Variable.PASSWORD[ENV_NAME]}
						</label>
						<div className="position-relative">
							<input
								id="password"
								type={isShowPassword ? "text" : "password"}
								className={`J-inputComp-input ${errorMessage ? "borError" : ""}`}
								name="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								placeholder={`${Variable.ENTER[ENV_NAME]} ${Variable.PASSWORD[ENV_NAME]}`}
								required
							/>
							<div
								className={`pwd-eye cursorPointer ${
									!isShowPassword ? "active" : ""
								}`}
								onClick={() => setIsShowPassword((p) => !p)}
							>
								{isShowPassword ? (
									<OpenEyeIcon width="1.6rem" height="1.6rem" stroke="#056cf2" />
								) : (
									<CloseEyeIcon width="1.6rem" height="1.6rem" stroke="#BEC4CF" />
								)}
							</div>
						</div>
					</div>
					<div className="text-start w-100 mb-md lm-password">
						<Link
							to={RoutePath.FORGOT_PASSWORD}
							className="text-decoration-none"
							onClick={() => onHide()}
						>
							<span className="font-weight-medium cursorPointer">
								{`${Variable.FORGOT_PASSWORD[ENV_NAME]}?`}
							</span>
						</Link>
					</div>
					{errorMessage && <div className="error-msg">{errorMessage}</div>}
					<Button
						type="primary"
						size="sm"
						btnClassName="w-100 flex-grow-1"
						className="w-100 flex-grow-1"
						disabled={!email || !password || isProcessing || !isValidEmail(email)}
					>
						{isProcessing ? (
							<div className="spinner-border" role="status">
								<span className="visually-hidden">
									{Variable.LOADING_LABEL[ENV_NAME]}
								</span>
							</div>
						) : (
							Variable.SIGN_IN[ENV_NAME]
						)}
					</Button>
				</form>
				<div className="divider" />
				<div className="flex-all-center flex-column lm-footer">
					<h4 className="title">{Variable.NEW_USER[ENV_NAME]} ?</h4>
					<Link
						to={RoutePath.SELECT_ROLE_REGISTRATION}
						className="text-decoration-none"
						onClick={() => onHide()}
					>
						<h4 className="link">{Variable.SIGN_UP_FREE[ENV_NAME]}</h4>
					</Link>
				</div>
			</Modal.Body>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
	curr: state.auth.currency,
	ENV_NAME: state.auth.selectedEnvironment,
});

const mapStateToDispatch = (dispatch) => {
	return {
		dispatch_loginSucceed: (payload) =>
			dispatch({
				type: ActionTypes.LOGIN_SUCCEED_SAGA,
				payload: payload,
			}),
	};
};
export default connect(mapStateToProps, mapStateToDispatch)(withRouter(ModalLogin));
