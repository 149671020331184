import { CURRENCY_LIST } from "./variables";

const formatNumber = (num, currency = "idr") => {
	num = String(num);
	const locale = CURRENCY_LIST.find((curr) => curr?.id === currency)?.locale;

	const formattedNumber = new Intl.NumberFormat(locale).format(num?.replace(/[^0-9]/g, ""));

	return +formattedNumber === 0 ? "" : formattedNumber;
};

export default formatNumber;
