import { useEffect, useState } from "react";

import useToast from "shared/hooks/useToast";
import makeRequest from "../utils/request";
import { generateRequestOptions } from "../utils/apiEndPoints";
import { useDispatch } from "react-redux";
import { LOGOUT_SUCCEED } from "store/actions/actionTypes";

const useUserDetails = (userId) => {
	const toast = useToast();

	const [userDetails, setUserDetails] = useState({});
	const dispatch = useDispatch();

	useEffect(() => {
		const getUserDetails = async (userId) => {
			const res = await makeRequest(
				generateRequestOptions("getProfile", { urlParams: userId }),
			);

			if (res.code === 200) setUserDetails(res.data);
			else if (
				res.message.toLowerCase().includes("authentication credentials were not provided.")
			)
				dispatch({ type: LOGOUT_SUCCEED });
		};

		if (!userId) return;
		getUserDetails(userId);
	}, [userId]);

	return userDetails;
};

export default useUserDetails;
