import { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import cookies from "react-cookies";

import useToast from "shared/hooks/useToast";
import * as RoutePath from "../../shared/utils/routeLink";
import makeRequest from "../../shared/utils/request";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import Image from "../../shared/component/UI/Image";
import Input from "../../shared/component/InputComponent/Input";
import Button from "../../shared/component/ButtonComponent/Button";
import Card from "../../shared/component/CardComponent/Card";
import HorizontalScroll from "../../shared/component/HorizontalScrollComponent/HorizontalScroll";
import NoContentComponent from "../../shared/component/NoContentComponent/NoContentComponent";
import ModalInfo from "../../shared/component/ModalComponent/ModalInfo";

import ApplyJobModal from "../ApplyJobComponent/ApplyJobModal";

import NoJobExist from "../../assets/svg/no_job_exist.svg";
import GreenCheck from "../../assets/svg/green-check.svg";
import Banner from "../../assets/image/banner/banner.png";

import "./HomeComponent.scss";
import { toggleLoginModalShow } from "store/actions/profileActions";
import {
	SET_JOBS_POPULAR,
	SET_JOBS_TRENDING,
	SET_JOBS_RECOMMENDED,
} from "store/actions/actionTypes";
import useTranslate from "shared/hooks/useTranslate";
import useFetch from "shared/hooks/useFetch";
import useDebounce from "shared/hooks/debounce";

const JOB_TYPE = ["popular", "trending", "recommended"];

const HomeComponent = ({ ENV_NAME, userId, ...props }) => {
	const toast = useToast();

	const dispatch = useDispatch();
	const t = useTranslate();
	// const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	// const trendingJob = useSelector((state) => state.auth.jobs?.trending);
	// const popularJob = useSelector((state) => state.auth.jobs?.popular);
	// const recommendedJob = useSelector((state) => state.auth.jobs?.recommended);
	const jobs = useSelector((state) => state?.auth?.jobs) ?? [];
	const MIN_COUNT = 8;
	// const MAX_COUNT = 20;
	const history = useHistory();

	const [jobsCount, setJobsCount] = useState({
		recommended: MIN_COUNT,
		popular: MIN_COUNT,
		trending: MIN_COUNT,
	});
	const [form, setForm] = useState({
		location: "",
		search: "",
	});
	const [jobApp, setJobApp] = useState(null);
	const [applyJobModal, setApplyJobModal] = useState({
		show: false,
		data: null,
	});
	const [isApplied, setIsApplied] = useState(false);
	const [isFetching, setIsFetching] = useState(true);
	const { getDatas: getLocations } = useFetch("getLocation", {
		lazy: true,
	});
	const [locations, setLocations] = useState([]);
	const [location, setLocation] = useState("");

	const onChange = (e) =>
		setForm((prevForm) => ({ ...prevForm, [e.target.name]: e.target.value }));

	const goToJobSearch = (jobType = null) => {
		props.history.push(
			`${RoutePath.JOB_SEARCH}?${form.search && `search=${form.search}`}${
				form.search && form.location && "&"
			}${form.location && `location=${form.location}`}`,
		);
	};

	useEffect(() => {
		if (isFetching) {
			fetchJobs(JOB_TYPE[0]);
			fetchJobs(JOB_TYPE[1]);
			if (cookies.load("ptkjauthtoken")) {
				fetchRecommendedJobs();
			}
			setIsFetching(false);
		}
	}, [isFetching]);

	const fetchRecommendedJobs = async () => {
		const res = await makeRequest({
			...generateRequestOptions("findJobs"),
		});

		if (res?.code === 200) {
			dispatch({ type: SET_JOBS_RECOMMENDED, payload: res.data });
		} else toast.error(t("SPECIFIED_JOB_NOT_FOUND"));
	};

	const fetchJobs = async (jobType) => {
		const res = await makeRequest(
			generateRequestOptions(jobType === JOB_TYPE[0] ? "popularJobs" : "trendingJobs"),
		);
		if (res.code === 200) {
			// TODO: Will delete this later after confirmation
			// setJobs((prev) => ({ ...prev, [jobType]: res.data }));
			if (jobType === "popular") {
				dispatch({ type: SET_JOBS_POPULAR, payload: res.data });
			} else {
				dispatch({ type: SET_JOBS_TRENDING, payload: res.data });
			}
		} else if (res.message.toLowerCase() === "Given token not valid for any token type") return;
		else toast.error(res.message);
	};

	const createJobApplication = async (job_ad, company) => {
		const res = await makeRequest({
			...generateRequestOptions("createJobApplication"),
			body: {
				job_ad,
			},
		});

		if (res.code === 200) setJobApp({ id: job_ad, company });
		else toast.error(res.message);
	};

	/**
	 * @return {boolean}
	 */
	const needUserToBeLoggedIn = () => {
		if (!userId) {
			dispatch(toggleLoginModalShow(true));
			return false;
		}
		return true;
	};

	const toggleBookmark = async (job_id, isBookmarked, jobType) => {
		if (!needUserToBeLoggedIn()) return;

		let url = {
			...generateRequestOptions("addBookmark"),
			json: true,
			body: { job_ad: job_id },
		};

		if (isBookmarked)
			url = generateRequestOptions("removeBookmark", {
				urlParams: job_id,
			});

		const res = await makeRequest(url);

		if (res.code === 200) {
			const jobsClone = { ...jobs };

			const job = jobsClone[jobType].find((job) => job.id === job_id);

			job["is_bookmarked"] = !isBookmarked;

			if (jobType === "popular") {
				dispatch({ type: SET_JOBS_POPULAR, payload: jobsClone[JOB_TYPE[0]] });
				return;
			}

			if (jobType === "recommended") {
				dispatch({ type: SET_JOBS_RECOMMENDED, payload: jobsClone[JOB_TYPE[2]] });
				return;
			}

			dispatch({ type: SET_JOBS_TRENDING, payload: jobsClone[JOB_TYPE[1]] });
		} else toast.error(res.message);
	};

	const debounceLocation = useDebounce(location, 500);

	useEffect(() => {
		if (!!debounceLocation.length) {
			getLocations({
				queryParams: { search: debounceLocation, page_size: 7 },
			}).then((res) => {
				setLocations(res.data);
			});
		}
	}, [debounceLocation]);

	const getSlideCount = (type) =>
		window.innerWidth < 576 ? (type === "recommended" ? 2 : 5) : 8;

	// Initial count of cards for each category
	useEffect(() => {
		setJobsCount({
			recommended: getSlideCount("recommended"),
			popular: getSlideCount("popular"),
			trending: getSlideCount("trending"),
		});
	}, []);

	// Number of cards when the screen size changes
	useEffect(() => {
		const handleResize = () => {
			setJobsCount({
				recommended: getSlideCount("recommended"),
				popular: getSlideCount("popular"),
				trending: getSlideCount("trending"),
			});
		};

		window.addEventListener("resize", handleResize);

		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	// const handleCardCount = (type) => {
	// 	setJobsCount((p) => ({ ...p, [type]: p[type] === MIN_COUNT ? MAX_COUNT : MIN_COUNT }));
	// };

	return (
		<div className="hc">
			<div className="hc-banner">
				<div className="banner-bg show-desktop">
					<img src={Banner} alt="Banner" className="" />
					<div className="gradient"></div>
				</div>
				<div className="hc-content">
					<div className="hc-content-wrapper">
						<h1 className="show-desktop">
							GET YOUR
							<br />
							DREAM JOB!
						</h1>
						<h2 className="show-desktop text-center">{t("BANNER_DESC")}</h2>
						<div className="hc-search">
							<Input
								dropdownType="search"
								placeholder={t("JOB_SEARCH_PLACEHOLDER_HOMEPAGE")}
								name="search"
								value={form.search}
								onChange={(e) => {
									onChange(e);
									setLocations([]);
								}}
								onClickClose={() => setForm((prev) => ({ ...prev, search: "" }))}
								autoComplete="off"
								className="job-search"
							/>
							<div className="position-relative">
								<Input
									dropdownType="location"
									placeholder={t("LOCATION_PLACEHOLDER")}
									name="location"
									value={form.location}
									onChange={async (e) => {
										onChange(e);
										if (!e.target.value) {
											setLocations([]);
											return;
										}
										setLocation(e.target.value);
									}}
									onClickClose={() =>
										setForm((prev) => ({ ...prev, location: "" }))
									}
									className="location"
								/>
								<div className="location__search position-absolute">
									{locations.length > 0 ? (
										<div className="J-ddComp-menu mt-3">
											{locations &&
												locations.map((loc, idx) => (
													<div
														className="menuDD"
														key={idx}
														onClick={() => {
															setForm((prev) => ({
																...prev,
																location: `${loc?.province}, ${loc?.city}`,
															}));
															setLocations([]);
														}}
													>
														<p>
															{loc?.province}, {loc?.city}
														</p>
													</div>
												))}
										</div>
									) : (
										""
									)}
								</div>
							</div>

							<Button
								title="Find Job"
								type="secondary"
								size="sm"
								onClick={goToJobSearch}
								className="find-job-btn"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="container py-xl">
				{Object.values(jobs).length < 1 ? (
					<div className="hc-no-content">
						<NoContentComponent
							icon={NoJobExist}
							title={t("NO_JOB_FOUND_2")}
							description={t("NO_JOB_FOUND_DESC_2")}
						/>
						<Button
							title={t("SEARCH_JOB")}
							type="secondary"
							size="sm"
							onClick={goToJobSearch}
						/>
					</div>
				) : (
					""
				)}
				{/* Recommended */}
				{jobs.recommended?.length ? (
					<div className="hc-main">
						<div className="hc-main-header">
							<h5>{t("RECOMMENDATION")}</h5>
							<p
								onClick={() => {
									history.push({
										pathname: RoutePath.RECOMMENDED_JOB,
										state: {
											key: "recommended",
											title: "Recommendation",
										},
									});
								}}
							>
								{t("VIEW_ALL")}
							</p>
						</div>

						<HorizontalScroll>
							{jobs.recommended.slice(0, jobsCount["recommended"])?.map((job) => (
								<div className="carousel-card" key={job?.id}>
									<Card
										job={job}
										toggleBookmark={() =>
											toggleBookmark(job?.id, job?.is_bookmarked, JOB_TYPE[2])
										}
										onClick={(e) => {
											e.stopPropagation();
											if (!userId) return toast.error(t("LOGIN_FIRST_ERROR"));

											// TODO: Continue Application
											if (job?.is_applied && !job?.is_submitted) {
												setApplyJobModal({
													show: true,
													data: {
														id: job?.id,
														title: job?.company?.name,
														isContinued: true,
													},
												});
												setJobApp({
													id: job?.id,
													company: job?.company?.name,
												});
												return;
											}

											createJobApplication(job?.id, job?.company?.name);
											setApplyJobModal({
												show: true,
												data: { id: job?.id, title: job?.company?.name },
											});
										}}
										isApplied={job?.is_applied}
										isSubmitted={job?.is_submitted}
										redirectToJobDetail={() =>
											props.history.push(RoutePath.JOB_DETAIL + "/" + job?.id)
										}
									/>
								</div>
							))}
						</HorizontalScroll>
					</div>
				) : (
					""
				)}

				{/* Popular Jobs */}
				{jobs?.popular?.length ? (
					<div className="hc-main">
						<div className="hc-main-header">
							<h5>Popular Jobs</h5>

							<p
								onClick={() => {
									history.push({
										pathname: RoutePath.POPULAR_JOB,
										state: {
											key: "popular",
											title: "Popular Jobs",
										},
									});
								}}
							>
								{t("VIEW_ALL")}
							</p>
						</div>
						<HorizontalScroll showNext={jobs.popular?.length > 4}>
							{jobs.popular.slice(0, jobsCount["popular"])?.map((job) => (
								<div className="carousel-card" key={job?.id}>
									<Card
										job={job}
										toggleBookmark={() =>
											toggleBookmark(job?.id, job?.is_bookmarked, JOB_TYPE[0])
										}
										onClick={(e) => {
											e.stopPropagation();
											if (!userId) return toast.error(t("LOGIN_FIRST_ERROR"));

											// TODO: Continue Application
											if (job?.is_applied && !job?.is_submitted) {
												setApplyJobModal({
													show: true,
													data: {
														id: job?.id,
														title: job?.company?.name,
														isContinued: true,
													},
												});
												setJobApp({
													id: job?.id,
													company: job?.company?.name,
												});
												return;
											}

											// TODO: Create a new application
											createJobApplication(job?.id, job?.company?.name);
											setApplyJobModal({
												show: true,
												data: { id: job?.id, title: job?.company?.name },
											});
										}}
										redirectToJobDetail={() =>
											props.history.push(RoutePath.JOB_DETAIL + "/" + job?.id)
										}
									/>
								</div>
							))}
						</HorizontalScroll>
					</div>
				) : (
					""
				)}
				{jobs.trending?.length ? (
					<div className="hc-main">
						<div className="hc-main-header">
							<h5>Trending Jobs</h5>
							<p
								onClick={() => {
									history.push({
										pathname: RoutePath.TRENDING_JOB,
										state: {
											key: "trending",
											title: "Trending Jobs",
										},
									});
								}}
							>
								{t("VIEW_ALL")}
							</p>
						</div>

						<HorizontalScroll>
							{jobs.trending.slice(0, jobsCount["trending"])?.map((job) => (
								<div className="carousel-card" key={job?.id}>
									<Card
										job={job}
										toggleBookmark={() =>
											toggleBookmark(job?.id, job?.is_bookmarked, JOB_TYPE[1])
										}
										onClick={(e) => {
											e.stopPropagation();
											if (!userId) return toast.error(t("LOGIN_FIRST_ERROR"));

											// TODO: Continue Application
											if (job?.is_applied && !job?.is_submitted) {
												setApplyJobModal({
													show: true,
													data: {
														id: job?.id,
														title: job?.company?.name,
														isContinued: true,
													},
												});
												setJobApp({
													id: job?.id,
													company: job?.company?.name,
												});
												return;
											}

											createJobApplication(job?.id, job?.company?.name);
											setApplyJobModal({
												show: true,
												data: { id: job?.id, title: job?.company?.name },
											});
										}}
										isApplied={job?.is_applied}
										isSubmitted={job?.is_submitted}
										redirectToJobDetail={() =>
											props.history.push(RoutePath.JOB_DETAIL + "/" + job?.id)
										}
									/>
								</div>
							))}
						</HorizontalScroll>
					</div>
				) : (
					""
				)}
			</div>

			{applyJobModal.show && (
				<ApplyJobModal
					ENV_NAME={ENV_NAME}
					show={applyJobModal.show}
					onHide={() => setApplyJobModal({ show: false, data: null })}
					userId={userId}
					onFetchJobs={() => setIsFetching(true)}
					jobAppId={jobApp?.id}
					jobData={applyJobModal.data}
					onApply={() => setIsApplied(true)}
				/>
			)}

			<ModalInfo
				isShow={isApplied}
				onConfirm={() => {
					setIsApplied(false);
					setJobApp(null);
					setIsFetching(true);
				}}
				type="other"
				title={`${t("APPLICATION_SENT_HEAD")} ${jobApp?.company || "-"}`}
				description={t("APPLICATION_SENT_DESC")}
				customIcon={<Image src={GreenCheck} className="modal-image-icon" />}
				confirmButtonType="primary"
				confirmLabel={t("DONE")}
				showCancelBtn={false}
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
	userId: state.auth?.userDetails?.id,
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(HomeComponent));
