import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { PiGraduationCap, PiPencilSimple, PiTrash } from "react-icons/pi";
import moment from "moment";

import useToast from "shared/hooks/useToast";
import * as Variable from "../../../shared/utils/variables";
import * as RoutePath from "../../../shared/utils/routeLink";
import makeRequest from "../../../shared/utils/request";
import { generateRequestOptions } from "../../../shared/utils/apiEndPoints";
import Image from "../../../shared/component/UI/Image";
import { getIconByName } from "../../../shared/utils/file";
import Button from "../../../shared/component/ButtonComponent/Button";
import { PlusIcon } from "../../../shared/component/svg/Icons";
import CustomPopupMenu from "../../../shared/component/CustomPopupMenuComponent/CustomPopupMenu";
import { renderLocation } from "../../../shared/utils/stringUtils";

function ReviewEducation({ ENV_NAME, onBack, onPrevStep, onNextStep, onComplete }) {
	const toast = useToast();

	const ITEMS = [
		{
			name: Variable.EDIT_LABEL[ENV_NAME],
			task: (id) => handleEdit(id),
		},
		{
			name: Variable.DELETE_LABEL[ENV_NAME],
			task: (id) => handleDelete(id),
		},
	];

	const regionNames = new Intl.DisplayNames([ENV_NAME === "bhs" ? "id" : "en"], {
		type: "region",
	});
	const history = useHistory();
	const [educations, setEducations] = useState([]);
	const [showPopup, setShowPopup] = useState(false);

	useEffect(() => {
		listEducation();
	}, []);

	const listEducation = async () => {
		const res = await makeRequest(generateRequestOptions("listEducation"));

		if (res.code === 200) setEducations(res.data);
		else toast.error(res.message);
	};

	const handleEdit = (educationId) => {
		// Move back to the prev screen
		// update the URL with the education id --> education/id
		onBack();
		history.push(RoutePath.SETUP_PROFILE + "/education/" + educationId);
	};

	const handleDelete = async (educationId) => {
		const res = await makeRequest(
			generateRequestOptions("deleteEducation", { urlParams: educationId }),
		);

		if (res.code === 200) {
			const updatedEducation = educations.filter((edu) => edu.id !== educationId);
			setEducations(updatedEducation);
			
			if (updatedEducation.length > 0) return;
			onBack();
		} else toast.error(res.message);
	};

	return (
		<>
			<div className="review-wrapper">
				{educations.map((education) => (
					<div className="edu-review" key={education?.id}>
						<PiGraduationCap size={30} className="icon icon-cap" />
						<div className="edu-content">
							<h3>{education?.institution_name}</h3>
							<p className="country">
								{renderLocation(
									education?.address,
									education?.city,
									null,
									regionNames.of(education?.country || ""),
								)}
							</p>
							<div className="otherInfo">
								{education?.institution_level && (
									<p>{education?.institution_level}</p>
								)}
								{education?.major && <p>{education?.major}</p>}
								{education?.field_of_study && <p>{education?.field_of_study}</p>}
							</div>
							<div className="years">
								{moment(education?.started_at).format("MMM, YYYY")} -{" "}
								{education?.graduated_at
									? moment(education?.graduated_at).format("MMM, YYYY")
									: Variable.PRESENT[ENV_NAME]}
							</div>
							{!["SD", "SMP", "SMA/SMU/SMK/STM"].includes(
								education?.institution_level,
							) &&
								education?.gpa !== "0.00" && (
									<div className="text-sm text-label-gray-400">
										{`${Variable.GPA[ENV_NAME]}: ${education?.gpa || "-"}`}
									</div>
								)}

							{education?.education_document_proof.length ? (
								<div className="files">
									{education?.education_document_proof?.map((doc) => (
										<div className="file" key={doc?.id}>
											<Image
												src={getIconByName(doc?.file_name)}
												width="32rem"
												height="32rem"
											/>
											<p>{doc?.file_name}</p>
										</div>
									))}
								</div>
							) : (
								""
							)}
						</div>
						<div className="edit-delete-icons">
							<CustomPopupMenu
								id={education?.id}
								items={ITEMS}
								className="d-sm-none"
							/>

							<PiPencilSimple
								size={25}
								className="icon icon-edit d-none d-md-block"
								onClick={() => handleEdit(education?.id)}
							/>

							<PiTrash
								size={25}
								className="icon icon-delete d-none d-md-block"
								onClick={() => {
									handleDelete(education?.id);
								}}
							/>
						</div>
					</div>
				))}
			</div>

			<Button
				title={
					<div className="flex-all-center gap-xxs">
						<PlusIcon width="1.8rem" height="1.8rem" stroke="#667085" strokeWidth="3" />
						{Variable.ADD[ENV_NAME] + " " + Variable.EDUCATION[ENV_NAME]}
					</div>
				}
				type="outline"
				className="mt-5"
				size="sm"
				onClick={() => {
					onBack();
					history.push(RoutePath.SETUP_PROFILE);
				}}
			/>
			<Button
				title={Variable.NEXT_STEP[ENV_NAME]}
				type="primary"
				className="mt-5"
				size="sm"
				onClick={() => {
					onNextStep();
					onComplete();
				}}
			/>
		</>
	);
}

export default ReviewEducation;
