import { useEffect, useState } from "react";
import { ProgressBar } from "react-step-progress-bar";
import { pick } from "lodash";

import useToast from "shared/hooks/useToast";
import * as Variable from "../../shared/utils/variables";
import makeRequest from "../../shared/utils/request";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import ModalForm from "../../shared/Modals/ModalFormComponent/ModalForm";
import useUserDetails from "../../shared/hooks/userDetails";

import ContactInfoForm from "./ContactInfoForm";
import ResumeForm from "./ResumeForm";
import AdditionalQuestions from "./AdditionalQuestions";
import Pitch from "./Pitch";
import CoverLetter from "./CoverLetter";
import ReviewForm from "./ReviewForm";

import "./ApplyJobModal.scss";
import "react-step-progress-bar/styles.css";

function ApplyJobModal({
	ENV_NAME,
	userId,
	show,
	jobAppId,
	jobData,
	onHide,
	onApply,
	onFetchJobs,
	...props
}) {
	const toast = useToast();

	const FORM_TYPE = {
		"contact-info": Variable.CONTACT_INFO[ENV_NAME],
		resume: Variable.RESUME[ENV_NAME],
		"additional-questions": Variable.ADDITIONAL_QUESTIONS[ENV_NAME],
		pitch: Variable.MAKE_PITCH[ENV_NAME],
		"cover-letter": Variable.COVER_LETTER[ENV_NAME],
		review: Variable.REVIEW_PROFILE[ENV_NAME],
	};
	const CURR_FORM = Object.keys(FORM_TYPE);
	const userDetails = useUserDetails(userId);
	const [jobApp, setJobApp] = useState();

	const [activeForm, setActiveForm] = useState(1);
	const [editingForm, setEditingForm] = useState(null);
	const [hasQuestions, setHasQuestions] = useState(false);

	const updateActiveForm = (form) => setActiveForm(form);

	useEffect(() => {
		if (jobData?.id) retrieveJobApplication();
	}, [jobData]);

	const retrieveJobApplication = async () => {
		const res = await makeRequest(
			generateRequestOptions("getJobApplication", {
				queryParams: { job_ad_id: jobData?.id },
			}),
		);

		if (res?.code === 200) setJobApp(res.data?.[0]?.id);
		else toast.error(res.message);
	};

	return (
		<>
			<ModalForm
				className="apply-job"
				title={`${Variable.APPLY_TO[ENV_NAME]} ${jobData?.title}`}
				closeButton
				show={show}
				onHide={() => {
					onHide();
					setActiveForm(1);
					onFetchJobs();
				}}
				{...props}
			>
				<div className="aj-form">
					<div className="progress-bar">
						<ProgressBar
							percent={(activeForm * 100) / CURR_FORM.length}
							filledBackground="#193560"
						/>
						<h4>{FORM_TYPE[CURR_FORM[activeForm - 1]]}</h4>
					</div>

					{jobApp ? (
						<div className="aj-form-container">
							{activeForm === 1 ? (
								<ContactInfoForm
									ENV_NAME={ENV_NAME}
									onHide={onHide}
									userId={userId}
									jobAppId={jobApp}
									onUpdateActiveForm={updateActiveForm}
									userDetails={pick(userDetails?.personal_info, [
										"profile_picture",
									])}
									onFetchJobs={onFetchJobs}
								/>
							) : activeForm === 2 ? (
								<ResumeForm
									ENV_NAME={ENV_NAME}
									jobAppId={jobApp}
									resume={pick(userDetails?.resume, ["resume_file", "file_name"])}
									onUpdateActiveForm={updateActiveForm}
								/>
							) : activeForm === 3 ? (
								<AdditionalQuestions
									ENV_NAME={ENV_NAME}
									jobAppId={jobApp}
									jobId={jobData?.id}
									isEditing={activeForm === editingForm}
									clearEditingForm={() => setEditingForm(null)}
									onUpdateActiveForm={updateActiveForm}
									onUpdateQuestions={setHasQuestions}
								/>
							) : activeForm === 4 ? (
								<Pitch
									ENV_NAME={ENV_NAME}
									jobAppId={jobApp}
									isContinued={jobData?.isContinued}
									hasQuestions={hasQuestions}
									onUpdateActiveForm={updateActiveForm}
								/>
							) : activeForm === 5 ? (
								<CoverLetter
									ENV_NAME={ENV_NAME}
									jobAppId={jobApp}
									onUpdateActiveForm={updateActiveForm}
								/>
							) : activeForm === 6 ? (
								<ReviewForm
									ENV_NAME={ENV_NAME}
									jobAppId={jobApp}
									userDetails={pick(userDetails?.personal_info, [
										"profile_picture",
									])}
									onApply={onApply}
									onHide={() => {
										onHide();
										setActiveForm(1);
									}}
									jumpToForm={(formId) => {
										setActiveForm(formId);
										setEditingForm(formId);
									}}
									onUpdateActiveForm={updateActiveForm}
								/>
							) : (
								""
							)}
						</div>
					) : (
						""
					)}
				</div>
			</ModalForm>
		</>
	);
}

export default ApplyJobModal;
