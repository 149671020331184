import { useState, useEffect } from "react";
import { isEmpty } from "lodash";

import useToast from "shared/hooks/useToast";
import * as Variable from "../../../shared/utils/variables";
import makeRequest from "./../../../shared/utils/request";
import { generateRequestOptions } from "../../../shared/utils/apiEndPoints";
import Input from "../../../shared/component/InputComponent/Input";
import DatePickerDropdown from "../../../shared/component/DatePickerDropdown/DatePickerDropdown";
import Button from "../../../shared/component/ButtonComponent/Button";
import useUserDetails from "../../../shared/hooks/userDetails";

function PersonalInfo({ ENV_NAME, userId, onNext }) {
	const toast = useToast();
	const userDetails = useUserDetails(userId);
	const [form, setForm] = useState({
		first_name: "",
		last_name: "",
		date_of_birth: "",
		gender: "",
	});
	const [isEdited, setIsEdited] = useState(false);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		if (!isEmpty(userDetails)) {
			const { first_name, last_name, date_of_birth, gender } = userDetails?.personal_info;

			setForm((prev) => ({
				...prev,
				first_name,
				last_name,
				date_of_birth,
				gender,
			}));
		}
	}, [userDetails]);

	const onChange = (e) => {
		const { name, value } = e.target;
		setForm((prev) => ({
			...prev,
			[name]: value,
		}));

		setIsEdited(true);
		setErrors((prev) => ({ ...prev, [name]: false }));
	};

	const handleSave = async () => {
		const { first_name, last_name, date_of_birth, gender } = form;

		const errorsData = {};
		if (!first_name) errorsData["first_name"] = true;
		if (!date_of_birth) errorsData["date_of_birth"] = true;
		if (!gender) errorsData["gender"] = true;

		const hasErrors = Object.values(errorsData).some((err) => err);
		if (hasErrors) return setErrors(errorsData);

		// TODO: if the user haven't edited the correctly filled form
		// then instead of making api call simply move to the next form
		if (!hasErrors && !isEdited) return onNext();

		const res = await makeRequest({
			...generateRequestOptions("updateProfile"),
			data: {
				first_name,
				last_name,
				date_of_birth,
				gender,
			},
		});

		if (res.code === 200) onNext();
		else toast.error(res.message);
	};

	return (
		<>
			<div className="am-form">
				<Input
					label={Variable.FIRST_NAME[ENV_NAME]}
					placeholder={Variable.INPUT[ENV_NAME] + " " + Variable.FIRST_NAME[ENV_NAME]}
					value={form?.first_name || ""}
					name="first_name"
					inputType="label"
					className="w-100"
					onChange={onChange}
					isError={errors["first_name"]}
					required
				/>
				<Input
					label={Variable.LAST_NAME[ENV_NAME]}
					placeholder={Variable.INPUT[ENV_NAME] + " " + Variable.LAST_NAME[ENV_NAME]}
					value={form?.last_name || ""}
					name="last_name"
					inputType="label"
					className="w-100"
					onChange={onChange}
				/>
				<div>
					<label className="custom-label isRequired">{Variable.DOB[ENV_NAME]}</label>
					<DatePickerDropdown
						className="am-form-row"
						dayLabel={<></>}
						monthLabel={<></>}
						yearLabel={<></>}
						name="date_of_birth"
						value={form?.date_of_birth}
						onChange={onChange}
						isError={errors["date_of_birth"]}
					/>
				</div>

				<div className="am-gender">
					<label className="isRequired">{Variable.GENDER[ENV_NAME]}</label>
					<div className="options mt-2">
						<Input
							id="male"
							choiceLabel={Variable.MALE[ENV_NAME]}
							value={form?.gender}
							name="gender"
							inputType="radio"
							onChange={() => {
								setForm((prev) => ({
									...prev,
									gender: "male",
								}));
								setErrors((prev) => ({ ...prev, gender: false }));
							}}
							isError={errors["gender"]}
							checked={form?.gender === "male"}
						/>
						<Input
							id="female"
							choiceLabel={Variable.FEMALE[ENV_NAME]}
							value={form?.gender}
							name="gender"
							inputType="radio"
							onChange={() => {
								setForm((prev) => ({
									...prev,
									gender: "female",
								}));
								setErrors((prev) => ({ ...prev, gender: false }));
							}}
							isError={errors["gender"]}
							checked={form?.gender === "female"}
						/>
					</div>
				</div>
			</div>
			<Button
				type="primary"
				size="sm"
				title={Variable.NEXT[ENV_NAME]}
				className="mt-5"
				onClick={handleSave}
				disabled={!form?.first_name || !form?.date_of_birth || !form?.gender}
			/>
		</>
	);
}

export default PersonalInfo;
