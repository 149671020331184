export const MoneyOutlineIcon = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={props.className}
			width={props.width || "32"}
			height={props.height || "32"}
			viewBox="0 0 32 32"
			style={{ minWidth: props.width || "32" }}
		>
			<path
				d="M29 8H3C2.44772 8 2 8.44772 2 9V23C2 23.5523 2.44772 24 3 24H29C29.5523 24 30 23.5523 30 23V9C30 8.44772 29.5523 8 29 8Z"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M22 8L30 15"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M22 24L30 17"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10 8L2 15"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10 24L2 17"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const BuildingOutlineIcon = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={props.className}
			width={props.width || "14"}
			height={props.height || "14"}
			viewBox="0 0 14 14"
			style={{ minWidth: props.width || "14" }}
		>
			<path
				d="M0.875 11.8125H13.125"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="0.875"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.875 11.8125V2.1875C7.875 2.07147 7.82891 1.96019 7.74686 1.87814C7.66481 1.79609 7.55353 1.75 7.4375 1.75H2.1875C2.07147 1.75 1.96019 1.79609 1.87814 1.87814C1.79609 1.96019 1.75 2.07147 1.75 2.1875V11.8125"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="0.875"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.25 11.8125V5.6875C12.25 5.57147 12.2039 5.46019 12.1219 5.37814C12.0398 5.29609 11.9285 5.25 11.8125 5.25H7.875"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="0.875"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.5 3.9375H5.25"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="0.875"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.375 7.4375H6.125"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="0.875"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.5 9.625H5.25"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="0.875"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.625 9.625H10.5"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="0.875"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.625 7.4375H10.5"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="0.875"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const BriefcaseOutlineIcon = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={props.className}
			width={props.width || "14"}
			height={props.height || "14"}
			viewBox="0 0 14 14"
			style={{ minWidth: props.width || "14" }}
		>
			<path
				d="M11.8125 3.9375H2.1875C1.94588 3.9375 1.75 4.13338 1.75 4.375V11.375C1.75 11.6166 1.94588 11.8125 2.1875 11.8125H11.8125C12.0541 11.8125 12.25 11.6166 12.25 11.375V4.375C12.25 4.13338 12.0541 3.9375 11.8125 3.9375Z"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="0.875"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.1875 3.9375V3.0625C9.1875 2.83044 9.09531 2.60788 8.93122 2.44378C8.76712 2.27969 8.54456 2.1875 8.3125 2.1875H5.6875C5.45544 2.1875 5.23288 2.27969 5.06878 2.44378C4.90469 2.60788 4.8125 2.83044 4.8125 3.0625V3.9375"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="0.875"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.25 6.90698C10.6544 7.82979 8.84326 8.31464 7 8.31245C5.1564 8.31725 3.34462 7.83222 1.75 6.90698"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="0.875"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.34375 6.5625H7.65625"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="0.875"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const IdCardIcon = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={props.className}
			width={props.width || "14"}
			height={props.height || "14"}
			viewBox="0 0 14 14"
			style={{ minWidth: props.width || "14" }}
		>
			<path
				d="M7 9.1875C7.9665 9.1875 8.75 8.404 8.75 7.4375C8.75 6.471 7.9665 5.6875 7 5.6875C6.0335 5.6875 5.25 6.471 5.25 7.4375C5.25 8.404 6.0335 9.1875 7 9.1875Z"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="0.875"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.375 10.5C4.68064 10.0925 5.07696 9.76172 5.53258 9.53391C5.9882 9.3061 6.4906 9.1875 7 9.1875C7.5094 9.1875 8.0118 9.3061 8.46742 9.53391C8.92304 9.76172 9.31936 10.0925 9.625 10.5"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="0.875"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.375 11.8125V2.1875C11.375 1.94588 11.1791 1.75 10.9375 1.75L3.0625 1.75C2.82087 1.75 2.625 1.94588 2.625 2.1875V11.8125C2.625 12.0541 2.82087 12.25 3.0625 12.25H10.9375C11.1791 12.25 11.375 12.0541 11.375 11.8125Z"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="0.875"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.25 3.5H8.75"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="0.875"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const BookmarkIcon = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={props.className}
			width={props.width || "24"}
			height={props.height || "24"}
			viewBox="0 0 24 24"
			style={{ minWidth: props.width || "24" }}
		>
			<path
				d="M18 21L12 17.25L6 21V4.5C6 4.30109 6.07902 4.11032 6.21967 3.96967C6.36032 3.82902 6.55109 3.75 6.75 3.75H17.25C17.4489 3.75 17.6397 3.82902 17.7803 3.96967C17.921 4.11032 18 4.30109 18 4.5V21Z"
				stroke={props.stroke || "black"}
				fill={props.fill || "transparent"}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const CaretDown = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={props.className}
			width={props.width || "24"}
			height={props.height || "24"}
			viewBox="0 0 24 24"
		>
			<path
				d="M20 9L12.5 16.5L5 9"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth={props.strokeWidth || "2"}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const RedCrossIcon = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={props.className}
			width={props.width || "32"}
			height={props.height || "32"}
			viewBox="0 0 32 32"
			style={{ minWidth: props.width || "32", ...(props.style || {}) }}
		>
			<path
				d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path
				d="M20 12L12 20"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M20 20L12 12"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const IconPortalSekolah = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={props.className}
			width={props.width || "96"}
			height={props.height || "96"}
			viewBox="0 0 96 96"
		>
			<g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)">
				<path
					d="M195 755 c-19 -10 -31 -12 -33 -6 -10 30 -82 1 -113 -45 -17 -26 -19 -48 -19 -252 0 -242 3 -260 56 -301 15 -12 40 -21 55 -21 l29 0 0 134 0 135 35 -15 c77 -32 167 -1 220 74 26 38 30 53 30 110 0 77 -19 118 -76 164 -46 37 -137 49 -184 23z m97 -122 c26 -23 25 -96 -2 -123 -28 -28 -84 -27 -104 2 -21 30 -20 96 1 120 20 23 80 24 105 1z"
					fill={props.fill || "black"}
				/>
				<path
					d="M607 756 c-78 -28 -112 -81 -105 -164 4 -46 10 -60 35 -82 31 -28 83 -51 160 -70 97 -24 110 -82 20 -88 -38 -3 -49 1 -64 19 -56 71 -94 92 -149 81 -24 -4 -26 -8 -21 -39 14 -81 90 -154 182 -173 96 -20 209 14 249 75 22 33 22 137 -1 171 -20 31 -84 64 -180 93 -39 11 -73 27 -76 34 -17 48 66 66 101 22 18 -23 27 -25 92 -25 l73 0 -7 33 c-8 41 -71 102 -122 116 -53 15 -141 13 -187 -3z"
					fill={props.fill || "black"}
				/>
			</g>
		</svg>
	);
};

export const CloseEyeIcon = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={props.className}
			width={props.width || "32"}
			height={props.height || "32"}
			viewBox="0 0 32 32"
		>
			<path
				d="M25.1377 15.9121L28.0002 20.8496"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M19.2754 18.6621L20.1629 23.6996"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.7127 18.6504L11.8252 23.7004"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.8498 15.9121L3.9873 20.8746"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4 13.1133C6.1 15.7133 9.95 19.0008 16 19.0008C22.05 19.0008 25.9 15.7133 28 13.1133"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
export const OpenEyeIcon = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={props.className}
			width={props.width || "32"}
			height={props.height || "32"}
			viewBox="0 0 32 32"
		>
			<path
				d="M16 7C6 7 2 16 2 16C2 16 6 25 16 25C26 25 30 16 30 16C30 16 26 7 16 7Z"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21Z"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const IconCollegeDirectory = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={props.className}
			width={props.width || "116"}
			height={props.height || "116"}
			viewBox="0 0 116 116"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 0C4.47715 0 0 4.47715 0 10V106C0 111.523 4.47715 116 10 116H106C111.523 116 116 111.523 116 106V10C116 4.47715 111.523 0 106 0H10ZM52.9026 94.6771H35.9021C30.3793 94.6771 25.9021 90.2 25.9021 84.6771V31.3229C25.9021 25.8 30.3793 21.3229 35.9021 21.3229H52.9026C73.2974 21.3229 90.0979 37.377 90.0979 58.0026C90.0979 78.623 73.1994 94.6771 52.9026 94.6771Z"
				fill="url(#paint0_linear_243_2593)"
			/>
			<path
				d="M65.7944 73.5081C66.8445 73.0479 67.8581 72.5085 68.8266 71.8945V79.2789C68.8266 79.6817 68.6669 80.0679 68.3825 80.3527C68.0982 80.6375 67.7126 80.7975 67.3105 80.7975C66.9084 80.7975 66.5228 80.6375 66.2385 80.3527C65.9542 80.0679 65.7944 79.6817 65.7944 79.2789V73.5081ZM54.5945 51.2221C54.4108 51.581 54.3734 51.9972 54.4901 52.3832C54.6067 52.7691 54.8683 53.0947 55.2199 53.2913L64.0889 58.018L67.3105 56.2906L56.6601 50.5957C56.3019 50.4117 55.8863 50.3742 55.501 50.4911C55.1157 50.6079 54.7907 50.87 54.5945 51.2221ZM79.4012 50.5957L56.6601 38.4466C56.4365 38.3346 56.19 38.2764 55.94 38.2764C55.69 38.2764 55.4435 38.3346 55.2199 38.4466L32.4788 50.5957C32.2377 50.7283 32.0366 50.9233 31.8966 51.1604C31.7565 51.3975 31.6826 51.668 31.6826 51.9435C31.6826 52.219 31.7565 52.4894 31.8966 52.7265C32.0366 52.9636 32.2377 53.1586 32.4788 53.2913L36.9891 55.6831V65.1366C36.9863 65.7884 37.1996 66.4227 37.5955 66.94C39.0927 68.9522 44.8537 75.4823 55.94 75.4823C59.3273 75.5106 62.6835 74.8318 65.7944 73.4891V58.9292L64.0889 58.018L55.94 62.3651L39.2253 53.4431L36.4206 51.9435L55.94 41.5218L75.4594 51.9435L72.6547 53.4431L67.3105 56.2906L68.0306 56.6702C68.287 56.8185 68.497 57.0354 68.6371 57.2967C68.7631 57.516 68.8285 57.765 68.8266 58.018V71.8945C70.9207 70.5749 72.7679 68.898 74.2844 66.94C74.6804 66.4227 74.8937 65.7884 74.8909 65.1366V55.6831L79.4012 53.2913C79.6423 53.1586 79.8433 52.9636 79.9834 52.7265C80.1235 52.4894 80.1974 52.219 80.1974 51.9435C80.1974 51.668 80.1235 51.3975 79.9834 51.1604C79.8433 50.9233 79.6423 50.7283 79.4012 50.5957Z"
				fill="url(#paint1_linear_243_2593)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_243_2593"
					x1="116"
					y1="4.60568e-05"
					x2="-8.00839"
					y2="9.30824"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1E63BE" />
					<stop offset="1" stopColor="#4A8DE5" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_243_2593"
					x1="80.1974"
					y1="38.2764"
					x2="28.4208"
					y2="42.7106"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1E63BE" />
					<stop offset="1" stopColor="#4A8DE5" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export const PlusIcon = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={props.className}
			width={props.width || "28"}
			height={props.height || "28"}
			viewBox="0 0 28 28"
			style={{ minWidth: props.width || "28", ...(props.style || {}) }}
		>
			<path
				d="M4.375 14H23.625"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth={props.strokeWidth || "1.75"}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14 4.375V23.625"
				stroke={props.stroke || "black"}
				fill="transparent"
				strokeWidth={props.strokeWidth || "1.75"}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const GreenCheck = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={props.className}
			width={props.width || "32"}
			height={props.height || "32"}
			viewBox="0 0 32 32"
			style={{ minWidth: props.width || "32", ...(props.style || {}) }}
		>
			<path
				d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.9934 12.5542 27.6216 9.25145 25.1851 6.81491C22.7486 4.37837 19.4458 3.00661 16 3ZM22.1875 13.725L14.8625 20.725C14.6731 20.9031 14.4225 21.0016 14.1625 21C14.0354 21.0018 13.9093 20.9785 13.7913 20.9313C13.6733 20.8841 13.5658 20.814 13.475 20.725L9.81251 17.225C9.71092 17.1364 9.62831 17.0281 9.56963 16.9067C9.51095 16.7854 9.47741 16.6534 9.47104 16.5187C9.46467 16.3841 9.48559 16.2495 9.53255 16.1231C9.57951 15.9968 9.65154 15.8812 9.7443 15.7834C9.83706 15.6856 9.94865 15.6075 10.0723 15.5539C10.196 15.5003 10.3293 15.4723 10.4641 15.4715C10.5989 15.4707 10.7325 15.4972 10.8568 15.5493C10.9811 15.6015 11.0936 15.6783 11.1875 15.775L14.1625 18.6125L20.8125 12.275C21.0069 12.1054 21.2594 12.0174 21.5172 12.0296C21.7749 12.0418 22.018 12.1532 22.1955 12.3404C22.3731 12.5277 22.4714 12.7763 22.4699 13.0343C22.4684 13.2924 22.3672 13.5398 22.1875 13.725Z"
				fill={props.fill || "black"}
			/>
		</svg>
	);
};
