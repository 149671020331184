import { FunctionComponent, ReactElement, useEffect, useState, useCallback } from "react";
import "./style.scss";
import { useHistory, useLocation, Link } from "react-router-dom";
import { JOB_DETAIL, ROOT } from "shared/utils/routeLink";
import { BsChevronLeft } from "react-icons/bs";
import { ENV, RootState } from "component/JobDetail/types";
import { useDispatch, useSelector } from "react-redux";
import {
	APPLICATION_SENT_DESC,
	APPLICATION_SENT_HEAD,
	APPLIED,
	APPLY_JOB,
	CONTINUE,
	DONE,
	LOGIN_FIRST_ERROR,
	PERIOD_UNIT,
	POSTED,
	RESOURCE_NOT_FOUND,
	SPECIFIED_JOB_NOT_FOUND,
} from "shared/utils/variables";
import { State, Job } from "./type";
import { BsBookmark, BsFillBookmarkFill } from "react-icons/bs";
import makeRequest from "shared/utils/request";
import { generateRequestOptions } from "shared/utils/apiEndPoints";
import ApplyJobModal from "component/ApplyJobComponent/ApplyJobModal";
import {
	SET_JOBS_POPULAR,
	SET_JOBS_RECOMMENDED,
	SET_JOBS_TRENDING,
} from "store/actions/actionTypes";
import ModalInfo from "shared/component/ModalComponent/ModalInfo";
import GreenCheck from "../../assets/svg/green-check.svg";
import Image from "../../shared/component/UI/Image";
import useToast from "shared/hooks/useToast";
import { toggleLoginModalShow } from "store/actions/profileActions";
import { salaryRangeDesc } from "shared/utils/salaryRangeDesc";
import buildingIcon from "assets/svg/building.svg";
import { getCurrencySymbol, getLocale } from "shared/utils/string";

const JobCategory: FunctionComponent = (): ReactElement => {
	const toast = useToast();

	const { state } = useLocation<State>();
	const ENV_NAME: ENV = useSelector((state: RootState) => state.auth.selectedEnvironment);
	const jobList: Job[] = useSelector((root_state: RootState) => root_state.auth.jobs[state?.key]);
	//   const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
	const userId = useSelector((state: RootState) => state?.auth?.userDetails?.id);
	const history = useHistory();
	const dispatch = useDispatch();
	const [jobApp, setJobApp] = useState<{ id: number; company: string } | null>(null);
	const [applyJobModal, setApplyJobModal] = useState<{
		show: boolean;
		data: null | { id: number; title: string; isContinued: boolean };
	}>({
		show: false,
		data: null,
	});
	const [isApplied, setIsApplied] = useState(false);

	const currencyFormatter = new Intl.NumberFormat("id-ID", {
		style: "currency",
		currency: "IDR",
		maximumFractionDigits: 0,
	});

	const createJobApplication = async (job_id: number, job_company_name: string) => {
		try {
			const { code, error, data } = await makeRequest({
				...generateRequestOptions("createJobApplication"),
				body: {
					job_ad: job_id,
				},
			});
			if (code === 200 && error === false) {
				setJobApp({ id: data.id, company: job_company_name });
			} else {
				toast.error(RESOURCE_NOT_FOUND[ENV_NAME]);
			}
		} catch (err) {
			toast.error(RESOURCE_NOT_FOUND[ENV_NAME]);
		}
	};

	const handleApplyJob = (job: Job) => {
		if (!userId) {
			return toast.error(LOGIN_FIRST_ERROR[ENV_NAME]);
		}
		if (job.is_applied && !job.is_submitted) {
			setApplyJobModal({
				show: true,
				data: {
					id: job?.id,
					title: job?.company?.name,
					isContinued: true,
				},
			});
			setJobApp({
				id: job?.id,
				company: job?.company?.name,
			});
		} else {
			createJobApplication(job.id, job.company.name);
			setApplyJobModal({
				show: true,
				data: { id: job.id, title: job.company.name, isContinued: false },
			});
			// setApplyJobModal({
			//     show: true,
			//     data: null
			// });
		}
	};

	const needUserToBeLoggedIn = () => {
		if (!userId) {
			dispatch(toggleLoginModalShow(true));
			return false;
		}
		return true;
	};

	const toggleBookmark = async (job_id: number, isBookmarked: boolean, jobType: string) => {
		if (!needUserToBeLoggedIn()) return;

		let url = {
			...generateRequestOptions("addBookmark"),
			json: true,
			body: { job_ad: job_id },
		};
		if (isBookmarked)
			url = generateRequestOptions("removeBookmark", {
				urlParams: job_id,
			});
		try {
			const { code, error } = await makeRequest(url);
			if (code === 200 && error === false) {
				if (jobType === "recommended") {
					fetchRecommendedJobs();
				} else {
					fetchJobs(jobType);
				}
			} else {
				toast.error(RESOURCE_NOT_FOUND[ENV_NAME]);
			}
		} catch (err) {
			toast.error(RESOURCE_NOT_FOUND[ENV_NAME]);
		}
	};

	const fetchRecommendedJobs = useCallback(async () => {
		const res = await makeRequest({
			...generateRequestOptions("findJobs"),
		});
		if (res?.code === 200) {
			dispatch({ type: SET_JOBS_RECOMMENDED, payload: res.data });
		} else toast.error(SPECIFIED_JOB_NOT_FOUND[ENV_NAME]);
	}, [dispatch]);

	const fetchJobs = useCallback(
		async (jobType: string) => {
			const res = await makeRequest(
				generateRequestOptions(jobType === "popular" ? "popularJobs" : "trendingJobs"),
			);
			if (res.code === 200) {
				if (jobType === "popular") {
					dispatch({ type: SET_JOBS_POPULAR, payload: res.data });
				} else {
					dispatch({ type: SET_JOBS_TRENDING, payload: res.data });
				}
			} else if (res.message.toLowerCase() === "Given token not valid for any token type")
				return;
			else toast.error(res.message);
		},
		[dispatch],
	);

	const onFetchJobs = useCallback(() => {
		if (state.key === "recommended") {
			fetchRecommendedJobs();
		}
		if (state.key === "popular") {
			fetchJobs("popular");
		}
		if (state.key === "trending") {
			fetchJobs("trending");
		}
	}, [state?.key, fetchRecommendedJobs, fetchJobs]);

	useEffect(() => {
		if (!state) {
			history.push(ROOT);
		}
	}, [state]);

	if (!state) return <></>;

	return (
		<>
			<div className="job_category_viewall">
				<div className="job_category_viewall_data">
					<p>
						<BsChevronLeft onClick={() => history.push(ROOT)} />
						{state.title}
					</p>
					<div className="job_category_viewall_data_content">
						{jobList.map((el) => (
							<div className="item" key={el.id}>
								<div className="img_wrap">
									<img src={el.company.company_logo ?? buildingIcon} alt="" />
								</div>
								<div className="title">
									<Link to={JOB_DETAIL + "/" + el?.id} target="_blank">
										{el.job_title}
									</Link>
									<span>{el.company.name}</span>
								</div>
								<div className="position">
									<span className="location">
										{[el.posting_location_district, el.posting_location_city]
											.filter(Boolean)
											.join(", ")}
									</span>
									{el?.display_salary && (
										<span className="salary">
											<span>
												{getCurrencySymbol(
													el?.salary_currency,
													getLocale(ENV_NAME),
												)}
											</span>{" "}
											{salaryRangeDesc(el, ENV_NAME, "short")}{" "}
											<span>
												{PERIOD_UNIT[el?.salary_rate][
													ENV_NAME
												]?.toLowerCase()}
											</span>
										</span>
									)}
								</div>
								<div className="action">
									<span>{`${POSTED[ENV_NAME]}: ${el.posting_timestamp}`}</span>
									<div className="button">
										<button
											className="apply"
											disabled={el.is_submitted}
											onClick={() => handleApplyJob(el)}
										>
											{`${
												el.is_submitted
													? APPLIED[ENV_NAME]
													: el.is_applied
													? CONTINUE[ENV_NAME]
													: APPLY_JOB[ENV_NAME]
											}`}
										</button>
										<button
											className="bookmark"
											onClick={() =>
												toggleBookmark(el.id, el.is_bookmarked, state.key)
											}
										>
											{el.is_bookmarked ? (
												<BsFillBookmarkFill
													size={16}
													color="#fc9607"
													className="left-icon"
												/>
											) : (
												<BsBookmark
													size={16}
													color="#193560"
													className="left-icon"
												/>
											)}
										</button>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			{applyJobModal.show && (
				<ApplyJobModal
					ENV_NAME={ENV_NAME}
					show={applyJobModal.show}
					onHide={() => setApplyJobModal({ show: false, data: null })}
					userId={userId}
					onFetchJobs={onFetchJobs}
					jobAppId={jobApp?.id}
					jobData={applyJobModal.data}
					onApply={() => setIsApplied(true)}
				/>
			)}
			<ModalInfo
				isShow={isApplied}
				onConfirm={() => {
					setIsApplied(false);
					setJobApp(null);
					onFetchJobs();
				}}
				type="other"
				title={`${APPLICATION_SENT_HEAD[ENV_NAME]} ${jobApp?.company || "-"}`}
				description={APPLICATION_SENT_DESC[ENV_NAME]}
				customIcon={<Image src={GreenCheck} className="modal-image-icon" />}
				confirmButtonType="primary"
				confirmLabel={DONE[ENV_NAME]}
				showCancelBtn={false}
			/>
		</>
	);
};

export default JobCategory;
