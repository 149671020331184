import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import cookies from "react-cookies";
import { Overlay } from "react-bootstrap";

import * as RoutePath from "../../shared/utils/routeLink";
import * as Variable from "../../shared/utils/variables.js";
import * as ActionTypes from "../../store/actions/actionTypes";
import { generateRequestOptions, homeUrl } from "../../shared/utils/apiEndPoints";

import "./ResetPassword.scss";
import arrowLeft from "../../assets/svg/arrow_left.svg";
import Image from "../../shared/component/UI/Image";
import Button from "../../shared/component/ButtonComponent/Button";
import Icon from "../../shared/component/IconComponent/Icon";
import ModalInfo from "../../shared/component/ModalComponent/ModalInfo";
import { CloseEyeIcon, OpenEyeIcon } from "../../shared/component/svg/Icons";
import ResetPasswordSuccess from "./ResetPasswordSuccess";
import makeRequest from "../../shared/utils/request";
import useToast from "shared/hooks/useToast";
import { useEffect } from "react";
import Countdown from "shared/component/CountdownComponent/Countdown";
import useTranslate from "shared/hooks/useTranslate";

const ResetPassword = ({ setLoginModalShow, ENV_NAME, ...props }) => {
	const toast = useToast();

	const [form, setForm] = useState({
		user_id: "",
		user_type: "",
		timestamp: "",
		signature: "",
		password: "",
		confirmPassword: "",
	});

	const [showPassword, setShowPassword] = useState({
		password: false,
		confirmPassword: false,
	});
	const [success, setSuccess] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const t = useTranslate();

	const validatePassword = (password) => {
		const hasValidLength = password.length >= 8;
		const hasNumber = /\d/.test(password);
		const hasUpperCase = /[A-Z]/.test(password);
		const hasLowerCase = /[a-z]/.test(password);

		return hasValidLength && hasNumber && hasUpperCase && hasLowerCase;
	};

	useEffect(() => {
		// Here, we are extracting the query parameters from the URL sent
		// by the backend in password reset email
		const URLParams = new URLSearchParams(props.history.location.search);
		const user_id = URLParams.get("user_id");
		const user_type = URLParams.get("user_type");
		const timestamp = URLParams.get("timestamp");
		const signature = URLParams.get("signature");

		setForm((prev) => ({
			...prev,
			user_id,
			user_type,
			timestamp,
			signature,
		}));
	}, []);

	const resetPassword = async () => {
		const { user_id, user_type, timestamp, signature, password, confirmPassword } = form;

		if (!password || !confirmPassword)
			return setErrorMessage(Variable.RESET_PASSWORD_FAILED_1[ENV_NAME]);

		if (password !== confirmPassword)
			return setErrorMessage(Variable.RESET_PASSWORD_FAILED_2[ENV_NAME]);

		try {
			const res = await makeRequest({
				...generateRequestOptions("resetPassword"),
				data: {
					user_id,
					user_type,
					timestamp,
					signature,
					password,
				},
			});

			if (res.code === 200) {
				setSuccess(true);
				resetState();
			} else toast.error(res.message);
		} catch (error) {
			toast.error(error || error.message);
		}
	};

	const goToHomepage = () => props.history.push(RoutePath.ROOT);

	const isPasswordMatch = (password, confirmPassword) => {
		if (password !== confirmPassword) {
			setErrorMessage(t("PASSWORD_DONT_MATCH_ERR_MSG"));
			return;
		}
		setErrorMessage("");
	};

	const onChange = (e) => {
		setForm((prevForm) => ({ ...prevForm, [e.target.name]: e.target.value }));
		if (e.target.name === "password") {
			const passwordValidation = validatePassword(e.target.value);
			if (!passwordValidation) {
				setErrorMessage(t("PASSWORD_VALIDATION_ERR_MSG"));
				return;
			}

			isPasswordMatch(e.target.value, form.confirmPassword);
		}

		if (e.target.name === "confirmPassword") {
			isPasswordMatch(form.password, e.target.value);
		}
	};

	const resetState = () => {
		setForm({
			user_id: "",
			user_type: "",
			timestamp: "",
			signature: "",
			password: "",
			confirmPassword: "",
		});
	};
	return (
		<>
			<div className="rpc">
				<div className="container d-flex justify-content-center">
					{success ? (
						<ResetPasswordSuccess ENV_NAME={ENV_NAME} />
					) : (
						<div className="rpc-main">
							<Icon iconType="reset" borderType="outline" />
							<h1>{Variable.SET_NEW_PASSWORD[ENV_NAME]}</h1>
							<h3>{Variable.HELP_TEXT_NP[ENV_NAME]}</h3>

							<div className="rpc-main-form">
								<div className="form-group J-inputComp mb-1p6rem w-100 lm-form">
									<label htmlFor="password" className="as-label pb-0">
										{Variable.PASSWORD[ENV_NAME]}
									</label>
									<div className="position-relative">
										<input
											id="password"
											type={showPassword.password ? "text" : "password"}
											className="J-inputComp-input"
											name="password"
											value={form.password}
											onChange={onChange}
											placeholder={`${Variable.ENTER[ENV_NAME]} ${Variable.PASSWORD[ENV_NAME]}`}
											required
										/>
										<div
											className={`pwd-eye cursorPointer ${
												!showPassword.password ? "active" : ""
											}`}
											onClick={() =>
												setShowPassword((prev) => ({
													...prev,
													password: !prev.password,
												}))
											}
										>
											{showPassword.password ? (
												<OpenEyeIcon
													width="1.6rem"
													height="1.6rem"
													stroke="#056cf2"
												/>
											) : (
												<CloseEyeIcon
													width="1.6rem"
													height="1.6rem"
													stroke="#BEC4CF"
												/>
											)}
										</div>
									</div>
								</div>
								<div className="form-group J-inputComp mb-1p6rem w-100 lm-form">
									<label htmlFor="confirm-password" className="as-label pb-0">
										{Variable.CONFIRM_PASSWORD[ENV_NAME]}
									</label>
									<div className="position-relative">
										<input
											id="confirm-password"
											type={
												showPassword.confirmPassword ? "text" : "password"
											}
											className="J-inputComp-input"
											name="confirmPassword"
											value={form.confirmPassword}
											onChange={onChange}
											placeholder={`${Variable.ENTER[ENV_NAME]} ${Variable.PASSWORD[ENV_NAME]}`}
											required
										/>
										<div
											className={`pwd-eye cursorPointer ${
												!showPassword.confirmPassword ? "active" : ""
											}`}
											onClick={() =>
												setShowPassword((prev) => ({
													...prev,
													confirmPassword: !prev.confirmPassword,
												}))
											}
										>
											{showPassword.confirmPassword ? (
												<OpenEyeIcon
													width="1.6rem"
													height="1.6rem"
													stroke="#056cf2"
												/>
											) : (
												<CloseEyeIcon
													width="1.6rem"
													height="1.6rem"
													stroke="#BEC4CF"
												/>
											)}
										</div>
									</div>
								</div>
							</div>
							{errorMessage && <div className="error-msg">{errorMessage}</div>}
							<Button
								type="primary"
								title={Variable.RESET_PASSWORD[ENV_NAME]}
								size="md"
								className="d-flex flex-column w-100"
								onClick={resetPassword}
								disabled={!!errorMessage}
							/>
							<Button
								type="textGray"
								leftIcon={arrowLeft}
								title={Variable.BACK_TO_LOGIN[ENV_NAME]}
								size="md"
								className="rpc-main-button"
								onClick={() => {
									goToHomepage();
									setLoginModalShow(true);
								}}
							/>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {
		setLoginModalShow: (payload = true) => {
			if (payload) {
				dispatch({
					type: ActionTypes.OPEN_LOGIN_MODAL,
				});
			} else {
				dispatch({
					type: ActionTypes.CLOSE_LOGIN_MODAL,
				});
			}
		},
	};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(ResetPassword));
