import { useState, useEffect } from "react";
import { isEmpty } from "lodash";

import useToast from "shared/hooks/useToast";
import * as Variable from "../../../shared/utils/variables";
import makeRequest from "../../../shared/utils/request";
import { generateRequestOptions } from "../../../shared/utils/apiEndPoints";
import Input from "../../../shared/component/InputComponent/Input";
import Button from "../../../shared/component/ButtonComponent/Button";
import useUserDetails from "../../../shared/hooks/userDetails";
import { isValueAllowed } from "shared/utils/numberUtils";

function ContactInfo({ ENV_NAME, userId, onNext }) {
	const toast = useToast();
	const userDetails = useUserDetails(userId);
	const [form, setForm] = useState({
		email: "",
		username: "",
		mobile_phone_number: "",
		mobile_phone_number_2: "",
	});
	const [errors, setErrors] = useState({});
	const [isEdited, setIsEdited] = useState(false);

	useEffect(() => {
		if (!isEmpty(userDetails)) {
			const { email, username, mobile_phone_number, mobile_phone_number_2 } =
				userDetails?.personal_info;

			setForm((prev) => ({
				...prev,
				email,
				username,
				mobile_phone_number,
				mobile_phone_number_2,
			}));
		}
	}, [userDetails]);

	const onChange = (e, type = null) => {
		const { name, value } = e.target;

		if (type === "phone") {
			setForm((prev) => ({
				...prev,
				[name]: isValueAllowed(value, type) ? value : prev[name],
			}));
		} else
			setForm((prev) => ({
				...prev,
				[name]: value,
			}));

		setIsEdited(true);
		setErrors((prev) => ({ ...prev, [name]: false }));
	};

	const handleSave = async () => {
		const { email, username, mobile_phone_number, mobile_phone_number_2 } = form;

		const errorsData = {};
		if (!email) errorsData["email"] = true;
		if (!mobile_phone_number) errorsData["mobile_phone_number"] = true;

		const hasErrors = Object.values(errorsData).some((err) => err);
		if (hasErrors) return setErrors(errorsData);

		// TODO: if the user haven't edited the correctly filled form
		// then instead of making api call simply move to the next form
		if (!hasErrors && !isEdited) return onNext();

		const res = await makeRequest({
			...generateRequestOptions("updateProfile"),
			data: {
				email,
				username,
				mobile_phone_number,
				mobile_phone_number_2,
			},
		});

		if (res.code === 200) onNext();
		else toast.error(res.message);
	};

	return (
		<>
			<div className="am-form">
				<Input
					label={Variable.EMAIL[ENV_NAME]}
					placeholder={Variable.INPUT[ENV_NAME] + " " + Variable.EMAIL[ENV_NAME]}
					value={form?.email || ""}
					name="email"
					inputType="label"
					className="w-100"
					isError={errors["email"]}
					required
					isReadOnly
					disabled={true}
				/>
				<Input
					label={Variable.USERNAME[ENV_NAME]}
					placeholder={Variable.INPUT[ENV_NAME] + " " + Variable.USERNAME[ENV_NAME]}
					value={form?.username || ""}
					name="username"
					inputType="label"
					className="w-100"
					isError={errors["username"]}
					onChange={onChange}
				/>
				<Input
					label={Variable.MOBILE_NUMBER_1[ENV_NAME]}
					placeholder={Variable.PHONE_PLACEHOLDER[ENV_NAME]}
					value={form?.mobile_phone_number || ""}
					name="mobile_phone_number"
					inputType="label"
					className="w-100"
					isError={errors["mobile_phone_number"]}
					onChange={(e) => onChange(e, "phone")}
					required
				/>
				<Input
					label={Variable.ALTERNATE_MOBILE_NUMBER[ENV_NAME]}
					placeholder={Variable.PHONE_PLACEHOLDER[ENV_NAME]}
					value={form?.mobile_phone_number_2 || ""}
					name="mobile_phone_number_2"
					inputType="label"
					className="w-100"
					onChange={(e) => onChange(e, "phone")}
				/>
			</div>
			<Button
				type="primary"
				size="sm"
				title={Variable.NEXT[ENV_NAME]}
				className="mt-5"
				onClick={handleSave}
				disabled={!form?.email || !form?.mobile_phone_number}
			/>
		</>
	);
}

export default ContactInfo;
