import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import * as RoutePath from "../../shared/utils/routeLink";

import "./DummyComponent.scss";
import bookmark from "../../assets/svg/bookmark.svg";
import Pagination from "../../shared/component/Pagination/Pagination";
import Button from "../../shared/component/ButtonComponent/Button";
import Dropdown from "../../shared/component/DropdownComponent/Dropdown";
import Icon from "../../shared/component/IconComponent/Icon";
import Input from "../../shared/component/InputComponent/Input";
import Card from "../../shared/component/CardComponent/Card";
import Toggle from "../../shared/component/ToggleComponent/Toggle";

const DummyComponent = ({ ENV_NAME, ...props }) => {
  // Temporary value
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(10);
  const [search, setSearch] = useState("");

  return (
    <div className="d-flex flex-column w-100 h-100">
      {/* Test Toggle */}
      <div className="h-70">
        <div className="py-5 px-3 mb-5 d-flex justify-content-evenly align-items-center">
          <Toggle leftText="From Left" name="test"/>
          <Toggle name="again"/>
          <Toggle rightText="From Right" name="bbb"/>
          <Toggle name="aaa"/>
        </div>
      </div>
      {/* Test Navigation Filter Find Job */}
      <div className="bDummy">
        <div className="py-5 px-3 bDummy-container">
          <Input dropdownType="search" className="bDummy-input" />
          <Input dropdownType="location" className="bDummy-input" />
          <Button
            type="secondary"
            title="Find Job"
            className="bDummy-input"
            size="md"
            onClick={() => props.history.push(RoutePath.JOB_SEARCH)}
          />
        </div>
        <div className="py-5 bDummy-container">
          <Dropdown type="date" />
          <Dropdown type="salary" />
          <Dropdown type="jobType" />
          <Dropdown type="workMode" />
          <Dropdown type="experienceLevel" />
          <Dropdown type="industry" />
          <Dropdown type="underTenApp" />
        </div>
      </div>
      {/* Test Icon Component and Pagination */}
      <div className="h-70">
        <div className="py-5 px-3 d-flex justify-content-evenly align-items-center">
          <Icon iconType="bookmark" />
          <Pagination
            selectedEnvironment={ENV_NAME}
            page={1}
            paginationSize={10}
            getRequestedPage={(inputPage) => {
              if (inputPage > 0 && inputPage <= maxPage) {
                setPage(Number(inputPage));
              }
            }}
            className="py-0"
          />
          <Card />
        </div>
        <div className="py-5 px-3 d-flex justify-content-evenly align-items-center">
          <Icon iconType="error" />
          <Icon iconType="warning" />
          <Icon iconType="success" />
          <Icon iconType="reset" />
          <Icon iconType="email" />
        </div>
        <div className="py-5 px-3 d-flex justify-content-evenly align-items-center">
          <Icon iconType="error" borderType="outline" />
          <Icon iconType="warning" borderType="outline" />
          <Icon iconType="success" borderType="outline" />
          <Icon iconType="reset" borderType="outline" />
          <Icon iconType="email" borderType="outline" />
        </div>
        <div className="py-5 px-3 d-flex justify-content-evenly align-items-center">
          <Icon iconType="error" borderType="bold" />
          <Icon iconType="warning" borderType="bold" />
          <Icon iconType="success" borderType="bold" />
          <Icon iconType="reset" borderType="bold" />
          <Icon iconType="email" borderType="bold" />
        </div>
      </div>
      {/* Test Input Field Special */}
      <div className="h-70">
        <div className="py-5 px-3 mb-5 d-flex justify-content-evenly align-items-center">
          <Input inputType="label" label="Briyan" required/>
          <Input inputType="label" isError label="Briyan" required/>
        </div>
        <div className="py-5 px-3 mb-5 d-flex justify-content-evenly align-items-center">
          <Input inputType="label" />
          <Input inputType="password" />
          <Input inputType="label" isError />
          <Input inputType="password" isError />
        </div>
        <div className="py-5 px-3 mt-5 d-flex justify-content-evenly align-items-center">
          <Input inputType="salary" />
          <Input inputType="phone" />
          <Input inputType="email" />
          <Input inputType="email" isError />
        </div>
        <div className="py-5 px-3 mt-5 d-flex justify-content-evenly align-items-center">
          <Input inputType="salary" isError />
          <Input inputType="phone" isError />
          <Input inputType="salary" disabled />
          <Input inputType="phone" disabled />
        </div>
        <div className="py-5 px-3 d-flex justify-content-evenly align-items-center">
          <Input inputType="textarea" value={search} onChange={setSearch} />
          <Input inputType="textarea" value={search} onChange={setSearch} isError />
          <Input dropdownType="company" />
        </div>
      </div>
      {/* Test Input Field with icon */}
      <div className="h-70">
        <div className="py-5 px-3 mb-5 d-flex justify-content-evenly align-items-center">
          <Input dropdownType="location" />
          <Input dropdownType="college" />
          <Input dropdownType="search" />
          <Input dropdownType="dropdown" />
        </div>
        <div className="py-5 px-3 mt-5 d-flex justify-content-evenly align-items-center">
          <Dropdown type="jobType" />
          <Dropdown type="salary" />
          <Dropdown type="date" />
          <Dropdown type="industry" />
          <Dropdown type="experienceLevel" />
          <Dropdown type="underTenApp" />
          <Dropdown type="workMode" />
        </div>
      </div>
      {/* Test Button with every condition */}
      <div className="h-70">
        <div className="py-5 d-flex justify-content-evenly align-items-center">
          <Button type="primary" />
          <Button type="primary" disabled />
          <Button type="secondary" />
          <Button type="secondary" disabled />
        </div>
        <div className="py-5 d-flex justify-content-evenly align-items-center">
          <Button type="error" />
          <Button type="error" disabled />
          <Button type="outline" />
          <Button type="outline" disabled />
        </div>
        <div className="py-5 mb-5 d-flex justify-content-evenly align-items-center">
          <Button type="textGray" />
          <Button type="textGray" disabled />
          <Button type="textLink" />
          <Button type="textLink" disabled />
        </div>
        <div className="py-5 mt-5 d-flex justify-content-evenly align-items-center">
          <Button type="primary" leftIcon={bookmark} />
          <Button type="secondary" leftIcon={bookmark} />
          <Button type="error" rightIcon={bookmark} />
          <Button type="outline" rightIcon={bookmark} />
        </div>
        <div className="py-5 d-flex justify-content-evenly align-items-center">
          <Button type="textGray" leftIcon={bookmark} />
          <Button type="textLink" rightIcon={bookmark} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(withRouter(DummyComponent));
