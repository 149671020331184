import { useLocation } from "react-router";

const useSearchParams = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	const getParam = (paramName: string) => searchParams.get(paramName);

	return {
		getParam,
		searchParams,
	};
};

export default useSearchParams;
