import { classHelper } from "../../utils/stringUtils";
import tokpedIcon from "../../../assets/svg/tokped.svg";
import publicImg from "../../../assets/svg/public_icon.svg";
import privateImg from "../../../assets/svg/private_icon.svg";

import "./CardComponent.scss";
import Image from "../UI/Image";
import Button from "../ButtonComponent/Button";
import {
	APPLIED,
	APPLY,
	APPLY_JOB,
	CONTINUE,
	PERIOD_UNIT,
	PRIVATE,
	PRIVATE_DESCRIPTION,
	PUBLIC,
	PUBLIC_DESCRIPTION,
} from "../../utils/variables";

import { BsBookmark, BsFillBookmarkFill } from "react-icons/bs";
import { getCurrencySymbol, getLocale, nFormatter } from "../../utils/string";
import { useSelector } from "react-redux";
import formatNumber from "../../utils/formatNumber";
import { salaryRangeDesc } from "shared/utils/salaryRangeDesc";
import useTranslate from "shared/hooks/useTranslate";
import { useEnvironment } from "shared/hooks/useEnvironment";

const CardComponent = ({ type = "job", onClick, job, ...props }) => {
	const t = useTranslate();
	const ENV_NAME = useEnvironment();

	return (
		<>
			{type === "job" ? (
				<div
					className={classHelper("J-cardComp", props.className, " cursorPointer")}
					onClick={() =>
						typeof props?.redirectToJobDetail === "function"
							? props?.redirectToJobDetail()
							: {}
					}
				>
					<div className="J-cardComp-icon">
						<Image
							src={job?.company?.company_logo || tokpedIcon}
							className="J-cardComp-logo"
						/>
					</div>
					<div className="J-cardComp-title">
						{job?.job_title || "-"}
						<p>{job?.company?.name || "-"}</p>
					</div>
					<div className="J-cardComp-region">
						<div>{job?.posting_location_city || "-"}</div>
						{job?.display_salary && (
							<div>
								<p className="desc">
									{`${getCurrencySymbol(
										job?.salary_currency,
										getLocale(ENV_NAME),
									)} ${salaryRangeDesc(job, ENV_NAME, "short")} ${
										job?.salary_rate
											? PERIOD_UNIT[job?.salary_rate][ENV_NAME].toLowerCase()
											: ""
									}`}
								</p>
							</div>
						)}
					</div>
					<div className="J-cardComp-postedAt">{job?.posting_timestamp || "-"}</div>
					<div className="J-cardComp-btn">
						<Button
							type="primary"
							size="md"
							title={
								job?.is_submitted
									? t("APPLIED")
									: job?.is_applied
									? t("CONTINUE")
									: t("APPLY_JOB")
							}
							className="w-100"
							btnClassName="widthCard"
							onClick={onClick}
							disabled={job?.is_submitted}
						/>

						<Button
							type="outline"
							size="sm"
							onClick={(e) => {
								e.stopPropagation();
								props.toggleBookmark(job?.id, !job?.is_bookmarked);
							}}
							customIcon={
								job?.is_bookmarked ? (
									<BsFillBookmarkFill size={16} color="#fc9607" />
								) : (
									<BsBookmark size={16} color="#193560" />
								)
							}
						/>
					</div>
				</div>
			) : type === "private" || type === "public" ? (
				<div
					className={classHelper(
						`J-pubPriv cursorPointer ${props.active ? "active" : ""}`,
						props.className,
					)}
					onClick={onClick}
				>
					<div className="ppLeft">
						<Image
							src={type === "public" ? publicImg : privateImg}
							width="32rem"
							height="32rem"
						/>
					</div>
					<div className="ppRight">
						<h1>{type === "public" ? t("PUBLIC") : t("PRIVATE")}</h1>
						<h4>
							{type === "public" ? t("PUBLIC_DESCRIPTION") : t("PRIVATE_DESCRIPTION")}
						</h4>
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	);
};

export default CardComponent;
