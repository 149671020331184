import React, { useState, useEffect } from "react";
import * as Variable from "../../../shared/utils/variables";

function Countdown({
	init = 59,
	disableTimer,
	ENV_NAME,
	textColor = "#fff",
	timerEnabled,
	localStorageName = "",
}) {
	const [counter, setCounter] = useState(init);

	useEffect(() => {
		const storedCounter = localStorage.getItem(`countdownCounter${localStorageName}`);
		const storedTimerState = localStorage.getItem(`timerState${localStorageName}`);

		if (storedCounter !== null && storedTimerState !== null) {
			const parsedCounter = Number(storedCounter);
			const parsedTimerState = JSON.parse(storedTimerState);

			if (!isNaN(parsedCounter) && parsedCounter > 0 && parsedTimerState) {
				setCounter(parsedCounter);
			} else {
				setCounter(init);
				localStorage.setItem(`countdownCounter${localStorageName}`, String(init));
				localStorage.setItem(`timerState${localStorageName}`, JSON.stringify(false));
			}
		}
	}, [init]);

	useEffect(() => {
		if (counter > 0 && timerEnabled) {
			const timer = setInterval(() => setCounter((prevCounter) => prevCounter - 1), 1000);
			return () => clearInterval(timer);
		} else if (counter > 0 && !timerEnabled) {
			localStorage.setItem(`countdownCounter${localStorageName}`, String(counter));
			localStorage.setItem(`timerState${localStorageName}`, JSON.stringify(false));
			return;
		} else if (counter === 0) {
			localStorage.removeItem(`timerState${localStorageName}`);
			localStorage.removeItem(`countdownCounter${localStorageName}`);
			disableTimer();
		}
	}, [counter, disableTimer, timerEnabled]);

	useEffect(() => {
		if (counter > 0) {
			localStorage.setItem(`countdownCounter${localStorageName}`, String(counter));
		}
	}, [counter]);

	useEffect(() => {
		localStorage.setItem(`timerState${localStorageName}`, JSON.stringify(timerEnabled));
	}, [timerEnabled]);

	return (
		<span
			style={{
				color: textColor,
				fontWeight: "600",
				fontSize: "1rem",
				letterSpacing: "0.5px",
			}}
		>
			<span className="cursorPointer text-disabled font-weight-bold">
				{Variable.RESEND_IN[ENV_NAME]} {`0:${String(counter).padStart(2, "0")}`}
			</span>
		</span>
	);
}

export default Countdown;
