import { useEffect, useRef, useState } from "react";

import useToast from "shared/hooks/useToast";
import * as Variable from "../../shared/utils/variables";
import makeRequest from "../../shared/utils/request";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import Button from "../../shared/component/ButtonComponent/Button";
import { getIconByName, isFileSizeLessOrEqual } from "../../shared/utils/file";
import Image from "../../shared/component/UI/Image";

import uploadResume from "../../assets/svg/upload_resume.svg";
import Loader from "../../assets/svg/loader.svg";

import "./ApplyJobModal.scss";

function ResumeForm({ ENV_NAME, jobAppId, resume, ...props }) {
	const toast = useToast();

	const ALLOWED_FILES = [
		"application/pdf",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		"text/plain",
		"application/msword",
	];
	const MAX_FILE_SIZE = 20000000; // 20MB
	const [form, setForm] = useState({
		file: "",
		file_name: "",
	});
	const [tempFileUrl, setTempFileUrl] = useState(null);
	const [isEdited, setIsEdited] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const uploadRef = useRef(null);

	useEffect(() => {
		jobAppId && fetchResumeDetails();
	}, [jobAppId]);

	const fetchResumeDetails = async () => {
		const res = await makeRequest(
			generateRequestOptions("fetchResumeDetails", { urlParams: jobAppId }),
		);

		if (res.code === 200) {
			setForm({
				file: res.data?.resume_file?.resume_file,
				file_name: res.data?.resume_file?.file_name,
			});
		} else toast.error(res.message);
	};

	useEffect(() => {
		if (!form?.file?.toString()?.startsWith("https")) {
			const fileUrl = form?.file ? URL.createObjectURL(form?.file) : undefined;
			setTempFileUrl(fileUrl);

			return () => {
				URL.revokeObjectURL(fileUrl);
			};
		}
	}, [form]);

	const validateAndSave = async () => {
		// if the form is not edited just skip this step
		if (!isEdited) return props.onUpdateActiveForm(3);

		const formData = new FormData();
		formData.append("resume_file", form.file);

		setIsUploading(true);
		setIsSubmitting(true);
		const res = await makeRequest({
			...generateRequestOptions("updateResumeDetails", { urlParams: jobAppId }),
			body: formData,
		});

		if (res.code === 200) {
			props.onUpdateActiveForm(3);
			resetState();
			setIsEdited(false);
		} else toast.error(res.message);
		setIsSubmitting(false);
		setIsUploading(false);
	};

	const onFileChange = async (e) => {
		const file = e.target.files[0];

		if (file) {
			if (!ALLOWED_FILES.includes(file?.type))
				return toast.error(Variable.UNSUPPORTED_FILE[ENV_NAME]);

			const isValidFile = isFileSizeLessOrEqual(file, MAX_FILE_SIZE);
			if (!isValidFile) return toast.error(Variable.FILE_SIZE_ERROR[ENV_NAME]);

			const newForm = {
				file,
				file_name: file?.name,
			};
			setForm(newForm);
			setIsEdited(true);
		}
		uploadRef.current.value = null;
	};

	const resetState = () => setForm({ file: "", file_name: "" });

	return (
		<>
			<div className="contact-form resume">
				<div className="body">
					{form.file ? (
						<div className="file-card">
							<div className="file-name">
								<Image src={getIconByName(form?.file_name)} />
								<p>{form?.file_name}</p>
							</div>
							<div className="file-card-btns">
								<Button
									type="textGray"
									title={Variable.VIEW[ENV_NAME]}
									className="btn-view"
									onClick={() => window.open(tempFileUrl || form?.file, "_blank")}
								/>
								<div>|</div>

								<input
									id="upload-files"
									type="file"
									name="subjectIcon"
									className="inputfile d-none"
									onChange={onFileChange}
									accept={ALLOWED_FILES.join(",")}
									ref={uploadRef}
								/>
								<Button
									type="textGray"
									title={Variable.CHANGE[ENV_NAME]}
									className="btn-view"
									onClick={() => uploadRef?.current?.click()}
								/>
							</div>

							{/* uploading animation */}
							{isUploading && (
								<div className="loader-wrapper">
									<div className="loader-container">
										<Image src={Loader} alt="File uploading" />
									</div>
								</div>
							)}
						</div>
					) : (
						<>
							<input
								id="upload-files"
								type="file"
								name="subjectIcon"
								className="inputfile d-none"
								onChange={onFileChange}
								accept={ALLOWED_FILES.join(",")}
								ref={uploadRef}
							/>

							<Button
								leftIcon={uploadResume}
								title={Variable.UPLOAD_RESUME[ENV_NAME]}
								type="secondary"
								size="md"
								onClick={() => uploadRef?.current?.click()}
							/>
							<p className="mt-3">{Variable.UPLOAD_RESUME_FILE_FORMAT[ENV_NAME]}</p>
						</>
					)}
				</div>
			</div>
			<div className="btn-grp">
				<div className="flex-all-center gap-md w-100">
					<Button
						type="outline"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={() => props.onUpdateActiveForm(1)}
						title={Variable.BACK[ENV_NAME]}
						disabled={isSubmitting}
					/>
					<Button
						type="primary"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={validateAndSave}
						title={Variable.NEXT[ENV_NAME]}
						disabled={isSubmitting}
					/>
				</div>
			</div>
		</>
	);
}

export default ResumeForm;
