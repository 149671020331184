import { FunctionComponent, ReactElement, useEffect, useCallback, useState, useRef } from "react";
import "./job-detail.scss";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { ENV, JobDetailType, JobData, RootState } from "./types";
import useToast from "shared/hooks/useToast";
import {
	EXPERIENCE_LEVEL_HELPER,
	JOB_TYPE_HELPER,
	PERIOD_UNIT,
	WORK_MODEL_HELPER,
} from "../../shared/utils/variables";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import LoadingComponent from "../../shared/component/LoadingComponent/LoadingComponent";
import makeRequest from "../../shared/utils/request";
import { BsBookmark, BsFillBookmarkFill } from "react-icons/bs";
import Slider from "react-slick";
import { CaretDown } from "../../shared/component/svg/Icons";
import { JOB_DETAIL, JOB_SEARCH } from "shared/utils/routeLink";
import { Form, Modal } from "react-bootstrap";
import ReportImage from "../../assets/svg/report_warning_icon.svg";
import HasReported from "../../assets/svg/has_reported.svg";
import SuccessReport from "../../assets/svg/success_report.svg";
import ModalInfo from "shared/component/ModalComponent/ModalInfo";
import Image from "../../shared/component/UI/Image";
import GreenCheck from "../../assets/svg/green-check.svg";
import ApplyJobModal from "../ApplyJobComponent/ApplyJobModal";
import { toggleLoginModalShow } from "store/actions/profileActions";
import { salaryRangeDesc } from "shared/utils/salaryRangeDesc";
import buildingIcon from "assets/svg/building.svg";
import Button from "shared/component/ButtonComponent/Button";
import useTranslate from "shared/hooks/useTranslate";
import { getCurrencySymbol, getLocale } from "shared/utils/string";

interface ArrowProps {
	onClick: () => void;
}
function PrevArrow({ onClick }: ArrowProps) {
	return (
		<button className="nav-button prev" onClick={onClick}>
			<CaretDown width="2rem" height="2rem" strokeWidth="3" stroke="#193560" />
		</button>
	);
}

function NextArrow({ onClick }: ArrowProps) {
	return (
		<button className="nav-button next" onClick={onClick}>
			<CaretDown width="2rem" height="2rem" strokeWidth="3" stroke="#193560" />
		</button>
	);
}

type JobApp = {
	id: number;
	company: string | undefined;
};
type ApplyJobModalType = {
	show: boolean;
	data: { id: number | undefined; title: string | undefined; isContinued?: boolean } | null;
};
type AnotherData = {
	urgency: string;
	jobType: string[];
	experienceLevel: string;
	workModel: string;
};

const urgencyEnum = [
	"1_to_3_days",
	"3_to_7_days",
	"1_to_2_weeks",
	"2_to_4_weeks",
	"more_than_4_weeks",
];
const jobTypeEnum = ["full_time", "part_time", "freelance", "contract", "internship", "temporary"];
const workModelEnum = ["onsite", "hybrid", "remote"];
const experienceLevelEnum = [
	"entry_level",
	"associate",
	"intermediate",
	"senior",
	"director",
	"executive",
];

const JobDetail: FunctionComponent = (): ReactElement => {
	const toast = useToast();
	const t = useTranslate();
	const { pathname } = useLocation();
	const JOB_ID = Number(pathname.split("/")[pathname.split("/").length - 1]);

	const ENV_NAME: ENV = useSelector((state: RootState) => state.auth.selectedEnvironment);

	const auth = useSelector((state: RootState) => state.auth.userDetails);
	const userId = useSelector((state: RootState) => state?.auth?.userDetails?.id);
	const [jobDetail, setJobDetail] = useState<JobDetailType | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [jobRecomm, setJobRecom] = useState<JobData[]>([]);
	const slider = useRef<any>(null);
	const history = useHistory();
	const dispatch = useDispatch();
	const [isApplied, setIsApplied] = useState(false);
	const [reportModal, setReportModal] = useState(false);
	const [reportFailedModal, setReportFailedModal] = useState(false);
	const [reportSuccessModal, setReportSuccessModal] = useState(false);
	const [radio, setRadio] = useState("");
	const [isError, setIsError] = useState(false);
	const [comment, setComment] = useState("");
	const [jobApp, setJobApp] = useState({} as JobApp);
	const [applyJobModal, setApplyJobModal] = useState<ApplyJobModalType>({
		show: false,
		data: null,
	});
	const [anotherData, setAnotherData] = useState<AnotherData>({
		urgency: "",
		jobType: [],
		experienceLevel: "",
		workModel: "",
	});

	const radioOptions = [
		{
			name: t("SPAM_OR_SCAM"),
			value: "spam_or_scam",
		},
		{
			name: t("MISSLEADING"),
			value: "misleading",
		},
		{
			name: t("DISCRIMINATION_OR_OFFENSIVE"),
			value: "discrimination_or_offensive",
		},
		{
			name: t("OTHER"),
			value: "other",
		},
	];

	const getJobDetail = useCallback(async (id: number) => {
		setIsLoading(true);
		try {
			const { code, error, data } = await makeRequest({
				...generateRequestOptions("getDetailJob", {
					urlParams: id,
				}),
			});
			if (code === 200 && error === false) {
				setJobDetail(data);
				for (const key in data) {
					if (key === "urgency") {
						if (data[key]) {
							switch (data[key]) {
								case urgencyEnum[0]:
									setAnotherData((prev) => ({
										...prev,
										urgency: t("URGENCY_1_TO_3"),
									}));
									break;
								case urgencyEnum[1]:
									setAnotherData((prev) => ({
										...prev,
										urgency: t("URGENCY_3_TO_7"),
									}));
									break;
								case urgencyEnum[2]:
									setAnotherData((prev) => ({
										...prev,
										urgency: t("URGENCY_1_TO_2"),
									}));
									break;
								case urgencyEnum[3]:
									setAnotherData((prev) => ({
										...prev,
										urgency: t("URGENCY_2_TO_4"),
									}));
									break;
								case urgencyEnum[4]:
									setAnotherData((prev) => ({
										...prev,
										urgency: t("URGENCY_MORE_4"),
									}));
									break;
								default:
									break;
							}
						} else {
							setAnotherData((prev) => ({ ...prev, urgency: "Unknown" }));
						}
					}
					if (key === "job_type") {
						if (data[key]) {
							data[key].forEach((el: string) => {
								const selectedJobTypeEnum = jobTypeEnum.find(
									(jt_enum) => jt_enum === el,
								);
								switch (selectedJobTypeEnum) {
									case jobTypeEnum[0]:
										setAnotherData((prev) => ({
											...prev,
											jobType: [...prev.jobType, t("JOB_TYPE_FT")],
										}));
										break;
									case jobTypeEnum[1]:
										setAnotherData((prev) => ({
											...prev,
											jobType: [...prev.jobType, t("JOB_TYPE_PT")],
										}));
										break;
									case jobTypeEnum[2]:
										setAnotherData((prev) => ({
											...prev,
											jobType: [...prev.jobType, t("JOB_TYPE_FREEL")],
										}));
										break;
									case jobTypeEnum[3]:
										setAnotherData((prev) => ({
											...prev,
											jobType: [...prev.jobType, t("JOB_TYPE_CONTRACT")],
										}));
										break;
									case jobTypeEnum[4]:
										setAnotherData((prev) => ({
											...prev,
											jobType: [...prev.jobType, t("JOB_TYPE_INTERNSHIP")],
										}));
										break;
									case jobTypeEnum[5]:
										setAnotherData((prev) => ({
											...prev,
											jobType: [...prev.jobType, t("JOB_TYPE_TEMPORARY")],
										}));
										break;
									default:
										break;
								}
							});
						} else {
							setAnotherData((prev) => ({ ...prev, jobType: ["Unknown"] }));
						}
					}
					if (key === "work_model") {
						if (data[key]) {
							switch (data[key]) {
								case workModelEnum[0]:
									setAnotherData((prev) => ({
										...prev,
										workModel: t("WORK_MODEL_ONSITE"),
									}));
									break;
								case workModelEnum[1]:
									setAnotherData((prev) => ({
										...prev,
										workModel: t("WORK_MODEL_HYBRID"),
									}));
									break;
								case workModelEnum[2]:
									setAnotherData((prev) => ({
										...prev,
										workModel: t("WORK_MODEL_REMOTE"),
									}));
									break;
								default:
									break;
							}
						} else {
							setAnotherData((prev) => ({ ...prev, workModel: "Unknown" }));
						}
					}
					if (key === "experience_level") {
						if (data[key]) {
							switch (data[key]) {
								case experienceLevelEnum[0]:
									setAnotherData((prev) => ({
										...prev,
										experienceLevel: t("EXPERIENCE_ENTRY"),
									}));
									break;
								case experienceLevelEnum[1]:
									setAnotherData((prev) => ({
										...prev,
										experienceLevel: t("EXPERIENCE_ASSOCIATE"),
									}));
									break;
								case experienceLevelEnum[2]:
									setAnotherData((prev) => ({
										...prev,
										experienceLevel: t("EXPERIENCE_INTERMEDIATE"),
									}));
									break;
								case experienceLevelEnum[3]:
									setAnotherData((prev) => ({
										...prev,
										experienceLevel: t("EXPERIENCE_SENIOR"),
									}));
									break;
								case experienceLevelEnum[4]:
									setAnotherData((prev) => ({
										...prev,
										experienceLevel: t("EXPERIENCE_DIRECTOR"),
									}));
									break;
								case experienceLevelEnum[5]:
									setAnotherData((prev) => ({
										...prev,
										experienceLevel: t("EXPERIENCE_EXECUTIVE"),
									}));
									break;
								default:
									break;
							}
						} else {
							setAnotherData((prev) => ({ ...prev, experienceLevel: "Unknown" }));
						}
					}
				}
			} else {
				toast.error(t("SPECIFIED_JOB_NOT_FOUND"));
			}
		} catch (err) {
			toast.error(t("SPECIFIED_JOB_NOT_FOUND"));
		}
		setIsLoading(false);
	}, []);

	const getRecommendedJob = useCallback(async () => {
		try {
			const { code, error, data } = await makeRequest({
				...generateRequestOptions("findJobs"),
			});
			if (code === 200 && error === false) {
				setJobRecom(data);
			} else {
				toast.error(t("SPECIFIED_JOB_NOT_FOUND"));
			}
		} catch (err) {
			toast.error(t("SPECIFIED_JOB_NOT_FOUND"));
		}
	}, []);

	const createJobApplication = async (
		job_ad: number | undefined,
		company: string | undefined,
	) => {
		const res = await makeRequest({
			...generateRequestOptions("createJobApplication"),
			body: {
				job_ad,
			},
		});

		if (res.code === 200) setJobApp({ id: res.data.id, company });
		else toast.error(res.message);
	};

	const handleApplyJob = (job: JobData | JobDetailType) => {
		if (!userId) return toast.error(t("LOGIN_FIRST_ERROR"));

		// TODO: Continue Application
		if (job?.is_applied && !job?.is_submitted) {
			setApplyJobModal({
				show: true,
				data: {
					id: job?.id,
					title: job?.company?.name,
					isContinued: true,
				},
			});
			setJobApp({
				id: job?.id,
				company: job?.company?.name,
			});
			return;
		}

		// TODO: Create a new job application
		createJobApplication(job?.id, job?.company?.name);
		setApplyJobModal({
			show: true,
			data: {
				id: job?.id,
				title: job?.company?.name,
			},
		});
	};
	const needUserToBeLoggedIn = (): boolean => {
		if (!userId) {
			dispatch(toggleLoginModalShow(true));
			return false;
		}
		return true;
	};

	const toggleBookmark = async (job_id: number, isBookmarked: boolean) => {
		if (!needUserToBeLoggedIn()) return;

		let url = {
			...generateRequestOptions("addBookmark"),
			json: true,
			body: { job_ad: job_id },
		};

		if (isBookmarked)
			url = generateRequestOptions("removeBookmark", {
				urlParams: job_id,
			});

		const res = await makeRequest(url);

		if (res.code === 200) {
			const jobDetailClone = { ...jobDetail } as JobDetailType;
			jobDetailClone["is_bookmarked"] = !isBookmarked;

			setJobDetail(jobDetailClone);

			const jobRecommClone = [...jobRecomm];
			const job = jobRecommClone.find((job) => job.id === job_id);
			if (job) job.is_bookmarked = !isBookmarked;
			setJobRecom(jobRecommClone);
		} else toast.error(res.message);
	};

	const handleReport = async () => {
		if (!radio || !comment) {
			setIsError(true);
		} else {
			try {
				const { code, error, message } = await makeRequest({
					...generateRequestOptions("reportJob"),
					body: {
						reason: radio,
						comment,
						job_ad: jobDetail?.id,
					},
					json: true,
				});
				if (code === 200 && error === false) {
					setReportModal(false);
					setReportSuccessModal(true);
				}
				if (code === 400 && message === "You have reported this job ad") {
					setReportModal(false);
					setReportFailedModal(true);
				}
				if (code === 400 && message !== "You have reported this job ad") {
					setReportModal(false);
					return toast.error(t("RESOURCE_NOT_FOUND"));
				}
			} catch (err) {
				return toast.error(t("RESOURCE_NOT_FOUND"));
			}
		}
	};

	const handleClickRecommendationJob = (jobId: number) => {
		history.push(`${JOB_DETAIL}/${jobId}`);
	};

	const goToJobSearch = () => {
		history.push(`${JOB_SEARCH}?search=${jobDetail?.company.name}`);
	};

	useEffect(() => {
		if (JOB_ID) {
			getJobDetail(JOB_ID);
		}
	}, [JOB_ID, getJobDetail]);

	useEffect(() => {
		getRecommendedJob();
	}, [getRecommendedJob]);

	return (
		<>
			{isLoading || !jobDetail ? (
				<LoadingComponent />
			) : (
				<div className="job_detail_page">
					<div className="job_detail_page_content">
						<div className="top">
							<div className="img_wrap">
								<img
									src={jobDetail.company.company_logo ?? buildingIcon}
									alt="Building Icon"
								/>
							</div>
							<div className="job">
								<h2>{jobDetail.job_title}</h2>
								<div className="company">
									<span className="name">{jobDetail.company.name}</span>
									<span className="location">
										{[
											jobDetail.posting_location_district,
											jobDetail.posting_location_city,
										]
											.filter(Boolean)
											.join(", ")}
									</span>
									{jobDetail?.display_salary && (
										<p className="salary">
											{`${getCurrencySymbol(
												jobDetail?.salary_currency,
												getLocale(ENV_NAME),
											)} ${salaryRangeDesc(jobDetail, ENV_NAME, "short")} ${
												jobDetail?.salary_rate
													? PERIOD_UNIT[jobDetail?.salary_rate][
															ENV_NAME
													  ].toLowerCase()
													: ""
											}`}
										</p>
									)}
								</div>
								<div className="desc">
									{`
										${t("POSTED")}: ${jobDetail.posting_timestamp} |
										${t("URGENCYY")}: ${anotherData.urgency} |
										${jobDetail.applicants} ${t("APPLICANT")}
									`}
								</div>
							</div>
							<div className="actions">
								<Button
									type="primary"
									title={`${
										jobDetail.is_submitted
											? t("APPLIED")
											: jobDetail.is_applied
											? t("CONTINUE")
											: t("APPLY_NOW")
									}`}
									size="sm"
									disabled={jobDetail.is_submitted}
									onClick={() => handleApplyJob(jobDetail)}
								></Button>
								{/* <button className="comparet">{("ADD_TO_COMPARE")}</button> */}
								<Button
									type="outline"
									size="sm"
									className="save"
									onClick={() =>
										toggleBookmark(jobDetail?.id, jobDetail?.is_bookmarked)
									}
								>
									{jobDetail.is_bookmarked ? (
										<p>
											<BsFillBookmarkFill
												size={16}
												color="#fc9607"
												className="left-icon"
											/>
											{t("SAVED")}
										</p>
									) : (
										<p>
											<BsBookmark
												size={16}
												color="#193560"
												className="left-icon"
											/>
											{t("SAVE_JOB")}
										</p>
									)}
								</Button>
							</div>
						</div>
						<div className="bottom">
							<h3>{t("FULL_JOB_DESCRIPTION")}</h3>
							<h4>{t("QUALIFICATIONS")}</h4>
							<div
								className="ul_wrapper"
								dangerouslySetInnerHTML={{ __html: jobDetail.job_description }}
							/>
							<h4>{t("RESPONSIBILITIES")}</h4>
							<div
								className="ul_wrapper"
								dangerouslySetInnerHTML={{ __html: jobDetail.job_requirements }}
							/>
							<h4>{t("JOB_BENEFIT")}</h4>
							<div
								className="ul_wrapper"
								dangerouslySetInnerHTML={{ __html: jobDetail.job_benefits }}
							/>
							<hr />
							<h3>{t("JOB_DETAILS")}</h3>
							<div className="detail_wrapper">
								<div>
									<h4>{t("JOB_TYPE_LABEL")}</h4>

									<span>
										{jobDetail?.job_type
											?.filter((v) => !!JOB_TYPE_HELPER(v))
											.map((v) => JOB_TYPE_HELPER(v)[ENV_NAME])
											.join(", ")}
									</span>
								</div>
								<div>
									<h4>{t("EXPERIENCE_LEVEL_LABEL")}</h4>
									<span>
										{EXPERIENCE_LEVEL_HELPER(jobDetail?.experience_level)[
											ENV_NAME
										] || "-"}
									</span>
								</div>
								<div>
									<h4>{t("WORK_MODE_LABEL")}</h4>
									<span>
										{WORK_MODEL_HELPER(jobDetail?.work_model)[ENV_NAME] || "-"}
									</span>
								</div>
							</div>
							<hr />
							<div className="btn_wrapper">
								<button className="more" onClick={goToJobSearch}>
									{t("MORE_JOB_SEARCH")}
								</button>
								{auth && (
									<button
										className="report"
										onClick={() => {
											setReportModal(true);
											setRadio("");
											setComment("");
											setIsError(false);
										}}
									>
										{t("REPORT")}
									</button>
								)}
							</div>
						</div>
					</div>
					{!!auth && (
						<>
							<h2>{t("RECOMMENDATION")}</h2>
							<div className="job_detail_page_recommendation">
								<div className="carousel-cont mb-lg">
									<Slider
										ref={slider}
										dots={false}
										infinite={true}
										speed={250}
										slidesToShow={4}
										slidesToScroll={1}
										prevArrow={
											<PrevArrow
												onClick={() => slider?.current?.slickPrev()}
											/>
										}
										nextArrow={
											<NextArrow
												onClick={() => slider?.current?.slickNext()}
											/>
										}
										className="recomm-carousel"
										responsive={[
											{
												breakpoint: 1200,
												settings: {
													slidesToShow: 3,
												},
											},
											{
												breakpoint: 900,
												settings: {
													slidesToShow: 2,
												},
											},
											{
												breakpoint: 600,
												settings: {
													slidesToShow: 1,
												},
											},
										]}
									>
										{(jobRecomm || [])?.map((job) => (
											<div
												className="job_card_custom cursorPointer"
												key={job.id}
												onClick={() => handleClickRecommendationJob(job.id)}
											>
												<div className="img_wrapper">
													<img
														src={
															job.company.company_logo ?? buildingIcon
														}
														alt=""
													/>
												</div>
												<div>
													<p className="title">{job.job_title}</p>
													<span className="company_name">
														{job.company.name}
													</span>
												</div>
												<div>
													<span className="desc">{`${job.posting_location_district}, ${job.posting_location_city}`}</span>
													{jobDetail?.display_salary && (
														<p className="desc">
															{`${getCurrencySymbol(
																jobDetail?.salary_currency,
																getLocale(ENV_NAME),
															)} ${salaryRangeDesc(
																jobDetail,
																ENV_NAME,
																"short",
															)} ${PERIOD_UNIT[
																jobDetail?.salary_rate
															][ENV_NAME].toLowerCase()}`}
														</p>
													)}
												</div>
												<span>
													{t("POSTED")} {job.posting_timestamp}
												</span>
												<div>
													<button
														disabled={job.is_submitted}
														onClick={(e) => {
															e.stopPropagation();
															handleApplyJob(job);
														}}
													>
														{`${
															job.is_submitted
																? t("APPLIED")
																: job.is_applied
																? t("CONTINUE")
																: t("APPLY_JOB")
														}`}
													</button>
													<button
														onClick={(e) => {
															e.stopPropagation();
															toggleBookmark(
																job?.id,
																job?.is_bookmarked,
															);
														}}
													>
														{job.is_bookmarked ? (
															<BsFillBookmarkFill
																size={16}
																color="#fc9607"
															/>
														) : (
															<BsBookmark size={16} color="#193560" />
														)}
													</button>
												</div>
											</div>
										))}
									</Slider>
								</div>
							</div>
						</>
					)}
				</div>
			)}
			<ApplyJobModal
				ENV_NAME={ENV_NAME}
				show={applyJobModal.show}
				onHide={() => setApplyJobModal({ show: false, data: null })}
				userId={userId}
				onFetchJobs={() => {
					if (applyJobModal?.data?.id) getJobDetail(applyJobModal?.data?.id);
					getRecommendedJob();
				}}
				jobAppId={jobApp?.id}
				jobData={applyJobModal.data}
				onApply={() => setIsApplied(true)}
			/>
			<ModalInfo
				isShow={isApplied}
				onConfirm={() => {
					getJobDetail(JOB_ID);
					setIsApplied(false);
					setJobApp({} as JobApp);
				}}
				type="other"
				title={`${t("APPLICATION_SENT_HEAD")} ${jobApp?.company || "-"}`}
				description={t("APPLICATION_SENT_DESC")}
				customIcon={<Image src={GreenCheck} className="modal-image-icon" />}
				confirmButtonType="primary"
				confirmLabel={t("DONE")}
				showCancelBtn={false}
			/>
			<Modal
				show={reportModal}
				dialogClassName="report_modal"
				aria-labelledby="example-custom-modal-styling-title"
				centered
			>
				<Modal.Body>
					<div className="top">
						<img src={ReportImage} alt="Report" />
						<p>{t("REPORT_THIS_JOB")}</p>
					</div>
					<div className="reason">
						{!isError && <span className="labelt">{"REASON_LABEL"}</span>}
						{isError && radio && <span className="labelt">{"REASON_LABEL"}</span>}
						{isError && !radio && (
							<span
								className="label"
								style={{ color: "#1D2939", fontWeight: "600", fontSize: "16px" }}
							>
								t{"REASON_LABEL"}
							</span>
						)}
						{isError && !radio && (
							<span style={{ display: "block" }}>{t("SELECT_REASON")}</span>
						)}
						<div className="reason_option">
							{radioOptions.map((el) => (
								<Form.Check
									label={el.name}
									name={el.value}
									key={el.value}
									type={"radio"}
									value={el.value}
									onChange={() => {
										setRadio(el.value);
										setIsError(false);
									}}
									id={`inline-${el.value}-1`}
									checked={el.value === radio}
								/>
							))}
						</div>
					</div>
					<div className="comment">
						<span>{t("ADDITIONAL_COMMENTS")}</span>
						<Form.Control
							as="textarea"
							rows={5}
							placeholder={t("ENTER_DESCRIPTION")}
							value={comment}
							onChange={(e) => setComment(e.target.value)}
						/>
						<div>
							{isError && !comment ? <span>{t("ERROR_MESSAGE")}</span> : <span />}
							<span>{`${comment.length}/5000`}</span>
						</div>
					</div>
					<div className="action">
						<button onClick={() => setReportModal(false)}>{t("CLOSE")}</button>
						<button onClick={handleReport} className="submit">
							t{"SUBMIT_LABEL"}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			<Modal show={reportFailedModal} dialogClassName="report_result_modal" centered>
				<Modal.Body>
					<img src={HasReported} alt="Reported" />
					<p>{t("INFORMATION")}</p>
					<span>{t("HAS_REPORTED")}</span>
					<button onClick={() => setReportFailedModal(false)}>{t("DONE")}</button>
				</Modal.Body>
			</Modal>
			<Modal
				show={reportSuccessModal}
				dialogClassName="report_result_modal"
				onHide={() => setReportSuccessModal(false)}
				centered
			>
				<Modal.Body>
					<img src={SuccessReport} alt="Reported" />
					<p>{t("SUCCESS_REPORT_TITLE")}</p>
					<span>{t("SUCCESS_REPORT_MESSAGE")}</span>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default JobDetail;

// let dataJobType: string[] = []
// data[key].forEach((el: string) => {
//     jobTypeEnum.forEach((jobT: string, _idx: number, arr: string[]) => {
//         if (jobT === el && el === arr[0]) {
//             // setAnotherData(prev => ({ ...prev, jobType: [...prev.jobType, t("el")] }))
//             dataJobType = [...dataJobType, t("JOB_TYPE_FT")]
//         }
//         if (jobT === el && el === arr[1]) {
//             dataJobType = [...dataJobType, t("JOB_TYPE_FT")]
//         }
//     })
// })
// const newData = data[key].map((el: string) => {
//     jobTypeEnum.forEach((jobT: string) => {
//         if (jobT === el) {
//             return t("el")
//         }
//         return t("el")
//     })
//     return t("el")
// })
// setAnotherData(prev => ({ ...prev, jobType: newData }))

// //this is api response
// job_type: ['part_time', 'full_time']

// //we need to transform the job_type data like this:
// job_type: ['Part Time', 'Full Time']   // in english
// job_type: ['Paruh Waktu', 'Penuh Waktu']   // in bahasa

// let job_type: string[] = []
// let experience_level = ''
// for (const key in data) {
//     if (key === 'job_type') {
//         job_type = data[key].map((el: string) => {
//             if (el === "full_time") return 'Full Time'
//             if (el === "part_time") return 'Part Time'
//             if (el === "contract") return 'Contract'
//             else return 'Unknown'
//         })
//     }
//     if (key === 'experience_level') {
//         if (data[key] === 'entry_level') return experience_level = 'Entry Level'
//         if (data[key] === 'associate') return experience_level = 'Associate'
//         else experience_level = 'Unknown'
//     }
// }
// setJobDetail({ ...data, job_type, experience_level })
