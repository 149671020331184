import { useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { PiArrowLeft } from "react-icons/pi";

import * as Variable from "../../../shared/utils/variables";

import PersonalInfo from "./PersonalInfo";
import Location from "./Location";
import ContactInfo from "./ContactInfo";
import ProfilePicture from "./ProfilePicture";

function About({ ENV_NAME, userId, onNextStep, onComplete }) {
	const FORMS = [
		Variable.PERSONAL_INFORMATION[ENV_NAME],
		Variable.CONTACT_INFO[ENV_NAME],
		Variable.LOCATION[ENV_NAME],
		Variable.PROFILE_PICTURE[ENV_NAME],
	];
	const [currForm, setCurrForm] = useState(1);

	const handleBack = () => currForm > 1 && setCurrForm((prev) => prev - 1);

	const handleNext = () => currForm < FORMS.length && setCurrForm((prev) => prev + 1);

	return (
		<div className="about-me">
			<div className="header">
				{currForm !== 1 && (
					<PiArrowLeft
						color="#667085"
						className="cursorPointer icon-back"
						onClick={handleBack}
					/>
				)}
				<ProgressBar
					completed={(currForm * 100) / FORMS.length}
					height="10px"
					bgColor="#193560"
					baseBgColor="#f2f4f7"
					isLabelVisible={false}
				/>

				<h2>{FORMS[currForm - 1]}</h2>
			</div>
			<section>
				{currForm === 1 && (
					<PersonalInfo ENV_NAME={ENV_NAME} userId={userId} onNext={handleNext} />
				)}
				{currForm === 2 && (
					<ContactInfo ENV_NAME={ENV_NAME} userId={userId} onNext={handleNext} />
				)}
				{currForm === 3 && (
					<Location ENV_NAME={ENV_NAME} userId={userId} onNext={handleNext} />
				)}
				{currForm === 4 && (
					<ProfilePicture
						ENV_NAME={ENV_NAME}
						userId={userId}
						onNext={handleNext}
						onNextStep={onNextStep}
						onComplete={onComplete}
					/>
				)}
			</section>
		</div>
	);
}

export default About;
