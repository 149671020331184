import React, { useRef, useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory, withRouter } from "react-router";
import cookies from "react-cookies";
import { Anchor, Overlay } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PiUserSwitchFill } from "react-icons/pi";

import * as RoutePath from "../../shared/utils/routeLink";
import * as Variable from "../../shared/utils/variables.js";
import * as ActionTypes from "../../store/actions/actionTypes";
import { generateRequestOptions, homeUrl } from "../../shared/utils/apiEndPoints";
import ErrorIcon from "assets/icons/alert/alert-error.png";

import "./HeaderComponent.scss";
import Image from "../../shared/component/UI/Image";
import Button from "../../shared/component/ButtonComponent/Button";
import homepageIcon from "../../assets/svg/homepage.svg";
import IdIcon from "../../assets/icons/indo.png";
import EnIcon from "../../assets/icons/eng.png";
import { ReactComponent as HAccHelp } from "../../assets/svg/h-acc-Help.svg";
import { ReactComponent as HAccJob } from "../../assets/svg/h-acc-job.svg";
import { ReactComponent as HAccSetting } from "../../assets/svg/h-acc-setting.svg";
import { ReactComponent as HAccSignOut } from "../../assets/svg/h-acc-SignOut.svg";
import { ReactComponent as HAccUser } from "../../assets/svg/h-acc-User.svg";
import { ReactComponent as HAccUserBlank } from "../../assets/svg/h-acc-notification-user.svg";
import hamburgerIcon from "../../assets/svg/hamburger_icon.svg";
import { CaretDown } from "../../shared/component/svg/Icons";
import makeRequest from "../../shared/utils/request";
import CompanyLogo from "assets/image/company-logo.png";
import HeaderMobileComponent from "./HeaderMobileComponent";
import useUserDetails from "shared/hooks/userDetails";
import { useEnvironment } from "shared/hooks/useEnvironment";
import useToast from "shared/hooks/useToast";
import DropdownModule from "./DropdownModule/DropdownModule";
import useAuth from "shared/hooks/useAuth";
import AnnouncementBell from "./AnnouncementBell/AnnouncementBell";
import { configCookies, setCookies } from "shared/utils/cookies";
import ModalInfo from "shared/component/ModalComponent/ModalInfo";
import { USER_TYPES } from "shared/constants/constants";
import useTranslate from "shared/hooks/useTranslate";

export const MENU_ENUM = {
	JOBS: "jobs",
	COMPANIES: "companies",
};

const ProfilePicture = ({ userId }) => {
	const userDetails = useUserDetails(userId);

	if (!userDetails?.personal_info?.profile_picture) return <HAccUserBlank />;
	return (
		<Image
			src={userDetails.personal_info.profile_picture}
			style={{
				width: "2.8rem",
				height: "2.8rem",
				borderRadius: "50rem",
				objectFit: "cover",
			}}
		/>
	);
};

const HeaderComponent = ({ isAuthenticated, setLoginModalShow, active, userDetails, ...props }) => {
	const [showLangDropdown, setShowLangDropdown] = useState(false);
	const [showMobileMenu, setShowMobileMenu] = useState(false);
	const [profileDropdown, setProfileDropdown] = useState(false);
	const [modals, setShowModals] = useState({
		permission_role: false,
	});
	const [modalHRMSRestriction, setModalHRMSRestriction] = useState(false);
	const history = useHistory();
	const languageRef = useRef(null);
	const profileRef = useRef(null);
	const dispatch = useDispatch();
	const ENV_NAME = useEnvironment();
	const toast = useToast();
	const t = useTranslate();

	// Function to show and hide dropdown
	const openDropdown = (open = true, type = "language") => {
		if (type === "language") {
			setShowLangDropdown(open);
		} else if (type === "profile") {
			setProfileDropdown(open);
		}
	};

	const goToHomepage = () => {
		props.history.push(RoutePath.ROOT);
	};

	const goToMegaMenu = () => {
		// props.history.push(RoutePath.ALL_COMPONENT);
	};

	const goToSignUpComponent = () => {
		props.history.push(RoutePath.SELECT_ROLE_REGISTRATION);
	};

	const handleSwitchRole = () => {
		if (userDetails.user_type === USER_TYPES.JOB_SEEKER) {
			setShowModals({
				permission_role: true,
			});
			return;
		}
		window.open(`https://employer.${homeUrl}/job-ads-list`, "_self");

		setCookies("moduleSelector", "JobPoster");
	};

	// Function to change the language
	const changeLang = async (lang) => {
		if (lang !== ENV_NAME) {
			let val = lang;
			await cookies.save("lang", val, {
				domain: homeUrl,
				path: "/",
				expires: new Date("9999-12-31T12:00:00.000Z"),
			});
			await props.changeLanguage(val);
			window.location.reload();
		}
	};
	const handleLogout = async () => {
		await logout().then(async (status) => {
			if (status) {
				await fetchJobs("popular");
				await fetchJobs("trending");
				history.push("/");
			} else {
				toast.error("Something went wrong");
			}
		});
	};

	const logout = async () => {
		if (cookies.load("ptkjrefreshtoken")) {
			let res = await makeRequest({
				...generateRequestOptions("logout"),
				headers: {
					Accept: "application/json",
					"content-type": "application/json",
				},
				body: {
					refresh_token: cookies.load("ptkjrefreshtoken"),
				},
				json: true,
			});
			if (res?.code === 200) {
				toast.success(Variable.LOGGED_OUT[ENV_NAME]);

				cookies.remove("ptkjauthtoken", { path: "/", domain: homeUrl });
				cookies.remove("ptkjrefreshtoken", { path: "/", domain: homeUrl });
				cookies.remove("moduleSelector", { path: "/", domain: homeUrl });

				dispatch({
					type: ActionTypes.LOGOUT_SUCCEED,
				});
				return true;
			}
			return false;
		}

		cookies.remove("ptkjauthtoken", { path: "/", domain: homeUrl });
		cookies.remove("ptkjrefreshtoken", { path: "/", domain: homeUrl });
		cookies.remove("moduleSelector", { path: "/", domain: homeUrl });

		dispatch({
			type: ActionTypes.LOGOUT_SUCCEED,
		});
		return false;
	};

	const fetchJobs = async (jobType) => {
		const res = await makeRequest(
			generateRequestOptions(jobType === "popular" ? "popularJobs" : "trendingJobs"),
		);
		if (res.code === 200) {
			if (jobType === "popular") {
				props.dispatch_popularJobs(res.data);
			} else {
				props.dispatch_trendingJobs(res.data);
			}
		} else toast.error(res.message);
	};

	const changeCurr = async (curr) => {
		if (curr !== props.currency) {
			let val = curr;
			cookies.save("curr", val, {
				domain: homeUrl,
				path: "/",
				expires: new Date("9999-12-31T12:00:00.000Z"),
			});
			await props.changeCurrency(val);
			window.location.reload();
		}
	};

	const handleSwitchModule = () => {
		if (!userDetails.hrms_type) {
			setModalHRMSRestriction(true);
			return;
		}
		cookies.save("moduleSelector", "HRMS", configCookies);

		window.open(`https://hrms.${homeUrl}`, "_self");
	};

	useEffect(() => {
		if (!isAuthenticated && profileDropdown) {
			openDropdown(false, "profile");
		}
	}, [isAuthenticated, profileDropdown]);

	return (
		<>
			<nav className="header-nav">
				<div className="container hn">
					<div className="hn-left">
						<Link to={RoutePath.ROOT}>
							<Image
								src={homepageIcon}
								onClick={() => goToHomepage()}
								className="cursorPointer hn-left-img"
							/>
						</Link>
						<div className="hn-menu-cont show-desktop hidden">
							<Link
								to={RoutePath.JOB_SEARCH}
								className={`hn-menu-item ${
									active === MENU_ENUM.JOBS ? "active" : ""
								}`}
							>
								<h5>{Variable.JOBS_LABEL[ENV_NAME]}</h5>
								<div className="bottom-accent" />
							</Link>
							{/* <div
								className={`hn-menu-item ${
									active === MENU_ENUM.COMPANIES ? "active" : ""
								}`}
							>
								<h5>{Variable.COMPANY[ENV_NAME]}</h5>
								<div className="bottom-accent" />
							</div> */}
						</div>
					</div>
					{showMobileMenu && (
						<div className="show-tablet show-mobile hidden">
							<HeaderMobileComponent
								isAuthenticated={isAuthenticated}
								ENV_NAME={ENV_NAME}
								visible={showMobileMenu}
								onClose={() => setShowMobileMenu(false)}
								onLoginClick={() => setLoginModalShow(true)}
								onSignUpClick={goToSignUpComponent}
								changeLang={changeLang}
								userDetails={userDetails}
								changeCurr={changeCurr}
								currency={props.currency}
								handleLogout={handleLogout}
								active={active}
							/>
						</div>
					)}
					<div className="hn-right">
						<div
							className="show-tablet show-mobile hidden cursorPointer"
							onClick={() => setShowMobileMenu(true)}
						>
							<Image
								className="cursorPointer"
								onClick={() => goToMegaMenu()}
								src={hamburgerIcon}
							/>
						</div>
						<div
							className={`item cursorPointer show-desktop hidden positionRelative ${
								showLangDropdown ? "active" : ""
							}`}
							onMouseEnter={() => openDropdown(true, "language")}
							onMouseLeave={() => openDropdown(false, "language")}
							onClick={() => openDropdown(true, "language")}
							ref={languageRef}
						>
							<div className="flex-all-center">
								<Image
									className="hn-language"
									src={ENV_NAME === "eng" ? EnIcon : IdIcon}
								/>
								<CaretDown
									className="ms-xs"
									stroke="#193560"
									width="2rem"
									height="2rem"
								/>
							</div>
							<Overlay
								target={languageRef.current}
								show={showLangDropdown}
								placement="bottom-end"
							>
								{({
									placement,
									arrowProps,
									show: _show,
									popper,
									...OverlayProps
								}) => (
									<div
										{...OverlayProps}
										id="hn-dropdown-lang"
										className="hn-dropdown hn-lang"
									>
										<button
											className={`hn-dropdown-item ${
												ENV_NAME === "bhs" ? "active" : ""
											}`}
											onClick={() => changeLang("bhs")}
										>
											<Image
												className="hn-dropdown-item-icon lang-logo"
												src={IdIcon}
												alt="logo"
											/>
											<div className="hn-dropdown-item-label">
												Bahasa Indonesia
											</div>
										</button>
										<button
											className={`hn-dropdown-item ${
												ENV_NAME === "eng" ? "active" : ""
											}`}
											onClick={() => changeLang("eng")}
										>
											<Image
												className="hn-dropdown-item-icon lang-logo"
												src={EnIcon}
												alt="logo"
											/>
											<div className="hn-dropdown-item-label">English</div>
										</button>
									</div>
								)}
							</Overlay>
						</div>
						{isAuthenticated ? (
							<div
								className={`item cursorPointer show-desktop hidden flex-all-center ${
									profileDropdown ? "active" : ""
								}`}
								onMouseEnter={() => openDropdown(true, "profile")}
								onMouseLeave={() => openDropdown(false, "profile")}
								onClick={() => openDropdown(true, "profile")}
								ref={profileRef}
							>
								<div>
									{userDetails?.id ? (
										<ProfilePicture userId={userDetails?.id} />
									) : (
										<HAccUserBlank />
									)}
								</div>
								<div className="flex-all-center">
									<h5 className="mb-0 hn-profile-label">
										{`${userDetails?.first_name || ""} ${
											userDetails?.last_name || ""
										}`}
									</h5>
									<CaretDown stroke="#193560" width="2rem" height="2rem" />
								</div>
								<Overlay
									target={profileRef.current}
									show={profileDropdown}
									placement="bottom-end"
								>
									{({
										placement,
										arrowProps,
										show: _show,
										popper,
										...OverlayProps
									}) => (
										<div
											{...OverlayProps}
											id="hn-dropdown-profile"
											className="hn-dropdown"
										>
											<Anchor
												href={"https://myjob." + homeUrl + "/profil"}
												className="hn-dropdown-item"
											>
												<HAccUser />
												<div className="hn-dropdown-item-label mxw-auto">
													{Variable.VIEW_PROFILE[ENV_NAME]}
												</div>
											</Anchor>
											<Anchor
												href={"https://myjob." + homeUrl + "/my-jobs"}
												className="hn-dropdown-item"
											>
												<HAccJob />
												<div className="hn-dropdown-item-label mxw-auto">
													{Variable.MY_JOBS[ENV_NAME]}
												</div>
											</Anchor>
											<Anchor
												href={"https://myjob." + homeUrl + "/settings"}
												className="hn-dropdown-item"
											>
												<HAccSetting />
												<div className="hn-dropdown-item-label mxw-auto">
													{Variable.ACCOUNT_SETTINGS[ENV_NAME]}
												</div>
											</Anchor>
											<button
												className="hn-dropdown-item"
												onClick={handleSwitchRole}
											>
												<Image
													src={CompanyLogo}
													style={{
														width: 16,
														height: 16,
													}}
												/>
												<div className="hn-dropdown-item-label mxw-auto">
													{Variable.SWITCH_TO_JOB_POSTER[ENV_NAME]}
												</div>
											</button>
											<button
												className="hn-dropdown-item"
												onClick={handleSwitchModule}
												style={{
													display: "flex",
													alignItems: "center",
												}}
											>
												<div
													style={{
														width: 16,
														height: 16,
														display: "flex",
														alignItems: "center",
													}}
												>
													<PiUserSwitchFill
														color="#667085"
														width={16}
														height={16}
													/>
												</div>
												<div className="hn-dropdown-item-label mxw-auto">
													{Variable.SWITCH_HRMS_MODULE[ENV_NAME]}
												</div>
											</button>
											<button className="hn-dropdown-item">
												<HAccHelp />
												<div className="hn-dropdown-item-label mxw-auto">
													{Variable.HELP[ENV_NAME]}
												</div>
											</button>
											<div className="hn-border-top" />
											<button
												className="hn-dropdown-item hn-exit-btn"
												onClick={handleLogout}
											>
												<HAccSignOut />
												<div className="hn-dropdown-item-label mxw-auto">
													{Variable.LOGOUT[ENV_NAME]}
												</div>
											</button>
										</div>
									)}
								</Overlay>
							</div>
						) : (
							<>
								<Button
									type="outline"
									title={Variable.LOGIN[ENV_NAME]}
									size="sm"
									className="mx-2 show-desktop hidden"
									onClick={() => setLoginModalShow(true)}
								/>
								<Button
									type="primary"
									title={Variable.REGISTER[ENV_NAME]}
									size="sm"
									className="mx-2 show-desktop hidden"
									onClick={goToSignUpComponent}
								/>
							</>
						)}
					</div>
				</div>
				<ModalInfo
					isShow={modals.permission_role}
					onConfirm={() =>
						setShowModals({
							permission_role: false,
						})
					}
					type="other"
					title={Variable.PERMISSION_ROLE_MODAL_ERROR[ENV_NAME]}
					description={""}
					customIcon={<Image src={ErrorIcon} style={{ width: "56px", height: "56px" }} />}
					confirmButtonType="primary"
					confirmLabel={Variable.OKAY[ENV_NAME]}
					showCancelBtn={false}
				/>
				<ModalInfo
					isShow={modalHRMSRestriction}
					onConfirm={() => setModalHRMSRestriction(false)}
					type="other"
					title={t("NO_PERMISSION_TITLE")}
					description={t("NO_PERMISSION_DESC")}
					customIcon={<Image src={ErrorIcon} style={{ width: "56px", height: "56px" }} />}
					confirmButtonType="primary"
					confirmLabel={t("OKAY")}
					showCancelBtn={false}
				/>
			</nav>
		</>
	);
};

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
	currency: state.auth.currency || { id: "IDR", name: "IDR" },
	userDetails: state.auth.userDetails,
	jobActive: state.auth.jobTabActive,
	jobs: state.auth.jobs,
});

const mapStateToDispatch = (dispatch) => {
	return {
		setJobActive: (payload) => {
			dispatch({ type: ActionTypes.ACTIVE_MENU_TAB, payload: payload });
		},
		logout: () => dispatch({ type: ActionTypes.LOGOUT }),
		logoutSucceed: () => dispatch({ type: ActionTypes.LOGOUT_SUCCEED }),
		setLoginModalShow: (payload = true) => {
			if (payload) {
				dispatch({
					type: ActionTypes.OPEN_LOGIN_MODAL,
				});
			} else {
				dispatch({
					type: ActionTypes.CLOSE_LOGIN_MODAL,
				});
			}
		},
		changeLanguage: (payload) =>
			dispatch({
				type: ActionTypes.UPDATE_LANGUAGE,
				payload: payload,
			}),
		changeCurrency: (payload) =>
			dispatch({
				type: ActionTypes.UPDATE_CURRENCY,
				payload: payload,
			}),
		dispatch_trendingJobs: (payload) => {
			dispatch({ type: ActionTypes.SET_JOBS_TRENDING, payload: payload });
		},
		dispatch_popularJobs: (payload) => {
			dispatch({ type: ActionTypes.SET_JOBS_POPULAR, payload: payload });
		},
	};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(HeaderComponent));
