import { useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { PiArrowLeft } from "react-icons/pi";

import * as Variable from "../../../shared/utils/variables";

import AddSkill from "./AddSkill";

function Skills({ ENV_NAME, onPrevStep, onNextStep, onComplete, onSkip }) {
	const FORMS = [Variable.SKILLS[ENV_NAME]];

	const [currForm, setCurrForm] = useState(1);

	const handleBack = () => {
		if (currForm === 1) onPrevStep();
		if (currForm > 1) setCurrForm((prev) => prev - 1);
	};

	return (
		<div className="about-me">
			<div className="header">
				<PiArrowLeft
					color="#667085"
					className="cursorPointer icon-back"
					onClick={handleBack}
				/>
				<ProgressBar
					completed={(currForm * 100) / FORMS.length}
					height="10px"
					bgColor="#193560"
					baseBgColor="#f2f4f7"
					isLabelVisible={false}
				/>

				<h2>{FORMS[currForm - 1]}</h2>
			</div>
			<section>
				{currForm === 1 && (
					<AddSkill
						ENV_NAME={ENV_NAME}
						onNextStep={onNextStep}
						onComplete={onComplete}
						onSkip={onSkip}
					/>
				)}
			</section>
		</div>
	);
}

export default Skills;
