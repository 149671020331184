import React from "react";

import { Typography } from "shared/component/Typography/Typography";

import "./CardModule.scss";

export type CardModuleProps = {
	id?: number;
	icon: React.ReactNode;
	title: string;
	onClick: () => void;
};

export const moduleIconStyles = {
	width: 32,
	height: 32,
	color: "#475467",
};

const CardModule = ({ icon, title, onClick }: CardModuleProps) => {
	return (
		<div className="modules-select__card-module" onClick={onClick}>
			{icon}
			<Typography.Text size="md" fontWeight="semibold" color="#1D2939">
				{title}
			</Typography.Text>
		</div>
	);
};

export default CardModule;
