import { connect } from "react-redux";
import { Modal } from "react-bootstrap";

import {
	MODAL_VERIFICATION_TITLE,
	UPLOAD_FAILED,
	DELETE_MEDIA_LABEL,
	REPORT_SUCCESS_TITLE,
	RESET_PASSWORD_VERIFICATION,
	FORMAT_NOT_SUPPORTED,
	DELETE_TITLE,
	REPORT_SUCCESS_TEXT,
	CANCEL_LABEL,
	DELETE_LABEL,
} from "../../utils/variables.js";

import Icon from "../IconComponent/Icon";
import Button from "../ButtonComponent/Button";
import { classHelper } from "../../utils/stringUtils";

import "./ModalComponent.scss";
import { Typography } from "../Typography/Typography";
// DOCUMENTATION
// type : [verification, error, delete]

const ModalInfo = ({
	type,
	isShow,
	ENV_NAME,
	onConfirm,
	title,
	description,
	customIcon,
	className = "",
	userEmail = "",
	onHide = () => {},
	showCancelBtn = true,
	cancelButtonType = "outline",
	cancelLabel = CANCEL_LABEL[ENV_NAME],
	confirmButtonType = "error",
	confirmLabel = DELETE_LABEL[ENV_NAME],
}) => {
	const iconType = () => {
		switch (type) {
			case "verification":
			case "delete":
				return "email";
			case "error":
				return "error";
			default:
				return "success";
		}
	};

	const classNameWrapper = () => {
		switch (type) {
			case "verification":
			case "error":
				return "ms m-email";
			case "delete":
				return "ms m-delete";
			default:
				return "ms";
		}
	};

	const titleText = () => {
		switch (type) {
			case "verification":
				return MODAL_VERIFICATION_TITLE[ENV_NAME];
			case "error":
				return UPLOAD_FAILED[ENV_NAME];
			case "delete":
				return DELETE_MEDIA_LABEL[ENV_NAME];
			case "other":
				return title;
			default:
				return REPORT_SUCCESS_TITLE[ENV_NAME];
		}
	};

	const descriptionText = () => {
		switch (type) {
			case "verification":
				return RESET_PASSWORD_VERIFICATION[ENV_NAME] + "<b>" + userEmail + "</b>";
			case "error":
				return FORMAT_NOT_SUPPORTED[ENV_NAME];
			case "delete":
				return DELETE_TITLE[ENV_NAME];
			case "other":
				return description;
			default:
				return REPORT_SUCCESS_TEXT[ENV_NAME];
		}
	};

	const modalSize = () => {
		switch (type) {
			case "verification":
			case "error":
			case "delete":
				return "md";
			default:
				return "lg";
		}
	};

	return (
		<>
			<Modal
				show={isShow}
				onHide={onHide}
				dialogClassName={classHelper(
					"modal-dialog-centered modal-report-job modal-info",
					className,
				)}
				size={modalSize()}
			>
				<Modal.Body>
					<div>
						<div className="ms-head">
							{customIcon || (
								<Icon iconType={iconType()} borderType="outline" className="mr-0" />
							)}
							<Typography.Text
								size="xl"
								fontWeight="bold"
								color="#1D2939"
								className="text-center"
								style={{
									marginTop: "32px",
									marginBottom: "8px",
								}}
							>
								{titleText()}
							</Typography.Text>

							<Typography.Text
								dangerouslySetInnerHTML={{
									__html: `${descriptionText()}`,
								}}
								size="md"
								className="text-center"
							/>
						</div>
					</div>
					{(showCancelBtn || onConfirm) && (
						<div className="mButton">
							{showCancelBtn && (
								<Button
									size="sm"
									onClick={onHide}
									title={cancelLabel}
									type={cancelButtonType}
								/>
							)}
							{onConfirm && (
								<Button
									size="sm"
									onClick={onConfirm}
									title={confirmLabel}
									type={confirmButtonType}
								/>
							)}
						</div>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(ModalInfo);
