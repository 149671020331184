/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import * as Variable from "../../shared/utils/variables";
import { ROOT, SETUP_PROFILE } from "../../shared/utils/routeLink";
import Image from "../../shared/component/UI/Image";

import About from "./AboutMeComponent/About";
import Education from "./EducationComponent/Education";
import Experience from "./ExperienceComponent/Experience";
import Skills from "./SkillsComponent/Skills";
import Language from "./LanguageComponent/Language";
import PortfolioAndResume from "./PortfolioAndResumeComponent/PortfolioAndResume";
import JobResumePref from "./JobResumePrefComponent/JobResumePref";

import logo from "../../assets/svg/homepage.svg";

import "./InitialProfile.scss";

function InitialProfile({ ENV_NAME, userId, isInitialLogin, ...props }) {
	const FORM_TYPE = [
		{ id: 1, name: "about_me", label: Variable.ABOUT_ME[ENV_NAME] },
		{ id: 2, name: "education", label: Variable.EDUCATION[ENV_NAME] },
		{ id: 3, name: "experience", label: Variable.EXPERIENCE[ENV_NAME] },
		{ id: 4, name: "skills", label: Variable.SKILLS[ENV_NAME] },
		{ id: 5, name: "language", label: Variable.LANGUAGE[ENV_NAME] },
		{
			id: 6,
			name: "portfolio_resume",
			label: Variable.PORTFOLIO_RESUME[ENV_NAME],
		},
		{
			id: 7,
			name: "job_resume_pref",
			label: Variable.JOB_RESUME_PREF[ENV_NAME],
		},
	];

	const [currStep, setCurrStep] = useState(1);
	const [currStatus, setCurrStatus] = useState({
		complete: [],
		skip: [],
	});

	useEffect(() => {
		// TODO: This route should be accessible on first time login only
		if (isInitialLogin === false) props.history.push(ROOT);
	}, [isInitialLogin]);

	useEffect(() => {
		const profileProgress = JSON.parse(sessionStorage.getItem("profileSetup"));
		setCurrStep(
			Math.min(
				Math.min(profileProgress?.current_step + 1, FORM_TYPE.length - 1),
				FORM_TYPE.length - 1,
			) || 1,
		);
		setCurrStatus({
			complete: profileProgress?.complete || [],
			skip: profileProgress?.skip || [],
		});
	}, []);

	const handlePrevStep = () => currStep > 1 && setCurrStep((prev) => prev - 1);

	const handleNextStep = () => {
		if (currStep < FORM_TYPE.length) setCurrStep((prev) => prev + 1);

		const profileProgress = JSON.parse(sessionStorage.getItem("profileSetup"));
		sessionStorage.setItem(
			"profileSetup",
			JSON.stringify({
				...profileProgress,
				current_step: Math.max(currStep + 1, profileProgress?.current_step),
			}),
		);
	};

	const handleSkipOrComplete = (name) => {
		const currentFormName = FORM_TYPE[currStep - 1]?.name;
		const currentStatusClone = { ...currStatus };
		currentStatusClone[name].push(currentFormName);

		const profileProgress = JSON.parse(sessionStorage.getItem("profileSetup"));
		// If the user again comes to the previously skipped step
		// but this time user is trying to complete the step
		// then in that case on completing the step remove from
		// the skipped step and same situation will be there for
		// completed step
		if (name === "complete" && profileProgress?.["skip"]?.includes(currentFormName)) {
			profileProgress["skip"] = profileProgress["skip"].filter((n) => n !== currentFormName);

			currentStatusClone["skip"] = currentStatusClone["skip"].filter(
				(n) => n !== currentFormName,
			);
		}

		if (name === "skip" && profileProgress?.["complete"]?.includes(currentFormName)) {
			profileProgress["complete"] = profileProgress["complete"].filter(
				(n) => n !== currentFormName,
			);

			currentStatusClone["complete"] = currentStatusClone["complete"].filter(
				(n) => n !== currentFormName,
			);

			props.history.push(SETUP_PROFILE);
		}

		sessionStorage.setItem(
			"profileSetup",
			JSON.stringify({
				...profileProgress,
				current_step: Math.min(currStep + 1, FORM_TYPE.length - 1),
				[name]: [...new Set(currentStatusClone[name])],
			}),
		);

		setCurrStatus({
			...currentStatusClone,
			[name]: [...new Set(currentStatusClone[name])],
		});
	};

	return (
		<div className="container-fluid ipf">
			<div className="row p-0">
				{/* TODO: Mobile Header */}
				<div className="col-sm-12 p-0 d-lg-none">
					<div className="ipf-header">
						<p
							className={
								currStatus?.complete.includes(FORM_TYPE[currStep - 1]?.name)
									? "complete"
									: currStatus?.skip.includes(FORM_TYPE[currStep - 1]?.name)
									? "skip"
									: "current"
							}
						>
							{FORM_TYPE[currStep - 1]?.label}
						</p>
					</div>
				</div>

				<div className="col-12 p-0">
					<div className="wrapper">
						<div className="ipf-left hidden show-desktop">
							<Image src={logo} className="home-logo" />
							<div className="steps">
								<ul>
									{FORM_TYPE.map((form, idx) => (
										<li
											key={form.id}
											className={
												currStatus?.complete.includes(form.name)
													? "completed"
													: currStatus?.skip.includes(form.name)
													? "skip"
													: currStep === idx + 1
													? "current"
													: ""
											}
										>
											{form.label}
										</li>
									))}
								</ul>
							</div>
						</div>

						<div className="ipf-right">
							<div className="ipf-right-container">
								{currStep === 1 && (
									<About
										ENV_NAME={ENV_NAME}
										userId={userId}
										onNextStep={handleNextStep}
										onComplete={() => handleSkipOrComplete("complete")}
									/>
								)}
								{currStep === 2 && (
									<Education
										ENV_NAME={ENV_NAME}
										userId={userId}
										onPrevStep={handlePrevStep}
										onNextStep={handleNextStep}
										onComplete={() => handleSkipOrComplete("complete")}
									/>
								)}
								{currStep === 3 && (
									<Experience
										ENV_NAME={ENV_NAME}
										userId={userId}
										onPrevStep={handlePrevStep}
										onNextStep={handleNextStep}
										onComplete={() => handleSkipOrComplete("complete")}
										onSkip={() => handleSkipOrComplete("skip")}
									/>
								)}
								{currStep === 4 && (
									<Skills
										ENV_NAME={ENV_NAME}
										onPrevStep={handlePrevStep}
										onNextStep={handleNextStep}
										onComplete={() => handleSkipOrComplete("complete")}
										onSkip={() => handleSkipOrComplete("skip")}
									/>
								)}
								{currStep === 5 && (
									<Language
										ENV_NAME={ENV_NAME}
										userId={userId}
										onPrevStep={handlePrevStep}
										onNextStep={handleNextStep}
										onComplete={() => handleSkipOrComplete("complete")}
										onSkip={() => handleSkipOrComplete("skip")}
									/>
								)}
								{currStep === 6 && (
									<PortfolioAndResume
										ENV_NAME={ENV_NAME}
										userId={userId}
										onPrevStep={handlePrevStep}
										onNextStep={handleNextStep}
										onComplete={() => handleSkipOrComplete("complete")}
										onSkip={() => handleSkipOrComplete("skip")}
									/>
								)}
								{currStep === 7 && (
									<JobResumePref
										ENV_NAME={ENV_NAME}
										userId={userId}
										onPrevStep={handlePrevStep}
										onComplete={() => handleSkipOrComplete("complete")}
										onSkip={() => handleSkipOrComplete("skip")}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
	userId: state?.auth?.userDetails?.id,
	isInitialLogin: state?.auth?.userDetails?.is_initial_login,
});

export default connect(mapStateToProps, null)(withRouter(InitialProfile));
