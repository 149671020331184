/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useRef, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { isArray } from "lodash";

import * as Variable from "../../utils/variables";
import { classHelper } from "../../utils/stringUtils";
import arrowDown from "../../../assets/svg/arrow_dropdown.svg";
import arrowTop from "../../../assets/svg/arrow_top.svg";

import "./DropdownComponent.scss";
import Image from "../UI/Image";
import useOutside from "../../hooks/outsideClick";
import CustomDualRangeSlider from "./../CustomDualRangeSlider/CustomDualRangeSlider";
import useTranslate from "shared/hooks/useTranslate";
import { nFormatter } from "shared/utils/string";

import InputComponent from "../InputComponent/Input";
import INDUSTRIES_EN from "../../../component/JobSearching/industriesEN.json";
import INDUSTRIES_ID from "../../../component/JobSearching/industriesID.json";

// DOCUMENTATION
// type : [date, salary, jobType, workMode, experienceLevel, industry, underTenApp]

const ApplyButton = ({ onApplyButton, ENV_NAME }) => (
	<div className="J-ddComp-apply">
		<button onClick={onApplyButton}>{Variable.APPLY[ENV_NAME]}</button>
	</div>
);

const SelectedNumber = ({ number }) => (
	<div className="selected-number">
		<div>{number}</div>
	</div>
);

const DropdownComponent = ({ type, ENV_NAME, value, onApplyButton, isNotEmpty, ...props }) => {
	const t = useTranslate();

	const DATE_POST = [
		{
			id: "anyTime",
			name: Variable.ANY_TIME_LABEL[ENV_NAME],
			value: Variable.ANY_TIME_LABEL["eng"],
		},
		{
			id: "24hours",
			name: Variable.LAST_24_HOURS[ENV_NAME],
			value: Variable.LAST_24_HOURS["eng"],
		},
		{ id: "3days", name: Variable.LAST_3_DAYS[ENV_NAME], value: Variable.LAST_3_DAYS["eng"] },
		{ id: "7days", name: Variable.LAST_7_DAYS[ENV_NAME], value: Variable.LAST_7_DAYS["eng"] },
		{
			id: "14days",
			name: Variable.LAST_14_DAYS[ENV_NAME],
			value: Variable.LAST_14_DAYS["eng"],
		},
		{
			id: "30days",
			name: Variable.LAST_30_DAYS[ENV_NAME],
			value: Variable.LAST_30_DAYS["eng"],
		},
	];

	const WORK_MODE = [
		{
			id: "onSite",
			name: Variable.ONSITE_LABEL[ENV_NAME],
			value: Variable.ONSITE_LABEL["eng"],
		},
		{
			id: "hybrid",
			name: Variable.HYBRID_LABEL[ENV_NAME],
			value: Variable.HYBRID_LABEL["eng"],
		},
		{
			id: "remote",
			name: Variable.REMOTE_LABEL[ENV_NAME],
			value: Variable.REMOTE_LABEL["eng"],
		},
	];

	const JOB_TYPE = [
		{
			id: "fullTime",
			name: Variable.FULL_TIME_LABEL[ENV_NAME],
			value: Variable.FULL_TIME_LABEL["eng"],
		},
		{
			id: "partTime",
			name: Variable.PART_TIME_LABEL[ENV_NAME],
			value: Variable.PART_TIME_LABEL["eng"],
		},
		{
			id: "freelance",
			name: Variable.FRELANCE_LABEL[ENV_NAME],
			value: Variable.FRELANCE_LABEL["eng"],
		},
		{
			id: "contract",
			name: Variable.CONTRACT_LABEL[ENV_NAME],
			value: Variable.CONTRACT_LABEL["eng"],
		},
		{
			id: "internship",
			name: Variable.INTERNSHIP_LABEL[ENV_NAME],
			value: Variable.INTERNSHIP_LABEL["eng"],
		},
		{
			id: "temporary",
			name: Variable.TEMPORARY_LABEL[ENV_NAME],
			value: Variable.TEMPORARY_LABEL["eng"],
		},
	];

	const EXPERIENCE_LEVEL = [
		{
			id: "entryLevel",
			name: Variable.ENTRY_LEVELLABEL[ENV_NAME],
			value: Variable.ENTRY_LEVELLABEL["eng"],
		},
		{
			id: "associate",
			name: Variable.ASSOCIATE_LABEL[ENV_NAME],
			value: Variable.ASSOCIATE_LABEL["eng"],
		},
		{
			id: "intermediate",
			name: Variable.INTERMEDIATE_LABEL[ENV_NAME],
			value: Variable.INTERMEDIATE_LABEL["eng"],
		},
		{
			id: "senior",
			name: Variable.SENIOR_LABEL[ENV_NAME],
			value: Variable.SENIOR_LABEL["eng"],
		},
		{
			id: "director",
			name: Variable.DIRECTOR_LABEL[ENV_NAME],
			value: Variable.DIRECTOR_LABEL["eng"],
		},
		{
			id: "executive",
			name: Variable.EXECUTIVE_LABEL[ENV_NAME],
			value: Variable.EXECUTIVE_LABEL["eng"],
		},
	];

	const currency = new Intl.NumberFormat("id-ID", {
		style: "currency",
		currency: "IDR",
		currencyDisplay: "code",
		trailingZeroDisplay: "stripIfInteger",
	});

	const INDUSTRIES = ENV_NAME === "eng" ? INDUSTRIES_EN : INDUSTRIES_ID;

	const dropdownRef = useRef();
	const menuPopupRef = useRef();
	const [isOpen, setIsOpen] = useState(false);
	const [search, setSearch] = useState("");
	const [filteredIndustries, setFilteredIndustries] = useState(INDUSTRIES);
	const [lazyValue, setLazyValue] = useState(value);

	const onHide = () => {
		// Reset to initial value from props.value on dropdown Hide
		setInitialLazyValue(value);
		if (isOpen) setIsOpen(false);
	};

	useOutside(dropdownRef, onHide);

	useEffect(() => {
		setInitialLazyValue(value);
	}, [value]);

	/**
	 *
	 * @param {boolean | string | number | {value: string, isChecked?: boolean}} value
	 * @param {*} isArray
	 */
	const handleChange = (value, isArray = false, triggerChange = false) => {
		if (isArray && "value" in value) {
			const idxIfExist = lazyValue.map((v) => v.value).indexOf(value.value);
			const tempArr = [...new Set(lazyValue)];

			if (idxIfExist >= 0) tempArr[idxIfExist] = value;
			else tempArr.push(value);

			setLazyValue(tempArr);
		} else {
			setLazyValue(value);
		}
		triggerChange && props.onChange(value);
	};

	/**
	 * @desc Set initial lazyValue from value props, this should be called only when the props value changed or reset current value to the value props
	 * @param {*} value
	 */
	const setInitialLazyValue = (value) => {
		let valueFormatted = "";
		const CheckBoxTypeMapLookUp = {
			jobType: JOB_TYPE,
			experienceLevel: EXPERIENCE_LEVEL,
			industry: INDUSTRIES,
		};

		if (value) {
			if (Object.keys(CheckBoxTypeMapLookUp).indexOf(type) >= 0) {
				valueFormatted =
					value?.map((v) => ({
						value: CheckBoxTypeMapLookUp[type].find((jt) => jt.value === v).value,
						isChecked: true,
					})) ?? [];
			} else {
				valueFormatted = value;
			}
		}

		handleChange(valueFormatted, isArray(value), false);
	};

	const handleOnApply = () => {
		onApplyButton(lazyValue);
		setIsOpen(false);
	};

	const handleUnderTenApp = () => {
		setLazyValue((currVal) => {
			onApplyButton(!currVal);
			return !currVal;
		});
	};

	const DateMenuType = (
		<>
			<div className="J-ddComp-menu">
				{DATE_POST.map((dp, dpIdx) => (
					<div className="menuDD" key={dpIdx}>
						<label htmlFor={`${dp.id}-inMenu`}>{dp.name}</label>
						<input
							type="radio"
							id={`${dp.id}-inMenu`}
							name={`${dp.id}-inMenu`}
							value={value}
							checked={lazyValue === dp.value}
							onChange={() => handleChange(dp.value)}
						/>
					</div>
				))}
			</div>
			<ApplyButton {...{ onApplyButton: handleOnApply, ENV_NAME }} />
		</>
	);

	const SalaryMenuType = (
		<>
			<div className="J-ddComp-menu h-padding">
				<p className="text-center py-4 pb-3">
					<strong>{Variable.MONTHLY[ENV_NAME]}</strong> {Variable.SALARY_RANGE[ENV_NAME]}
				</p>
				<div className="salary_inputs">
					<input
						className="min_input input"
						placeholder="0"
						value={currency.format(lazyValue?.min ?? 0)}
						disabled
						readOnly
					/>
					{t("TO")}
					<input
						className="max_input input"
						placeholder="100.000.000"
						value={currency.format(lazyValue?.max ?? 100_000_000)}
						disabled
						readOnly
					/>
				</div>
				<div className="range_slider my-5 mb-0">
					<CustomDualRangeSlider
						min={0}
						max={100_000_000}
						value={lazyValue}
						onUpdate={({ min, max }) => handleChange({ min, max })}
						ENV_NAME={ENV_NAME}
					/>
				</div>
			</div>
			<ApplyButton {...{ onApplyButton: handleOnApply, ENV_NAME }} />
		</>
	);

	const JobTypeMenuType = (
		<>
			<div className="J-ddComp-menu">
				{JOB_TYPE?.map((jt, jtIdx) => (
					<div className="menuDD" key={jtIdx}>
						<label htmlFor={`${jt?.id}-inMenu`}>{jt.name}</label>
						<input
							type="checkbox"
							id={`${jt?.id}-inMenu`}
							name="job_type"
							checked={
								isArray(lazyValue) &&
								lazyValue?.find((v) => v.value === (jt?.value ?? ""))?.isChecked
							}
							onChange={(e) =>
								handleChange(
									{ value: jt?.value, isChecked: e.target.checked },
									true,
								)
							}
						/>
					</div>
				))}
			</div>
			<ApplyButton {...{ onApplyButton: handleOnApply, ENV_NAME }} />
		</>
	);

	const WorkModeMenuType = (
		<>
			<div className="J-ddComp-menu">
				{WORK_MODE.map((wm, wmIdx) => (
					<div className="menuDD" key={wmIdx}>
						<label htmlFor={`${wm.id}-inMenu`}>{wm.name}</label>
						<input
							type="radio"
							id={`${wm.id}-inMenu`}
							name={`${wm.id}-inMenu`}
							checked={lazyValue === wm.value}
							onChange={() => handleChange(wm.value)}
						/>
					</div>
				))}
			</div>
			<ApplyButton {...{ onApplyButton: handleOnApply, ENV_NAME }} />
		</>
	);

	const ExperienceLevelMenuType = (
		<>
			<div className="J-ddComp-menu">
				{EXPERIENCE_LEVEL.map((el, elIdx) => (
					<div className="menuDD" key={elIdx}>
						<label htmlFor={`${el.id}-inMenu`}>{el.name}</label>
						<input
							type="checkbox"
							id={`${el.id}-inMenu`}
							name={`${el.id}-inMenu`}
							checked={
								isArray(lazyValue) &&
								lazyValue.find((v) => v.value === el.value)?.isChecked
							}
							onChange={(e) =>
								handleChange({ value: el.value, isChecked: e.target.checked }, true)
							}
						/>
					</div>
				))}
			</div>
			<ApplyButton {...{ onApplyButton: handleOnApply, ENV_NAME }} />
		</>
	);

	const IndustryMenuType = (
		<>
			<div className="J-ddComp-menu mt-3">
				<InputComponent
					dropdownType="search"
					placeholder={Variable.SEARCH_LABEL[ENV_NAME]}
					className="flex-grow-1 w-mobile-100 h-padding"
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					onClickClose={() => setSearch("")}
				/>
				<hr />
				<div className="industry">
					{filteredIndustries?.map((ind, indIdx) => (
						<div className="menuDD" key={indIdx}>
							<label htmlFor={`${ind?.id}-inMenu`}>{ind?.name}</label>
							<input
								type="checkbox"
								id={`${ind?.id}-inMenu`}
								name={`${ind?.id}-inMenu`}
								checked={
									isArray(lazyValue) &&
									lazyValue.find((v) => v.value === ind.value)?.isChecked
								}
								onChange={(e) =>
									handleChange(
										{
											value: ind?.value,
											isChecked: e.target.checked,
										},
										true,
									)
								}
							/>
						</div>
					))}
				</div>
			</div>
			<ApplyButton {...{ onApplyButton: handleOnApply, ENV_NAME }} />
		</>
	);

	const MenuType =
		{
			date: DateMenuType,
			salary: SalaryMenuType,
			jobType: JobTypeMenuType,
			workMode: WorkModeMenuType,
			experienceLevel: ExperienceLevelMenuType,
			industry: IndustryMenuType,
		}[type] ?? null;

	const Label =
		{
			date: Variable.DATE_LABEL[ENV_NAME],
			salary: !isNotEmpty
				? Variable.SALARY[ENV_NAME]
				: `${nFormatter(lazyValue.min, 0, ENV_NAME)} - ${nFormatter(
						lazyValue.max,
						0,
						ENV_NAME,
				  )}`,
			jobType: Variable.JOB_TYPE_LABEL[ENV_NAME],
			workMode: Variable.WORK_MODE_LABEL[ENV_NAME],
			experienceLevel: Variable.EXPERIENCE_LEVEL_LABEL[ENV_NAME],
			industry: Variable.INDUSTRY_LABEL[ENV_NAME],
			underTenApp: (
				<p className="mb-0" onClick={handleUnderTenApp}>
					{Variable.UNDER_TEN_APP_LABEL[ENV_NAME]}
				</p>
			),
		}[type] ?? Variable.SOMETHING_WENT_WRONG_TRY_AGAIN[ENV_NAME];

	useEffect(() => {
		const debouncedSearch = setTimeout(() => filterIndustries(search), 800);
		return () => clearTimeout(debouncedSearch);
	}, [search]);

	useEffect(() => {
		if (!isOpen) return;

		const bounding = menuPopupRef.current.getBoundingClientRect();
		const clientWidth = document.body.clientWidth;
		const popupPosition = clientWidth - (bounding.width + bounding.left + 24);

		if (popupPosition < 0) menuPopupRef.current.style.left = `${popupPosition}px`;
	}, [isOpen]);

	const filterIndustries = (search) => {
		if (!search) return setFilteredIndustries(INDUSTRIES);

		const filteredIndustries = INDUSTRIES.filter((i) =>
			i.name.toLowerCase().includes(search.toLowerCase()),
		);
		setFilteredIndustries(filteredIndustries);
	};

	return (
		<div className={classHelper("J-ddComp", props.className)} ref={dropdownRef}>
			<div
				className={classHelper("J-ddComp-container", isNotEmpty && "active")}
				onClick={() => setIsOpen(!isOpen)}
			>
				{Label}
				{typeof value === "object" && value.length !== undefined && value.length > 0 && (
					<SelectedNumber number={value.length} />
				)}
				<span>
					{type !== "underTenApp" && (
						<Image
							className="J-ddComp-down"
							src={`${isOpen ? arrowTop : arrowDown}`}
							onClick={() => setIsOpen(!isOpen)}
						/>
					)}
				</span>
			</div>
			<div className="J-ddComp-menu-cont" ref={menuPopupRef}>
				{isOpen && MenuType}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(memo(DropdownComponent)));
