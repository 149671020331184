import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import useToast from "shared/hooks/useToast";
import makeRequest from "../../../shared/utils/request";
import { generateRequestOptions } from "../../../shared/utils/apiEndPoints";
import * as Variable from "../../../shared/utils/variables";
import Input from "../../../shared/component/InputComponent/Input";
import Select from "../../../shared/component/SelectComponent/Select";
import Toggle from "../../../shared/component/ToggleComponent/Toggle";
import Button from "../../../shared/component/ButtonComponent/Button";
import { PlusIcon } from "../../../shared/component/svg/Icons";
import FileCard from "../../../shared/component/CardComponent/FileCard";
import { getIconByName } from "../../../shared/utils/file";
import ModalInfo from "../../../shared/component/ModalComponent/ModalInfo";
import Image from "../../../shared/component/UI/Image";
import { SETUP_PROFILE } from "../../../shared/utils/routeLink";

import RemoveMediaIcon from "../../../assets/image/remove-media.png";
import WarningIcon from "../../../assets/svg/red_warning.svg";
import { formatString } from "shared/utils/string";
import { isValueAllowed } from "shared/utils/numberUtils";

function AddLanguage({ ENV_NAME, onNext, onNextStep, onSkip }) {
	const toast = useToast();

	const PROFICIENCY = [
		{ id: "beginner", name: Variable.BEGINNER_LABEL[ENV_NAME] },
		{ id: "intermediate", name: Variable.INTERMEDIATE_SKILL_LABEL[ENV_NAME] },
		{ id: "fluent", name: Variable.FLUENT_LABEL[ENV_NAME] },
		{ id: "expert", name: Variable.EXPERT_LABEL[ENV_NAME] },
		{ id: "native", name: Variable.NATIVE_LABEL[ENV_NAME] },
	];

	const history = useHistory();
	const languageId = history?.location?.pathname?.split("/")?.[3];

	const [form, setForm] = useState({
		name: "",
		spoken_proficiency: "",
		written_proficiency: "",
		language_proficiency_name: "",
		language_proficiency_score: "",
		is_primary: "",
		language_document_proof: [],
	});
	const [errors, setErrors] = useState({});
	const [monitor, setMonitor] = useState({});
	const [isEdited, setIsEdited] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [showConfirm, setShowConfirm] = useState({ show: false, data: null });
	const [showSkip, setShowSkip] = useState(false);

	const uploadRef = useRef();

	// if there is any language id in the url then retrieve the language
	useEffect(() => {
		if (languageId) retrieveLanguage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [languageId]);

	const retrieveLanguage = async () => {
		const res = await makeRequest(
			generateRequestOptions("getLanguage", { urlParams: languageId }),
		);

		if (res.code === 200) setForm(res.data);
		else toast.error(res.message);
	};

	const onChange = (e, type = null) => {
		const { name, value } = e.target;
		setForm((p) => ({
			...p,
			[name]: type ? (isValueAllowed(value, type) ? value : p[name]) : value,
		}));
		setErrors((prev) => ({ ...prev, [name]: false }));
		setMonitor((prev) => ({ ...prev, [name]: true }));
		setIsEdited(true);
	};

	const handleSave = async () => {
		const { name, spoken_proficiency, written_proficiency } = form;

		const errorsData = {};
		if (!name) errorsData["name"] = true;
		if (!spoken_proficiency) errorsData["spoken_proficiency"] = true;
		if (!written_proficiency) errorsData["written_proficiency"] = true;

		const hasErrors = Object.values(errorsData).some((err) => err);
		if (hasErrors) return setErrors(errorsData);

		// TODO: if the user haven't edited the correctly filled form
		// then instead of making api call simply move to the next form
		if (!hasErrors && !isEdited) return onNext();

		const docs = form?.language_document_proof
			?.filter((doc) => !doc?.language)
			?.map((doc) => doc?.language_document_proof);

		const formData = new FormData();
		for (const item in monitor) {
			if (item === "language_document_proof" && docs.length && !languageId)
				docs.forEach((doc) => formData.append("language_document_proof", doc));
			else if (item !== "language_document_proof") formData.append(item, form[item]);
		}

		let url = generateRequestOptions("createLanguage");
		if (languageId) {
			url = generateRequestOptions("editLanguage", {
				urlParams: languageId,
			});
		}

		setIsUploading(true);
		const res = await makeRequest({
			...url,
			data: formData,
		});

		if (res.code === 200) {
			if (docs.length && languageId) {
				const uploadPromises = docs.map((file) => {
					return handleUploadDocument(file);
				});

				await Promise.all(uploadPromises);
			}
			history.push(SETUP_PROFILE);
			onNext();
		} else toast.error(res.message);
		setIsUploading(false);
	};

	const handleUploadDocument = async (file) => {
		const formData = new FormData();
		formData.append("language_document_proof", file);
		formData.append("language", languageId);

		const res = await makeRequest({
			...generateRequestOptions("createLanguageDocument"),
			data: formData,
		});

		if (res.code !== 200) toast.error(res.message);
	};

	const onFileChange = async (e) => {
		console.log("Uploading File");
		const file = e.target.files[0];
		const fileObj = {
			language_document_proof: file,
			file_name: file?.name,
			id: Date.now(),
		};
		const formClone = { ...form };
		if (formClone.language_document_proof) formClone.language_document_proof.push(fileObj);
		else formClone.language_document_proof = [{ ...fileObj }];

		setForm(formClone);
		setMonitor((prev) => ({ ...prev, language_document_proof: true }));
		setIsEdited(true);
	};

	const filterRemainingDocs = (docId) => {
		setForm({
			...form,
			language_document_proof: form.language_document_proof.filter((doc) => doc.id !== docId),
		});
	};

	const handleDeleteDocument = async () => {
		// There will be two kinds of delete
		// if there exists an experience for the document proof, then call the API
		// otherwise, filter out the documents
		const docId = showConfirm?.data?.id;

		const documentProof = form.language_document_proof.find((doc) => doc.id === docId);
		if (documentProof?.language) {
			const res = await makeRequest(
				generateRequestOptions("deleteLanguageDocument", {
					urlParams: docId,
				}),
			);

			if (res.code === 200) {
				filterRemainingDocs(docId);
				setShowConfirm({ show: false, data: null });
			} else toast.error(res.message);
		} else {
			filterRemainingDocs(docId);
			setShowConfirm({ show: false, data: null });
		}
	};

	return (
		<div>
			<div className="edu-form">
				<Input
					label={Variable.LANGUAGE_NAME[ENV_NAME]}
					placeholder={`${Variable.INPUT[ENV_NAME]} ${Variable.LANGUAGE_NAME[ENV_NAME]}`}
					value={form?.name || ""}
					name="name"
					onChange={onChange}
					inputType="label"
					className="w-100"
					required
					isError={errors["name"]}
				/>
				<div className="form-row">
					<Select
						label={Variable.SPOKEN_PROFICIENCY[ENV_NAME]}
						placeholder={Variable.SPOKEN_PROFICIENCY[ENV_NAME]}
						name="spoken_proficiency"
						value={form?.spoken_proficiency || ""}
						items={PROFICIENCY}
						onChange={onChange}
						searchable={false}
						noDefault
						required
						isError={errors["spoken_proficiency"]}
					/>
					<Select
						label={Variable.WRITTEN_PROFICIENCY[ENV_NAME]}
						placeholder={Variable.WRITTEN_PROFICIENCY[ENV_NAME]}
						name="written_proficiency"
						value={form?.written_proficiency || ""}
						items={PROFICIENCY}
						onChange={onChange}
						searchable={false}
						noDefault
						required
						isError={errors["written_proficiency"]}
					/>
				</div>
				<div className="form-row">
					<Input
						label={Variable.LANGUAGE_CERTIFICATION_NAME[ENV_NAME]}
						placeholder={Variable.LANGUAGE_CERTIFICATION_NAME[ENV_NAME]}
						value={form?.language_proficiency_name || ""}
						name="language_proficiency_name"
						onChange={onChange}
						inputType="label"
						className="w-100"
					/>
					<Input
						label={Variable.LANGUAGE_PROFICIENCY_SCORE[ENV_NAME]}
						placeholder={Variable.LANGUAGE_PROFICIENCY_SCORE[ENV_NAME]}
						value={form?.language_proficiency_score || ""}
						name="language_proficiency_score"
						onChange={(e) => onChange(e, "numberWithoutDecimal")}
						inputType="label"
						className="w-100"
					/>
				</div>
				<Toggle
					type="switch"
					leftText={Variable.PRIMARY[ENV_NAME]}
					name="is_primary"
					checked={form?.is_primary}
					onChange={(e) => {
						setForm((p) => ({ ...p, is_primary: e.target.checked }));
						setMonitor((prev) => ({ ...prev, is_primary: true }));
						setIsEdited(true);
					}}
				/>
				<div>
					<h5 className="text-md font-weight-medium text-label-gray mb-sm">
						{Variable.DOCUMENT[ENV_NAME]}
					</h5>
					<span className="text-sm">{Variable.DOC_SKILL_SUBTITLE[ENV_NAME]}</span>
				</div>
				<div className="input-btn">
					<Button
						type="secondary"
						size="md"
						title={
							<div className="flex-all-center gap-xxs">
								<PlusIcon
									width="1.8rem"
									height="1.8rem"
									stroke="white"
									strokeWidth="3"
								/>
								{Variable.ADD_FILE[ENV_NAME]}
							</div>
						}
						onClick={() => uploadRef?.current?.click()}
					/>
					<input
						id="upload-files"
						type="file"
						name="subjectIcon"
						className="inputfile d-none"
						onChange={(e) => {
							onFileChange(e);
							uploadRef.current.value = null;
						}}
						accept="image/*, application/pdf"
						ref={uploadRef}
					/>
				</div>
				<div>
					{form?.language_document_proof?.map((i) => (
						<FileCard
							key={i?.id}
							title={i?.file_name}
							icon={getIconByName(i?.file_name)}
							onDelete={() => setShowConfirm({ show: true, data: i })}
							className="mb-3 fileCard"
							showGreenCheck={false}
							isUploading={isUploading}
						/>
					))}
				</div>
			</div>

			{/* Delete Modal */}
			<ModalInfo
				isShow={showConfirm?.show}
				onHide={() => setShowConfirm({ show: false, data: null })}
				onConfirm={handleDeleteDocument}
				type="delete"
				customIcon={<Image src={RemoveMediaIcon} className="modal-image-icon" />}
			/>

			{/* Skip Modal */}
			<ModalInfo
				isShow={showSkip}
				onHide={() => setShowSkip(false)}
				onConfirm={() => {
					onSkip();
					onNextStep();
				}}
				type="other"
				customIcon={<Image src={WarningIcon} className="modal-image-icon" />}
				title={Variable.SURE_TO_SKIP[ENV_NAME]}
				description={
					formatString(
						Variable.SKIP_DESC[ENV_NAME],
						Variable.LANGUAGE[ENV_NAME].toLowerCase(),
					) +
					" " +
					Variable.HISTORY[ENV_NAME]
				}
				confirmLabel={Variable.SKIP_BTN_TITLE[ENV_NAME]}
			/>

			<div className="d-flex align-items-center">
				<Button
					type="outline"
					size="md"
					title={Variable.SKIP_STEP[ENV_NAME]}
					className="mt-5 me-4"
					btnClassName="btn-initial-profile"
					onClick={() => setShowSkip(true)}
				/>
				<Button
					type="primary"
					size="sm"
					title={Variable.SAVE[ENV_NAME]}
					className="mt-5"
					btnClassName="btn-initial-profile"
					onClick={handleSave}
					disabled={
						!form?.name ||
						!form?.spoken_proficiency ||
						!form?.written_proficiency ||
						isUploading
					}
				/>
			</div>
		</div>
	);
}

export default AddLanguage;
