import { useRef } from "react";

import * as Variable from "../../../shared/utils/variables";
import Input from "../../../shared/component/InputComponent/Input";
import { PiTrash } from "react-icons/pi";
import Select from "../../../shared/component/SelectComponent/Select";
import Button from "../../../shared/component/ButtonComponent/Button";
import { PlusIcon } from "../../../shared/component/svg/Icons";
import FileCard from "../../../shared/component/CardComponent/FileCard";
import { getIconByName } from "../../../shared/utils/file";

function SkillCard({
	ENV_NAME,
	skill,
	onChange,
	onFileChange,
	showModal,
	onDeleteSkill,
	isUploading,
}) {
	const LEVELS = [
		{ id: "beginner", name: Variable.BEGINNER_LABEL[ENV_NAME] },
		{ id: "intermediate", name: Variable.INTERMEDIATE_SKILL_LABEL[ENV_NAME] },
		{ id: "advance", name: Variable.ADVANCE_LABEL[ENV_NAME] },
	];

	const uploadRef = useRef(null);

	return (
		<div className="skill-card">
			<div className="form-row">
				<Input
					label={Variable.SKILL[ENV_NAME]}
					placeholder={Variable.SKILL_PLACEHOLDER[ENV_NAME]}
					value={skill?.name || ""}
					name="name"
					onChange={(e) => onChange(e, skill?.id)}
					inputType="label"
					className="w-100"
				/>
				<Select
					label={Variable.LEVEL[ENV_NAME]}
					placeholder={Variable.LEVEL[ENV_NAME]}
					name="level"
					value={skill?.level || ""}
					items={LEVELS}
					onChange={(e) => onChange(e, skill?.id)}
					searchable={false}
					noDefault
				/>

				<PiTrash
					size={60}
					color="#667085"
					className="cursorPointer"
					onClick={onDeleteSkill}
				/>
			</div>

			<div className="docs mt-4">
				<div>
					<h5 className="text-md font-weight-medium text-label-gray mb-sm">
						{Variable.DOCUMENT[ENV_NAME]}
					</h5>
					<p className="text-sm">{Variable.DOC_SKILL_SUBTITLE[ENV_NAME]}</p>
				</div>

				<div className="input-btn">
					<Button
						type="secondary"
						size="sm"
						title={
							<div className="flex-all-center gap-xxs">
								<PlusIcon
									width="1.8rem"
									height="1.8rem"
									stroke="white"
									strokeWidth="3"
								/>
								{Variable.ADD_FILE[ENV_NAME]}
							</div>
						}
						onClick={() => uploadRef?.current?.click()}
					/>
					<input
						id="upload-files"
						type="file"
						name="subjectIcon"
						className="inputfile d-none"
						onChange={(e) => {
							onFileChange(e, skill?.id);
							uploadRef.current.value = null;
						}}
						accept="image/*, application/pdf"
						ref={uploadRef}
					/>
				</div>
				{skill?.skill_document_proof.length ? (
					<div className="mt-3">
						{skill?.skill_document_proof?.map((i) => (
							<FileCard
								key={i?.id}
								title={i?.file_name}
								icon={getIconByName(i?.file_name)}
								onDelete={() => showModal(true, { ...i, skillId: skill?.id })}
								className="mb-3 fileCard"
								showGreenCheck={false}
								isUploading={isUploading}
							/>
						))}
					</div>
				) : (
					""
				)}
			</div>
		</div>
	);
}

export default SkillCard;
