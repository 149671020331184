import { useEffect, useState } from "react";
import { last } from "lodash";

import useToast from "shared/hooks/useToast";
import * as Variable from "../../shared/utils/variables";
import makeRequest from "../../shared/utils/request";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import Button from "../../shared/component/ButtonComponent/Button";
import Image from "../../shared/component/UI/Image";
import { getIconByName } from "../../shared/utils/file";

import ReviewSection from "./ReviewSection";

import "./ApplyJobModal.scss";

function ReviewForm({ ENV_NAME, userDetails, jobAppId, jumpToForm, onApply, onHide, ...props }) {
	const toast = useToast();

	const [form, setForm] = useState({});

	useEffect(() => {
		if (jobAppId) retrieveFormData();
	}, [jobAppId]);

	const retrieveFormData = async () => {
		const res = await makeRequest(
			generateRequestOptions("fetchReviewApplicationData", {
				urlParams: jobAppId,
			}),
		);

		if (res.code === 200) setForm(res.data);
		else toast.error(res.message);
	};

	const validateAndSave = async () => {
		const res = await makeRequest({
			...generateRequestOptions("submitApplication", { urlParams: jobAppId }),
			body: { stage: "submitted" },
		});

		if (res.code === 200) {
			onApply();
			onHide();
		} else toast.error(res.message);
	};

	return (
		<div className="form-container">
			<div className="contact-form review">
				<div className="body">
					{/* Contact Info */}
					<ReviewSection
						title={Variable.CONTACT_INFO[ENV_NAME]}
						onEdit={() => jumpToForm(1)}
					>
						<div className="section-info contact">
							<div className="user">
								<Image src={userDetails?.profile_picture} />
								<div className="user-details">
									<p className="name">{`${form?.first_name} ${form?.last_name}`}</p>
									<p className="email">{form?.email}</p>
									<p className="company-role">
										{form?.current_company}{" "}
										{form?.current_job_title ? <span>|</span> : ""}{" "}
										{form?.current_job_title}
									</p>
								</div>
							</div>

							<div className="user-contact mb-4">
								<p className="label">{Variable.MOBILE_NUMBER_1[ENV_NAME]}</p>
								<p className="contact">{form?.mobile_phone_number}</p>
							</div>
							<div className="user-contact">
								<p className="label">
									{Variable.ALTERNATE_MOBILE_NUMBER[ENV_NAME]}
								</p>
								<p className="contact">{form?.mobile_phone_number_2 || "-"}</p>
							</div>
						</div>
					</ReviewSection>

					{/* Resume */}
					<ReviewSection title={Variable.RESUME[ENV_NAME]} onEdit={() => jumpToForm(2)}>
						<div className="section-info resume">
							{form?.resume_file?.resume_file ? (
								<div className="file-card">
									<div className="file-name">
										<Image src={getIconByName(form?.resume_file?.file_name)} />
										<p>{form?.resume_file?.file_name}</p>
									</div>
									<div className="file-card-btns">
										<Button
											type="textGray"
											title={Variable.VIEW[ENV_NAME]}
											className="btn-view"
											onClick={() =>
												window.open(
													form?.resume_file?.resule_file,
													"_blank",
												)
											}
										/>
									</div>
								</div>
							) : (
								<p>{Variable.NOT_UPLOADED[ENV_NAME]}</p>
							)}
						</div>
					</ReviewSection>

					{/* Additional Questions */}
					{form?.pre_screening_answer?.length && (
						<ReviewSection
							title={Variable.ADDITIONAL_QUESTIONS[ENV_NAME]}
							onEdit={() => jumpToForm(3)}
						>
							<div className="section-info">
								{form?.pre_screening_answer ? (
									<>
										{form?.pre_screening_answer.map((answer) => (
											<div className="question mb-4" key={answer?.id}>
												<p className="label mb-0">
													{answer?.question_data?.question_text}
												</p>
												<p className="answer">
													{answer?.text_answer ||
														answer?.numeric_answer ||
														answer?.mcq_answer?.join(", ") ||
														(answer?.tf_answer
															? Variable.YES[ENV_NAME]
															: Variable.NO[ENV_NAME])}
												</p>
											</div>
										))}
									</>
								) : (
									<p>{`${Variable.NO[ENV_NAME]} ${Variable.ADDITIONAL_QUESTIONS[ENV_NAME]}`}</p>
								)}
							</div>
						</ReviewSection>
					)}

					{/* Pitch */}
					<ReviewSection
						title={Variable.MAKE_PITCH[ENV_NAME]}
						onEdit={() => jumpToForm(4)}
					>
						<div className="section-info">
							{form?.self_promote_note ? (
								<p>{form?.self_promote_note}</p>
							) : (
								<p className="light-text">{Variable.NOT_FILLED_YET[ENV_NAME]}</p>
							)}
						</div>
					</ReviewSection>

					{/* Cover Letter */}
					<ReviewSection
						title={Variable.COVER_LETTER[ENV_NAME]}
						onEdit={() => jumpToForm(5)}
					>
						<div className="section-info resume">
							{form?.cover_letter ? (
								<div className="file-card">
									<div className="file-name">
										<Image
											src={getIconByName(last(form?.cover_letter.split("/")))}
										/>
										<p>{last(form?.cover_letter.split("/"))}</p>
									</div>
									<div className="file-card-btns">
										<Button
											type="textGray"
											title={Variable.VIEW[ENV_NAME]}
											className="btn-view"
										/>
									</div>
								</div>
							) : (
								<p>{Variable.NOT_UPLOADED[ENV_NAME]}</p>
							)}
						</div>
					</ReviewSection>
				</div>
			</div>
			<div className="btn-grp">
				<div className="flex-all-center gap-md w-100">
					<Button
						type="outline"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={() => props.onUpdateActiveForm(5)}
						title={Variable.BACK[ENV_NAME]}
					/>
					<Button
						type="primary"
						size="md"
						className="flex-grow-1"
						btnClassName="w-100"
						onClick={validateAndSave}
						title={Variable.SUBMIT_APPLICATION[ENV_NAME]}
					/>
				</div>
			</div>
		</div>
	);
}

export default ReviewForm;
