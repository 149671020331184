import { useEffect, useState } from "react";
import { isEmpty, orderBy } from "lodash";

import useToast from "shared/hooks/useToast";
import * as Variable from "../../../shared/utils/variables";
import makeRequest from "../../../shared/utils/request";
import { generateRequestOptions } from "../../../shared/utils/apiEndPoints";
import Select from "../../../shared/component/SelectComponent/Select";
import { COUNTRY_LIST } from "../../../shared/utils/dataOptions";
import Input from "../../../shared/component/InputComponent/Input";
import Button from "../../../shared/component/ButtonComponent/Button";
import useUserDetails from "../../../shared/hooks/userDetails";
import { isValueAllowed } from "shared/utils/numberUtils";

function Location({ ENV_NAME, userId, onNext }) {
	const toast = useToast();

	const regionNames = new Intl.DisplayNames([ENV_NAME === "bhs" ? "id" : "en"], {
		type: "region",
	});
	const userDetails = useUserDetails(userId);
	const [form, setForm] = useState({
		country: "",
		city: "",
		street_address: "",
		postal_code: "",
		nationality: "",
	});
	const [errors, setErrors] = useState({});
	const [isEdited, setIsEdited] = useState(false);
	const [countryList, setCountryList] = useState(null);

	useEffect(() => {
		if (ENV_NAME) {
			setCountryList(
				orderBy(
					COUNTRY_LIST?.map((i) => ({ id: i, name: regionNames?.of(i) })),
					"name",
				),
			);
		}
	}, [ENV_NAME]); // eslint-disable-line

	useEffect(() => {
		if (!isEmpty(userDetails)) {
			const { country, city, street_address, postal_code, nationality } =
				userDetails?.personal_info;

			setForm((prev) => ({
				...prev,
				country,
				city,
				street_address,
				postal_code,
				nationality,
			}));
		}
	}, [userDetails]);

	const onChange = (e, type = null) => {
		const { name, value } = e.target;

		if (type === "numberWithoutDecimal")
			setForm((prev) => ({
				...prev,
				[name]: isValueAllowed(value, type) ? value : prev[name],
			}));
		else
			setForm((prev) => ({
				...prev,
				[name]: value,
			}));

		setIsEdited(true);
		setErrors((prev) => ({ ...prev, [name]: false }));
	};

	const handleSave = async () => {
		const { country, city, street_address, postal_code, nationality } = form;

		const errorsData = {};
		if (!country) errorsData["country"] = true;
		if (!city) errorsData["city"] = true;
		if (!nationality) errorsData["nationality"] = true;

		const hasErrors = Object.values(errorsData).some((err) => err);
		if (hasErrors) return setErrors(errorsData);

		// TODO: if the user haven't edited the correctly filled form
		// then instead of making api call simply move to the next form
		if (!hasErrors && !isEdited) return onNext();

		const res = await makeRequest({
			...generateRequestOptions("updateProfile"),
			data: {
				country,
				city,
				street_address,
				postal_code,
				nationality,
			},
		});

		if (res.code === 200) onNext();
		else toast.error(res.message);
	};
	return (
		<>
			<div className="am-form">
				<Select
					label={Variable.COUNTRY[ENV_NAME]}
					placeholder={Variable.COUNTRY[ENV_NAME]}
					name="country"
					value={form?.country || ""}
					items={countryList}
					onChange={onChange}
					searchable={false}
					required
					noDefault
					isError={errors["country"]}
				/>
				<Input
					label={Variable.CITY[ENV_NAME]}
					placeholder={Variable.INPUT[ENV_NAME] + " " + Variable.CITY[ENV_NAME]}
					value={form?.city || ""}
					name="city"
					inputType="label"
					className="w-100"
					isError={errors["city"]}
					onChange={onChange}
					required
				/>
				<Input
					label={Variable.STREET_ADDRESS[ENV_NAME]}
					placeholder={Variable.INPUT[ENV_NAME] + " " + Variable.STREET_ADDRESS[ENV_NAME]}
					value={form?.street_address || ""}
					name="street_address"
					inputType="label"
					className="w-100"
					onChange={onChange}
				/>
				<Input
					label={Variable.POSTAL_CODE[ENV_NAME]}
					placeholder={Variable.INPUT[ENV_NAME] + " " + Variable.POSTAL_CODE[ENV_NAME]}
					value={form?.postal_code || ""}
					name="postal_code"
					inputType="label"
					className="w-100"
					onChange={(e) => onChange(e, "numberWithoutDecimal")}
				/>
				<Select
					label={Variable.NATIONALITY[ENV_NAME]}
					placeholder={Variable.NATIONALITY[ENV_NAME]}
					value={form?.nationality || ""}
					name="nationality"
					items={countryList}
					onChange={onChange}
					isError={errors["nationality"]}
					searchable={false}
					required
					noDefault
				/>
			</div>
			<Button
				type="primary"
				size="sm"
				title={Variable.NEXT[ENV_NAME]}
				className="mt-5"
				onClick={handleSave}
				disabled={!form?.country || !form?.city || !form?.nationality}
			/>
		</>
	);
}

export default Location;
