import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link, useHistory } from "react-router-dom";

import * as Variable from "../../shared/utils/variables.js";
import * as RoutePath from "../../shared/utils/routeLink.js";

import Image from "../../shared/component/UI/Image";
import homepage from "../../assets/svg/homepage.svg";
import facebook from "../../assets/svg/facebook.svg";
import instagram from "../../assets/svg/instagram.svg";
import youtube from "../../assets/svg/youtube.svg";
import tiktok from "../../assets/svg/tiktok.svg";

import MapPin from "../../assets/svg/map-pin.svg";
import Mail from "../../assets/svg/mail.svg";
import Whatsapp from "../../assets/svg/whatsapp-icon.svg";

import "./FooterComponent.scss";

/**
 *
 * @param {{ENV_NAME: string, footerType?: 'minimal'}} param0
 * @returns
 */
const FooterComponent = ({ ENV_NAME, footerType }) => {
	const history = useHistory();

	const footerMenu = (
		<div className={`fc-early ${footerType === "minimal" ? "d-none" : ""}`}>
			<div className="fc-early-left">
				<Image src={homepage} className="footer-logo" />

				<div className="social-icons show-desktop hidden">
					<a
						href="https://www.facebook.com/profile.php?id=61551744644747"
						target="_blank"
						rel="noreferrer"
					>
						<Image src={facebook} className="icon" />
					</a>
					<a
						href="https://www.instagram.com/portalkerjaid/"
						target="_blank"
						rel="noreferrer"
					>
						<Image src={instagram} className="icon" />
					</a>
					<a
						href="https://www.youtube.com/channel/UCoUw8KDZmWtL9ctxa6jLbRw"
						target="_blank"
						rel="noreferrer"
					>
						<Image src={youtube} className="icon" />
					</a>
					<a href="https://www.tiktok.com/@portalkerja" target="_blank" rel="noreferrer">
						<Image src={tiktok} className="icon" />
					</a>
				</div>
			</div>
			<div className="fc-early-mid">
				<h4>Site Links</h4>
				<ul className="fc-early-mid-list">
					<li onClick={() => history.push(RoutePath.JOB_SEARCH)}>
						{Variable.JOBS_LABEL[ENV_NAME]}
					</li>
					<li>{Variable.COMPANY[ENV_NAME]}</li>
					<li onClick={() => history.push(RoutePath.PRIVACY_POLICY)}>
						{Variable.PRIVACY_POLICY[ENV_NAME]}
					</li>
					<li onClick={() => history.push(RoutePath.TERMS_OF_USE)}>
						{Variable.TNC[ENV_NAME]}
					</li>
				</ul>
			</div>
			<div className="fc-early-right">
				<h4>{Variable.CONTACT_US[ENV_NAME]}</h4>
				<div>
					<Image src={MapPin} className="icon icon-pin" />
					<address>
						Kantor Utama (Main Office) <br />
						Tokopedia Tower Lantai 35 <br />
						Jl. Prof. Dr. Satrio Kav. 11 <br />
						Jakarta Selatan 12950
					</address>
				</div>
				<div>
					<Image src={Mail} className="icon" />
					<address>info@portalkerja.co.id</address>
				</div>
				<div>
					<Image src={Whatsapp} className="icon" />
					<address>(+62) 811-1002-0977</address>
				</div>
			</div>
		</div>
	);

	return (
		<div className={`footer-component ${footerType}`}>
			<div className="fc container">
				{footerType !== "minimal" ? footerMenu : null}
				{footerType !== "minimal" ? <div className="fc-line" /> : null}
				<div className="fc-line"></div>
				<div className="fc-last">
					<div className="social-icons show-mobile hidden">
						<Image src={facebook} className="icon" alt="Facebook" title="Facebook" />
						<Image src={instagram} className="icon" alt="Instagram" title="Instagram" />
						<Image src={youtube} className="icon" alt="Youtube" title="Youtube" />
						<Image src={tiktok} className="icon" alt="TikTok" title="TikTok" />
					</div>
					<div className="fc-last-text text-left">
						&copy; 2023 Portal Kerja. All rights reserved.
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(FooterComponent));
