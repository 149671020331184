import { useState, useEffect, useRef } from "react";

import useToast from "shared/hooks/useToast";
import * as Variable from "../../../shared/utils/variables";
import makeRequest from "../../../shared/utils/request";
import { generateRequestOptions } from "../../../shared/utils/apiEndPoints";
import Image from "../../../shared/component/UI/Image";
import Button from "../../../shared/component/ButtonComponent/Button";
import { PlusIcon } from "../../../shared/component/svg/Icons";
import ModalInfo from "../../../shared/component/ModalComponent/ModalInfo";

import SkillCard from "./SkillCard";

import RemoveMediaIcon from "../../../assets/image/remove-media.png";
import WarningIcon from "../../../assets/svg/red_warning.svg";
import { formatString } from "shared/utils/string";

const SKILL_OBJ = {
	id: Date.now(), // TODO: manually add the ID for new skills
	name: "",
	level: "",
	skill_document_proof: [],
	notInDB: "yes", // can be any truthy value. Indicate the skill is not yet saved
};

function AddSkill({ ENV_NAME, onNextStep, onComplete, onSkip }) {
	const toast = useToast();

	const [skills, setSkills] = useState([]);
	const [monitorSkills, setMonitorSkills] = useState([]);
	const [showSkip, setShowSkip] = useState(false);
	const [isEdited, setIsEdited] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [showDeleteDialog, setShowDeleteDialog] = useState({
		show: false,
		data: null,
	});
	const containerRef = useRef(null);

	useEffect(() => {
		listSkills();
	}, []);

	const listSkills = async () => {
		const res = await makeRequest(generateRequestOptions("listSkills"));

		if (res.code === 200) {
			setSkills([...res.data, { ...SKILL_OBJ, id: Date.now() }]);
		} else toast.error(res.message);
	};

	const prepareFormData = (skills, isEdit) =>
		skills.map((skill) => {
			const formData = new FormData();
			formData.append("id", skill?.id);
			formData.append("name", skill?.name);
			formData.append("level", skill?.level);

			if (!isEdit)
				skill?.skill_document_proof.forEach((doc) =>
					formData.append("skill_document_proof", doc.skill_document_proof),
				);

			return formData;
		});

	const handleSave = async () => {
		const newSkills = prepareFormData(
			skills.filter((skill) => monitorSkills.includes(skill?.id) && skill?.notInDB),
		);

		const existingSkills = prepareFormData(
			skills.filter((skill) => monitorSkills.includes(skill?.id) && !skill?.notInDB),
			true,
		);

		const files = skills
			.filter((skill) => monitorSkills.includes(skill?.id) && !skill?.notInDB)
			.map((skill) => {
				const formData = new FormData();

				skill?.skill_document_proof.forEach((doc) => {
					if (typeof doc.skill_document_proof !== "string") {
						formData.append("skill_document_proof", doc.skill_document_proof);
						formData.append("skill", skill?.id);
					}
				});

				return formData;
			});

		// TODO: Create new Skills
		if (newSkills.length) {
			const skillPromises = newSkills.map((d) => createEditSkill(d));
			await Promise.all(skillPromises);
		}

		// TODO: Update Exisitng skills
		if (existingSkills.length) {
			const skillPromises = existingSkills.map((d) => createEditSkill(d, true));
			await Promise.all(skillPromises);

			const isEmptyFiles = files.some((file) => !file.get("skill_document_proof"));

			// TODO: Update the skill documents
			if (!isEmptyFiles) {
				const uploadPromises = files.map((file) => {
					return handleUploadDocument(file);
				});

				await Promise.all(uploadPromises);
			}
		}
	};

	const createEditSkill = async (data, isEdit = false) => {
		setIsSaving(true);
		let url = generateRequestOptions("createSkill");
		if (isEdit) url = generateRequestOptions("editSkill", { urlParams: data.get("id") });

		const res = await makeRequest({
			...url,
			data,
		});

		if (res.code === 200) {
			setIsEdited(false);
			setIsSaved(true);
			setMonitorSkills([]);
		} else {
			setIsSaved((prevIsSaved) => !prevIsSaved);
			toast.error(res.message);
		}
		setIsSaving(false);
	};

	const handleUploadDocument = async (file) => {
		const res = await makeRequest({
			...generateRequestOptions("createSkillDocument"),
			data: file,
		});

		if (res.code === 200) {
			setMonitorSkills([]);
			setIsEdited(false);
			setIsSaved(true);
		} else {
			setIsSaved((prevIsSaved) => !prevIsSaved);
			toast.error(res.message);
		}
	};

	const onChange = (e, skillId) => {
		const { name, value } = e.target;
		const skillsClone = [...skills];
		const skill = skillsClone.find((s) => s?.id === skillId);
		skill[name] = value;

		setSkills(skillsClone);
		setMonitorSkills((prev) => [...new Set([...prev, skillId])]);
		setIsEdited(true);
	};

	const onFileChange = async (e, skillId) => {
		console.log("Uploading File");
		const file = e.target.files[0];
		const fileObj = {
			skill_document_proof: file,
			file_name: file?.name,
			id: Date.now(),
		};
		const skillsClone = [...skills];
		const skill = skillsClone.find((s) => s.id === skillId);
		if (skill.skill_document_proof) skill.skill_document_proof.push(fileObj);
		else skill.skill_document_proof = [{ ...fileObj }];

		setSkills(skillsClone);
		setMonitorSkills((prev) => [...new Set([...prev, skillId])]);
		setIsEdited(true);
	};

	const handleAddSKill = () => {
		setSkills((prev) => [...prev, { ...SKILL_OBJ, id: Date.now() }]);
	};

	const handleDeleteSkill = async (skillId) => {
		// TODO: Handle for delete skill api call
		const skill = skills.find((skill) => skill.id === skillId);
		const remainingSkills = skills.filter((skill) => skill.id !== skillId);

		// TODO: handle deletion locally
		if (skill?.notInDB) {
			setSkills(remainingSkills);
			return;
		}

		const res = await makeRequest(
			generateRequestOptions("deleteSkill", { urlParams: skillId }),
		);

		if (res.code === 200) setSkills(remainingSkills);
		else toast.error(res.message);
	};

	const filterRemainingDocs = (skillId, docId) => {
		if (!docId) return;
		const skillsClone = [...skills];
		const skill = skillsClone.find((s) => s.id === skillId);
		skill["skill_document_proof"] = skill?.skill_document_proof?.filter(
			(file) => file.id !== docId,
		);

		setSkills(skillsClone);
	};

	const handleDeleteDocument = async () => {
		// There will be two kinds of delete
		// if there exists an experience for the document proof, then call the API
		// otherwise, filter out the documents
		const skillId = showDeleteDialog?.data?.skillId;
		const docId = showDeleteDialog?.data?.id;
		const skill = skills.find((skill) => skill.id === skillId);

		const documentProof = skill.skill_document_proof.find((doc) => doc.id === docId);

		if (documentProof?.skill) {
			const res = await makeRequest(
				generateRequestOptions("deleteSkillDocument", {
					urlParams: docId,
				}),
			);

			if (res.code === 200) {
				filterRemainingDocs(skill?.id, docId);
				setShowDeleteDialog({ show: false, data: null });
			} else toast.error(res.message);
		} else {
			filterRemainingDocs(skill?.id, docId);
			setShowDeleteDialog({ show: false, data: null });
		}
	};

	const checkDisabled = () => {
		const isDisabled = skills.some(
			(skill) =>
				!skill?.name || !skill?.level || (isSaving && monitorSkills.includes(skill?.id)),
		);

		return isDisabled;
	};

	return (
		<>
			<div className="skills mt-5">
				<div className="skills-container" ref={containerRef}>
					{skills.map((skill, idx) => (
						<SkillCard
							key={idx}
							ENV_NAME={ENV_NAME}
							skill={skill}
							onFileChange={onFileChange}
							onChange={onChange}
							showModal={(show, data) => setShowDeleteDialog({ show, data })}
							onDeleteSkill={() => handleDeleteSkill(skill?.id)}
							isUploading={isSaving && monitorSkills.includes(skill?.id)}
						/>
					))}
				</div>

				<Button
					type="textGray"
					size="sm"
					title={
						<div className="flex-all-center gap-xxs">
							<PlusIcon
								width="1.8rem"
								height="1.8rem"
								stroke="#193560"
								strokeWidth="3"
							/>
							{Variable.ADD_SKILL[ENV_NAME]}
						</div>
					}
					color="#193560"
					btnClassName="p-0 mt-3"
					onClick={handleAddSKill}
				/>
			</div>

			{/* Delete Modal */}
			<ModalInfo
				isShow={showDeleteDialog?.show}
				onHide={() => setShowDeleteDialog({ show: false, data: null })}
				onConfirm={handleDeleteDocument}
				type="delete"
				customIcon={<Image src={RemoveMediaIcon} className="modal-image-icon" />}
			/>

			{/* Skip Modal */}
			<ModalInfo
				isShow={showSkip}
				onHide={() => setShowSkip(false)}
				onConfirm={() => {
					onSkip();
					onNextStep();
				}}
				type="other"
				customIcon={<Image src={WarningIcon} className="modal-image-icon" />}
				title={Variable.SURE_TO_SKIP[ENV_NAME]}
				description={
					formatString(
						Variable.SKIP_DESC[ENV_NAME],
						Variable.SKILLS[ENV_NAME].toLowerCase(),
					) +
					" " +
					Variable.HISTORY[ENV_NAME]
				}
				confirmLabel={Variable.SKIP_BTN_TITLE[ENV_NAME]}
			/>

			<div className="d-flex align-items-center">
				<Button
					type="outline"
					size="md"
					title={Variable.SKIP_STEP[ENV_NAME]}
					className="mt-5 me-4"
					btnClassName="btn-initial-profile"
					onClick={() => setShowSkip(true)}
				/>
				<Button
					type="primary"
					size="sm"
					title={
						skills?.length
							? Variable.SAVE[ENV_NAME] + " & " + Variable.NEXT_STEP[ENV_NAME]
							: Variable.SAVE[ENV_NAME]
					}
					className="mt-5"
					btnClassName="btn-initial-profile"
					onClick={async () => {
						await handleSave();
						onComplete();
						onNextStep();
					}}
					disabled={checkDisabled()}
				/>
			</div>
		</>
	);
}

export default AddSkill;
